<template>
    <div class="team-form">
        <div v-for="match in matches">
            <span v-if="match.goalshome == match.goalsguest" class="team-form-item team-form-item-draw">U</span>
            <span v-else-if="match.fk_team_home == teamId && match.goalshome < match.goalsguest" class="team-form-item team-form-item-lost">N</span>
            <span v-else-if="match.fk_team_home == teamId && match.goalshome > match.goalsguest" class="team-form-item team-form-item-won">S</span>
            <span v-else-if="match.fk_team_guest == teamId && match.goalshome < match.goalsguest" class="team-form-item team-form-item-won">S</span>
            <span v-else-if="match.fk_team_guest == teamId && match.goalshome > match.goalsguest" class="team-form-item team-form-item-lost">N</span>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'ResultTrendBox',
    props: {
      matches: {
        type: Array,
        default: () => {
          return []
        }
      },
      teamId: {
        type: Number,
        default: () => {
          return 0
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .team-form {
        display: flex;

        &-item {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            color: $colorWhite;
            width: 24px;
            height: 24px;
            border-radius: 4px;
            margin: 0 2px;

            @media screen and (max-width: 320px) {
              width: 22px;
              height: 22px;
              margin: 1px 2px;
            }

            &-won {
                background: $colorPositive;
            }
            &-draw {
                background-color: $colorGreyMid;
            }
            &-lost {
                background-color: $colorNegative;
            }
        }
    }
</style>
