import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger';
import axios from 'axios'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import VuexPersistence from 'vuex-persist'
import APIService from '@/services/APIService'
import { isNumeric } from '@/vuex/helpers'
import {
    TOP_TEN,
    MATCH_DATE_FORMAT,
    PADDING_BOTTOM_DEFAULT,
    PADDING_BOTTOM_DSP,
    PADDING_BOTTOM_FUNKE,
    API_TIMEOUT,
    DEFAULT_BET, DASHBOARD_RANKINGS_SIZE
} from '../main'
import moment from 'moment'
import { isToday } from './helpers'
/*
import Crypto from 'crypto-js';
import Cookie from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
*/

Vue.use(Vuex)

/*
const cookieName = 'spmcn';
const storageKey = 'spmsk';
// Get the encryption token from cookie or generate a new one.
const encryptionToken = Cookie.get(cookieName) || uuidv4();
// Store the encryption token in a secure cookie.
Cookie.set(cookieName, encryptionToken, process.env.NODE_ENV === 'production' ? { sameSite: 'lax', secure: true, expires: 360 } : { expires: 360 });
const vuexLocal = new VuexPersistence({
    storage: {
        getItem: () => {
            // Get the store from local storage.
            const store = window.localStorage.getItem(storageKey);
            
            if (store) {
                try {
                    // Decrypt the store retrieved from local storage
                    // using our encryption token stored in cookies.
                    const bytes = Crypto.AES.decrypt(store, encryptionToken);
                    
                    return JSON.parse(bytes.toString(Crypto.enc.Utf8));
                } catch (e) {
                    // The store will be reset if decryption fails.
                    window.localStorage.removeItem(storageKey);
                }
            }
            
            return null;
        },
        setItem: (key, value) => {
            // Encrypt the store using our encryption token stored in cookies.
            const store = Crypto.AES.encrypt(value, encryptionToken).toString();
            
            // Save the encrypted store in local storage.
            return window.localStorage.setItem(storageKey, store);
        },
        removeItem: () => window.localStorage.removeItem(storageKey),
    },
})
*/

const vuexLocal = new VuexPersistence({
    key: 'spmvx',
    storage: window.localStorage
})

function setBGValue(key, value) {
    window.localStorage.setItem(key, value)
}

function getBGValue(key) {
    return window.localStorage.getItem(key)
}

export default new Vuex.Store({
    state: {
        paddingBottom: PADDING_BOTTOM_DEFAULT,
        tournamentRounds: [],
        tournamentGroups: [],
        tournamentMatches: [],
        tournamentGroupTables: [],
        matches: [],
        bets: [],
        allbets: [],
        matchdayCount: 0,
        currentMatchdayBet: 1,
        currentMatchdayRankingByMatchday: 1,
        matchdayRankingSorting: 1,
        positions: [],
        rankings: [],
        liveRankings: [],
        liveRankingsByMatchday: [],
        teams: [],
        betgame: null,
        matchDayWins: [],
        lastBetSaveSuccess: null,
        lastAnswerSaveSuccess: null,
        userRanking: null,
        userLiveRanking: null,
        userMatchdayRankings: [],
        userNeighbourRankings: null,
        matchdayRankings: [],
        betgameId: null,
        userSource: null,
        apiUrl: null,
        userBetgames: [],
        leagueId: null,
        currentSeason: { id: 15, name: '2024/25', is_current: true },
        runningMatches: [],
        todaysMatches: [],
        matchBets: [],
        user: null,
        token: null,
        betgames_owner: null,
        betgames_author: null,
        betgames_participant: null,
        missing_bets: null,
        questions: null,
        solutions: null,
        answers: null,
        updateAnswerCount: true,
        publicBetgames: [],
        sidebarComponentKey: 0,
        userCount: -1,
        betCount: -1,
        incomingBetgameTransfers: [],
        updateUserBetgames: true,
        last_token_refresh_check: null,
        last_token_refresh: null,
        lastFetchSchedule: null,
        lastRoute: null,
        betgameUsers: [],
        language: (navigator.language.split('-')[0] === 'de' ? 'de' : 'en') || 'en',
        geoData: null,
        betgameSlides: [],
        betgameAds: []
    },
    mutations: {
        SET_BETGAME_ADS(state, data) {
            state.betgameAds = data
        },
        SET_BETGAME_SLIDES(state, data) {
            state.betgameSlides = data
        },
        SET_USER_AVATAR(state, avatar) {
            state.user.avatar = avatar
        },
        SET_GEO_DATA(state, data) {
            state.geoData = data
        },
        SET_LANGUAGE(state, lang) {
            state.language = lang
            if(state.user) {
                state.user.lang = lang
            }
        },
        SET_BETGAME_ICON(state, icon) {
            state.betgame.icon = icon
        },
        SET_BETGAME_INVITATION_CODE(state, code) {
            state.betgame.invitation_code = code
        },
        SET_BETGAME_USERS(state, data) {
            state.betgameUsers = data
        },
        SET_LAST_ROUTE(state, data) {
            state.lastRoute = data
        },
        SET_LAST_FETCH_SCHEDULE(state, data) {
            state.lastFetchSchedule = data
        },
        SET_LAST_TOKEN_REFRESH_CHECK(state, data) {
            state.last_token_refresh_check = data
        },
        SET_LAST_TOKEN_REFRESH(state, data) {
            state.last_token_refresh = data
        },
        SET_TOURNAMENT_GROUP_TABLESS(state, data) {
            state.tournamentGroupTables = data
        },
        SET_TOURNAMENT_GROUPS(state, data) {
            state.tournamentGroups = data
        },
        SET_TOURNAMENT_MATCHES(state, data) {
            state.tournamentMatches = data
        },
        SET_TOURNAMENT_ROUNDS(state, data) {
            const t = data.sort(function(a, b) {
                return a.sorting - b.sorting
            })
            state.tournamentRounds = t
        },
        SET_UPDATE_ANSWER_COUNT(state, doUpdate) {
            state.updateAnswerCount = doUpdate
        },
        SET_UPDATE_USER_BETGAMES(state, doUpdate) {
            state.updateUserBetgames = doUpdate
        },
        SET_BETGAME_COMPETITION(state, data) {
            state.betgame.competition = data
        },
        SET_MISSING_BETS(state, data) {
            state.missing_bets = data
        },
        SET_INCOMING_BETGAME_TRANSFERS(state, transfers) {
            state.incomingBetgameTransfers = transfers
        },
        SET_BETGAME_TRANSFER(state, transfer) {
          state.betgame.transfer = transfer
        },
        SET_USER_COUNT (state, count) {
            state.userCount = count
        },
        SET_BET_COUNT (state, count) {
            state.betCount = count
        },
        SET_SIDEBAR_COMPONENT_KEY (state, data) {
            state.sidebarComponentKey = data
        },
        SET_PUBLIC_BETGAMES (state, data) {
            state.publicBetgames = data
        },
        SET_QUESTIONS (state, data) {
            for (var i = 0; i < data.length; i++) {
                var d = data[i]
                if(d.type === 'TEAM') {
                    if (d.options !== null && d.options !== undefined) {
                        try {
                            d.optionsJSON = JSON.parse(d.options.replace(/^\s+|\s+$/g, ''))
                            d.optionsJSON.sort(function(a,b){
                                var x = a.name < b.name? -1:1;
                                return x;
                            })
                        } catch (e) {
                            d.optionsJSON = []
                        }
                    } else {
                        d.optionsJSON = []
                    }
                }
                data[i] = d
            }
            state.questions = data
        },
        SET_ANSWERS (state, data) {
            state.answers = data
        },
        SET_SOLUTIONS (state, data) {
            state.solutions = data
        },
        SET_BETGAMES_OWNER (state, data) {
            state.betgames_owner = data.sort((a, b) => a.id - b.id)
        },
        ADD_BETGAMES_OWNER (state, data) {
            state.betgames_owner.push(data)
            state.betgames_owner = state.betgames_owner.sort((a, b) => a.id - b.id)
        },
        SET_BETGAMES_AUTHOR (state, data) {
            state.betgames_author = data
        },
        SET_BETGAMES_PARTICIPANT (state, data) {
            state.betgames_participant = data
        },
        SET_MATCHDAY_RANKING_SORTING (state, sorting) {
            state.matchdayRankingSorting = sorting
        },
        SET_PADDING_BOTTOM (state, data) {
            state.paddingBottom = data
        },
        SET_MATCH_BETS (state, data) {
            state.matchBets = data
        },
        SET_TODAYS_MATCHES (state, season) {
            state.todaysMatches = season
        },
        SET_RUNNING_MATCHES (state, season) {
            state.runningMatches = season
        },
        SET_CURRENT_SEASON (state, season) {
            state.currentSeason = season
        },
        SET_LEAGUE_ID (state, league) {
            state.leagueId = league
        },
        SET_USER_BETGAMES (state, betgames) {
            state.userBetgames = betgames
        },
        SET_BETGAME_ID (state, id) {
            state.betgameId = id
        },
        SET_USER_SOURCE (state, src) {
            state.userSource = src
        },
        SET_API_URL (state, url) {
            state.apiUrl = url
        },
        SET_USER_NEIGHBOUR_RANKINGS (state, rankings) {
            state.userNeighbourRankings = rankings
        },
        SET_USER_RANKING (state, ranking) {
            state.userRanking = ranking[0]
        },
        SET_RANKINGS (state, rankings) {
            state.rankings = rankings
        },
        SET_USER_LIVE_RANKING (state, ranking) {
            state.userLiveRanking = ranking[0]
        },
        SET_LIVE_RANKINGS_BY_MATCHDAY (state, params) {
            if(params.length === 2) {
                Vue.set(state.liveRankingsByMatchday, params[0], params[1])
                //state.liveRankings = rankings
            } else {
                state.liveRankingsByMatchday = []
            }
        },
        SET_LIVE_RANKINGS (state, rankings) {
            state.liveRankings = rankings
        },
        //ADD_LIVE_RANKINGS (state, rankings) {
        //    state.liveRankings = state.liveRankings.concat(rankings)
        //},
        UPDATE_BET (state, betData) {
            const oldBet = state.bets.filter(bet => bet.fk_user === betData.fk_user && bet.fk_match === betData.fk_match && bet.fk_betgame === betData.fk_betgame)
            if(oldBet != null && oldBet.length === 1) {
                oldBet[0].goalshome = betData.goalshome
                oldBet[0].goalsguest = betData.goalsguest
            }
            state.bets = state.bets.filter(function (item) {
                return true
            })
        },
        SET_BETS (state, betData) {
            /*
            for (var i = 0; i < betData.length; i++) {
                var bet = betData[i]
                bet.oldGoalsHome = bet.goalshome
                bet.oldGoalsGuest = bet.goalsguest
            }
             */
            state.bets = betData
        },
        SET_ALL_BETS (state, betData) {
            state.allbets = betData
            //Vue.set(state, 'allbets', betdata)
        },
        /*
        ADD_ALL_BETS (state, betData) {
            state.allbets = state.allbets.concat(betData)
        },
        */
        ADD_BET (state, betData) {
            state.bets.push(betData)
        },
        SET_TABLE (state, tableData) {
            state.positions = tableData
        },
        SET_TEAMS (state, teamData) {
            state.teams = teamData
        },
        SET_SCHEDULE (state, scheduleData) {
            state.matches = scheduleData
        },
        SET_CURRENT_MATCHDAY_BET (state, day) {
            state.currentMatchdayBet = day
        },
        SET_CURRENT_MATCHDAY_RANKING (state, day) {
            state.currentMatchdayRankingByMatchday = day
        },
        SET_MATCHDAY_COUNT (state, count) {
            state.matchdayCount = count
        },
        SET_USER_DATA (state, userData) {
            state.user = userData
        },
        SET_USER_STATUS (state, status) {
            const u = state.user
            u.status = status
            state.user = u
        },
        SET_TOKEN (state, data) {
            const payload = jwt_decode(JSON.stringify(data))
            var token = data[0].token
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            state.token = token
            state.user = payload
            if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                setBGValue('user', JSON.stringify(payload))
                setBGValue('token', token)
            }
            if(payload.lang !== null && payload.lang !== undefined && payload.lang.length > 1) {
                if(payload.lang !== state.language) {
                    state.language = payload.lang
                }
            }
        },
        SET_TOKEN_LOCAL (state, token) {
            const payload = jwt_decode(JSON.stringify(token))
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            state.token = token
            state.user = payload
            if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                setBGValue('user', JSON.stringify(payload))
                setBGValue('token', token)
            }
            if(payload.lang !== null && payload.lang !== undefined && payload.lang.length > 1) {
                if(payload.lang !== state.language) {
                    state.language = payload.lang
                }
            }
        },
        CLEAR_USER_DATA (state) {
            state.token = null
            state.user = null
            state.geoData = null
        },
        SET_BETGAME (state, data) {
            Vue.$log.debug('store SET_BETGAME ' + data.id)
            state.betgame = data
        },
        SET_MATCHDAY_WINS (state, data) {
            state.matchDayWins = data
        },
        UPDATE_USER_EMAIL(state, data) {
            state.user.email = data.email_new
        },
        UPDATE_USER_NICKNAME(state, data) {
            state.user.nickname = data.nickname
        },
        UPDATE_USER_NOTIFICATIONS(state, data) {
            state.user.push_notifications = data.push_notifications
            state.user.email_notifications = data.email_notifications
        },
        UPDATE_USER(state, data) {
            Object.keys(data).forEach(key => state.user[key] = data[key])
        },
        UPDATE_BET_GOALS_HOME(state, data) {
            const userbets = this.state.bets.filter(bet => bet.fk_user === state.user.id && bet.fk_match === data.fk_match && bet.fk_betgame === data.fk_betgame)
            if (userbets.length > 0) {
                const userbet = userbets[0]
                userbet.goalshome = data.goalshome
            }
        },
        UPDATE_BET_GOALS_GUEST(state, data) {
            const userbets = this.state.bets.filter(bet => bet.fk_user === state.user.id && bet.fk_match === data.fk_match && bet.fk_betgame === data.fk_betgame)
            if (userbets.length > 0) {
                const userbet = userbets[0]
                userbet.goalsguest = data.goalsguest
            }
        },
        SET_LAST_BET_SAVE_DATE (state, date) {
            state.lastBetSaveSuccess = date
        },
        SET_LAST_ANSWER_SAVE_DATE (state, date) {
            state.lastAnswerSaveSuccess = date
        },
        SET_MATCHDAY_RANKINGS (state, rankings) {
            if(rankings.length > 0) {
                const md = rankings[0].matchday
                Vue.set(state.matchdayRankings, md, rankings)
            } else {
                state.matchdayRankings = []
            }
        },
        SET_USER_MATCHDAY_RANKINGS (state, rankings) {
            if(rankings.length > 0) {
                for (var i = 0; i < rankings.length; i++) {
                    const md = rankings[i].matchday
                    Vue.set(state.userMatchdayRankings, md, [rankings[i]])
                }
            } else {
                state.userMatchdayRankings = []
            }
        }
    },
    actions: {
        setCurrentSeason ({ commit }, season) {
            commit('SET_CURRENT_SEASON', season)  
        },
        setLanguage ({ commit }, l) {
            commit('SET_LANGUAGE', l)
        },
        uploadBetgameIcon ({ commit }, file) {
            const config = {
                headers: {
                    Authorization: 'Bearer ' + this.state.token,
                    'X-BETGAME-ID': '' + this.state.betgameId
                }
            }
            return axios.post(this.state.apiUrl + '/upload', file, config)
            .then(({ data }) => {
                commit('SET_BETGAME_ICON', data.filename)
                Vue.$log.debug('uploadBetgameIcon ' + JSON.stringify(data))
            }).catch(function (error) {
                Vue.$log.debug(error.toString())
            })
        },
        uploadUserAvatar ({ commit }, file) {
            const config = {
                headers: {
                    Authorization: 'Bearer ' + this.state.token,
                    'X-USER-ID': '' + this.state.user.id
                }
            }
            return axios.post(this.state.apiUrl + '/uploadavatar', file, config)
            .then(({ data }) => {
                commit('SET_USER_AVATAR', data.filename)
                Vue.$log.debug('uploadUserAvatar ' + JSON.stringify(data))
            }).catch(function (error) {
                Vue.$log.debug(error.toString())
            })
        },
        updateUserAvatar ({ commit }, a) {
            commit('SET_USER_AVATAR', a)
        },
        getGeoData ({ commit }, unused) {
            axios.get(this.state.apiUrl + '/geoservice', {})
            .then(({ data }) => {
                commit('SET_GEO_DATA', data)
            }).catch(function (error) {
                Vue.$log.debug(error.toString())
            })
        },
        changeLanguage ({ commit }, lang) {
            return axios.post(this.state.apiUrl + '/rpc/update_user_lang',
              {
                  id: '' + this.state.user.id,
                  lang: lang
              }, { timeout: API_TIMEOUT, headers: { Authorization: 'Bearer ' + this.state.token } })
            .then(({ result }) => {
                commit('SET_LANGUAGE', lang)
            })
        },
        updateBetgameIcon ({ commit }, icon) {
            commit('SET_BETGAME_ICON', icon)
        },
        updateBetgameInvitationCode ({ commit }, code) {
            commit('SET_BETGAME_INVITATION_CODE', code)
        },
        addBetgamesOwner ({ commit }, data) {
            commit('ADD_BETGAMES_OWNER', data)
        },
        setLastRoute ({ commit }, r) {
            commit('SET_LAST_ROUTE', r)
        },
        setLastFetchSchedule ({ commit }, f) {
            commit('SET_LAST_FETCH_SCHEDULE', f)
        },
        setLastTokenRefreshCheck ({ commit }, c) {
            commit('SET_LAST_TOKEN_REFRESH_CHECK', c)
        },
        setLastTokenRefresh ({ commit }, r) {
            commit('SET_LAST_TOKEN_REFRESH', r)
        },
        updateUserBetgames ({ commit }, u) {
            commit('SET_UPDATE_USER_BETGAMES', u)
        },
        updateAnswerCount ({ commit }, u) {
            commit('SET_UPDATE_ANSWER_COUNT', u)
        },
        updateSidebarComponentKey ({ commit }, s) {
            commit('SET_SIDEBAR_COMPONENT_KEY', this.state.sidebarComponentKey + 1)
        },
        setMatchdayRakingSorting ({ commit }, s) {
            commit('SET_MATCHDAY_RANKING_SORTING', s)
        },
        setCurrentMatchdayBet ({ commit }, matchday) {
            commit('SET_CURRENT_MATCHDAY_BET', matchday)
        },
        setCurrentMatchdayRankingByMatchday ({ commit }, matchday) {
            commit('SET_CURRENT_MATCHDAY_RANKING', matchday)
        },
        setPaddingBottom ({ commit }, data) {
            commit('SET_PADDING_BOTTOM', data)
        },
        setUserSource ({ commit }, src) {
            commit('SET_USER_SOURCE', src)
        },
        setBetgameId ({ commit }, id) {
            commit('SET_BETGAME_ID', id)
        },
        setApiUrl ({ commit }, url) {
            commit('SET_API_URL', url)
        },
        setBetgame ({ commit }, data) {
            commit('SET_BETGAME', data)
        },
        fetchUserAnswers ({ commit }, params) {
            return new Promise((resolve, reject) => {
                APIService.getAnsers(this.state.betgameId, this.state.user.id)
                .then(response => {
                    commit('SET_ANSWERS', response.data)
                    resolve
                })
                .catch(error => {
                    reject(error)
                })
            });
        },
        fetchQuestions ({ commit }, params) {
            return new Promise((resolve, reject) => {
                APIService.getQuestions(this.state.betgameId, this.state.currentSeason.id)
                .then(response => {
                    var questionsIDs = ''
                    for (var i = 0; i < response.data.length; i++) {
                        const q = response.data[i]
                        if(parseInt(q.fk_betgame) === parseInt(this.state.betgameId)) {
                            questionsIDs += q.id + ','
                        }
                    }
                    questionsIDs += '0'
                    commit('SET_QUESTIONS', response.data)
                    APIService.getSolutions(questionsIDs)
                    .then(response1 => {
                        commit('SET_SOLUTIONS', response1.data)
                        APIService.getAnsers(this.state.betgameId, this.state.user.id)
                        .then(response2 => {
                            commit('SET_ANSWERS', response2.data)
                            resolve()
                        })
                        .catch(error2 => {
                            reject(error2)
                        })
                    })
                    .catch(error1 => {
                        reject(error1)
                    })
                })
                .catch(error => {
                    reject(error)
                })
            });
        },
        fetchBetsForMatchdayRanking ({ commit }, params) {
            if(this.state.matches === null || this.state.matches === undefined || this.state.matches.length === 0) {
                return
            }
    
            Vue.$log.debug('fetchBetsForMatchdayRanking ' + params)
            
            const betgame = params[0]
            const matchday = params[1]
            const username = params.length > 2 ? params[2] : ''
            
            APIService.getMatchdayRanking(betgame, this.state.leagueId, matchday, TOP_TEN, username)
            .then(response => {
                // commit('SET_MATCHDAY_RANKINGS', response.data)
                
                var userIDs = ''
                for (var i = 0; i <= TOP_TEN && i < this.state.liveRankings.length; i++) {
                    const r = this.state.liveRankings[i]
                    if(r.fk_user !== undefined) {
                        userIDs += r.fk_user + ','
                    }
                }
                for (var i = 0; i < response.data.length; i++) {
                    const r = response.data[i]
                    if(r !== null && r !== undefined) {
                        userIDs += r.fk_user + ','
                    }
                }
                userIDs += '0' //this.state.user.id
                
                var matchIDs = ''
                for (var i = 0; i < this.state.matches.length; i++) {
                    const m = this.state.matches[i]
                    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                    if(!timeZone) {
                        timeZone = 'Europe/Berlin'
                    }
                    const valueFromDB = moment.tz(String(m.matchdate), "Europe/Berlin")
                    const md = valueFromDB.clone().tz(timeZone)
                    if(m.matchday === matchday && ((md < moment().tz(timeZone)) || (isNumeric(m.goalshome) && isNumeric(m.goalsguest)) || (isNumeric(m.livegoalshome) && isNumeric(m.livegoalsguest)))) {                    
                        matchIDs += m.id + ','
                    }
                }
                matchIDs += '0'
                userIDs = userIDs.split(",").removeDuplicates().sort().join(",")
                matchIDs = matchIDs.split(",").removeDuplicates().sort().join(",")
                APIService.getBetsByUsersAndMatches(betgame, userIDs, matchIDs)
                .then(response => {
                    commit('SET_ALL_BETS', response.data)
                })
                .catch(error => {
                })
                
            })
            .catch(error => {
            })
        },
        fetchLiveRanking ({ commit }, params) {
    
            /*
            const lastFetch = getBGValue('lastFetchLiveRanking_' + params[0] + '_' + params[1] + '_' + params[2])
            if(lastFetch !== undefined && lastFetch !== null) {
                const duration = (new Date() - new Date(lastFetch)) / 1000
                if (duration > this.getters.getCachingDuration) {
                } else {
                    return
                }
            }
    
            setBGValue('lastFetchLiveRanking_' + params[0] + '_' + params[1] + '_' + params[2], new Date())
            */
    
            Vue.$log.debug('fetchLiveRanking ' + params)
            
            APIService.getLiveRanking(params[0], params[1], params.length > 3 ? params[3] : '', this.state.user.id)
            .then(response => {
                if(response.data !== null && response.data.length > 0) {
                    commit('SET_LIVE_RANKINGS', response.data)
                } else {
                    APIService.getRanking(params[0], params[1])
                    .then(response => {
                        commit('SET_LIVE_RANKINGS', response.data)
                    })
                    .catch(error => {
                    })
                }
            })
            .catch(error => {
            })
    
            APIService.getUserRanking(params[0], params[1], this.state.user.id)
            .then(response => {
                commit('SET_USER_RANKING', response.data)
                if(response.data !== null && response.data.length > 0) {
                    commit('SET_USER_LIVE_RANKING', response.data)
                }
                const pos = response.data && response.data.length > 0 ? response.data[0].position : DASHBOARD_RANKINGS_SIZE
                APIService.getLiveRankingsByPosition(params[0], params[1], pos)
                    .then(response => {
                        commit('SET_USER_NEIGHBOUR_RANKINGS', response.data)
                    })
                    .catch(error => {
                    })
            })
            .catch(error => {
            })
        },
        fetchMatchdayRanking ({ commit }, params) {
            
            /*
            if(this.getters.matchdayFinished(params[2])) {
                const t = this.state.matchdayRankings[params[2]]
                if(t.length > 0) {
                    return
                }
            }
            */
            
            /*
            const lastFetch = getBGValue('lastFetchMatchdayRanking_' + params[0] + '_' + params[1] + '_' +  params[2])
            if(lastFetch !== undefined && lastFetch !== null) {
                const duration = (new Date() - new Date(lastFetch)) / 1000
                if (duration > this.getters.getCachingDuration) {
                } else {
                    return
                }
            }
    
            setBGValue('lastFetchMatchdayRanking_' + params[0] + '_' + params[1] + '_' + params[2], new Date())
            */

            Vue.$log.debug('fetchMatchdayRanking ' + params)
            
            APIService.getMatchdayRanking(params[0], params[1], params[2], TOP_TEN, params.length > 3 ? params[3] : '', this.state.user.id)
            .then(response => {
                commit('SET_MATCHDAY_RANKINGS', response.data)
    
                var fetchBetsUsers = ''
                var fetchLiveRankingUsers = ''
                for (var i = 0; i < TOP_TEN && i < response.data.length; i++) {
                    const u = response.data[i].fk_user
                    const b = this.state.allbets.filter(bet => bet.fk_user === u)
                    //const r = this.state.liveRankings.filter(ranking => ranking.fk_user === u)
                    if(b === undefined || b === null || b.length === 0) {
                        fetchBetsUsers += u + ','
                    }
                    //if(r === undefined || r === null || r.length === 0) {
                        fetchLiveRankingUsers += u + ','
                    //}
                }
                
                if(fetchLiveRankingUsers !== '') {
                    fetchLiveRankingUsers += '0' //this.state.user.id
                    fetchLiveRankingUsers = fetchLiveRankingUsers.split(",").removeDuplicates().sort().join(",")
                    APIService.getLiveRankingByUsers(params[0], params[1], fetchLiveRankingUsers)
                    .then(response => {
                        commit('SET_LIVE_RANKINGS_BY_MATCHDAY', [params[2], response.data])
                    })
                    .catch(error => {
                    })
                }
            })
            .catch(error => {
            })
    
            //if(this.state.userMatchdayRankings.length === 0) {
                APIService.getMatchdayRankingsByUser(params[0], params[1], this.state.user.id)
                .then(response => {
                    if (response.data !== null) {
                        commit('SET_USER_MATCHDAY_RANKINGS', response.data)
                    }
                })
                .catch(error => {
                })
            //}
            
        },
        fetchBets ({ commit }, userId) {
            APIService.getBets(this.state.betgameId, userId)
                    .then(response => {
                        commit('SET_BETS', response.data)
                    })
                    .catch(error => {
                    })
        },
        fetchUserBetgames ({ commit }, userId) {
            APIService.getUserBetgames(userId)
            .then(response => {
                const bg1 = response.data.betgames_owner
                const bg2 = response.data.betgames_author
                const bg3 = response.data.betgames_participant
                const missing_bets = response.data.missing_bets
                const uniqueArrayAuthor = bg2.filter(function(item, pos) {
                    return bg1.indexOf(item) === -1
                })
                const uniqueArrayParticipant = bg3.filter(function(item, pos) {
                    return bg1.indexOf(item) === -1 && bg2.indexOf(item) === -1
                })
                commit('SET_BETGAMES_OWNER', bg1)
                commit('SET_BETGAMES_AUTHOR', uniqueArrayAuthor)
                commit('SET_BETGAMES_PARTICIPANT', uniqueArrayParticipant)
                commit('SET_MISSING_BETS', missing_bets)
                var ids = ''
                for (var i = 0; i < bg1.length; i++) {
                    const bg = bg1[i].id
                    ids += bg + ','
                }
                for (var i = 0; i < uniqueArrayAuthor.length; i++) {
                    const bg = uniqueArrayAuthor[i].id
                    ids += bg + ','
                }
                for (var i = 0; i < uniqueArrayParticipant.length; i++) {
                    const bg = uniqueArrayParticipant[i].id
                    ids += bg + ','
                }
                ids += '0'
                APIService.getBetgames(ids, userId)
                .then(response => {
                    commit('SET_USER_BETGAMES', response.data)
                })
                .catch(error => {
                })
            })
            .catch(error => {
            })
        },
        /*
        fetchAllBets ({ commit }, betgameId) {
            APIService.getBets(betgameId, null)
                    .then(response => {
                        commit('SET_ALL_BETS', response.data)
                    })
                    .catch(error => {
                    })
        },
         */
        /*
        fetchBetsOfLiveTopTen ({ commit }, betgameId) {
            var users = ''
            for (var i = 0; i < TOP_TEN && this.state.liveRankings.length; i++) {
                const r = this.state.liveRankings[i]
                users += r.fk_user + ','
            }
            users += this.state.user.id
            APIService.getBetsByUsers(betgameId, users)
            .then(response => {
                commit('SET_ALL_BETS', response.data)
            })
            .catch(error => {
            })
        },
         */
        fetchTable ({ commit }, leagueId) {
            //if(window.localStorage.token) {
            //  axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.token
            //}
            APIService.getTable(leagueId, this.state.currentSeason.id)
                    .then(response => {
                        commit('SET_TABLE', response.data)
                    })
                    .catch(error => {
                    })
        },
        fetchTeams ({ commit }, betgameId) {
            Vue.$log.debug('fetchTeams ' +  this.state.leagueId)
            if(this.state.leagueId && this.state.leagueId > 0) {
                APIService.getLeagueTeams(this.state.leagueId)
                .then(response => {
                    const temp = []
                    for (var i = 0; i < response.data.length; i++) {
                        if(response.data[i].bg_team) {
                            temp.push(response.data[i].bg_team)
                        }
                    }
                    commit('SET_TEAMS', temp)
                })
                .catch(error => {
                })
            } else {
                APIService.getTeams(betgameId)
                .then(response => {
                    commit('SET_TEAMS', response.data)
                })
                .catch(error => {
                })
            }
        },
        fetchCurrentSeason ({ commit }, betgameId) {
            APIService.getCurrentSeason()
            .then(response => {
                commit('SET_CURRENT_SEASON', response.data[0])
            })
            .catch(error => {
            })
        },
        fetchMatchdayWins ({ commit }, betgameId) {
            APIService.getMatchdayWins(this.state.leagueId, betgameId)
            .then(response => {
                commit('SET_MATCHDAY_WINS', response.data)
            })
            .catch(error => {
            })
        },
        checkDeleteBetgame ({ commit }, betgameId) {
            return new Promise((resolve, reject) => {
                const userid = this.state.user.id
                APIService.getOthersBets(betgameId, userid)
                .then(response => {
                    if(response.data.length > 0) {
                        reject('Bets ' + JSON.stringify(response.data))
                    } else {
                        APIService.getOthersAnswers(betgameId, userid)
                        .then(res => {
                            if(res.data.length > 0) {
                                reject('Answers ' + JSON.stringify(res.data))
                            } else {
                                resolve()
                            }
                        })
                        .catch(error => {
                            //reject(error)
                        })
                    }
                })
                .catch(error => {
                })
            });
        },
        fetchPublicBetgames ({ commit } ) {
            return new Promise((resolve, reject) => {
                APIService.getPublicBetgames()
                .then(response => {
                    commit('SET_PUBLIC_BETGAMES', response.data)
                    resolve()
                })
                .catch(error => {
                })
            });
        },
        userEnterBetgame ({ commit }, betgameId) {
            Vue.$log.debug('userEnterBetgame ' + betgameId + ' --- ' + this.state.user.id)
            return new Promise((resolve, reject) => {
                APIService.userEnterBetgame(betgameId, this.state.user.id)
                .then(response => {
                    Vue.$log.debug('userEnterBetgame ok')
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('userEnterBetgame error ' + error)
                    reject()
                })
            });
        },
        removeUserFromBetgame({ commit }, data) {
            const that = this
            return new Promise((resolve, reject) => {
                APIService.removeUserFromBetgame(data.user, data.betgame)
                .then(response => {
                    var temp = []
                    for (var i = 0; i < that.state.betgameUsers.length; i++) {
                        const u = that.state.betgameUsers[i]
                        if('' + u.fk_user !== '' + data.user) {
                            temp.push(u)
                        }
                    }
                    commit('SET_BETGAME_USERS', temp)
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('removeUserFromBetgame error ' + error)
                    reject()
                })
            })
        },
        userLeaveBetgame ({ commit }, betgameId) {
            Vue.$log.debug('userLeaveBetgame ' + betgameId + ' --- ' + this.state.user.id)
            return new Promise((resolve, reject) => {
                APIService.deleteUserAnswersByBetgame(betgameId, this.state.user.id)
                .then(response => {
                    Vue.$log.debug('userLeaveBetgame ok')
                    APIService.deleteUserBetsByBetgame(betgameId, this.state.user.id)
                    .then(response => {
                        APIService.deleteBetgameAuthor(betgameId, this.state.user.id)
                        .then(response => {
                            APIService.deleteUserBetgameRelation(betgameId, this.state.user.id)
                            .then(response => {
                                Vue.$log.debug('userLeaveBetgame ok')
                                if (this.state.betgameId === betgameId) {
                                    commit('SET_BETGAME_ID', 0)
                                    commit('SET_BETGAME', 0)
                                    window.localStorage.removeItem('spmbgid')
                                    if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                                        window.localStorage.removeItem('betgameId')
                                    }
                                }
                                var bp = []
                                for (var i = 0; i < this.state.publicBetgames.length; i++) {
                                    const bg = this.state.publicBetgames[i]
                                    if (bg.id !== betgameId) {
                                        bp.push(bg)
                                    }
                                }
                                commit('SET_PUBLIC_BETGAMES', bp)
                                resolve()
                            })
                            .catch(error => {
                                Vue.$log.debug('userLeaveBetgame error ' + error)
                                reject()
                            })
                        })
                        .catch(error => {
                            Vue.$log.debug('userLeaveBetgame error ' + error)
                            reject()
                        })
                    })
                    .catch(error => {
                        Vue.$log.debug('userLeaveBetgame error ' + error)
                        reject()
                    })
                })
                .catch(error => {
                    Vue.$log.debug('userLeaveBetgame error ' + error)
                    reject()
                })
            });
        },
        /*
        fetchBetgame ({ commit }, betgameId) {
            return new Promise((resolve, reject) => {
                APIService.getBetgame(betgameId)
                .then(response => {
                    Vue.$log.debug('strore fetchBetgame ' + JSON.stringify(response.data[0]))
                    commit('SET_BETGAME', response.data[0])
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('fetchBetgame error ' + error)
                    reject()
                })
            });
        },
         */
        fetchTournamentRounds ({ commit }, competition) {
            return new Promise((resolve, reject) => {
                APIService.fetchTournamentRounds(competition)
                .then(response => {
                    var result = []
                    for (var i = 0; i < response.data.length; i++) {
                        const r = response.data[i]
                        r.name_int = r.name.replace('Vorrunde', 'First round').replace('Vorrunde', 'First round')
                        .replace('Achtelfinale', 'Last 16').replace('Viertelfinale', 'Quarter finals')
                        .replace('Halbfinale', 'Semi finals').replace('3. Platz', '3rd place').replace('Finale', 'Finals')
                        result.push(r)
                    }
                    commit('SET_TOURNAMENT_ROUNDS', result)
                    var groupRounds = ''
                    for(var i=0; i<response.data.length; i++) {
                        const temp = response.data[i]
                        if(temp.type === 1) {
                            groupRounds += temp.id + ','
                        }
                    }
                    groupRounds += '0'
                    APIService.fetchTournamentGroups(groupRounds)
                    .then(groupresponse => {
                        var result = []
                        for (var i = 0; i < groupresponse.data.length; i++) {
                            const r = groupresponse.data[i]
                            r.name_int = r.name.replace('Gruppe', 'Group')
                            result.push(r)
                        }
                        commit('SET_TOURNAMENT_GROUPS', result)
                        var groupTables = ''
                        for(var i=0; i<groupresponse.data.length; i++) {
                            const temp = groupresponse.data[i]
                            groupTables += temp.id + ','
                        }
                        groupTables += '0'
                        APIService.fetchTournamentGroupTables(groupTables)
                        .then(tableresponse => {
                            commit('SET_TOURNAMENT_GROUP_TABLESS', tableresponse.data)
                            resolve()
                        })
                        .catch(error => {
                            Vue.$log.debug('fetchTournamentGroupTables error ' + error)
                            reject()
                        })
                    })
                    .catch(error => {
                        Vue.$log.debug('fetchTournamentGroups error ' + error)
                        reject()
                    })
                })
                .catch(error => {
                    Vue.$log.debug('fetchTournamentRounds error ' + error)
                    reject()
                })
            });
        },
        fetchTournamentMatches ({ commit }, competition) {
            return new Promise((resolve, reject) => {
                APIService.fetchTournamentMatches(competition)
                .then(response => {
                    commit('SET_TOURNAMENT_MATCHES', response.data)
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('fetchTournamentMatches error ' + error)
                    reject()
                })
            });
        },
        fetchBetgame ({ commit }, betgameId) {
            return new Promise((resolve, reject) => {
                APIService.getBetgameWithLeague(betgameId)
                .then(response => {
                    Vue.$log.debug('strore fetchBetgame betgame: ' + JSON.stringify(response.data[0].bg_betgame))
                    commit('SET_BETGAME', response.data[0].bg_betgame)
                    const leagueId = response.data[0].bg_competition.id
                    Vue.$log.debug('strore fetchBetgame leagueid ' + leagueId)
                    commit('SET_BETGAME_COMPETITION', response.data[0].bg_competition)
                    Vue.$log.debug('strore fetchBetgame competition ' + JSON.stringify(response.data[0].bg_competition))
                    commit('SET_LEAGUE_ID', leagueId)
                    resolve(leagueId)
                })
                .catch(error => {
                    Vue.$log.debug('fetchBetgame error ' + error)
                    reject()
                })
            });
        },
        fetchBetgameLeague ({ commit }, betgameId) {
            return new Promise((resolve, reject) => {
                APIService.getBetgameLeague(betgameId)
                .then(response => {
                    const leagueId = response.data[0].bg_competition.id
                    //var temp = this.state.betgame
                    //temp.competition = response.data[0].bg_competition
                    commit('SET_BETGAME_COMPETITION', response.data[0].bg_competition)
                    //commit('SET_BETGAME', temp)
                    commit('SET_LEAGUE_ID', leagueId)
                    resolve(leagueId)
                })
                .catch(error => {
                    //reject(error)
                })
            })
        },
        fetchMatchBets ({ commit }, competition) {
            APIService.getMatchBets(competition)
            .then(response => {
                const temp = []
                for (var i = 0; i < response.data.length; i++) {
                    if(response.data[i].bg_match_bet.length > 0) {
                        temp.push(response.data[i].bg_match_bet[0])
                    }
                }
                commit('SET_MATCH_BETS', temp)
            })
            .catch(error => {
            })
        },
        fetchMatchdaySchedule ({ commit }, params) {
            if(this.state.currentSeason === undefined || this.state.currentSeason === null) {
                this.dispatch('fetchCurrentSeason', this.state.betgameId)
                return
            }
            Vue.$log.debug('fetchMatchdaySchedule ' + params)
            APIService.getMatchdaySchedule(params[0], this.state.currentSeason.id, params[1])
            .then(response => {
                var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                if(!timeZone) {
                    timeZone = 'Europe/Berlin'
                }
                const now = moment().tz(timeZone)
                var runningMatches = []
                for (var i = 0; i < response.data.length; i++) {
                }
                var schedule = []
                for (var i = 0; i < this.state.matches.length; i++) {
                    const match = this.state.matches[i]
                    const updateMatch = response.data.filter(s => s.id === match.id)
                    if(updateMatch.length === 1) {
                        schedule.push(updateMatch[0])
                    } else {
                        schedule.push(match)
                    }
                    const valueFromDB = moment.tz(String(schedule[i].matchdate), "Europe/Berlin")
                    const matchdate = valueFromDB.clone().tz(timeZone)
                    if (matchdate < now) {
                        var diff = now - matchdate
                        const minutesDiff = diff/(1000*60)
                        if(minutesDiff < 135 || (isNumeric(schedule[i].livegoalshome) && isNumeric(schedule[i].livegoalsguest))) {
                            runningMatches.push(schedule[i].id)
                        }
                    }
                }
                commit('SET_SCHEDULE', schedule)
                commit('SET_RUNNING_MATCHES', runningMatches)
            })
            .catch(error => {
            })
        },
        fetchSchedule ({ commit }, leagueId) {
            Vue.$log.debug('fetchSchedule leagueId ' + leagueId)
            if(this.state.currentSeason === undefined || this.state.currentSeason === null) {
                Vue.$log.debug('fetchSchedule this.state.currentSeason NOT FOUND')
                this.dispatch('fetchCurrentSeason', this.state.betgameId)
                return
            }
    
            Vue.$log.debug('fetchSchedule this.state.currentSeason ' + JSON.stringify(this.state.currentSeason))
            
            setBGValue('spmlfs', new Date())
            
            APIService.getSchedule(leagueId, this.state.currentSeason.id)
                    .then(response => {
                        //Vue.$log.debug('fetchSchedule getSchedule ' + JSON.stringify(response.data))
                        var arr = []
                        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                        if(!timeZone) {
                            timeZone = 'Europe/Berlin'
                        }
                        const now = moment().tz(timeZone)
                        var pastDiff = Infinity
                        var futureDiff = Infinity
                        var nearestPastDate = null
                        var nearestFutureDate = null
                        var pastMatchday = 1
                        var futureMatchday = 1
                        var runningMatches = []
                        var runningMatchday = 1
                        var todaysMatches = []
                        var maxStartedMatchday = 1
                        for (var i = 0; i < response.data.length; i++) {
                            //var matchdate = Date.parse(response.data[i].matchdate)
                            const valueFromDB = moment.tz(String(response.data[i].matchdate), "Europe/Berlin")
                            const matchdate = valueFromDB.clone().tz(timeZone)
                            if(isToday(new Date(matchdate))) {
                                todaysMatches.push(response.data[i].id)
                            }
                            if((isNumeric(response.data[i].goalshome) && isNumeric(response.data[i].goalsguest)) ||
                              (isNumeric(response.data[i].livegoalshome) && isNumeric(response.data[i].livegoalsguest))) {
                                if(response.data[i].matchday > maxStartedMatchday) {
                                    maxStartedMatchday = response.data[i].matchday
                                }
                            }
                            if (matchdate < now) {
                                var diff = now - matchdate
                                const minutesDiff = diff/(1000*60)
                                if(minutesDiff < 135 || (isNumeric(response.data[i].livegoalshome) && isNumeric(response.data[i].livegoalsguest))) {
                                    runningMatches.push(response.data[i].id)
                                    runningMatchday = response.data[i].matchday
                                }
                                if (diff < pastDiff) {
                                    pastDiff = diff
                                    nearestPastDate = matchdate
                                    pastMatchday = response.data[i].matchday
                                }
                            } else if (matchdate > now) {
                                var diff = matchdate - now
                                if (diff < futureDiff) {
                                    futureDiff = diff
                                    nearestFutureDate = matchdate
                                    futureMatchday = response.data[i].matchday
                                }
                            }
                            arr.push(response.data[i].matchday)
                        }
                        
                        const temp = response.data
                        temp.sort(function (a, b) {
                            return b.matchdate.localeCompare(a.matchdate)
                        })
                        
                        const maxMatchday = Math.max(...arr)
                        /*
                        if (pastDiff < futureDiff) {
                            Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_BET1 ' + pastMatchday)
                            commit('SET_CURRENT_MATCHDAY_BET', pastMatchday)
                        } else if (futureDiff < pastDiff) {
                            Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_BET2 ' + futureMatchday)
                            commit('SET_CURRENT_MATCHDAY_BET', futureMatchday)
                        }
                        */
                        
                        if(runningMatches.length > 0) {
                            Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_BET3 ' + runningMatchday)
                            commit('SET_CURRENT_MATCHDAY_BET', runningMatchday)
                        } else {
                            Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_BET4 ' + futureMatchday)
                            commit('SET_CURRENT_MATCHDAY_BET', futureMatchday)
                        }
                        
                        Vue.$log.debug('fetchSchedule SET_CURRENT_MATCHDAY_RANKING ' + maxStartedMatchday)
                        Vue.$log.debug('fetchSchedule SET_MATCHDAY_COUNT ' + maxMatchday)
                        //Vue.$log.debug('fetchSchedule SET_SCHEDULE ' + JSON.stringify(temp))
                        Vue.$log.debug('fetchSchedule SET_TODAYS_MATCHES ' + JSON.stringify(todaysMatches))
                        Vue.$log.debug('fetchSchedule SET_RUNNING_MATCHES ' + JSON.stringify(runningMatches))
                        
                        commit('SET_CURRENT_MATCHDAY_RANKING', maxStartedMatchday)
                        commit('SET_MATCHDAY_COUNT', maxMatchday)
                        commit('SET_SCHEDULE', temp)
                        commit('SET_TODAYS_MATCHES', todaysMatches)
                        commit('SET_RUNNING_MATCHES', runningMatches)
                    })
                    .catch(error => {
                    })
        },
        register ({ commit }, credentials) {
            return axios
                    .post(this.state.apiUrl + '/rpc/signup_login', credentials)
                    .then(({ data }) => {
                        //commit('SET_USER_DATA', data)
                        setBGValue('spmltr', new Date())
                        commit('SET_TOKEN', [data.token])
                        commit('SET_BETGAME_ID', data.betgame)
                    })
        },
        registerUserCreateBetgame ({ commit }, val) {
            delete axios.defaults.headers.common['Authorization']
            return axios
            .post(this.state.apiUrl + '/rpc/register_user_create_betgame', val)
            .then(({ data }) => {
                setBGValue('spmltr', new Date())
                commit('SET_TOKEN', [data.token])
                window.localStorage.setItem('spmbgid', data.betgame)
                if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                    window.localStorage.setItem('betgameId', data.betgame)
                }
                commit('SET_BETGAME_ID', data.betgame)
            })
        },
        createBetgame ({ commit }, val) {
            return new Promise((resolve, reject) => {
                return axios
                .post(this.state.apiUrl + '/rpc/create_betgame', val, {
                    contentType: "application/json",
                    headers: {
                        Authorization: 'Bearer ' + this.state.token
                    }})
                .then(({ data }) => {
                    window.localStorage.setItem('spmbgid', data.betgame)
                    if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                        window.localStorage.setItem('betgameId', data.betgame)
                    }
                    commit('SET_BETGAME_ID', data.betgame)
                    resolve(data.betgame)
                }).catch(function (error) {
                    Vue.$log.debug('strore createBetgame ' + error.toString())
                    reject(error)
                })
            })
        },
        validateRegistration ({ commit }, credentials) {
            delete axios.defaults.headers.common['Authorization']
            return new Promise((resolve, reject) => {
                return axios
                .post(this.state.apiUrl + '/rpc/validate_registration', credentials, {
                    contentType: "application/json"
                })
                .then(({ data }) => {
                    // commit('SET_USER_DATA', data)
                    if(this.state.user && this.state.user !== 'active') {
                        const u = this.state.user
                        u.status = 'active'
                        commit('SET_USER_DATA', u)
                    }
                    resolve()
                }).catch(function (error) {
                    reject(error)
                })
            })
        },
        confirmAdConsent ({ commit }, credentials) {
            return new Promise((resolve, reject) => {
                return axios
                .post(this.state.apiUrl + '/rpc/confirm_ad_consent', credentials, {
                    contentType: "application/json"
                })
                .then(({ data }) => {
                    resolve()
                }).catch(function (error) {
                    reject(error)
                })
            })
        },
        requestPasswordReset ({ commit }, credentials) {
            delete axios.defaults.headers.common['Authorization']
            return new Promise((resolve, reject) => {
                return axios
                    .post(this.state.apiUrl + '/rpc/request_password_reset', credentials)
                    .then(({ data }) => {
                        // commit('SET_USER_DATA', data)
                        resolve()
                    }).catch(function (error) {
                        Vue.$log.debug('store requestPasswordReset error ' + JSON.stringify(error))
                        reject(error)
                    })
            })
        },
        resetPassword ({ commit }, credentials) {
            delete axios.defaults.headers.common['Authorization']
            return axios
                    .post(this.state.apiUrl + '/rpc/reset_password_data', credentials)
                    .then(({ data }) => {
                        const bg1 = data.betgames_owner
                        const bg2 = data.betgames_author
                        const bg3 = data.betgames_participant
                        const user_betgame = data.user_betgame
                        const uniqueArrayAuthor = bg2.filter(function(item, pos) {
                            return bg1.indexOf(item) === -1
                        })
                        const uniqueArrayParticipant = bg3.filter(function(item, pos) {
                            return bg1.indexOf(item) === -1 && bg2.indexOf(item) === -1
                        })
                        commit('SET_BETGAMES_OWNER', bg1)
                        commit('SET_BETGAMES_AUTHOR', uniqueArrayAuthor)
                        commit('SET_BETGAMES_PARTICIPANT', uniqueArrayParticipant)
                        if(this.state.betgameId === undefined || this.state.betgameId === null || this.state.betgameId === 0) {
                            var bgid = 0
                            if(bg1.length > 0) {
                                bgid  = bg1[0].id
                            } else if(bg2.length > 0) {
                                bgid  = bg2[0].id
                            } else if(bg3.length > 0) {
                                bgid  = bg3[0].id
                            } else if(user_betgame !== null) {
                                bgid  = user_betgame
                            }
                            if(bgid > 0) {
                                window.localStorage.setItem('spmbgid', bgid)
                                if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                                    window.localStorage.setItem('betgameId', bgid)
                                }
                                commit('SET_BETGAME_ID', bgid)
                                setBGValue('spmltr', new Date())
                                commit('SET_TOKEN', [data.token])
                            } else {
                                // route search betgame page
                                const id = window.localStorage.getItem('spmbgid')
                                if(id !== undefined && id !== null && id > 0) {
                                    commit('SET_BETGAME_ID', id)
                                    setBGValue('spmltr', new Date())
                                    commit('SET_TOKEN', [data.token])
                                } else {
                                    const payload = jwt_decode(JSON.stringify([data.token]))
                                    const fkbetgame = payload.fk_betgame
                                    if(fkbetgame !== undefined && fkbetgame !== null && fkbetgame > 0) {
                                        window.localStorage.setItem('spmbgid', fkbetgame)
                                        if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                                            window.localStorage.setItem('betgameId', fkbetgame)
                                        }
                                        commit('SET_BETGAME_ID', fkbetgame)
                                        setBGValue('spmltr', new Date())
                                        commit('SET_TOKEN', [data.token])
                                    } else {
                                        window.localStorage.setItem('spmbgid', 0)
                                        if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                                            window.localStorage.setItem('betgameId', 0)
                                        }
                                        setBGValue('spmltr', new Date())
                                        commit('SET_TOKEN', [data.token])
                                    }
                                }
                            }
                        } else {
                            setBGValue('spmltr', new Date())
                            commit('SET_TOKEN', [data.token])
                        }
                    })
        },
        login ({ commit }, credentials) {
            delete axios.defaults.headers.common['Authorization']
            return new Promise((resolve, reject) => {
                axios.defaults.withCredentials = true
                axios.post(this.state.apiUrl + '/rpc/login_data', credentials)
                .then(({ data }) => {
                    commit('SET_TOKEN', [data.token])
                    const bg1 = data.betgames_owner
                    const bg2 = data.betgames_author
                    const bg3 = data.betgames_participant
                    const user_betgame = data.user_betgame
                    const uniqueArrayAuthor = bg2.filter(function(item, pos) {
                        return bg1.indexOf(item) === -1
                    })
                    const uniqueArrayParticipant = bg3.filter(function(item, pos) {
                        return bg1.indexOf(item) === -1 && bg2.indexOf(item) === -1
                    })
                    commit('SET_BETGAMES_OWNER', bg1)
                    commit('SET_BETGAMES_AUTHOR', uniqueArrayAuthor)
                    commit('SET_BETGAMES_PARTICIPANT', uniqueArrayParticipant)
                    if(this.state.betgameId === undefined || this.state.betgameId === null || this.state.betgameId === 0) {
                        var bgid = 0
                        if(bg1.length > 0) {
                            bgid  = bg1.sort((a, b) => a.id - b.id)[0].id
                        } else if(bg2.length > 0) {
                            bgid  = bg2.sort((a, b) => a.id - b.id)[0].id
                        } else if(bg3.length > 0) {
                            bgid  = bg3.sort((a, b) => a.id - b.id)[0].id
                        } else if(user_betgame !== null) {
                            bgid  = user_betgame
                        }
                        if(bgid > 0) {
                            window.localStorage.setItem('spmbgid', bgid)
                            if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                                window.localStorage.setItem('betgameId', bgid)
                            }
                            commit('SET_BETGAME_ID', bgid)
                            setBGValue('spmltr', new Date())
                            resolve(bgid)
                        } else {
                            const id = window.localStorage.getItem('spmbgid')
                            if(id !== undefined && id !== null && id > 0) {
                                commit('SET_BETGAME_ID', id)
                                setBGValue('spmltr', new Date())
                                resolve(id)
                            } else {
                                const payload = jwt_decode(JSON.stringify([data.token]))
                                const fkbetgame = payload.fk_betgame
                                if(fkbetgame !== undefined && fkbetgame !== null && fkbetgame > 0) {
                                    window.localStorage.setItem('spmbgid', fkbetgame)
                                    if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                                        window.localStorage.setItem('betgameId', fkbetgame)
                                    }
                                    commit('SET_BETGAME_ID', fkbetgame)
                                    setBGValue('spmltr', new Date())
                                    resolve(id)
                                } else {
                                    window.localStorage.setItem('spmbgid', 0)
                                    if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                                        window.localStorage.setItem('betgameId', 0)
                                    }
                                    setBGValue('spmltr', new Date())
                                    resolve(0)
                                }
                            }
                        }
                    } else {
                        setBGValue('spmltr', new Date())
                        resolve(this.state.betgameId)
                    }
                }).catch(function (error) {
                    reject(error)
                })
            })
        },
        loginLocal ({ commit }, token) {
    
            commit('SET_USER_NEIGHBOUR_RANKINGS', [])
            commit('SET_USER_RANKING', [])
            commit('SET_USER_LIVE_RANKING', [])
            commit('SET_RANKINGS', [])
            commit('SET_LIVE_RANKINGS', [])
            commit('SET_LIVE_RANKINGS_BY_MATCHDAY', [])
            commit('SET_MATCHDAY_WINS', [])
            commit('SET_MATCHDAY_RANKINGS', [])
            commit('SET_USER_MATCHDAY_RANKINGS', [])
            commit('SET_CURRENT_MATCHDAY_BET', 0)
            commit('SET_CURRENT_MATCHDAY_RANKING', 0)
            commit('SET_MATCHDAY_COUNT', 0)
            commit('SET_SCHEDULE', [])
            commit('SET_BETS', [])
            commit('SET_ALL_BETS', [])
            commit('SET_TABLE', [])
            commit('SET_TEAMS', [])
            commit('SET_TODAYS_MATCHES', [])
            commit('SET_RUNNING_MATCHES', [])
            commit('SET_MATCH_BETS', [])
            commit('SET_MATCHDAY_RANKING_SORTING', 1)
            commit('SET_QUESTIONS', [])
            commit('SET_ANSWERS', [])
            commit('SET_SOLUTIONS', [])
            commit('SET_USER_COUNT', -1)
            commit('SET_BET_COUNT', -1)
            commit('SET_BETGAME_USERS', [])
            commit('SET_BETGAME_SLIDES', [])
            commit('SET_BETGAME_ADS', [])
            
            delete axios.defaults.headers.common['Authorization']
            return new Promise((resolve, reject) => {
                var tokenExpired = false
                try {
                    const payload = jwt_decode(token)
                    const expDate = payload.exp * 1000
                    if (expDate < Date.now()) {
                        tokenExpired = true
                    }
                } catch (e) {
                    tokenExpired = token
                }
                if(!tokenExpired) {
                    const payload = jwt_decode(JSON.stringify(token))
                    commit('SET_TOKEN_LOCAL', token)
                    resolve(payload)
                } else {
                    reject(token)
                }
            })
        },
        deleteAccount ({ commit }, data) {
            return axios.post(this.state.apiUrl + '/rpc/delete_account', data, { timeout: API_TIMEOUT, headers: { Authorization: 'Bearer ' + this.state.token } })
            .then(({ data }) => {
            })
        },
        resetBetgame ({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post(this.state.apiUrl + '/rpc/reset_betgame', data, {
                    timeout: API_TIMEOUT,
                    headers: { Authorization: 'Bearer ' + this.state.token }
                })
                .then(({ result }) => {
                    // commit('SET_PUBLIC_BETGAMES', bp)
                    resolve()
                }).catch(function (error) {
                    Vue.$log.debug('store deleteBetgame error ' + JSON.stringify(error))
                    reject(error)
                })
            })
        },
        deleteBetgame ({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post(this.state.apiUrl + '/rpc/delete_betgame', data, {
                    timeout: API_TIMEOUT,
                    headers: { Authorization: 'Bearer ' + this.state.token }
                })
                .then(({ result }) => {
                    if (this.state.betgameId === data.betgame) {
                        commit('SET_BETGAME_ID', 0)
                        commit('SET_BETGAME', 0)
                        window.localStorage.removeItem('spmbgid')
                        if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                            window.localStorage.removeItem('betgameId')
                        }
                    }
                    var bo = []
                    for (var i = 0; i < this.state.betgames_owner.length; i++) {
                        const bg = this.state.betgames_owner[i]
                        if (bg.id !== data.betgame) {
                            bo.push(bg)
                        }
                    }
                    commit('SET_BETGAMES_OWNER', bo)
                    var bp = []
                    for (var i = 0; i < this.state.publicBetgames.length; i++) {
                        const bg = this.state.publicBetgames[i]
                        if (bg.id !== data.betgame) {
                            bp.push(bg)
                        }
                    }
                    commit('SET_PUBLIC_BETGAMES', bp)
                    resolve()
                }).catch(function (error) {
                    Vue.$log.debug('store deleteBetgame error ' + JSON.stringify(error))
                    reject(error)
                })
            })
        },
        clearDataBeforeLogin ({ commit }) {
            commit('SET_USER_NEIGHBOUR_RANKINGS', [])
            commit('SET_USER_RANKING', [])
            commit('SET_USER_LIVE_RANKING', [])
            commit('SET_RANKINGS', [])
            commit('SET_LIVE_RANKINGS', [])
            commit('SET_LIVE_RANKINGS_BY_MATCHDAY', [])
            commit('SET_MATCHDAY_WINS', [])
            commit('SET_MATCHDAY_RANKINGS', [])
            commit('SET_USER_MATCHDAY_RANKINGS', [])
            commit('SET_CURRENT_MATCHDAY_BET', 0)
            commit('SET_CURRENT_MATCHDAY_RANKING', 0)
            commit('SET_MATCHDAY_COUNT', 0)
            commit('SET_SCHEDULE', [])
            commit('SET_BETS', [])
            commit('SET_ALL_BETS', [])
            commit('SET_TABLE', [])
            commit('SET_TEAMS', [])
            commit('SET_TODAYS_MATCHES', [])
            commit('SET_RUNNING_MATCHES', [])
            commit('SET_MATCH_BETS', [])
            commit('SET_MATCHDAY_RANKING_SORTING', 1)
            commit('SET_QUESTIONS', [])
            commit('SET_ANSWERS', [])
            commit('SET_SOLUTIONS', [])
            commit('SET_BETGAMES_OWNER', [])
            commit('SET_BETGAMES_AUTHOR', [])
            commit('SET_BETGAMES_PARTICIPANT', [])
            commit('SET_MISSING_BETS', [])
            commit('SET_USER_COUNT', -1)
            commit('SET_BET_COUNT', -1)
            commit('SET_BETGAME_USERS', [])
            commit('SET_BETGAME_SLIDES', [])
            commit('SET_BETGAME_ADS', [])
        },
        logout ({ commit }) {
            commit('SET_LEAGUE_ID', 0)
            commit('SET_USER_BETGAMES', [])
            commit('SET_USER_NEIGHBOUR_RANKINGS', [])
            commit('SET_USER_RANKING', [])
            commit('SET_USER_LIVE_RANKING', [])
            commit('SET_RANKINGS', [])
            commit('SET_LIVE_RANKINGS', [])
            commit('SET_LIVE_RANKINGS_BY_MATCHDAY', [])
            commit('SET_MATCHDAY_WINS', [])
            commit('SET_MATCHDAY_RANKINGS', [])
            commit('SET_USER_MATCHDAY_RANKINGS', [])
            commit('SET_CURRENT_MATCHDAY_BET', 0)
            commit('SET_CURRENT_MATCHDAY_RANKING', 0)
            commit('SET_MATCHDAY_COUNT', 0)
            commit('SET_SCHEDULE', [])
            commit('SET_BETS', [])
            commit('SET_ALL_BETS', [])
            commit('SET_TABLE', [])
            commit('SET_TEAMS', [])
            commit('SET_TODAYS_MATCHES', [])
            commit('SET_RUNNING_MATCHES', [])
            commit('SET_MATCH_BETS', [])
            commit('SET_MATCHDAY_RANKING_SORTING', 1)
            commit('SET_QUESTIONS', [])
            commit('SET_ANSWERS', [])
            commit('SET_SOLUTIONS', [])
            commit('SET_BETGAMES_OWNER', [])
            commit('SET_BETGAMES_AUTHOR', [])
            commit('SET_BETGAMES_PARTICIPANT', [])
            commit('SET_MISSING_BETS', [])
            commit('SET_USER_COUNT', -1)
            commit('SET_BET_COUNT', -1)
            commit('SET_BETGAME_USERS', [])
            commit('SET_BETGAME_SLIDES', [])
            commit('SET_BETGAME_ADS', [])
            commit('CLEAR_USER_DATA')
            window.localStorage.removeItem('user')
            window.localStorage.removeItem('spmlr')
            window.localStorage.removeItem('spmvr')
            for(var i=0; i<window.localStorage.length; i++) {
                const key = window.localStorage.key(i)
                if(key.indexOf('match_stats_') >= 0) {
                    window.localStorage.removeItem(key)
                }
            }
    
            commit('SET_BETGAME_ID', 0)
            //commit('SET_BETGAME', 0)
            //window.localStorage.removeItem('spmbgid')
            //if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
            //    window.localStorage.removeItem('betgameId')
            //}
            
            //window.localStorage.removeItem(storageKey)
            //Cookie.set(cookieName, uuidv4(), { expires: 360 })
        },
        switchBetgame ({ commit }, betgame) {
            return new Promise((resolve, reject) => {
                commit('SET_LEAGUE_ID', 0)
                commit('SET_USER_BETGAMES', [])
                commit('SET_USER_NEIGHBOUR_RANKINGS', [])
                commit('SET_USER_RANKING', [])
                commit('SET_USER_LIVE_RANKING', [])
                commit('SET_RANKINGS', [])
                commit('SET_LIVE_RANKINGS', [])
                commit('SET_LIVE_RANKINGS_BY_MATCHDAY', [])
                commit('SET_MATCHDAY_WINS', [])
                commit('SET_MATCHDAY_RANKINGS', [])
                commit('SET_USER_MATCHDAY_RANKINGS', [])
                commit('SET_CURRENT_MATCHDAY_BET', 0)
                commit('SET_CURRENT_MATCHDAY_RANKING', 0)
                commit('SET_MATCHDAY_COUNT', 0)
                commit('SET_SCHEDULE', [])
                commit('SET_BETS', [])
                commit('SET_ALL_BETS', [])
                commit('SET_TABLE', [])
                commit('SET_TEAMS', [])
                commit('SET_TODAYS_MATCHES', [])
                commit('SET_RUNNING_MATCHES', [])
                commit('SET_MATCH_BETS', [])
                commit('SET_MATCHDAY_RANKING_SORTING', 1)
                commit('SET_QUESTIONS', [])
                commit('SET_ANSWERS', [])
                commit('SET_SOLUTIONS', [])
                commit('SET_USER_COUNT', -1)
                commit('SET_BET_COUNT', -1)
                commit('SET_BETGAME_USERS', [])
                commit('SET_BETGAME_SLIDES', [])
                commit('SET_BETGAME_ADS', [])
                
                if(betgame === 1) {
                    commit('SET_PADDING_BOTTOM', PADDING_BOTTOM_FUNKE)
                } else if(betgame === 2) {
                    commit('SET_PADDING_BOTTOM', PADDING_BOTTOM_DSP)
                } else if(betgame === 12) {
                    commit('SET_PADDING_BOTTOM', PADDING_BOTTOM_DSP)
                } else if(betgame === 31) {
                    commit('SET_PADDING_BOTTOM', PADDING_BOTTOM_DSP)
                } else {
                    commit('SET_PADDING_BOTTOM', PADDING_BOTTOM_DEFAULT)
                }
                
                this.dispatch('setBetgameId', betgame)
                //this.dispatch('fetchCurrentSeason', betgame)
                const that = this
                this.dispatch('fetchBetgame', betgame).then(function (response1) {
                    //that.dispatch('fetchBetgameLeague', betgame).then(function (response2) {
                        that.dispatch('fetchSchedule', response1).then(function (response3) {
                            resolve(response1)
                        })
                    //})
                })
            })
        },
        addBet ({ commit }, bet) {
            commit('ADD_BET', bet)
        },
        setLastBetSaveDate ({ commit }, date) {
            commit('SET_LAST_BET_SAVE_DATE', date)
        },
        setLastAnswerSaveDate ({ commit }, date) {
            commit('SET_LAST_ANSWER_SAVE_DATE', date)
        },
        updateBet ({ commit }, bet) {
            const missing = this.state.missing_bets
            return new Promise((resolve, reject) => {
                APIService.putBet(this.state.token, bet)
                .then(function (response) {
                    if(response.status >= 200 && response.status <= 204) {
                        commit('UPDATE_BET', bet)
                    }
                    commit('SET_UPDATE_USER_BETGAMES', true)
                    if(missing.length > 0) {
                        var missing_new = []
                        for (var i = 0; i < missing.length; i++) {
                            const m = missing[i]
                            const n = {
                                "id": m.id,
                                "matchcount": m.matchcount,
                                "betcount": m.betcount
                            }
                            if (n.id === bet.fk_betgame && n.betcount < n.matchcount) {
                                n.betcount = n.betcount + 1
                            }
                            missing_new.push(n)
                        }
                        commit('SET_MISSING_BETS', missing_new)
                    }
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        updateAnswer ({ commit }, answer) {
            return new Promise((resolve, reject) => {
                var deleteParam = answer
                if(Array.isArray(answer)) {
                    deleteParam = answer[0]
                }
                APIService.deleteUserAnswers(deleteParam)
                .then(function (response) {
                    return new Promise((res, rej) => {
                        APIService.postAnswer(answer)
                        .then(function (response) {
                            commit('SET_LAST_ANSWER_SAVE_DATE', moment().format('YYYY-MM-DD HH:mm:ss'))
                            commit('SET_UPDATE_ANSWER_COUNT', true)
                            resolve(response)
                        })
                        .catch(function (error) {
                            reject(error)
                        })
                    })
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        updateUserNotifications ({ commit }, data) {
            return axios.post(this.state.apiUrl + '/rpc/update_user_notifications', data, { timeout: API_TIMEOUT, headers: { Authorization: 'Bearer ' + this.state.token } })
            .then(({ result }) => {
                commit('UPDATE_USER_NOTIFICATIONS', data)
            })
        },
        updateUserNickname ({ commit }, data) {
            return axios.post(this.state.apiUrl + '/rpc/update_user_nickname', data, { timeout: API_TIMEOUT, headers: { Authorization: 'Bearer ' + this.state.token } })
            .then(({ result }) => {
                commit('UPDATE_USER_NICKNAME', data)
            })
        },
        updateUserEmail ({ commit }, data) {
            return axios.post(this.state.apiUrl + '/rpc/update_user_email_wl', data, { timeout: API_TIMEOUT, headers: { Authorization: 'Bearer ' + this.state.token } })
            .then(({ result }) => {
                commit('UPDATE_USER_EMAIL', data)
            })
            /*
            APIService.patchUser(this.state.token, this.getters.getUserId, user)
            .then(function (response) {
                commit('UPDATE_USER_EMAIL', user)
            })
            .catch(function (error) {
                // handle error
            })
            .finally(function () {
                // always executed
            })
             */
        },
        updateUserPassword ({ commit }, data) {
            return axios.post(this.state.apiUrl + '/rpc/update_user_pass', data, { timeout: API_TIMEOUT, headers: { Authorization: 'Bearer ' + this.state.token } })
            .then(({ data }) => {
            })
            /*
            APIService.patchUser(this.state.token, this.getters.getUserId, user)
            .then(function (response) {
                //this.$store.dispatch('logout')
            })
            .catch(function (error) {
            })
            .finally(function () {
                // always executed
            })
             */
        },
        refreshToken ({ commit }, params) {
            return axios.post(this.state.apiUrl + '/rpc/refresh_token', params, {
                    contentType: "application/json",
                    headers: {
                        Authorization: 'Bearer ' + this.state.token
                    }
                })
                .then(({ data }) => {
                    setBGValue('spmltr', new Date())
                    commit('SET_TOKEN', data)
                })
        },
        patchBetgame ({ commit }, params) {
            APIService.patchBetgame(this.state.betgameId, params)
            .then(function (response) {
                //this.state.betgame. = this.betgamendescription
            })
            .catch(function (error) {
                Vue.$log.debug('store patchBetgame catch ' + error)
            })
            .finally(function () {
                Vue.$log.debug('store patchBetgame finally ')
            })
        },
        postQuestion ({ commit }, question) {
            return new Promise((resolve, reject) => {
                APIService.postQuestion(question)
                .then(function (response) {
                    if(response.data.length === 1) {
                        resolve(response.data[0].id)
                    } else {
                        reject()
                    }
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        patchQuestion ({ commit }, question) {
            return new Promise((resolve, reject) => {
                APIService.patchQuestion(question.id, question)
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        deleteQuestion ({ commit }, questionId) {
            const betgame = this.state.betgameId
            return new Promise((resolve, reject) => {
                APIService.deleteAnswers(questionId)
                .then(function (response) {
                    APIService.deleteSolutions({fk_question: questionId})
                    .then(function (response) {
                        APIService.deleteQuestion(questionId, betgame)
                        .then(function (response) {
                            resolve(response)
                        })
                        .catch(function (error) {
                            reject(error)
                        })
                    })
                    .catch(function (error) {
                        reject(error)
                    })
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        fetchBetCount ({ commit }, preferred) {
            APIService.betCount(this.state.betgameId, preferred)
            .then(function (response) {
                const headers = response.headers
                const crange = headers['content-range']
                const betcount = crange.substring(crange.lastIndexOf('/') + 1)
                commit('SET_BET_COUNT', parseInt(betcount))
            })
            .catch(function (error) {
                Vue.$log.debug('store fetchBetCount error ' + error)
            })
        },
        fetchUserCount ({ commit }, preferred) {
            APIService.userCount(this.state.betgameId, preferred)
            .then(function (response) {
                const headers = response.headers
                const crange = headers['content-range']
                const usercount = crange.substring(crange.lastIndexOf('/') + 1)
                commit('SET_USER_COUNT', parseInt(usercount))
            })
            .catch(function (error) {
                Vue.$log.debug('store fetchUserCount error ' + error)
            })
        },
        startBetgameTransfer ({ commit }, params) {
            Vue.$log.debug('startBetgameTransfer ' + params + ' --- ' + this.state.user.id)
            return new Promise((resolve, reject) => {
                APIService.startBetgameTransfer(params[0], params[1])
                .then(response => {
                    Vue.$log.debug('startBetgameTransfer ok')
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('startBetgameTransfer error ' + error)
                    reject()
                })
            });
        },
        fetchBetgameTransfer ({ commit }, betgameId ) {
            return new Promise((resolve, reject) => {
                APIService.fetchBetgameTransfer(betgameId)
                .then(response => {
                    if(response.data.length === 1) {
                        commit('SET_BETGAME_TRANSFER', response.data[0])
                    }
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('fetchBetgameTransfer error ' + error)
                    reject()
                })
            });
        },
        fetchBetgameTransfersByUser ({ commit }, userId ) {
            return new Promise((resolve, reject) => {
                APIService.fetchBetgameTransfersByUser(userId)
                .then(response => {
                    Vue.$log.debug("fetchBetgameTransfersByUser " + JSON.stringify(response.data))
                    commit('SET_INCOMING_BETGAME_TRANSFERS', response.data)
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('fetchBetgameTransfersByUser error ' + error)
                    reject()
                })
            });
        },
        deleteBetgameTransfer ({ commit }, betgameId ) {
            return new Promise((resolve, reject) => {
                APIService.deleteBetgameTransfer(betgameId)
                .then(response => {
                    commit('SET_BETGAME_TRANSFER', {})
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('deleteBetgameTransfer error ' + error)
                    reject()
                })
            });
        },
        finishBetgameTransfer ({ commit }, betgameId) {
            Vue.$log.debug('finishBetgameTransfer ' + betgameId)
            return new Promise((resolve, reject) => {
                APIService.finishBetgameTransfer(betgameId)
                .then(response => {
                    Vue.$log.debug('finishBetgameTransfer ok')
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('finishBetgameTransfer error ' + error)
                    reject()
                })
            });
        },
        postUnsubscription ({ commit }, data) {
            return new Promise((resolve, reject) => {
                APIService.postUnsubscription(data)
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        checkInvitation ({ commit }, data) {
            return new Promise((resolve, reject) => {
                APIService.getBetgameByInvitation(data[0], data[1])
                .then(function (response) {
                    if(response.data.length > 0) {
                        resolve(response.data)
                    } else {
                        reject()
                    }
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        postB2BContact ({ commit }, data) {
            return new Promise((resolve, reject) => {
                APIService.postB2BContact(data)
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        patchUser ({ commit }, params) {
            return new Promise((resolve, reject) => {
                APIService.patchUser(this.state.token, this.state.user.id, params)
                .then(function (response) {
                    commit('UPDATE_USER', params)
                    Vue.$log.debug('UPDATE_USER commited ' + JSON.stringify(params))
                    resolve()
                })
                .catch(function (error) {
                    Vue.$log.debug('store patchUser catch ' + error)
                    reject()
                })
            })
        },
        updateSolution ({ commit }, solution) {
            return new Promise((resolve, reject) => {
                var deleteParam = solution
                var isArray = false
                if(Array.isArray(solution)) {
                    deleteParam = solution[0]
                    isArray = true
                }
                const solutions = this.state.solutions.filter(s => s.fk_question !== deleteParam.fk_question)
                APIService.deleteSolutions(deleteParam)
                .then(function (response) {
                    return new Promise((res, rej) => {
                        APIService.postSolution(solution)
                        .then(function (response) {
                            if(isArray) {
                                solutions.push(...solution)
                            } else {
                                solutions.push(solution)
                            }
                            commit('SET_SOLUTIONS', solutions)
                            resolve(response)
                        })
                        .catch(function (error) {
                            reject(error)
                        })
                    })
                })
                .catch(function (error) {
                    reject(error)
                })
            })
        },
        fetchBetgameUsers ({ commit }, betgameId ) {
            var currentUserId = 0
            const currentUser = this.state.user
            if(currentUser) {
                currentUserId = currentUser.id
            }
            return new Promise((resolve, reject) => {
                APIService.fetchBetgameUsersTable(betgameId, currentUserId)
                .then(response => {
                    var temp = []
                    for (var i = 0; i < response.data.length; i++) {
                        const u = response.data[i]
                        temp.push({"fk_user": u.bg_user.id, "nickname": u.bg_user.nickname, "fk_betgame": betgameId, "avatar": u.bg_user.avatar, "source": u.bg_user.source})
                    }
                    if(currentUserId > 0) {
                        const t = temp.filter(user => user.fk_user === currentUserId)
                        if(t.length === 0) {
                            temp.push({"fk_user": currentUserId, "nickname": currentUser.nickname, "fk_betgame": betgameId, "avatar": currentUser.avatar, "source": currentUser.source })
                        }
                        temp.sort(function(a,b) {
                            if(a.fk_user === currentUserId) {
                                return -1
                            }
                            if(b.fk_user === currentUserId) {
                                return 1
                            }
                            var x = a.nickname.toUpperCase() < b.nickname.toUpperCase() ? -1 : 1;
                            return x;
                        })
                    }
                    commit('SET_BETGAME_USERS', temp)
                    resolve()
                })
                .catch(error => {
                    Vue.$log.debug('fetchBetgameUsers error ' + error)
                    reject()
                })
            });
        },
        updateBetGoalsHome ({ commit }, params ) {
            commit('UPDATE_BET_GOALS_HOME', params)
        },
        updateBetGoalsGuest ({ commit }, params ) {
            commit('UPDATE_BET_GOALS_GUEST', params)
        },
        fetchBetgameSlides ({ commit }, betgame) {
            APIService.getBetgameSlides(betgame)
            .then(response => {
                Vue.$log.debug('fetchBetgameSlides ' + JSON.stringify(response.data))
                commit('SET_BETGAME_SLIDES', response.data)
            })
            .catch(error => {
                Vue.$log.debug(error)
            })
        },
        fetchBetgameAds ({ commit }, betgame) {
            APIService.getBetgameAds(betgame)
            .then(response => {
                Vue.$log.debug('fetchBetgameAds ' + JSON.stringify(response.data))
                commit('SET_BETGAME_ADS', response.data)
            })
            .catch(error => {
                Vue.$log.debug(error)
            })
        },
        checkUserStatus ({ commit }, userid) {
            APIService.getUserStatus(userid)
            .then(response => {
                if(response.data.length === 1) {
                    if(response.data[0].status === 'active') {
                        commit('SET_USER_STATUS', 'active')
                    }
                }
            })
            .catch(error => {
                Vue.$log.debug(error)
            })
        }
    },
    getters: {
        pointsBetResult(state) {
            return state.betgame.points_bet_result
        },
        pointsBetGoaldiff(state) {
            return state.betgame.points_bet_goaldiff
        },
        pointsBetTendency(state) {
            return state.betgame.points_bet_tendency
        },
        getUserId (state) {
            if(state.user) {
                return state.user.id
            }
            return null
        },
        loggedIn (state) {
            var tokenExpired = false
            if (state.token) {
                try {
                    const payload = jwt_decode(state.token)
                    const expDate = payload.exp * 1000
                    if (expDate < Date.now()) {
                        tokenExpired = true
                    }
                } catch (e) {
                    tokenExpired = false
                }
            }
            return !!state.user && !!state.token && !tokenExpired
        },
        getClosestFutureMatch: state => {
            var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            if(!timeZone) {
                timeZone = 'Europe/Berlin'
            }
            const now = moment().tz(timeZone)
            var result = null
            var resultDate = null
            for (var i = 0; i < state.matches.length; i++) {
                const match = state.matches[i]
                //const matchdate = Date.parse(match.matchdate)
                const valueFromDB = moment.tz(String(match.matchdate), "Europe/Berlin")
                const matchdate =  valueFromDB.clone().tz(timeZone)
                if (matchdate > now) {
                    if (result == null || matchdate < resultDate) {
                        result = match
                        resultDate = matchdate
                    }
                }
            }
            return result
        },
        matchWithLiveGoals: state => {
            const now = Date.now()
            for (var i = 0; i < state.matches.length; i++) {
                const match = state.matches[i]
                if(isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest)) {
                    return true
                }
            }
            return false
        },
        getLiveMatch: state => {
            var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            if(!timeZone) {
                timeZone = 'Europe/Berlin'
            }
            const now = moment().tz(timeZone)
            for (var i = 0; i < state.matches.length; i++) {
                const match = state.matches[i]
                if(isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest)) {
                    return match
                }
                //const matchstart = moment(match.matchdate, MATCH_DATE_FORMAT, true)
                //const matchend = moment(match.matchdate, MATCH_DATE_FORMAT, true).add(120, 'minutes')
                const valueFromDB = moment.tz(String(match.matchdate), "Europe/Berlin")
                const matchstart =  valueFromDB.clone().tz(timeZone)
                const matchend =  valueFromDB.clone().tz(timeZone).add(120, 'minutes')
                if (matchstart < now && matchend > now && !isNumeric(match.goalshome) && !isNumeric(match.goalsguest)) {
                    return match
                }
            }
            return null
        },
        getMatchesByMatchday: state => matchday => {
            return state.matches.filter(match => match.matchday === matchday)
        },
        getMatchBet: state => match => {
            if(!state.matchBets || state.matchBets.length === 0) {
                return []
            }
            return state.matchBets.filter(matchBet => matchBet.fk_match === match)
        },
        getMatcheById: state => matchId => {
            return state.matches.filter(match => match.id === matchId)[0]
        },
        getTeamById: state => teamId => {
            return state.teams.filter(team => team.id === teamId)[0]
        },
        getMatchdayWin: state => (userId, matchday) => {
            return state.matchDayWins.filter(win => win.fk_user === userId && win.matchday === matchday)
        },
        getMatchdayWins: state => userId => {
            return state.matchDayWins.filter(win => win.fk_user === userId)
        },
        getAllBetByMatchUser: state => (betgame, matchId, userid) => {
            if(state.user.id === userid) {
                const b = state.bets.filter(bet => bet.fk_match === matchId && bet.fk_betgame == betgame && bet.fk_user === userid)
                if (b.length > 0) {
                    return b[0]
                } else {
                    return null
                }
            } else {
                const b = state.allbets.filter(bet => bet.fk_match === matchId && bet.fk_betgame == betgame && bet.fk_user === userid)
                if (b.length > 0) {
                    return b[0]
                } else {
                    return null
                }
            }
        },
        getBetByBetgameAndMatch: state => (betgame, matchId) => {
            const b = state.bets.filter(bet => bet.fk_match === matchId && bet.fk_betgame == betgame)
            if (b.length > 0) {
                return b[0]
            } else {
                return null
                /*
                const b = {
                  "fk_user": parseInt(state.user.id),
                  "fk_match": parseInt(matchId),
                  "fk_betgame": parseInt(2)
                }
                addBet(b)
                //state.bets.push(b)
                return b
                 */
            }
        },
        getMatchStats: state => matchId => {
            const ms = getBGValue('match_stats_' + matchId)
            if (ms !== undefined) {
                const msJSON = JSON.parse(ms)
                if (msJSON != undefined && msJSON != null) {
                    return msJSON
                }
            }
            var home_home_w = 0
            var home_home_d = 0
            var home_home_l = 0
            var home_guest_w = 0
            var home_guest_d = 0
            var home_guest_l = 0
            var guest_home_w = 0
            var guest_home_d = 0
            var guest_home_l = 0
            var guest_guest_w = 0
            var guest_guest_d = 0
            var guest_guest_l = 0
            const cur_match = state.matches.filter(match => match.id === matchId)[0]
            for (var i = 0; i < state.matches.length; i++) {
                var match = state.matches[i]
                if (match['id'] === cur_match.id || match['matchdate'] > cur_match.matchdate) {
                    continue
                }
                var gh = match['goalshome']
                var gg = match['goalsguest']
                if (gh == null || !isNumeric(gh) || gg == null || !isNumeric(gg)) {
                    continue
                }
                if (match['fk_team_home'] === cur_match.fk_team_home) {
                    if (gh > gg) {
                        home_home_w += 1
                    } else if (gh === gg) {
                        home_home_d += 1
                    } else {
                        home_home_l += 1
                    }
                } else if (match['fk_team_guest'] === cur_match.fk_team_home) {
                    if (gg > gh) {
                        home_guest_w += 1
                    } else if (gg === gh) {
                        home_guest_d += 1
                    } else {
                        home_guest_l += 1
                    }
                }
                if (match['fk_team_home'] === cur_match.fk_team_guest) {
                    if (gh > gg) {
                        guest_home_w += 1
                    } else if (gh === gg) {
                        guest_home_d += 1
                    } else {
                        guest_home_l += 1
                    }
                } else if (match['fk_team_guest'] === cur_match.fk_team_guest) {
                    if (gg > gh) {
                        guest_guest_w += 1
                    } else if (gg === gh) {
                        guest_guest_d += 1
                    } else {
                        guest_guest_l += 1
                    }
                }
            }
            
            //state.matches.sort(function (a, b) {
            //  return b.matchdate.localeCompare(a.matchdate);
            //});
            
            var last_matches_home = []
            var last_matches_guest = []
            for (var i = 0; i < state.matches.length; i++) {
                var match = state.matches[i]
                if (match['matchdate'] < cur_match.matchdate && isNumeric(match['goalshome']) && isNumeric(match['goalsguest'])) {
                    if (match['fk_team_home'] == cur_match.fk_team_home || match['fk_team_guest'] == cur_match.fk_team_home) {
                        last_matches_home.push(match)
                    }
                    if (match['fk_team_home'] == cur_match.fk_team_guest || match['fk_team_guest'] == cur_match.fk_team_guest) {
                        last_matches_guest.push(match)
                    }
                }
                
            }
            
            const result = {
                'home': {
                    'home_w': home_home_w,
                    'home_d': home_home_d,
                    'home_l': home_home_l,
                    'guest_w': home_guest_w,
                    'guest_d': home_guest_d,
                    'guest_l': home_guest_l,
                    'last_matches': last_matches_home.slice(0, Math.min(5, last_matches_home.length))
                },
                'guest': {
                    'home_w': guest_home_w,
                    'home_d': guest_home_d,
                    'home_l': guest_home_l,
                    'guest_w': guest_guest_w,
                    'guest_d': guest_guest_d,
                    'guest_l': guest_guest_l,
                    'last_matches': last_matches_guest.slice(0, Math.min(5, last_matches_guest.length))
                }
            }
            
            // localStorage.setItem('match_stats_' + matchId, JSON.stringify(result))
            setBGValue('match_stats_' + matchId, JSON.stringify(result))
            
            return result
        },
        getRankingForDashboard(state) {
            if(state.userNeighbourRankings) {
                const dashboardRankings = [...state.userNeighbourRankings]
                return dashboardRankings
            }
            return []
        },
        getTablePositions: state => match => {
            var result = []
            for (var i = 0; i < state.positions.length; i++) {
                const pos = state.positions[i]
                if(pos.fk_team === match.fk_team_home || pos.fk_team === match.fk_team_guest) {
                    result.push(pos)
                }
            }
            return result
        },
        getUserRanking: state => userId => {
            var result = []
            for (var i = 0; i < state.rankings.length; i++) {
                const r = state.rankings[i]
                if(r.fk_user === userId) {
                    return r
                }
            }
        },
        matchdayFinished: state => matchday => {
            if(state.matches === undefined || state.matches === null || state.matches.length === 0) {
                return true
            }
            const matchdayMatches = state.matches.filter(match => match.matchday === matchday)
            for (var i = 0; i < matchdayMatches.length; i++) {
                const match = matchdayMatches[i]
                if(!isNumeric(match.goalshome) || !isNumeric(match.goalsguest)) {
                    return false
                }
            }
            return true
        },
        getCachingDuration(state) {
            if(state.matches === undefined ||state.matches === null || state.matches.length === 0) {
                return 0
            }
            if(state.runningMatches.length > 0) {
                return 10
            } else if(state.todaysMatches.length > 0) {
                return 30
            } else {
                return 60 * 60
            }
        },
        finishedMatches(state) {
            return state.matches.filter(match => isNumeric(match.goalshome) && isNumeric(match.goalsguest)).length
        },
        startedMatches(state) {
            return state.matches.filter(match => (isNumeric(match.goalshome) && isNumeric(match.goalsguest)) ||
              (isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest))).length
        },
        packageVersion() {
            return process.env.PACKAGE_VERSION || '0'
        },
        isUserConnectedToBetgame: state => (betgame) => {
            const bg1 = state.betgames_owner.filter(bg => bg.id === betgame)
            if(bg1.length === 1) {
                return true
            }
            const bg2 = state.betgames_author.filter(bg => bg.id === betgame)
            if(bg2.length === 1) {
                return true
            }
            const bg3 = state.betgames_participant.filter(bg => bg.id === betgame)
            if(bg3.length === 1) {
                return true
            }
            return false
        },
        getUserAvatar: state => userid => {
            if(state.user && parseInt(state.user.id) === parseInt(userid)) {
                return state.user.avatar
            }
            if(state.betgameUsers === undefined || state.betgameUsers.length === 0) {
                return null
            }
            const u = state.betgameUsers.filter(user => parseInt(user.fk_user) === parseInt(userid))
            if(u !== undefined && u.length === 1) {
                return u[0].avatar
            }
            return null
        },
    },
    strict: process.env.NODE_ENV !== 'production',
    //plugins: [vuexLocal.plugin, createLogger({})]
    plugins: [vuexLocal.plugin]
})

export const menustore = Vue.observable({
    isNavOpen: false
});

export const menumutations = {
    setIsNavOpen(yesno) {
        menustore.isNavOpen = yesno;
    },
    toggleNav() {
        menustore.isNavOpen = !menustore.isNavOpen;
    }
};
