/* eslint-disable */
<template>
  <div id="app">
    
    <keep-alive>
      <Sidebar id="sideBarId" ref="sideBarRef" v-touch:swipe.left="swipeHandler">
      </Sidebar>
    </keep-alive>
    
    <!--
    <NavigationBar v-if="loggedIn">
      <template v-slot:left>
        // menu, profile, macthdayselect, text (center, 2-zeilen, back)
        <button v-if="" v-on:click="openMenu">Menu</button>
        <button v-else-if="" v-on:click="openMenu">Menu</button>
      </template>
      <template v-slot:center>
        Tippabgabe<br>{{ matchday }}. Spieltag ({{ matchdayCount }})
      </template>
      <template v-slot:right>
        <button v-on:click="openMatchdaySelect">Select</button>
      </template>
    </NavigationBar>
    -->
    
    <router-view v-bind:class="{ pageoffset: true }" class="page" />

    <div data-iframe-height></div>
    
  </div>
</template>

<script>
import { authComputed } from '@/vuex/helpers.js'
import NavigationBar from './components/NavigationBar'
import { menumutations } from '@/vuex/store.js'
import jwt_decode from 'jwt-decode'
import { PADDING_BOTTOM_DEFAULT, PADDING_BOTTOM_DSP, PADDING_BOTTOM_FUNKE, TOAST_DURATION } from './main'
import Sidebar from './components/Menu/Sidebar'
import { setOptions, bootstrap } from 'vue-gtag'
import Numeral from 'numeral'
import moment from 'moment'

export default {
  components: { NavigationBar, Sidebar },
  data() {
    return {
      showValidationHint: true
    }
  },
  computed: {
    ...authComputed
  },
  beforeCreate: function() {
    this.$log.debug('app beforeCreate ' + this.$route.query.code + ' --- ' + this.$route.query.email + ' --- ' + document.domain)
    const api = this.$route.query.api
    if(api !== undefined && api !== null && api.length > 0 && api.includes('teamtip.net')) {
      if(api.startsWith('http')) {
        window.localStorage.setItem('spmau', api)
      } else {
        window.localStorage.setItem('spmau', 'https://' + api)
      }
    } else {
      const apiUrl = window.localStorage.getItem('spmau')
      if(apiUrl === undefined || apiUrl === null || apiUrl === 'undefined') {
        const domain = document.domain
        if (domain !== undefined && (domain.includes('teamtip') || domain.includes('tippspiel') || domain.includes('deinsportplatz.de'))) {
          window.localStorage.setItem('spmau', 'https://' + domain)
          if (domain === 'wm2022.ovb24.teamtip.net') {
            window.localStorage.setItem('spmau', 'https://ovb24.teamtip.net')
          }
        } else {
          window.localStorage.setItem('spmau', '')
        }
      }
    }
    var id = this.$route.query.id
    if(id === undefined || document.domain !== 'localhost') {
      const domain = document.domain
      if(domain === 'boyens.teamtip.net') {
        id = '1'
      } else if(domain === 'snh.teamtip.net') {
        id = '2'
      } else if(domain === 'pz.teamtip.net') {
        id = '12'
        this.$store.dispatch('setCurrentSeason', { id: 13, name: '2022/23', is_current: true }).then(() => {
        })
      } else if(domain === 'fubanews.teamtip.net') {
        id = '4'
      } else if(domain === 'flarena.teamtip.net') {
        id = '5'
      } else if(domain === 'elfer.teamtip.net') {
        id = '6'
      } else if(domain === 'mm.teamtip.net') {
        id = '7'
      } else if(domain === 'fussifreunde.teamtip.net') {
        id = '1302'
        this.$store.dispatch('setCurrentSeason', { id: 12, name: '2021/22', is_current: true }).then(() => {
        })
      } else if(domain === 'ovb24.teamtip.net') {
        id = '1407'
        this.$store.dispatch('setCurrentSeason', { id: 15, name: '2024/25', is_current: true }).then(() => {
        })
      } else if (domain === 'wm2022.ovb24.teamtip.net') {
        id = '1404'
        this.$store.dispatch('setCurrentSeason', { id: 13, name: '2022/23', is_current: true }).then(() => {
        })
      } else if(domain === 'reviersport.teamtip.net') {
        id = '1607'
        this.$store.dispatch('setCurrentSeason', { id: 15, name: '2024/25', is_current: true }).then(() => {
        })
      } else if(domain === 'wm2022.reviersport.teamtip.net') {
        id = '1604'
        this.$store.dispatch('setCurrentSeason', { id: 13, name: '2022/23', is_current: true }).then(() => {
        })
      } else if(domain === 'nwz.teamtip.net') {
        id = '1702'
        this.$store.dispatch('setCurrentSeason', { id: 13, name: '2022/23', is_current: true }).then(() => {
        })
      } else if(domain === 'ez.teamtip.net') {
        id = '1902'
        this.$store.dispatch('setCurrentSeason', { id: 13, name: '2022/23', is_current: true }).then(() => {
        })
      } else if(domain === 'polygon.teamtip.net') {
        id = '3003'
        this.$store.dispatch('setCurrentSeason', { id: 14, name: '2023/24', is_current: true }).then(() => {
        })
      } else if(domain === 'bl1.funke.teamtip.net') {
        id = '4001'
        this.$store.dispatch('setCurrentSeason', { id: 14, name: '2023/24', is_current: true }).then(() => {
        })
      } else if(domain === 'bl2.funke.teamtip.net') {
        id = '4002'
        this.$store.dispatch('setCurrentSeason', { id: 14, name: '2023/24', is_current: true }).then(() => {
        })
      } else if(domain === 'em.funke.teamtip.net') {
        id = '4003'
        this.$store.dispatch('setCurrentSeason', { id: 14, name: '2023/24', is_current: true }).then(() => {
        })
      } else if(domain === 'fn.teamtip.net') {
        id = '5001'
        this.$store.dispatch('setCurrentSeason', { id: 14, name: '2023/24', is_current: true }).then(() => {
        })
      } else if(domain === 'sb.teamtip.net') {
        id = '6001'
        this.$store.dispatch('setCurrentSeason', { id: 14, name: '2023/24', is_current: true }).then(() => {
        })
      }
      
    }
    if(id === undefined) {
      id = this.$route.query.betgameid
      if(id === undefined) {
        id = window.localStorage.getItem('spmbgid')
        if(id === undefined) {
          id = undefined
        }
      }
    }
    window.localStorage.setItem('spmbgid', id)
    if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
      window.localStorage.setItem('betgameId', id)
    }
    const src = this.$route.query.src
    if(src !== undefined) {
      window.localStorage.setItem('userSource', src)
    } else {
      const domain = document.domain
      const s = domain.replace('.teamtip.net','')
      window.localStorage.setItem('userSource', s)
    }
    this.$log.debug('app beforeCreate id ' + id + ' --- api ' + window.localStorage.getItem('spmau') + ' --- src ' + src)
  },
  created () {
    this.$log.debug('app created ' + this.$route.params.id + ' --- ' + this.$route.name + ' --- ' + this.$route.query.code + ' --- ' + this.$route.query.email)
    if(this.getBGValue('spmau')) {
      this.$store.dispatch('setApiUrl', this.getBGValue('spmau'))
    }
    if((this.$route.name === 'validateregistration' || this.$route.name === 'vr') && this.$route.query.code && this.$route.query.email) {
      this.showValidationHint = false
      this.$store
      .dispatch(
              'validateRegistration',
              {
                email: this.$route.query.email,
                token: this.$route.query.code
              }
      )
      .then(() => {
        this.$toast.open({ message: this.$i18n.t('register.registration_finished_good_luck'), position: 'top', duration: TOAST_DURATION, type: 'success' })
        if(this.$store.getters.loggedIn) {
          this.$router.push({ name: 'dashboard' }).catch(err => {})
        } else {
          this.$router.push({ name: 'home', query: { email: this.$route.query.email } })
        }
      }).catch(function (error) {
      })
    }
    if(this.$store.getters.loggedIn && this.$store.state.user.id) {
        if(this.$store.state.user.lang) {
          this.$store.state.language = this.$store.state.user.lang
          Numeral.locale(this.$store.state.user.lang)
          moment.locale(this.$store.state.user.lang)
          if(this.$i18n.locale !== this.$store.state.user.lang) {
            this.$log.debug('app created set i18n.locale ' + this.$store.state.user.lang)
            this.$i18n.locale = this.$store.state.user.lang
          } else {
            this.$log.debug('app created use i18n.locale ' + this.$store.state.user.lang)
          }
        }
        if (this.$store.state.betgame.analytics && this.$store.state.betgame.analytics.length > 0) {
          const codes = this.$store.state.betgame.analytics.split(",")
          if(codes.length > 0) {
            if(codes.length > 1) {
              setOptions({
                config: { id: codes[0] },
                includes: [
                  { id: codes[1] } ]
              })
            } else {
              setOptions({
                config: { id: codes[0] }
              }) 
            }
            bootstrap().then(gtag => {
              this.$gtag.set({
                'user_id': '' + this.$store.state.user.id,
                'anonymize_ip': true,
                'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
                'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
                'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set'
              })
              this.$gtag.pageview({
                page_title: this.$route.name,
                page_path: this.$route.path,
                page_location: window.location.href
              })
            })
          }
      }
      const ltr = new Date(this.getBGValue('spmltr'))
      this.setBGValue('spmltrc', new Date())
      if(ltr === undefined || ltr.getDate() !== new Date().getDate() || ltr.getFullYear() !== new Date().getFullYear() || ltr.getMonth() !== new Date().getMonth()) {
        const data = {
          'id': '' + this.$store.state.user.id
        }
        this.$store.dispatch('refreshToken', data).then(response => {
        })
      }
    }
    if ((!this.$store.state.user || !this.$store.state.user.lang) && this.$store.state.language) {
      Numeral.locale(this.$store.state.language)
      moment.locale(this.$store.state.language)
      if (this.$i18n.locale !== this.$store.state.language) {
        this.$log.debug('app created set i18n.locale ' + this.$store.state.language)
        this.$i18n.locale = this.$store.state.language
      } else {
        this.$log.debug('app created use i18n.locale ' + this.$i18n.locale)
      }
    }
    this.$log.debug("this.getBGValue('spmbgid'): " + this.getBGValue('spmbgid'))
    if(this.getBGValue('spmbgid') === 'null' || this.getBGValue('spmbgid') === undefined || this.getBGValue('spmbgid') === 'undefined') {
      this.clearBGValue('spmbgid')
      if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
        this.clearBGValue('betgameId')
      }
    }
    if(this.$route.params.id && this.$route.name === 'join') {
      this.setBGValue('spmbgid', this.$route.params.id)
      if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
        this.setBGValue('betgameId', this.$route.params.id)
      }
    }
    if(this.$store.state.geoData === null) {
      this.$store.dispatch('getGeoData', []).then(response => {
        }, error => {
        })
    }
    if(this.getBGValue('spmbgid')) {
      const id = this.getBGValue('spmbgid')
      this.$log.debug("app created bg.id: " + id)
      this.$store.dispatch('setBetgameId', id)
      //this.$store.dispatch('fetchBetgameUsers', id)
      if(!this.$store.state.betgame || parseInt(this.$store.state.betgame.id) !== parseInt(id)) {
        this.$store.dispatch('fetchBetgame', id).then(() => {
          this.$store.dispatch('fetchBetgameSlides', id).then(() => {
          })
          this.$store.dispatch('fetchBetgameAds', id).then(() => {
          })
          this.styleDocument()
        })
      } else {
        if(this.$store.state.betgame) {
          this.styleDocument()
        }
      }
      if(!this.$store.state.currentSeason) {
        this.$store.dispatch('fetchCurrentSeason', id)
      }
      if(this.$store.state.token && this.$store.state.token.length > 0 &&
          (!this.$store.state.leagueId || !this.$store.state.teams || this.$store.state.teams.length === 0)) {
        this.$store.dispatch('fetchBetgameLeague', id).then(() => {
          this.$store.dispatch('fetchTeams', this.$store.state.leagueId).then(() => {
      
          })
        })
      }
    }
    if(this.getBGValue('userSource')) {
      this.$store.dispatch('setUserSource', this.getBGValue('userSource'))
    }
    this.$log.debug('app created before token')
    if(this.$store.state.token) {
      const r = window.localStorage.getItem('spmlr')
      this.$log.debug('app created before token lastr ' + r)
      if(r !== undefined && r !== null && r.length >= 0 && r !== 'undefined') {
        if(this.$route.query !== null && this.$route.query !== undefined) {
          this.$router.push({ name: r, query: this.$route.query }).catch(err => {
          })
        } else {
          this.$router.push({ name: r }).catch(err => {
          })
        }
      } else {
        this.$router.push({ name: 'dashboard' }).catch(err => {})
      }
    }
  },
  mounted: function () {
    this.$log.debug('app mounted '  + this.$route.query.id + ' --- ' + this.$route.query.code + ' --- ' + this.$route.query.email)
    if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
      window.bgIsLoggedIn = this.bgIsLoggedIn
      window.bgRouterPush = this.bgRouterPush
    }
    if(this.showValidationHint && this.$store.state.user && this.$store.state.user.status !== undefined && this.$store.state.user.status !== 'active') {
      this.$toast.open({ message: this.$i18n.t('register.please_finish_registration'),
        position: 'top',
        duration: TOAST_DURATION,
        type: 'error'
      })
    }
    if(this.$store.state.betgame && this.$store.state.betgame.name) {
      document.title = this.$store.state.betgame.name
    }
  },
  methods: {
    closeSidebarPanel: menumutations.toggleNav,
    swipeHandler (direction) {
      this.closeSidebarPanel()
    },
    bgIsLoggedIn(data) {
      return this.$store.getters.loggedIn
    },
    bgRouterPush(path) {
      this.$router.push( path ).catch(err => {})
    },
    styleDocument() {
      if(this.$store.state.betgame && this.$store.state.betgame.name) {
        document.title = this.$store.state.betgame.name
      }
      if(this.$store.state.betgame && this.$store.state.betgame.primary_color) {
        const rgb = this.hexToRgb(this.$store.state.betgame.primary_color)
        document.documentElement.style.setProperty('--color-primary', this.$store.state.betgame.primary_color)
        document.documentElement.style.setProperty('--color-primary-rgb', '' + rgb.r + ',' + rgb.g + ',' + rgb.b)
      } else {
        document.documentElement.style.setProperty('--color-primary', '#0C3EC1')
        document.documentElement.style.setProperty('--color-primary-rgb', '12,62,193')
      }
      if(this.$store.state.betgame && this.$store.state.betgame.button_color) {
        const rgb = this.hexToRgb(this.$store.state.betgame.button_color)
        document.documentElement.style.setProperty('--color-button', this.$store.state.betgame.button_color)
        document.documentElement.style.setProperty('--color-button-rgb', '' + rgb.r + ',' + rgb.g + ',' + rgb.b)
      } else {
        document.documentElement.style.setProperty('--color-button', '#0C3EC1')
        document.documentElement.style.setProperty('--color-button-rgb', '12,62,193')
      }
      if(this.$store.state.betgame && this.$store.state.betgame.link_color) {
        const rgb = this.hexToRgb(this.$store.state.betgame.link_color)
        document.documentElement.style.setProperty('--color-link', this.$store.state.betgame.link_color)
        document.documentElement.style.setProperty('--color-link-rgb', '' + rgb.r + ',' + rgb.g + ',' + rgb.b)
      } else {
        document.documentElement.style.setProperty('--color-link', '#0C3EC1')
        document.documentElement.style.setProperty('--color-link-rgb', '12,62,193')
      }      
    }
  },
  watch:{
    $route (to, from){
      // this.$log.debug(from.path + " --- " + to.patch + " --- " + this.$router.currentRoute.path)
    }
  }
}
</script>

<style lang="scss">
  
  @import './assets/styles/global.scss';

  #app {
    height: 100%;
  }
  
  .pageoffset {
    padding-top: 64px !important;
  }
  
</style>
