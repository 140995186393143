import { render, staticRenderFns } from "./BetgameEdit.vue?vue&type=template&id=0ce2101f&scoped=true"
import script from "./BetgameEdit.vue?vue&type=script&lang=js"
export * from "./BetgameEdit.vue?vue&type=script&lang=js"
import style0 from "./BetgameEdit.vue?vue&type=style&index=0&id=0ce2101f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ce2101f",
  null
  
)

export default component.exports