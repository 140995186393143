<template>
    <div class="betgameeditbetmode" id="betgameeditbetmode">
        
        <NavigationBar>
            <template v-slot:left>
                <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
            </template>
            <template v-slot:center>
              {{ $t('betgame_settings.bet_mode') }}
            </template>
            <template v-slot:right>
              <div v-on:click="openHelp"><HintIcon class="navigation_icon"></HintIcon></div>
            </template>
        </NavigationBar>
        
        <ul class="error-container">
            <li v-for="error in errors">{{ error }}</li>
        </ul>

        <form @submit.prevent="changeBetgameBetmode">
            <div class="input-container">
              <div>
                <div class="input-header">
                  {{ $t('betgame_settings.bet_mode_select') }}
                </div>
                <div class="input-infotext">
                  {{ $t('betgame_settings.bet_mode_description_3') }}
                </div>
                <div class="input-radio">
                  <input :disabled="!isEditable" v-model="betgame.bet_mode" v-bind:value="1" type="radio" id="result" name="betmode"/>
                  <label for="result">{{ $t('betgame_settings.bet_mode_result') }}</label>
                </div>
                <div class="input-radio">
                  <input :disabled="!isEditable" v-model="betgame.bet_mode" v-bind:value="2" type="radio" id="toto" name="betmode"/>
                  <label for="toto">{{ $t('betgame_settings.bet_mode_toto') }}</label>
                </div>
              </div>
              <div class="input-button-row">
                <button name="button" type="submit">
                  {{ $t('button_save_text') }}
                </button>
              </div>
            </div>
        </form>

        <modal class="modal" name="edit-points-help" id="edit-points-help" :scrollable="true" width="100%" height="auto">
          <div class="modal-header">
            <div class="modal-header-left"></div>
            <div class="modal-header-center">{{ $t('help') }}</div>
            <div class="modal-header-right">
              <button
                type="button"
                class="btn-close"
                @click="$modal.hide('edit-points-help')"
                aria-label="Close modal">
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div class="table-view">
              <div class="table-view-title">
                {{ $t('betgame_settings.bet_mode_result') }}
              </div>
              <div class="table-view-cell grouped">
                <div class="table-view-cell-label">
                  {{ $t('betgame_settings.bet_mode_description_1') }}
                </div>
              </div>
              <div class="table-view-title">
                {{ $t('betgame_settings.bet_mode_toto') }}
              </div>
              <div class="table-view-cell grouped">
                <div class="table-view-cell-label">
                  {{ $t('betgame_settings.bet_mode_description_2') }}
                </div>
              </div>
            </div>
          </div>
        </modal>
    </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  import HintIcon from './../components/icons/HintIcon'
  
  export default {
    name: 'BetgameEditBetmode',
    components: { NavigationBar, HintIcon },
    data () {
      return {
        betgame: null,
        errors: []
      }
    },
    created () {
      this.betgame = this.$route.params.betgame
    },
    mounted () {
      if (this.betgame === null || this.betgame === undefined) {
        this.goBack()
      }
    },
    computed: {
      isEditable() {
        return this.$store.getters.startedMatches === 0 || this.$store.state.betCount === 0
      },
    },
    methods: {
      openHelp() {
        this.$modal.show('edit-points-help');
      },
      updateBetgame (data) {
        this.$store.dispatch('patchBetgame', data)
        .then(() => {
          this.$toast.open({ message: this.$i18n.t('save_changes_success'),
            position: 'top',
            duration: TOAST_DURATION,
            type: 'success'
          })
          this.goBack()
        })
        .catch(function (error) {
          this.$toast.open({ message: this.$i18n.t('save_changes_error'),
            position: 'top',
            duration: TOAST_DURATION,
            type: 'error'
          })
        })
        .finally(function () {
        })
      },
      goBack () {
        this.$router.push('/betgameedit')
      },
      changeBetgameBetmode () {
        this.errors = []
        if (this.errors.length === 0) {
          this.$store.dispatch('setBetgame', this.betgame)
          this.updateBetgame({ bet_mode: this.betgame.bet_mode })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .betgameeditbetmode {
        
        form {
            padding-top: 16px;
        }
        
        h1 {
            text-align: center;
        }
        
        p {
            text-align: center;
            margin-bottom: 26px;
        }
    }

    
  .grouped {
    margin-top: -16px;
  }

  .table-view {
    margin-bottom: 0;
  }

  .table-view-cell:last-child {
    border-bottom: 0;
  }

  .table-view-cell-label {
    color: rgba($colorGreyDark, .48);
    font-weight: 400;
  }
</style>
