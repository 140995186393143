<template>
    <div class="betgameEditName" id="betgameEditName">
        
        <NavigationBar>
            <template v-slot:left>
                <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
            </template>
            <template v-slot:center>
              {{ $t('betgame_settings.betgame_name') }}
            </template>
            <template v-slot:right>
            </template>
        </NavigationBar>

        <ul class="error-container">
            <li v-for="error in errors">{{ error }}</li>
        </ul>
        
        <form @submit.prevent="changeBetgameName">
            
            <div class="input-container">
                
                <div>
                    <div class="input-element">
                        <input disabled id="betgamename_old" name="betgamename_old" placeholder="betgamename_old"
                               type="text" v-model="betgamename_old" value/>
                        <label for="betgamename_old">{{ $t('betgame_settings.current_betgame_name') }}</label>
                    </div>
                    
                    <div class="input-element">
                        <input autofocus id="betgamename_new" name="betgamename_new" placeholder="betgamename_new"
                               type="text" v-model="betgame.name" value/>
                        <label for="betgamename_new">{{ $t('betgame_settings.new_betgame_name') }}</label>
                    </div>
                </div>
                
                <div class="input-button-row">
                    <button name="button" type="submit">
                      {{ $t('button_save_text') }}
                    </button>
                </div>
            
            </div>
        
        </form>
    
    </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  
  export default {
    name: 'BetgameEditName',
    components: { NavigationBar },
    data () {
      return {
        betgamename_old: '',
        betgame: null,
        errors: []
      }
    },
    created () {
      this.betgame = this.$route.params.betgame
      this.betgamename_old = this.betgame.name
    },
    mounted () {
      if (this.betgame === null || this.betgame === undefined) {
        this.goBack()
      }
    },
    methods: {
      updateBetgame (data) {
        this.$store.dispatch('patchBetgame', data)
        .then(() => {
          this.$toast.open({ message: this.$i18n.t('save_changes_success'),
            position: 'top',
            duration: TOAST_DURATION,
            type: 'success'
          })
          this.goBack()
        })
        .catch(function (error) {
          this.$toast.open({ message: this.$i18n.t('save_changes_error'),
            position: 'top',
            duration: TOAST_DURATION,
            type: 'error'
          })
        })
        .finally(function () {
        })
      },
      goBack () {
        this.$router.push('/betgameedit')
      },
      changeBetgameName () {
        this.errors = []
        if (this.isNullOrUndefined(this.betgame.name) || this.betgame.name.length === 0) {
          this.errors.push('Bitte gib einen neuen Namen für deine Tipprunde an.')
        }
        if (this.betgame.name.length > 99) {
          this.errors.push('Maximal 100 Zeichen')
        }
        if (this.errors.length === 0 && this.betgame.name !== this.betgamename_old) {
          this.betgamename_old = this.betgame.name
          this.$store.dispatch('setBetgame', this.betgame)
          this.updateBetgame({ name: this.betgame.name })
        }
      },
      isNullOrUndefined (data) {
        return data === null || data === undefined
      }
    }
  }
</script>

<style lang="scss" scoped>
    .betgameEditName {
        
        form {
            padding-top: 16px;
        }
        
        h1 {
            text-align: center;
        }
        
        p {
            text-align: center;
            margin-bottom: 26px;
        }
    }
</style>
