<template>
  <div class="privacy" v-bind:style="{ paddingBottom: '96px' }">

    <NavigationBar>
      <template v-slot:left>
        <div v-if="canGoBack()">
          <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
        </div>
        <div v-else>
        </div>
      </template>
      <template v-slot:center>
        {{ $t('profile.data_protection') }}
      </template>
    </NavigationBar>

    <div v-if="privacyText" v-html="privacyText" style="margin-top: 24px; margin-bottom:  24px;"></div>
    <div v-else-if="userlang === 'de'">

      <div style="margin-top: 24px;">
        <h1>Inhaltsverzeichnis</h1>
      </div>

      <ul style="list-style-type: none; padding-left: 0px;">
        <li><a href="#paragraph_1">1. Datenschutz</a></li>
        <li><a href="#paragraph_2">2. Verantwortlicher</a></li>
        <li><a href="#paragraph_3">3. Allgemeines zur Datenverarbeitung</a></li>
        <li><a href="#paragraph_4">4. Bereitstellung der Webseite und Erstellung von Logfiles</a></li>
        <li><a href="#paragraph_5">5. Datenverarbeitung bei registrierten Nutzern</a></li>
        <li><a href="#paragraph_6">6. Minderjährige</a></li>
        <li><a href="#paragraph_7">7. Externes Hosting und Content Delivery Networks (CDN)</a></li>
        <li><a href="#paragraph_8">8. Kontakt</a></li>
        <li><a href="#paragraph_9">9. Newsletterdaten</a></li>
        <li><a href="#paragraph_10">10. Verwendung von Cookies und Analysewerkzeugen</a>
          <ul style="list-style-type: none;">
            <li><a href="#paragraph_10_a">a) Für den Betrieb erforderliche Cookies</a></li>
            <li><a href="#paragraph_10_b">b) Nicht zwingend erforderliche Cookies</a></li>
          </ul>
        </li>
        <li><a href="#paragraph_11">11. Plugins und Tools</a></li>
        <li><a href="#paragraph_12">12. Verschlüsselung</a></li>
        <li><a href="#paragraph_13">13. Datenschutzbeauftragter</a></li>
        <li><a href="#paragraph_14">14. Ihre Rechte</a>
          <ul style="list-style-type: none;">
            <li><a href="#paragraph_14_a">a) Auskunftsrecht</a></li>
            <li><a href="#paragraph_14_b">b) Recht auf Berichtigung</a></li>
            <li><a href="#paragraph_14_c">c) Recht auf Einschränkung der Verarbeitung</a></li>
            <li><a href="#paragraph_14_d">d) Recht auf Löschung</a></li>
            <li><a href="#paragraph_14_e">e) Recht auf Unterrichtung</a></li>
            <li><a href="#paragraph_14_f">f) Recht auf Datenübertragbarkeit</a></li>
            <li><a href="#paragraph_14_g">g) Widerspruchsrecht</a></li>
            <li><a href="#paragraph_14_h">h) Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</a>
            </li>
            <li><a href="#paragraph_14_i">i) Automatisierte Entscheidung im Einzelfall einschließlich Profiling</a></li>
            <li><a href="#paragraph_14_j">j) Recht auf Beschwerde bei einer Aufsichtsbehörde</a></li>
          </ul>
        </li>
        <li><a href="#paragraph_9">15. Änderung dieser Datenschutzhinweise</a></li>
      </ul>

      <div style="margin-top: 48px;">

        <div id="paragraph_1">
          <h3>1. Datenschutz</h3>
          <p>
            Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzhinweise.
          </p>
          <p>
            Diese Datenschutzhinweise gelten für die Verarbeitung personenbezogener Daten durch uns auf unserer Webseite <a href="https://www.teamtip.net/" target="_blank">teamtip.net</a> („Webseite“) sowie für unsere Web-Applikation (im Folgenden „Web-Applikation“) und unsere mobilen Apps (im Folgenden „Apps“) (Zusammen im Folgenden „Applikationen“). In ihnen werden Art, Zweck und Umfang der Datenverarbeitung im Rahmen der Webseite, der Web-Applikation und der mobilen Apps erläutert.
          </p>
          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
          </p>
        </div>

        <div id="paragraph_2">
          <h3>2. Verantwortlicher</h3>
          <p>
            „Verantwortlicher“ ist die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
          </p>
          <p>
            Verantwortlicher für die Datenverarbeitung zur Bereitstellung der Leistungen innerhalb der Webseite <a href="https://www.teamtip.net/" target="_blank">teamtip.net</a> und innerhalb der Applikationen ist:
          </p>
          <p>
            <b>SPM Sportplatz-Media GmbH</b><br>
            Schleidenstraße 3<br>
            22083 Hamburg<br>
            E-Mail: <a href="mailto:info@teamtip.net">info@teamtip.net</a><br>
            Webseite: <a href="https://www.teamtip.net/" target="_blank">https://www.teamtip.net/</a><br>
            Tel.: +49 40 537 9863 - 30<br>
          </p>
          <p>
            Für die Datenverarbeitung innerhalb einer Tipprunde in den Applikationen ist bzw. sind der oder die jeweiligen Admins Verantwortlicher.
          </p>
          <p>
            Wir (SPM Sportplatz-Media GmbH) handeln bezüglich dieser Datenverarbeitung insoweit nur im Auftrag und nach Weisung des Admins (Auftragsverarbeitung). Datenschutzhinweise des Adminaccounts für die Datenverarbeitung innerhalb einer Tipprunde finden Sie in den Einstellungen unter „Datenschutz (Admin)“
          </p>
          <p>
            Die nachfolgenden Datenschutzhinweise gelten daher nur für die Verarbeitung personenbezogener Daten unter unserer (SPM Sportplatz-Media GmbH) Verantwortlichkeit.
          </p>
        </div>

        <div id="paragraph_3">
          <h3>3. Allgemeines zur Datenverarbeitung</h3>
          <h6>Umfang der Verarbeitung personenbezogener Daten</h6>
          <p>
            Wir erheben und verwenden personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur Bereitstellung unserer Applikationen, unserer Inhalte und unserer Leistungen erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.
          </p>
          <h6>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h6>
          <p>
            Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage für die Verarbeitung personenbezogener Daten.
          </p>
          <p>
            Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage für die Verarbeitung personenbezogener Daten.
          </p>
          <p>
            Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.
          </p>
          <p>
            Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
          </p>
          <p>
            Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.
          </p>
          <h6>Datenlöschung und Speicherdauer</h6>
          <p>
            Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus dann erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
          </p>
          <h6>Hinweis zur Datenweitergabe in die USA</h6>
          <p>
            Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den USA eingebunden. Wenn diese Tools aktiv sind, können Ihre personenbezogenen Daten an die US-Server der jeweiligen Unternehmen weitergegeben werden. Die Verarbeitung personenbezogener Daten in den USA geschieht nur, wenn die Unternehmen mit uns und ihren Unterauftragsverarbeitern sog. EU-Standardvertragsklauseln abgeschlossen haben. Dies bietet hinreichende Garantien für einen angemessenen Datenschutz i.S.d. Art. 46 DSGVO.
          </p>
        </div>

        <div id="paragraph_4">
          <h3>4. Bereitstellung der Webseite und Erstellung von Logfiles</h3>
          <p>
            Bei jedem Aufruf unserer Webseite (einschließlich der Web-Applikation) erfasst unser System automatisiert Daten und Informationen vom Computersystem des aufrufenden Rechners. Folgende Daten werden hierbei zeitlich begrenzt erhoben:
          </p>
            <ul>
              <li>Die IP-Adresse des Nutzers</li>
              <li>Datum und Uhrzeit des Zugriffs</li>
              <li>Useragent des Nutzers</li>
              <li>Den Pfad zur aufgerufenen Seite</li>
            </ul>
          <p>
            Die Daten werden in den Logfiles unseres Systems gespeichert. Diese Daten werden nur zur Analyse etwaiger Störungen benötigt und spätestens binnen 30 Tagen gelöscht. Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO. Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der Webseite an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert bleiben. Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Webseite sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Webseite und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt und Rückschlüsse auf Ihre Person werden nicht gezogen. In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO. Die Erfassung der Daten zur Bereitstellung der Webseite und die Speicherung der Daten in Logfiles ist für den Betrieb der Webseite zwingend erforderlich. Es besteht folglich seitens des Nutzers keine Widerspruchsmöglichkeit.
          </p>
        </div>

        <div id="paragraph_5">
          <h3>5. Datenverarbeitung bei registrierten Nutzern</h3>
          <p>
            Wenn Sie sich registrieren und unsere Applikationen nutzen, werden folgende personenbezogenen Daten von Ihnen erfasst:
          </p>
          <ul>
            <li>Vor- und Nachname</li>
            <li>Benutzername</li>
            <li>Alter über/unter 18</li>
            <li>E-Mail-Adresse</li>
          </ul>
          <p>
            Ferner verarbeiten wir folgende technische Daten:
          </p>
          <ul>
            <li>IP-Adressen</li>
            <li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
            <li>Metadaten</li>
            <li>Gerätekennungen</li>
            <li>Spracheinstellungen</li>
            <li>Installierte App Version</li>
          </ul>
          <p>
            Die Verarbeitung dieser Daten erfolgt zur Durchführung des Nutzungsvertrags zwischen uns und Ihnen (Art. 6 Abs. 1 S. 1 lit. b DSGVO). Wir verarbeiten die Daten nur so lange, bis der Zweck für die Speicherung wegfällt oder Sie uns zur Löschung auffordern. Ein Zweckfortfall zur Speicherung von Daten liegt regelmäßig vor, wenn Sie sich von der Applikation abmelden. Wenn zwingende gesetzliche Aufbewahrungsfristen vorliegen, werden die betreffenden Daten jedoch erst nach Ablauf der gesetzlichen Fristen gelöscht (z.B. steuerrechtliche Aufbewahrungsfrist bei Rechnungsdaten).
          </p>
        </div>

        <div id="paragraph_6">
          <h3>6. Minderjährige</h3>
          <p>
            Personen, die das 18. Lebensjahr noch nicht vollendet haben, haben personenbezogene Daten gemäß Art. 8 DSGVO an uns nur mit Einwilligung der Erziehungsberechtigten zu übermitteln. Personenbezogene Daten von Minderjährigen werden von uns nicht bewusst erhoben und verarbeitet.
          </p>
        </div>

        <div id="paragraph_7">
          <h3>7. Externes Hosting und Content Delivery Networks (CDN)</h3>
          <h6>Externes Hosting</h6>
          <p>
            Die App bzw. Seite wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die über die App bzw. Seite erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v.a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Seitenzugriffe und sonstige Daten, die über eine App bzw. Seite generiert werden, handeln.
          </p>
          <p>
            Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
          </p>
          <p>
            Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.
          </p>
          <p>
            Wir setzen folgenden Hoster ein:
          </p>
          <p>
            Hetzner Online GmbH<br>
            Industriestr. 25<br>
            91710 Gunzenhausen<br>
            Deutschland<br>
          </p>
          <h6>Abschluss eines Vertrages über Auftragsverarbeitung</h6>
          <p>
            Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir einen Vertrag über Auftragsverarbeitung mit unserem Hoster geschlossen.
          </p>
        </div>

        <div id="paragraph_8">
          <h3>8. Kontakt</h3>
          <p>
            Sie können uns per E-Mail, Brief oder Telefon kontaktieren. Hierbei werden Ihre Angaben aus der Anfrage inklusive der von Ihnen dort angegebenen Kontaktdaten ausschließlich zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte.
          </p>
          <p>
            Rechtsgrundlage für die Verarbeitung der Daten ist Art. 6 Abs. 1 lit. f DSGVO. Unser Interesse an der Beantwortung Ihrer Anfrage überwiegt Ihr Interesse; da Sie uns schreiben, liegt eine Beantwortung ferner auch in Ihrem Interesse und Ihnen ist bewusst, dass wir zur Beantwortung Ihrer Anfrage Ihre Daten verarbeiten müssen.
          </p>
          <p>
            Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist die Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO.
          </p>
          <p>
            Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Dies ist dann der Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist. Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist.
          </p>
        </div>

        <div id="paragraph_9">
          <h3>9. Newsletterdaten</h3>
          <p>
            Wenn Sie den in unseren Applikationen angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht erhoben. Diese Daten verwenden wir ausschließlich für den Versand der angeforderten Informationen und geben sie nicht an Dritte weiter. Die Rechtsgrundlage für die Datenverarbeitung ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a) DSGVO).
          </p>
          <p>
            Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit widerrufen, etwa über den "Austragen"-Link im Newsletter. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
          </p>
          <p>
            Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Austragung gelöscht.
          </p>
        </div>

        <div id="paragraph_10">
          <h3>10. Verwendung von Cookies und Analysewerkzeugen</h3>
          <h5 id="paragraph_10_a">a) Für den Betrieb erforderliche Cookies</h5>
          <p>
            Wir nutzen auf unserer Webseite <a href="https://www.teamtip.net/" target="_blank">teamtip.net</a> und in unseren Applikationen sogenannte Session- oder Flash-Cookies. Cookies sind Textdateien, die im oder vom Internetbrowser auf dem Computersystem des Benutzers gespeichert werden. Wenn ein Benutzer eine Webseite besucht, kann ein Cookie auf dem Betriebssystem des Benutzers gespeichert werden. Dieses Cookie enthält eine charakteristische Zeichenkette, die es ermöglicht, den Browser beim nächsten Zugriff auf die Webseite eindeutig zu identifizieren. Einige Funktionen unserer Webseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es notwendig, dass der Browser auch nach einem Seitenwechsel erkannt wird. Die mit technisch notwendigen Cookies erhobenen Benutzerdaten werden nicht dazu verwendet, die Identität des Nutzers zu ermitteln oder Benutzerprofile zu erstellen. Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten mittels technisch notwendiger Cookies ist Art. 6 Abs. 1 lit. f) DSGVO.
          </p>
          <h5 id="paragraph_10_b">b) Nicht zwingend erforderliche Cookies</h5>
          <h6>Analysewerkzeuge</h6>
          <p>
            Wenn Sie auf unsere Applikationen zugreifen, kann Ihr Verhalten mit Hilfe bestimmter Analyse-Werkzeuge statistisch ausgewertet und zu Werbe- und Markforschungszwecke oder zur Verbesserung unserer Angebote analysiert werden. Bei der Verwendung derartiger Tools achten wir auf die Einhaltung der gesetzlichen Datenschutzbestimmungen. Beim Einsatz externer Dienstleister (Auftragsdatenverarbeiter) stellen wir durch entsprechende Verträge mit den Dienstleistern sicher, dass die Datenverarbeitung den deutschen und europäischen Datenschutzstandards entspricht.
          </p>
          <p>
            Zur Analyse des Nutzerverhaltens nutzen wir folgende Werkzeuge:
          </p>
          <h6>Google Analytics</h6>
          <p>
            Die Applikationen nutzen Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
          </p>
          <p>
            Google Analytics verwendet so genannte „Cookies“. Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Applikation durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Applikation werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
          </p>
          <p>
            Die Speicherung von Google-Analytics-Cookies und die Nutzung dieses Analyse-Tools erfolgen auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO, da eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies). Die Einwilligung ist jederzeit widerrufbar.
          </p>
          <p>
            Wir haben mit Google LLC EU-Standardvertragsklauseln abgeschlossen. Dies bietet hinreichende Garantien für einen angemessenen Datenschutz i.S.d. Art. 46 DSGVO.
          </p>
          <h6>IP Anonymisierung</h6>
          <p>
            Wir haben die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. In unserem Auftrag wird Google diese Informationen benutzen, um Ihre Nutzung der Applikation auszuwerten, um Reports über die -Applikationsaktivitäten zusammenzustellen und um weitere mit der Applikationsnutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Betreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
          </p>
          <h6>Browser Plugin</h6>
          <p>
            Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen der Applikation vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Applikation bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:
          </p>
          <p>
            <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>
          </p>
          <h6>Demografische Merkmale bei Google Analytics</h6>
          <p>
            Die Applikationen nutzen die Funktion „demografische Merkmale“ von Google Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics wie im Punkt „Widerspruch gegen Datenerfassung“ dargestellt generell untersagen.
          </p>
          <h6>Speicherdauer</h6>
          <p>
            Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die mit Cookies, Nutzerkennungen (z.B. User ID) oder Werbe-IDs (z.B. DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden nach 14 Monaten anonymisiert bzw. gelöscht. Details hierzu ersehen Sie unter folgendem Link: 
          </p>
          <p>
            <a href="https://support.google.com/analytics/answer/7667196?hl=de" target="_blank">https://support.google.com/analytics/answer/7667196?hl=de</a>
          </p>
          <h6>Google Tag Manager</h6>
          <p>
            Wir nutzen den Service Google Tag Manager. Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
          </p>
          <p>
            Durch diesen Dienst können Website-Tags über eine Oberfläche verwaltet werden. Der Google Tag Manager implementiert lediglich Tags. Das bedeutet: Es werden keine Cookies eingesetzt und es werden keine personenbezogenen Daten erfasst. Der Google Tag Manager löst andere Tags aus, die wiederum ggf. Daten erfassen. Jedoch greift der Google Tag Manager nicht auf diese Daten zu. Wurde auf Domain- oder Cookie-Ebene eine Deaktivierung vorgenommen, so bleibt sie für alle Tracking-Tags bestehen, insofern diese mit dem Google Tag Manager implementiert werden.
          </p>
        </div>

        <div id="paragraph_11">
          <h3>11. Plugins und Tools</h3>
          <h6>Google Web Fonts</h6>
          <p>
            Diese Applikationen nutzen zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von der Google LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA („Google“) bereitgestellt werden.
          </p>
          <p>
            Die Google Fonts sind lokal installiert. Eine Verbindung zu Servern von Google findet dabei nicht statt.
          </p>
          <p>
            Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq" target="_blank">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/" target="_blank">https://www.google.com/policies/privacy/</a>
          </p>
        </div>

        <div id="paragraph_12">
          <h3>12. Verschlüsselung</h3>
          <h6>SSL- bzw. TLS-Verschlüsselung</h6>
          <p>
            Wir nutzen aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Betreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          </p>
          <p>
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
          </p>
          <h6>Verschlüsselter Zahlungsverkehr auf dieser Seite</h6>
          <p>
            Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine Verpflichtung, uns Ihre Zahlungsdaten (z.B. Kontonummer bei Einzugsermächtigung) zu übermitteln, werden diese Daten zur Zahlungsabwicklung benötigt.
          </p>
          <p>
            Der Zahlungsverkehr über die gängigen Zahlungsmittel (PayPal) erfolgt ausschließlich über eine verschlüsselte SSL- bzw. TLS-Verbindung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          </p>
          <p>
            Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
          </p>
        </div>

        <div id="paragraph_13">
          <h3>13. Datenschutzbeauftragter</h3>
          <p>
            Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt.
          </p>
          <p>
            Dr. Christian Rauda<br>
            Fachanwalt für Informationstechnologierecht<br>
            GRAEF Rechtsanwälte Digital PartG mbB<br>
            20149 Hamburg<br>
            Tel. +49.40.80 6000 9-0<br>
            Fax +49.40.80 6000 9-10<br>
            Email: <a href="mailto:datenschutzbeauftragter@sportplatz-media.com">datenschutzbeauftragter@sportplatz-media.com</a><br>
          </p>
        </div>

        <div id="paragraph_14">
          <h3>14. Ihre Rechte</h3>
          <p>
            Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:
          </p>
          <h5 id="paragraph_14_a">a) Auskunftsrecht</h5>
          <p>
            Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.
          </p>
          <p>
            Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen Auskunft verlangen:
          </p>
          <ul>
            <li>die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</li>
            <li>die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</li>
            <li>die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;</li>
            <li>die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;</li>
            <li>das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung; </li>
            <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
            <li>alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;</li>
            <li>das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.</li>
          </ul>
          <p>
            Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
          </p>
          <h5 id="paragraph_14_b">b) Recht auf Berichtigung</h5>
          <p>
            Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
          </p>
          <h5 id="paragraph_14_c">c) Recht auf Einschränkung der Verarbeitung</h5>
          <p>
            Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
          </p>
          <ul>
            <li>wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;</li>
            <li>die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;</li>
            <li>der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder</li>
            <li>wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.</li>
          </ul>
          <p>
            Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
          </p>
          <p>
            Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.
          </p>
          <h5 id="paragraph_14_d">d) Recht auf Löschung</h5>
          <h6>Löschungspflich</h6>
          <p>
            Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
          </p>
          <ul>
            <li>Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</li>
            <li>Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.</li>
            <li>Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein. </li>
            <li>Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
            <li>Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.</li>
            <li>Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.</li>
          </ul>
          <h6>Information an Dritte</h6>
          <p>
            Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
          </p>
          <h6>Ausnahmen</h6>
          <p>
            Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist
          </p>
          <ul>
            <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
            <li>zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;</li>
            <li>aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;</li>
            <li>für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder</li>
            <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
          </ul>
          
          <h5 id="paragraph_14_e">e) Recht auf Unterrichtung</h5>
          <p>
            Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.
          </p>
          <p>
            Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.
          </p>
          <h5 id="paragraph_14_f">f) Recht auf Datenübertragbarkeit</h5>
          <p>
            Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
          </p>
          <ul>
            <li>die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und</li>
            <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
          </ul>
          <p>
            In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
          </p>
          <p>
            Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
          </p>
          <h5 id="paragraph_14_g">g) Widerspruchsrecht</h5>
          <p>
            Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
          </p>
          <p>
            Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
          </p>
          <p>
            Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
          </p>
          <p>
            Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
          </p>
          <p>
            Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
          </p>
          <h5 id="paragraph_14_h">h) Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h5>
          <p>
            Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
          </p>
          <h5 id="paragraph_14_i">i) Automatisierte Entscheidung im Einzelfall einschließlich Profiling</h5>
          <p>
            Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt.
          </p>
          <p>
            Dies gilt nicht, wenn die Entscheidung
          </p>
          <ul>
            <li>für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist,</li>
            <li>aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder</li>
            <li>mit Ihrer ausdrücklichen Einwilligung erfolgt.</li>
          </ul>
          <p>
            Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
          </p>
          <p>
            Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
          </p>
          <h5 id="paragraph_14_j">j) Recht auf Beschwerde bei einer Aufsichtsbehörde</h5>
          <p>
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
          </p>
          <p>
            Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
          </p>
        </div>

        <div id="paragraph_15">
          <h3>15. Änderung dieser Datenschutzhinweise</h3>
          <p>
            Wir behalten uns das Recht vor, diese Datenschutzbestimmungen unter Einhaltung der gesetzlichen Vorgaben jederzeit zu ändern.
          </p>
          <p>
            Stand: Februar 2021
          </p>
        </div>


      </div>
    </div>

    <div v-else>

      <div style="margin-top: 24px;">
        <h1>Contents</h1>
      </div>

      <ul style="list-style-type: none; padding-left: 0px;">
        <li><a href="#paragraph_1">1. Data protection</a></li>
        <li><a href="#paragraph_2">2. Controller</a></li>
        <li><a href="#paragraph_3">3. General information on data processing</a></li>
        <li><a href="#paragraph_4">4. Provision of the website and creation of log files</a></li>
        <li><a href="#paragraph_5">5. Data processing for registered users</a></li>
        <li><a href="#paragraph_6">6. Minors</a></li>
        <li><a href="#paragraph_7">7. Hosting and content delivery networks (CDN)</a></li>
        <li><a href="#paragraph_8">8. Contact</a></li>
        <li><a href="#paragraph_9">9. Newsletter data</a></li>
        <li><a href="#paragraph_10">10. Use of cookies and analysis tools</a>
          <ul style="list-style-type: none;">
            <li><a href="#paragraph_10_a">a) Cookies required for the operation</a></li>
            <li><a href="#paragraph_10_b">b) Not mandatory cookies</a></li>
          </ul>
        </li>
        <li><a href="#paragraph_11">11. Plugins and tools</a></li>
        <li><a href="#paragraph_12">12. Encryption</a></li>
        <li><a href="#paragraph_13">13. Data protection officer</a></li>
        <li><a href="#paragraph_14">14. Your rights</a>
          <ul style="list-style-type: none;">
            <li><a href="#paragraph_14_a">a) Right of access</a></li>
            <li><a href="#paragraph_14_b">b) Right to rectification</a></li>
            <li><a href="#paragraph_14_c">c) Right to restriction of processing</a></li>
            <li><a href="#paragraph_14_d">d) Right to erasure</a></li>
            <li><a href="#paragraph_14_e">e) Right to be notified</a></li>
            <li><a href="#paragraph_14_f">f) Right to data portability</a></li>
            <li><a href="#paragraph_14_g">g) Right to object</a></li>
            <li><a href="#paragraph_14_h">h) Right to revoke the data protection declaration of consent</a>
            </li>
            <li><a href="#paragraph_14_i">i) Automated decision-making in individual cases, including profiling</a></li>
            <li><a href="#paragraph_14_j">j) Right to lodge a complaint with a supervisory authority</a></li>
          </ul>
        </li>
        <li><a href="#paragraph_9">15. Amendments to this data protection information</a></li>
      </ul>

      <div style="margin-top: 48px;">

        <div id="paragraph_1">
          <h3>1. Data protection</h3>
          <p>
            We take the protection of your personal data very seriously. We treat your personal data confidentially and according to the legal data protection regulations as well as this data protection information.
          </p>
          <p>
            This data protection information applies to the processing of personal data by us on our website <a href="https://www.teamtip.net/" target="_blank">teamtip.net</a> (“website”) and for our web application (hereinafter “web application”) as well as for our mobile apps (hereinafter “apps”) (together hereinafter “applications”). It explains the type, purpose, and scope of the data processing within the framework of the website, the web application, and the mobile apps.
          </p>
          <p>
            We would like to point out that data transmissions on the Internet can have security gaps. Seamless protection of the data against access by third parties is not possible.
          </p>
        </div>

        <div id="paragraph_2">
          <h3>2. Controller</h3>
          <p>
            The “Controller” is the natural or legal person, public authority, agency, or other body that alone or with others determines the purposes and means of the processing of personal data.
          </p>
          <p>
            The controller for the data processing to provide the services within the website <a href="https://www.teamtip.net/" target="_blank">teamtip.net</a> and within the applications is:
          </p>
          <p>
            <b>SPM Sportplatz-Media GmbH</b><br>
            Schleidenstraße 3<br>
            22083 Hamburg<br>
            E-Mail: <a href="mailto:info@teamtip.net">info@teamtip.net</a><br>
            Webseite: <a href="https://www.teamtip.net/" target="_blank">https://www.teamtip.net/</a><br>
            Tel.: +49 40 537 9863 - 30<br>
          </p>
          <p>
            The respective trainer(s) and admin(s) are responsible for the data processing within a team in the applications.
         </p>
          <p>
            Regarding this data processing, we (SPM Sportplatz-Media GmbH) only act on behalf of and according to the instructions of the trainer (order processing). You can find the data protection information of the trainer account for the data processing within a team in the settings under “Trainer data protection”
         </p>
          <p>
            The following data protection information therefore only applies to the processing of personal data for which we (SPM Sportplatz-Media GmbH) are responsible.
         </p>
        </div>

        <div id="paragraph_3">
          <h3>3. General information on data processing</h3>
          <h6>Scope of the processing of personal data</h6>
          <p>
            We categorically collect and use the personal data of our users only to the extent required for the provision of our applications, our contents, and our services. The collection and use of our users’ personal data is regularly only carried out with the consent of the user. Exceptions are made in cases in which prior consent cannot be obtained for factual reasons and where the processing of the data is permitted by legal regulations.
          </p>
          <h6>Legal basis for the processing of personal data</h6>
          <p>
            Insofar as we obtain consent from the data subject for the processing of personal data, Art. 6 (1) (a) of the EU General Data Protection Regulation (GDPR) serves as the legal basis for the processing of personal data.
          </p>
          <p>
            In the processing of personal data that is required for the performance of a contract in which the contractual party is the data subject, Art. 6 (1) (b) GDPR serves as the legal basis. This also applies to processing that is required to carry out pre-contractual measures.
          </p>
          <p>
            Insofar as the processing of personal data is required to fulfill a legal obligation to which our company is subject, Art. 6 (1) (c) GDPR serves as the legal basis.
          </p>
          <p>
            If vital interests of the data subject or another natural person require the processing of personal data, Art. 6 (1) (d) GDPR serves as the legal basis.
          </p>
          <p>
            If the processing is required to safeguard a legitimate interest of our company or a third party and the interests, basic rights, and basic freedoms of the data subject do not outweigh the former interest, Art. 6 (1) (f) GDPR serves as the legal basis for the processing.
          </p>
          <h6>Data deletion and storage period</h6>
          <p>
            The personal data of the data subject are deleted or blocked as soon as the purpose of the storage is no longer applicable. Additionally, data may be stored if this has been provided for by the European or national legislator in EU regulations, laws, or other provisions to which the controller is subject. Data is also restricted or erased if a storage period prescribed by the above-mentioned standards expires, unless there is a need to continue storing the data in order to fulfill or conclude a contract.
          </p>
          <h6>Note on data transfer to the USA</h6>
          <p>
            Our website also includes tools from companies based in the USA. If these tools are enabled, your personal data may be transferred to these companies’ US servers. The processing of personal data in the USA only takes place if the companies have concluded so-called EU standard contract clauses with us and their subcontractors. This provides sufficient guarantees for adequate data protection within the meaning of Art. 46 GDPR.
          </p>
        </div>

        <div id="paragraph_4">
          <h3>4. Provision of the website and creation of log files</h3>
          <p>
            Whenever our website (including the web application) is accessed, our system automatically collects data and information from the computer system of the accessing computer. The following data is collected for a limited period of time:
          </p>
          <ul>
            <li>The user’s IP address</li>
            <li>The date and time of access</li>
            <li>The user’s user agent</li>
            <li>The path to the accessed page</li>
          </ul>
          <p>
            The data are stored in the log files of our system. These data are only required for the analysis of any malfunctions and are deleted within 30 days at the latest. The legal basis for the temporary storage of the data and log files is Article 6 (1) (f) GDPR. The temporary storage of the IP address by the system is necessary to enable the website to be delivered to the user’s computer. The user’s IP address must be stored for the duration of the session for this purpose. The purpose of the storage in log files is to ensure the functionality of the website. We also use the data to optimize the website and ensure the security of our information technology systems. The data are not evaluated for marketing purposes in this context, and no conclusions are drawn about your person. These purposes also include our legitimate interest in data processing in accordance with Art. 6 (1) (f) GDPR. The collection of data for the provision of the website and the storage of the data in log files is mandatory for the operation of the website. As a consequence, there is no possibility of objection on the part of the user.
          </p>
        </div>

        <div id="paragraph_5">
          <h3>5. Data processing for registered users</h3>
          <p>
            When you register and use our applications, the following personal data is collected from you:
          </p>
          <ul>
            <li>First and last name</li>
            <li>User name</li>
            <li>Age over/under 18</li>
            <li>E-Mail address</li>
          </ul>
          <p>
            We also process the following technical data:
          </p>
          <ul>
            <li>IP addresses</li>
            <li>Browser types and browser version</li>
            <li>Operating system</li>
            <li>Referrer URL</li>
            <li>Host name of the accessing computer</li>
            <li>Time of the server access</li>
            <li>Metadata</li>
            <li>Device IDs</li>
            <li>Language settings</li>
            <li>Installed app version</li>
          </ul>
          <p>
            These data are processed for the purpose of implementing the user agreement between us and you (Art. 6 (1) sentence 1 lit. b GDPR). We only process the data until the purpose for which it was stored no longer applies or you request us to delete it. Usually, there is no longer any purpose for storing data when you log out of the application. However, if there are mandatory statutory retention periods, the relevant data are only deleted after the statutory periods have expired (e.g., tax-related retention period for invoice data).
          </p>
        </div>

        <div id="paragraph_6">
          <h3>6. Minors</h3>
          <p>
            Persons who are under the age of 16 must only transmit their personal data to us with the consent of their legal guardians pursuant to Art. 8 GDPR. We do not knowingly collect and process the personal data of minors.
          </p>
        </div>

        <div id="paragraph_7">
          <h3>7. Hosting and content delivery networks (CDN)</h3>
          <h6>External hosting</h6>
          <p>
            The app or website is hosted by an external service provider (host). The personal data collected via the app or website are stored on the host’s servers. In particular, this may include IP addresses, contact requests, meta- and communication data, contract data, contact data, names, page accesses, and other data generated by an app or website.
          </p>
          <p>
            The host is used for the purpose of fulfilling the contract with our potential and existing customers (Art. 6 (1) (b) GDPR) and in the interest of a secure, fast and efficient provision of our online offer through a professional provider (Art. 6 (1) (f) GDPR).
          </p>
          <p>
            Our host will process your data only to the extent necessary to fulfill its service obligations and will follow our instructions with regard to these data.
          </p>
          <p>
            We use the following hoster:
          </p>
          <p>
            Hetzner Online GmbH<br>
            Industriestr. 25<br>
            91710 Gunzenhausen<br>
            Germany<br>
          </p>
          <h6>Conclusion of a contract on order processing</h6>
          <p>
            To ensure processing in accordance with data protection regulations, we have concluded a contract for order processing with our host.
          </p>
        </div>

        <div id="paragraph_8">
          <h3>8. Contact</h3>
          <p>
            You can contact us by email, fax or phone. In this process, your information from the inquiry and the contact details you provide there will be stored by us exclusively for purposes of processing the inquiry and in case of further questions. The data will not be transmitted to third parties in this context.
          </p>
          <p>
            The legal basis for the data processing is Art. 6 (1) (f) GDPR. Our interest in answering your inquiry outweighs your interest; since you are writing to us, an answer is also in your interest and you are aware that we have to process your data to answer your inquiry.
          </p>
          <p>
            If the purpose of the email contact is to conclude a contract, the legal basis for the processing is Art. 6 (1) (b) GDPR.
          </p>
          <p>
            The data will be deleted as soon as they are no longer required for the purpose of their collection. This is the case when the respective conversation with the user has ended. The conversation has ended when it can be derived from the circumstances that the relevant matter has been resolved conclusively.
          </p>
        </div>

        <div id="paragraph_9">
          <h3>9. Newsletter data</h3>
          <p>
            If you would like to subscribe to the newsletter offered in our applications, we need your email address and information that permit us to confirm that you are the owner of this email address and agree to receive the newsletter. No other data are collected. We exclusively use these data to send the requested information and do not transmit it to third parties. The legal basis for the data processing is your consent (Article 6 (1) (a) GDPR).
          </p>
          <p>
            You can withdraw your consent to store the data and email addresses and to use them to send the newsletter at any time, for example with the “Unsubscribe” link in the newsletter. The legality of the data processing operations that have already been performed remains unaffected by the revocation.
          </p>
          <p>
            If you are a regular customer of ours, we can also send information on features, updates, and TeamTip content to your email address in the form of a newsletter. The legal basis for the data processing of your email address for this purpose is our legitimate interest in the sending of additional useful information on our offering, which overrides your interest in non-processing (Art. (6) (1) (f) GDPR). You can informally cancel the receipt of future newsletters at any time, such as via the link included in the newsletter.
          </p>
          <p>
            The data that you provide to us for the purpose of subscribing to the newsletter will be stored by us until you unsubscribe from the newsletter and will be deleted after you unsubscribe.
          </p>
        </div>

        <div id="paragraph_10">
          <h3>10. Use of cookies and analysis tools</h3>
          <h5 id="paragraph_10_a">a) Cookies required for the operation</h5>
          <p>
            We use so-called session or flash cookies on our website www.spielerplus.de and in our applications. Cookies are text files that are stored in or by the Internet browser on the user’s computer system. When a user visits a website, a cookie may be stored on the user’s operating system. This cookie contains a distinctive string of characters that enables the browser to be uniquely identified when the website is accessed again. Some functions of our website cannot be offered without the use of cookies. These make it necessary for the browser to be identified even after a webpage is changed. The user data collected with the technically necessary cookies are not used to detect the identity of the user or to create user profiles. The legal basis for the processing of personal data with technically necessary cookies is Art. 6 (1) (f) GDPR.
          </p>
          <h5 id="paragraph_10_b">b) Not mandatory cookies</h5>
          <h6>Analytical tools</h6>
          <p>
            When you access our applications, your behaviour may be statistically evaluated with the help of certain analysis tools and analysed for advertising and market research purposes or to improve our offers. When using such tools, we ensure compliance with the legal data protection regulations. When using external service providers (commissioned data processors), we ensure through appropriate contracts with the service providers that the data processing complies with German and European data protection standards.
          </p>
          <p>
            We use the following tools to analyse user behaviour:
          </p>
          <h6>Google Analytics</h6>
          <p>
            The applications use functions of the web analysis service Google Analytics. The provider is Google Ireland Limited ("Google"), Gordon House, Barrow Street, Dublin 4, Ireland.
          </p>
          <p>
            Google Analytics uses so-called "cookies". These are text files that are stored on your computer and enable an analysis of your use of the application. The information generated by the cookie about your use of this application is usually transmitted to a Google server in the USA and stored there.
          </p>
          <p>
            The storage of Google Analytics cookies and the use of this analysis tool are carried out on the basis of Art. 6 Para. 1 lit. a DSGVO, as a corresponding consent has been requested (e.g. consent to the storage of cookies). The consent can be revoked at any time.
          </p>
          <p>
            We have concluded EU standard contractual clauses with Google LLC. This provides sufficient guarantees for adequate data protection within the meaning of Art. 46 DSGVO.
          </p>
          <h6>IP anonymisation</h6>
          <p>
            We have activated the IP anonymisation function. This means that your IP address is shortened by Google within member states of the European Union or in other contracting states of the Agreement on the European Economic Area before being transmitted to the USA. Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and shortened there. Google will use this information on our behalf for the purpose of evaluating your use of the application, compiling reports on application activity and providing other services relating to application usage and internet usage to the operator. The IP address transmitted by your browser as part of Google Analytics will not be merged with other Google data.
          </p>
          <h6>Browser plugin</h6>
          <p>
            You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this application. In addition, you can prevent the collection of the data generated by the cookie and related to your use of the application (including your IP address) to Google as well as the processing of this data by Google by downloading and installing the browser plugin available under the following link:
          </p>
          <p>
            <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>
          </p>
          <h6>Demographic characteristics with Google Analytics</h6>
          <p>
            The applications use the "demographic characteristics" function of Google Analytics. This allows reports to be generated that contain statements about the age, gender and interests of site visitors. This data comes from interest-based advertising from Google as well as visitor data from third-party providers. This data cannot be assigned to a specific person. You can deactivate this function at any time via the ad settings in your Google account or generally prohibit the collection of your data by Google Analytics as shown in the item "Objection to data collection".
          </p>
          <h6>Storage period</h6>
          <p>
            Data stored by Google at user and event level that is linked to cookies, user IDs or advertising IDs (e.g. DoubleClick cookies, Android advertising ID) will be anonymised or deleted after 14 months. Details can be found under the following link:
          </p>
          <p>
            <a href="https://support.google.com/analytics/answer/7667196" target="_blank">https://support.google.com/analytics/answer/7667196</a>
          </p>
          <h6>Google Tag Manager</h6>
          <p>
            The applications use functions of the Google Analytics web analysis service. The provider is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
          </p>
          <p>
            Google Analytics uses so-called cookies. These are text files that are stored on your computer that enable an analysis of your use of the application. The information created by the cookie about your use of this application is generally transmitted to a Google server in the USA and stored there.
          </p>
          <p>
            The storage of Google Analytics cookies and the use of this analysis tool are based on Art. 6 (1) (a) GDPR, since corresponding consent was requested (e.g., consent to the storage of cookies). The consent can be revoked at any time.
          </p>
          <p>
            We have concluded standard EU contract clauses with Google LLC. This provides sufficient guarantees for adequate data protection within the meaning of Art. 46 GDPR.
          </p>
        </div>

        <div id="paragraph_11">
          <h3>11. Plugins and tools</h3>
          <h6>Google Web Fonts</h6>
          <p>
            For the uniform display of fonts, these applications use so-called web fonts, which are provided by Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”).
          </p>
          <p>
            Google Fonts are installed locally. There is no connection to Google’s servers. You can find more information on Google Web Fonts at <a href="https://developers.google.com/fonts/faq" target="_blank">https://developers.google.com/fonts/faq</a>  and in Google’s privacy policy: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>.
          </p>
        </div>

        <div id="paragraph_12">
          <h3>12. Encryption</h3>
          <h6>SSL or TLS encryption</h6>
          <p>
            For security reasons and to protect the transmission of confidential contents, such as orders or inquiries that you send to us as the operator, we use SSL or TLS encryption. You can recognize an encrypted connection when the browser’s address bar changes from “http://” to “https://” and by the lock symbol in your browser line.
          </p>
          <p>
            When SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.
          </p>
          <h6>Encrypted payment transactions on this page</h6>
          <p>
            If, after the conclusion of a contract with costs, there is an obligation to provide us with your payment data (e.g. account number in the case of direct debit authorisation), this data is required for the processing of payments.
          </p>
          <p>
            Payment transactions via the common means of payment (PayPal) are made exclusively via an encrypted SSL or TLS connection. You can recognise an encrypted connection by the fact that the address line of the browser changes from "http://" to "https://" and by the lock symbol in your browser line.
          </p>
          <p>
            With encrypted communication, your payment data that you transmit to us cannot be read by third parties.
          </p>
        </div>

        <div id="paragraph_13">
          <h3>13. Data protection officer</h3>
          <p>
            We have assigned a data protection officer for our company.
          </p>
          <p>
            Dr. Christian Rauda<br>
            Fachanwalt für Informationstechnologierecht<br>
            GRAEF Rechtsanwälte Digital PartG mbB<br>
            20149 Hamburg<br>
            Tel. +49.40.80 6000 9-0<br>
            Fax +49.40.80 6000 9-10<br>
            Email: <a href="mailto:datenschutzbeauftragter@sportplatz-media.com">datenschutzbeauftragter@sportplatz-media.com</a><br>
          </p>
        </div>

        <div id="paragraph_14">
          <h3>14. Your rights</h3>
          <p>
            If your personal data are being processed, you are a data subject as defined by the GDPR, and you have the following rights in relation to the controller:
          </p>
          <h5 id="paragraph_14_a">a) Right of access</h5>
          <p>
            You can request confirmation from the controller as to whether we are processing your personal data.
          </p>
          <p>
            If such processing has taken place, you can request information from the controller on the following topics:
          </p>
          <ul>
            <li>the purposes for which the personal data are processed;</li>
            <li>the categories of personal data being processed;</li>
            <li>the recipients or categories of recipients to whom your personal data have been or will be disclosed;</li>
            <li>the planned duration of the storage of the personal data concerning you, or, if no specific information on this is available, criteria for determining the storage period;</li>
            <li>the existence of a right to the rectification or erasure of the personal data or restriction of processing of personal data concerning you, or a right to object to this processing;</li>
            <li>the existence of a right to appeal to a supervisory authority;</li>
            <li>any available information about the source of the data if the personal data are not being collected from the data subject;</li>
            <li>the existence of an automated decision-making process including profiling as defined by Art. 22 (1) and (4) GDPR and – at least in these cases – meaningful information about the logic involved as well as on the scope and intended effects of such processing on the data subject.</li>
          </ul>
          <p>
            You have the right to request information as to whether your personal data are being transferred to a third country or to an international organization. In this context, you can request to be informed about the appropriate safeguards pursuant to Art. 46 GDPR in connection with the transfer.
          </p>
          <h5 id="paragraph_14_b">b) Right to rectification</h5>
          <p>
            You have the right to request that the controller correct and/or complete the data if the processed personal data concerning you is incorrect or incomplete. The controller must immediately make the correction.
          </p>
          <h5 id="paragraph_14_c">c) Right to restriction of processing</h5>
          <p>
            Under the following conditions, you may request the restriction of the processing of your personal data:
          </p>
          <ul>
            <li>if you contest the accuracy of the personal data concerning you for a period that enables the controller to verify the accuracy of the personal data;</li>
            <li>if the processing is unlawful and you object to the erasure of the personal data and instead request a restriction of their use;</li>
            <li>if the controller no longer needs the personal data for the processing purposes, but you need them to assert, pursue, or defend legal claims; or</li>
            <li>if you have objected to the processing pursuant to Art. 21 (1) GDPR and it is not yet established whether the legitimate reasons of the controller outweigh your reasons.</li>
          </ul>
          <p>
            If the processing of your personal data has been restricted, these data – with the exception of storage – may only be processed with your consent or for the purpose of asserting, pursuing, or defending legal claims or for protecting the rights of another natural or legal person or for reasons related to an important public interest of the Union or a member state.
          </p>
          <p>
            If the restriction of processing was restricted in accordance with the above conditions, the controller will inform you before the restriction is lifted.
          </p>
          <h5 id="paragraph_14_d">d) Right to erasure</h5>
          <h6>Obligation to delete</h6>
          <p>
            You may request that the controller erases your personal data without delay. The controller is then obligated to immediately delete the data if one of the following reasons applies:
          </p>
          <ul>
            <li>The personal data concerning you are no longer necessary for the purposes for which they were collected or otherwise processed.</li>
            <li>You revoke your consent on which the processing pursuant to Art. 6 (1) (a) or Art. 9 (2) (a) GDPR was based and there is no other legal basis for the processing.</li>
            <li>You object to the processing pursuant to Art. 21 (1) GDPR and there are no overriding legitimate reasons for the processing, or you object to the processing pursuant to Art. 21 (2) GDPR.</li>
            <li>The personal data concerning you have been processed unlawfully.</li>
            <li>The erasure of the personal data concerning you is necessary to comply with a legal obligation under Union law or the law of the member states to which the controller is subject.</li>
            <li>The personal data concerning you have been collected in relation to services offered by an information society pursuant to Art. 8 (1) GDPR.</li>
          </ul>
          <h6>Information to third parties</h6>
          <p>
            If the controller has made your personal data public and is obligated to their erasure pursuant to Art. 17 (1) GDPR, the controller, in consideration of the available technology and implementation costs, will take appropriate measures, including technical ones, to inform the persons responsible for the data processing that you, as a data subject, have requested the erasure of all links to these personal data or copies or replications thereof.
          </p>
          <h6>Exceptions</h6>
          <p>
            The right to erasure does not exist if the processing is required
          </p>
          <ul>
            <li>to exercise the right to freedom of expression and information;</li>
            <li>to comply with a legal obligation that requires the processing according to a law of the Union or member states to which the controller is subject or to perform a task carried out in the public interest or in the exercise of official authority vested in the controller;</li>
            <li>for reasons of public interest in the field of public health pursuant to Art. 9 (2) (h) and (i) and Art. 9 (3) GDPR;</li>
            <li>for archival purposes, scientific or historic research purposes in the public interest, or for statistical purposes pursuant to Art. 89 (1) GDPR, insofar as the law referred to in section a) is likely to render impossible or seriously interfere with the attainment of the objectives of such processing; or</li>
            <li>to assert, exercise, or defend legal claims.</li>
          </ul>

          <h5 id="paragraph_14_e">e) Right to be notified</h5>
          <p>
            If you have asserted the right to the rectification, erasure, or restriction of processing towards the controller, the controller is obligated to notify all recipients to whom your personal data have been disclosed of this rectification, erasure or restriction of processing, unless this proves impossible or involves disproportionate effort.
          </p>
          <p>
            You are entitled to receive information from the controller about these recipients.
          </p>
          <h5 id="paragraph_14_f">f) Right to data portability</h5>
          <p>
            You have the right to receive the personal data concerning you that you provided to the controller in a structured, commonly used, and machine-readable format. You also have the right to have this data transmitted to another responsible person without interference from the controller to whom the personal data has been communicated, provided that
          </p>
          <ul>
            <li>the processing is based on consent pursuant to Art. 6 (1) (a) GDPR or Art. 9 (2) (a) GDPR or on a contract pursuant to Art. 6 (1) (b) GDPR; and</li>
            <li>the processing is carried out by automated means.</li>
          </ul>
          <p>
            In exercising this right, you also have the right to request that your personal data be transferred directly from one controller to another, insofar as this is technically feasible. This must not affect the freedoms and rights of other people.
          </p>
          <p>
            The right to data portability does not apply to the processing of personal data necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller.
          </p>
          <h5 id="paragraph_14_g">g) Right to object</h5>
          <p>
            You have the right to object, for reasons arising from your particular situation, at any time to the processing of your personal data that is carried out pursuant to Art. (6) (1) (e) or (f) GDRP; this also applies to profiling based on these provisions.
          </p>
          <p>
            The controller will no longer process your personal data, unless the controller can demonstrate compelling legitimate reasons for the processing that outweigh your interests, rights, and freedoms, or unless the processing serves to assert, exercise, or defend legal claims.
          </p>
          <p>
            If personal data concerning you are processed for the purpose of direct marketing, you have the right to object at any time to the processing of your personal data for the purpose of such marketing; this also applies to profiling to the extent that it is linked to such direct marketing.
          </p>
          <p>
            If you object to the processing for the purposes of direct marketing, your personal data will no longer be processed for those purposes.
          </p>
          <p>
            You have the option of exercising your right to object in relation to the use of information society services – notwithstanding Directive 2002/58/EC – by using automated procedures that involve technical specifications.
          </p>
          <h5 id="paragraph_14_h">h) Right to revoke the data protection declaration of consent</h5>
          <p>
            You have the right to revoke your data protection declaration of consent at any time. Revoking the consent does not affect the legality of the processing that has occurred on the basis of the consent up until it was revoked.
          </p>
          <h5 id="paragraph_14_i">i) Automated decision-making in individual cases, including profiling</h5>
          <p>
            You have the right not to be subject to a decision based solely on automated processing – including profiling – that has a legal effect on you or significantly affects you in a similar manner.
          </p>
          <p>
            This does not apply if the decision
          </p>
          <ul>
            <li>is required for the conclusion or performance of a contract between you and the controller;</li>
            <li>is permissible according to statutory regulations of the Union or member states to which the controller is subject and these regulations provide for appropriate measures to safeguard your rights, freedoms, and your legitimate interests; or</li>
            <li>is made with your express consent.</li>
          </ul>
          <p>
            However, these decisions may not be based on special categories of personal data pursuant to Art. 9 (1) GDPR, unless Art. 9 (2) (a) or (g) applies and appropriate measures have been taken to safeguard rights and freedoms and your legitimate interests.
          </p>
          <p>
            With respect to the cases referred to in (1) and (3), the controller will take appropriate measures to safeguard the rights and freedoms and your legitimate interests, which include at least the right to obtain the intervention of a person on behalf of the controller, to express his or her own point of view and to challenge the decision.
          </p>
          <h5 id="paragraph_14_j">j) Right to lodge a complaint with a supervisory authority</h5>
          <p>
            Regardless of any other administrative or judicial remedy, you have the right to lodge a complaint with a supervisory authority, in particular in the member state in which you reside, your place of work, or the place of the alleged infringement, if you believe that the processing of the personal data concerning you is in breach of the GDPR.
          </p>
          <p>
            The supervisory authority to which the complaint has been lodged will inform the complainant of the status and outcome of the complaint, including the possibility of a judicial remedy pursuant to Art. 78 GDPR.
          </p>
        </div>

        <div id="paragraph_15">
          <h3>15. Amendments to this data protection information</h3>
          <p>
            We reserve the right to change these data protection regulations at any time in compliance with the legal requirements.
          </p>
          <p>
            Current as of: Feb 2021
          </p>
        </div>


      </div>
      
    </div>

  </div>
</template>

<script>
import NavigationBar from '../components/NavigationBar'

export default {
  name: 'BetgamePrivacy',
  components: { NavigationBar },
  computed: {
    userlang() {
      return this.$store.state.language
    },
    privacyText () {
      if(this.$store.state.betgame && this.$store.state.betgame.privacy_text && this.$store.state.betgame.privacy_text.length > 0) {
        return this.$store.state.betgame.privacy_text
      }
      return ''
    }
  },
  methods: {
    goBack () {
      if (this.$store.getters.loggedIn) {
        this.$router.push({ name: 'profile' }).catch(err => {})
      } else {
        this.$router.go(-1)
      }
    },
    canGoBack () {
      return window.history && window.history.length > 1
    }
  }
}

</script>

<style lang="scss" scoped>
.privacy {
  h1 {
    font-weight: 600;
    font-size: 1.3em;
    color: black;
  }
  h3 {
    font-weight: 500;
    font-size: 1.2em;
  }
  h5 {
    font-weight: 300;
    font-size: 1.1em;
  }
  h6 {
    font-weight: 200;
    font-size: 1.0em;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
  }
  p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 100;
    font-size: 0.9em;
  }
  a {
    word-break: break-word;
  }
  ul > li {
    font-weight: 100;
    font-size: 0.9em;
    color: #5F5D61;
  }
}
</style>
