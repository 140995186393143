<template>
    <div class="table">
        <div class="searchbar" v-bind:class="{ searchbar_active: searchActive }">
          <div class="searchbar-item searchtype-user">
            <input class="search-input" @input="searchInputChanged" type="text" :placeholder="searchuser" ref="searchinput">
          </div>
          <div v-if="betgame_id === 1702" class="searchbar-item searchtype-location">
            <select class="search-input" @change="filterChanged($event)" ref="searchinput">
              <option value="">{{ $t('all_districts') }}</option>
              <option v-for="(src) in usersources_nwz" :value="src">{{ src }}</option>
            </select>
          </div>
          <div v-if="betgame_id === 1902" class="searchbar-item searchtype-location">
            <select class="search-input" @change="filterChanged($event)" ref="searchinput">
              <option value="">{{ $t('all_districts') }}</option>
              <option v-for="(src) in usersources_ez" :value="src">{{ src }}</option>
            </select>
          </div>
          <div v-if="betgame_id === 3003" class="searchbar-item searchtype-location">
            <select class="search-input" @change="filterChanged($event)" ref="searchinput">
              <option value="">{{ $t('all_countries') }}</option>
              <option v-for="(src) in usersources_polygon" :value="src">{{ src }}</option>
            </select>
          </div>
        </div>

        <thead>
            <!--<th class="col_search" colspan="2">
                <input @input="searchInputChanged" type="text" placeholder="User suchen..." style="width: 100%; max-width: 200px;"></input>
            </th>-->
            <th class="col_pos"></th>
            <th class="col_avatar"></th>
            <th class="col_user"></th>
            <th v-if="showUserSourceColumn && (betgame_id === 1702 || betgame_id === 1902)" class="col_user_source">{{ $t('district') }}</th>
            <th v-if="showUserSourceColumn && betgame_id === 3003" class="col_user_source">{{ $t('country') }}</th>
            <th v-else class="col_user_source"></th>
            <th class="col_value col_value--all">{{ $t('rankings_table_header_points') }}</th>
            <th v-if="questions && questions.length > 0" class="col_value col_value--points"><img src="../assets/icons/ic_16_points.svg" alt="Puktzahl Tippen" title="Puktzahl Tippen"></th>
            <th v-if="questions && questions.length > 0" class="col_value col_value--questions"><img src="../assets/icons/ic_16_questions.svg" alt="Puktzahl Sonderfragen" title="Puktzahl Sonderfragen"></th>
            <th style="padding: 14px 8px 12px 4px" class="col_value col_value--points"><img src="../assets/icons/ic_16_star.svg" alt="Spieltagssiege" title="Spieltagssiege"></th>
            <th v-if="betmode === 1" class="col_value col_value--points mobile-hidden">Exa</th>
            <th v-if="betmode === 1" class="col_value col_value--points mobile-hidden">Dif</th>
            <th v-if="betmode === 1" class="col_value col_value--points mobile-hidden">Ten</th>
            <th class="col_value col_value--points mobile-hidden">0Pk</th>
            <th class="col_value mobile-hidden">Anz</th>
        </thead>
        <tr
                v-for="(ranking, index) in rankings"
                :key="infiniteId + '_' + ranking.fk_user"
                v-bind:class="{ current_user: isCurrentUser(ranking.fk_user) }"
        >
            <td v-if="!equalPoints(index)" class="col_pos">{{ ranking.position }}.</td>
            <td v-else class="col_pos"></td>  
            <td class="col_avatar" v-if="isValidIcon(getAvatar(ranking.fk_user))"><img v-bind:src="buildAvatarPath(getAvatar(ranking.fk_user), 300)"></td>
            <td :colspan="isValidIcon(getAvatar(ranking.fk_user)) ? (showUserSourceColumn ? 1 : 2) : (showUserSourceColumn ? 2 : 3)" class="col_user">{{ ranking.user_name }}</td>
            <td v-if="showUserSourceColumn" class="col_user_source">{{ ranking.user_source }}</td>
            <td class="col_value col_value--all">{{ ranking.points_total }}</td>
            <td v-if="questions && questions.length > 0" class="col_value col_value--points">{{ ranking.bet_points }}</td>
            <td v-if="questions && questions.length > 0" class="col_value col_value--questions">{{ ranking.question_points }}</td>
            <td class="col_value col_value--points">{{ ranking.matchday_wins }}</td>
            <td v-if="betmode === 1" class="col_value col_value--points mobile-hidden">{{ ranking.exact }}</td>
            <td v-if="betmode === 1" class="col_value col_value--points mobile-hidden">{{ ranking.goaldiff }}</td>
            <td v-if="betmode === 1" class="col_value col_value--points mobile-hidden">{{ ranking.tendency }}</td>
            <td class="col_value col_value--points mobile-hidden">{{ ranking.no_points }}</td>
            <td class="col_value col_value--points mobile-hidden">{{ ranking.betcount }}</td>
        </tr>
    
        <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
            <div slot="spinner"><div class="messgae">{{ $t('rankings_table_loading') }}</div></div>
            <div slot="no-more">
                <div class="statistics-empty">
                  {{ $t('rankings_table_footer') }}
                </div>
            </div>
            <div slot="no-results">
                <div class="statistics-empty">
                  {{ $t('rankings_table_footer_teaser') }}
                </div>
            </div>
        </infinite-loading>
        
    </div>
    
</template>

<script>
  
  import InfiniteLoading from 'vue-infinite-loading';
  import APIService from '@/services/APIService'
  
  export default {
    name: 'RankingsTableScrollingPublic',
    components: {
      InfiniteLoading,
    },
    data() {
      return {
        page: 1,
        rankings: [],
        searchString: '',
        infiniteId: +new Date(),
        usersources_nwz: ['Stadt Oldenburg', 'Stadt Emden', 'Stadt Delmenhorst', 'Stadt Wilhelmshaven', 'Bremerhaven', 'Bremen',
          'LK Cuxhaven', 'LK Emsland', 'LK Diepholz', 'LK Osterholz', 'LK Ammerland',
          'LK Aurich', 'LK Cloppenburg', 'LK Friesland', 'LK Leer', 'LK Oldenburg', 'LK Vechta', 'LK Wesermarsch',
          'LK Wittmund', 'Ostfriesische Inseln'],
        usersources_ez: ['Stadt Oldenburg', 'Stadt Emden', 'Stadt Delmenhorst', 'Stadt Wilhelmshaven', 'LK Ammerland',
          'LK Aurich', 'LK Cloppenburg', 'LK Friesland', 'LK Leer', 'LK Oldenburg', 'LK Vechta', 'LK Wesermarsch',
          'LK Wittmund', 'Ostfriesische Inseln'],
        usersources_polygon: ['Polygon HQ', 'Austria', 'Germany', 'Sweden', 'Belgium',
          'Italy', 'Switzerland', 'Canada', 'Luxembourg', 'United Kingdom', 'Denmark', 'Netherlands',
          'United States', 'Finland', 'Norway', 'France', 'Singapore'],
        filterString: ''
      };
    },
    props: {
      searchActive: {
        type: Boolean,
        default: () => {
          return false
        }
      }
    },
    created() {
      if(this.$store.state.user && this.$store.state.betgameId && this.$store.state.betgameId > 0 && this.$store.state.currentSeason) {
        this.$store.dispatch('fetchQuestions', []).then(function (response) {
        })
      }
    },
    computed: {
      searchuser() {
        return this.$i18n.t('search_user')
      },
      showUserSourceColumn() {
        return this.betgame_id === 1702 || this.betgame_id === 1902 || this.betgame_id === 3003
      },
      betgame_id() {
        return this.$store.state.betgame ? this.$store.state.betgame.id : -1
      },
      betmode() {
        if(!this.$store.state.betgame) {
          return 1
        }
        return this.$store.state.betgame.bet_mode || 1
      },
      questions() {
        if(!this.$store.state.questions) {
          return []
        }
        return this.$store.state.questions.filter(q => parseInt(q.fk_betgame) === parseInt(this.$store.state.betgameId))
      },
    },
    methods: {
      filterChanged(event) {
        //console.log(event.target.value)
        this.filterString = event.target.value
        this.page = 1
        this.rankings = []
        this.infiniteId += 1
      },
      equalPoints(index) {
        if(index === 0 || !this.rankings || this.rankings.length <= index) {
          return false
        }
        const r1 = this.rankings[index]
        const r2 = this.rankings[index - 1]
        if(r1.points_total === r2.points_total && r1.matchday_wins === r2.matchday_wins && r1.exact === r2.exact && r1.goaldiff === r2.goaldiff) {
            return true
        }
        return false
      },      
      searchInputChanged({ type, target }) {
        this.searchString = target.value.trim()
        this.page = 1
        this.rankings = []
        this.infiniteId += 1
      },
      infiniteHandler($state) {

        var competition = this.$store.state.leagueId
        var betgame = parseInt(window.localStorage.getItem('spmbgid'))
        if(betgame === 12) {
          competition = 1
        } else if(betgame === 3003) {
          competition = 36
        } else {
          betgame = this.$store.state.betgameId
        }
        APIService.getLiveRankingPagePublic(betgame, competition, this.searchString, this.filterString, (this.page - 1) * 500, 500)
        .then(response => {
          if (response.data.length) {
            this.page += 1;
            this.rankings.push(...response.data);
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
        })
        
      },
      isCurrentUser(userId) {
        if(this.$store.state.user) {
          return this.$store.state.user.id === userId
        }
      }
    },
    watch: {
      searchActive: function (newValue) {
        if(newValue === false && (this.searchString.trim().length > 1 || this.filterString.length > 0)) {
          this.searchString = ''
          this.filterString = ''
          this.page = 1
          this.rankings = []
          this.infiniteId += 1
        } else if(newValue === true) {
          this.$refs.searchinput.value = ''
          this.filterString = ''
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .message {
        text-align: center;
        color: darkgray;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .table {

        td, th {
            border-bottom: 1px solid rgba($colorGreyLight, .08);

            thead & {
                font-weight: 400;
                color: $colorGreyLight;
            }
        }
      
        @media screen and (max-width: 567px) {
          .mobile-hidden {
            display: none;
        }
      }
    }

    .col_value {
      text-align: right;
      overflow: hidden;
      font-weight: 400;

      &:last-child {
        padding-left: 4px;
        padding-right: 8px;
        text-align: center;
      }

      &--all, &--points, &--questions {
        max-width: 32px;
        min-width: 24px;
        padding: 0 4px;
        text-align: center;

        img {
          margin-bottom: -3px;
        }
      }

      &--all {
        font-weight: 500;

        thead & {
          font-weight: 400;
        }
      }
    }

    .col_avatar {
      padding-right: 8px;
      position: relative;
      min-width: 24px;
      height: 18px;

      img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 3px;
      }
    }

    .col_user {
      text-align: center;
      overflow: hidden;
      padding-right: 16px;

      @media screen and (max-width: 414px) {
        max-width: 88px;
        padding-right: 8px;
      }
    }

    .col_user_source {
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      max-width: 100px;

      @media screen and (max-width: 414px) {
        max-width: 64px;
      }
    }
    
    .current_user {
        background-color: #F2F2F2;
    }

    .col_pos {
        text-align: center !important;
        overflow: hidden;
        padding-right: 8px;
    }

</style>
