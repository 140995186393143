<template>
  <div class="adconsent" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">

    <keep-alive>
      <NavigationBar id="adconsentNavigationBarId" ref="adconsentNavigationBarRef">
        <template v-slot:center>
          Werberichtlinien
        </template>
      </NavigationBar>
    </keep-alive>


    <div >
      
      <h1 class="adconsent_header">Einwilligung und Werbevereinbarung</h1>
      <p>Ich registriere mich zum kostenlosen Bundesliga-Tippspiel. Im Gegenzug willige ich bis auf Widerruf ein, 
        dass die FUNKE Medien NRW GmbH mich regelmäßig per E-Mail-Newsletter über Medienangebote (Digital und Print), 
        Gewinnspiele, Leserreisen, Treueangebote, Meinungsumfragen, Veranstaltungen sowie Angebote aus dem 
        Online-Shop informiert. Außerdem nehmen wir individuelle Messungen, Speicherung und Auswertung von 
        Öffnungsraten und Klickraten zur Verarbeitung in Empfängerprofilen zwecks Gestaltung künftiger 
        Newsletter entsprechend den Interessen unserer Leser vor. Diese Einwilligungen kann ich 
        jederzeit per E-Mail, durch Nutzung der im Newsletter angegebenen E-Mail-Adresse, widerrufen. 
        Der Widerruf der erteilten Einwilligung hat keine Auswirkung auf die Teilnahme am Bundesliga-Tippspiel.</p>
      <br>


    </div>
  </div>
</template>

<script>

import NavigationBar from '../components/NavigationBar'
export default {
  name: 'AdConsent',
  components: { NavigationBar },
  beforeCreate () {
  },
  methods: {
  },
  computed: {
  }
}
</script>
<style lang="scss" scoped>
.adconsent_header {
  margin-top: 30px;
  margin-bottom: 20px;
}
</style>
