/* eslint-disable */
<template>
  <div class="login" id ="login">

    <NavigationBar>
      <template v-slot:left>
      </template>
      <template v-slot:center>
        {{ pageTitle }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <div v-if="this.$store.state.betgameSlides.length > 0" style="margin-top: 20px">
      <div class="swiper" id="swiper_container">
        <swiper v-if="this.$store.state.betgameSlides.length > 0" :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(slide, index) in this.$store.state.betgameSlides" class="welcome_slide" :key="slide.id">
            <div v-if="slide.link" >
              <a target="_blank" :href="slide.link">
                <img :src="buildSlidePath(slide.image, 300)" style="max-width: 100%;">
              </a>
            </div>
            <div v-else>
              <img :src="buildSlidePath(slide.image, 300)" style="max-width: 100%;">
            </div>
            <div class="swiper-headline">{{ slide.title }}</div>
            <div class="swiper-subline">{{ slide.text }}</div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination" slot="pagination"></div>
      </div>
    </div>

    <div class="vld-parent">
      <loading :active.sync="isLoading"
               :can-cancel="false"
               :color="betgamePrimaryColor"
               :opacity="opacity"
               :is-full-page="isFullPage">
        <div style="margin-bottom: 20px; font-size: 18pt;" slot="before">{{ $t('login_user.loading_user_data') }}</div>
      </loading>
    </div>

    <!--
    <div class="swiper" id="swiper_container">
      <WelcomeSwiper></WelcomeSwiper>
    </div>
    -->
    
    <form @submit.prevent="login">

      <div class="input-container">

        <div>
          <div class="input-element">
            <input v-model="email" type="email" name="email" id="email" placeholder="email" value />
            <label for="email">{{ $t('email') }}</label>
          </div>

          <div class="input-element">
            <input v-model="password" type="password" name="password" id="password" placeholder="password" value />
            <label for="password">{{ $t('password') }}</label>
          </div>
        </div>

        <div class="input-button-row">
          <button type="submit" name="button">
            {{ $t('login') }}
          </button>
        </div>

      </div>
    </form>
  
    <!-- <button v-on:click="callJSString">JavaScript</button> -->
    
    <div class="forgot-password">
      <span>{{ $t('login_user.user_not_registered') }}</span>
      <router-link to="/register">{{ $t('login_user.register_now') }}</router-link>
    </div>
    <div class="forgot-password">
      <span>{{ $t('login_user.validation_code_received') }}</span>
      <router-link to="/validateregistration">{{ $t('login_user.enter_validation_code') }}</router-link>
    </div>
    <div class="forgot-password">
      <span>{{ $t('forgot_password') }}</span>
      <router-link to="/resetpassword">{{ $t('reset_password') }}</router-link>
    </div>

    <div v-if="dashboardAd && dashboardAd.image" style="padding-bottom: 24px; padding-top: 24px; text-align: center">
      <a :href="dashboardAd.link" target="_blank">
        <img style="max-width: 576px; width: 100%;" :src="buildAdPath(dashboardAd.image, 300)">
      </a>
    </div>
    <div v-else-if="dashboardAd && dashboardAd.title" style="margin: 10px auto 0px; max-width: 576px; padding-bottom: 24px; padding-top: 24px; text-align: center">
      <a :href="dashboardAd.link" target="_blank">
        <span>{{ dashboardAd.title }}</span>
      </a>
    </div>
    
  </div>
</template>

<script>
import NavigationBar from './../components/NavigationBar'
import WelcomeSwiper from '../components/WelcomeSwiper'
import { setOptions, bootstrap } from 'vue-gtag'
import Loading from 'vue-loading-overlay'
import Numeral from 'numeral'
import moment from 'moment'

export default {
  components: {
    NavigationBar,
    WelcomeSwiper,
    Loading
  },
  data() {
    return {
      email: '',
      password: '',
      action: '',
      isLoading: false,
      opacity: 0.9,
      isFullPage: true,
      betgameToJoin: '',
      swiperOption: {
        effect: 'slide',
        loop: true,
        autoplay: {
          delay: 5000
        },
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true
        },
        pagination: {
          el: '.swiper-pagination'
        }
      }
    }
  },
  created() {
    this.$store.dispatch('clearDataBeforeLogin')
    .then(() => {
      if(this.$route.query.email) {
        this.email = this.$route.query.email
      } else if(this.$route.params.email) {
        this.email = this.$route.params.email
      }
      if(this.$route.params.action) {
        this.action = this.$route.params.action
        this.$log.debug('LoginUser.created action ' + this.action)
      }
      if(this.$route.params.betgame) {
        this.betgameToJoin = this.$route.params.betgame
        this.$log.debug('LoginUser.created betgame ' + this.betgameToJoin)
      }
      if(this.$store.state.publicBetgames && this.$store.state.publicBetgames.length > 0) {
      } else {
        this.$store.dispatch('fetchPublicBetgames').then(() => {
        })
      }
      if(this.$store.state.betgameId) {
        if(!this.$store.state.betgameSlides || this.$store.state.betgameSlides.length === 0) {
          this.$store.dispatch('fetchBetgameSlides', this.$store.state.betgameId).then(() => {
            this.$log.debug('LoginUser fetchBetgameSlides ' + this.$store.state.betgameId)
          })
        }
        if(!this.$store.state.betgameAds || this.$store.state.betgameAds.length === 0) {
          this.$store.dispatch('fetchBetgameAds', this.$store.state.betgameId).then(() => {
            this.$log.debug('LoginUser fetchBetgameAds ' + this.$store.state.betgameId)
          })
        }
      }
      
      
    }).catch(error => {
    })
  },
  computed: {
    betgamePrimaryColor() {
      if(this.$store.state.betgame && this.$store.state.betgame.primary_color && this.$store.state.betgame.primary_color.length > 0) {
        return this.$store.state.betgame.primary_color
      }
      return '#000000'
    },
    dashboardAd() {
      const ads = this.$store.state.betgameAds.filter(a => a.position === 1)
      if(ads.length === 1) {
        return ads[0]
      }
      return null
    },
    pageTitle() {
      if(this.$store.state.betgame) {
        return this.$store.state.betgame.name
      }
      return this.$t('login_user.title')
    }
  },
  methods: {
    callJSString() {
      try {
        if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
          const a = window.TeamtipAppInterface.getPushToken()
        } else if (window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
          const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('getPushToken')
          const c = window.localStorage.getItem('pushToken')
        } else {
        }
      } catch (e) {
      }
    },
    sendLogin() {
      try {
        if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
          const a = window.TeamtipAppInterface.login()
        } else if (window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
          const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('login')
        } else {
        }
      } catch (e) {
      }
    },
    goBack () {
      this.$router.go(-1)
    },
    login() {
      this.isLoading = true
      const that = this
      this.$store
        .dispatch('login', {
          email: this.email,
          pass: this.password
        })
        .then((res) => {
          if(this.$store.state.language !== undefined && this.$store.state.language !== null &&
              this.$store.state.language.length > 1) {
            Numeral.locale(this.$store.state.language)
            moment.locale(this.$store.state.language)
          }
          if(this.$store.state.language !== undefined && this.$store.state.language !== null &&
              this.$store.state.language.length > 1 && this.$i18n.locale !== this.$store.state.language) {
            this.$i18n.locale = this.$store.state.language
          }
          this.sendLogin()
          if (this.$store.state.betgame.analytics && this.$store.state.betgame.analytics.length > 0) {
            const codes = this.$store.state.betgame.analytics.split(",")
            if (codes.length > 0) {
              if (codes.length > 1) {
                setOptions({
                  config: { id: codes[0] },
                  includes: [
                    { id: codes[1] }]
                })
              } else {
                setOptions({
                  config: { id: codes[0] }
                })
              }
              bootstrap().then(gtag => {
                this.$gtag.set({
                  'user_id': this.$store.state.user ? '' + this.$store.state.user.id : '',
                  'anonymize_ip': true,
                  'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
                  'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
                  'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set'
                })
                /*
                this.$gtag.event('login', {
                  'event_category': 'login',
                  'event_label': window.location.href,
                  'anonymize_ip': true,
                  'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
                  'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
                  'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set'
                })
                */
              })
            }
          }
          this.$log.debug("LoginUser: " + JSON.stringify(res))
          if(res && res > 0) {
            if(this.action === 'join' && this.betgameToJoin !== '') {
              this.$store.dispatch('userEnterBetgame', this.betgameToJoin).then(() => {
                this.$store.dispatch('switchBetgame', this.betgameToJoin).then(() => {
                  this.$router.push({ name: 'dashboard' })
                })
              })
            } else {
              this.$store.dispatch('switchBetgame', res).then(() => {
                this.$router.push({ name: 'dashboard' })
              })
            }
          } else if(this.$store.state.betgameId) {
            this.$store.dispatch('switchBetgame', this.$store.state.betgameId).then(() => {
              this.$router.push({ name: 'dashboard' })
            })
          } else if(this.$store.state.user && this.$store.state.user.fk_betgame) {
            this.$store.dispatch('switchBetgame', this.$store.state.user.fk_betgame).then(() => {
              this.$router.push({ name: 'dashboard' })
            })
          } else {
            this.$router.push('/mybetgames')
          }
        }).catch(function (error) {
          if (error.response && error.response.status == 403) {
            alert(that.$i18n.t('errors.email_or_password_wrong'))
          } else {
            alert(that.$i18n.t('errors.login_error') + ' ' + error)
          }
      }).finally( function () {
        that.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
    padding: 16px 16px 0 16px;

    h1 {
      text-align: center;
    }
    p {
      text-align: center;
      margin-bottom: 26px;
    }
  }

  .forgot-password {
    width: 100%;
    text-align: center;
    color: $colorGreyDark;
    padding: 21px 0 0;

    a {
      font-weight: 500;
      text-decoration: none;
      margin-left: 6px;
      padding: 12px 0;
    }
  }

  @media screen and (max-width: 414px) {
    .swiper {
      margin: 16px 0;
    }
  }
</style>
