<template>
  <div class="profileChangeName" id="profileChangeName">
  
    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('profile.change_name') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>
    
    <form @submit.prevent="changeName">

      <div class="input-container">
        
        <div>
          
          <div class="input-element">
            <input v-model="firstname" type="text" name="firstname" id="firstname" placeholder="firstname" autofocus value />
            <label for="firstname">{{ $t('profile.firstname') }}</label>
          </div>

          <div class="input-element">
            <input v-model="lastname" type="text" name="lastname" id="lastname" placeholder="lastname" autofocus value />
            <label for="lastname">{{ $t('profile.lastname') }}</label>
          </div>
          
        </div>

        <div class="input-button-row">
          <button type="submit" name="button">
            {{ $t('button_save_text') }}
          </button>
        </div>

      </div>
      
    </form>
  
    <ul class="error-container">
      <li v-for="error in errors">{{ error }}</li>
    </ul>
    
  </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  
  export default {
    name: 'profileChangeName',
  components: { NavigationBar },
  data() {
    return {
      firstname: '',
      lastname: '',
      errors: []
    }
  },
  mounted(){
    this.firstname = this.$store.state.user.firstname,
    this.lastname = this.$store.state.user.lastname
  },
  methods: {
    goBack() {
      this.$router.push('/profile')
    },
    changeName() {
      this.errors = []
      if(this.isNullOrUndefined(this.firstname) || this.firstname.length === 0) {
        this.errors.push(this.$i18n.t('profile.error_enter_firstname'))
      }
      if(this.isNullOrUndefined(this.lastname) || this.lastname.length === 0) {
        this.errors.push(this.$i18n.t('profile.error_enter_lastname'))
      }
      if (this.errors.length === 0) {
        this.$store.dispatch('patchUser', {
          firstname: this.firstname, lastname: this.lastname
        }).then(() => {
        }).catch((error) => {
          this.errors.push(error)
        })
      }
    },
    isNullOrUndefined(data) {
      return data === null || data === undefined
    }
  }
}
</script>

<style lang="scss" scoped>
  .profileChangeName {

    form {
      padding-top: 16px;
    }
    h1 {
      text-align: center;
    }
    p {
      text-align: center;
      margin-bottom: 26px;
    }
  }
</style>
