<template>
	<div class="leaguebetgame">
		<nav>
			<div class="navigation-logo">
        <img v-on:click="teamtipLogoClicked" src="../assets/landingpage/logo-teamtip.svg" alt="">
        <div class="navigation-divider"></div>
				<a href="https://www.sportplatzmedia.com" target="_blank">
					<img src="../assets/landingpage/logo-sportplatzmedia.svg" alt="">
				</a>
			</div>
			<ul class="navigation-list">
				<!--<li class="navigation-list-item">
					<a href="#whitelabel_id" v-smooth-scroll="{ updateHistory: false }">Whitelabel-Tippspiel</a>
				</li>
				<li class="navigation-list-item">
					<a href="#features_id" v-smooth-scroll="{ updateHistory: false }">Features</a>
				</li>-->
				<router-link v-if="!this.$store.getters.loggedIn" to="/login" class="router_link">
					<li class="navigation-list-button">
					  <img src="../assets/landingpage/ic_24_login.svg" alt="">
					  Login
					</li>
				</router-link>
			</ul>
		</nav>
		<div class="leaguebetgame__container">
			<div class="leaguebetgame__list">
				<div class="leaguebetgame__list__images">
					<img v-if="this.league === 111" v-on:click="flagSelected(111)" src="../assets/icons/ic_flag_italy.svg">
					<img v-else-if="this.league === 91" v-on:click="flagSelected(91)" src="../assets/icons/ic_flag_england.svg">
					<img v-else-if="this.league === 1" v-on:click="flagSelected(1)" src="../assets/icons/ic_flag_germany.svg">
					<img v-else-if="this.league === 97" v-on:click="flagSelected(97)" src="../assets/icons/ic_flag_spain.svg">
					<img v-else-if="this.league === 116" v-on:click="flagSelected(116)" src="../assets/icons/ic_flag_turkey.svg">
          <img v-else-if="this.league === 71" v-on:click="flagSelected(71)" src="../assets/icons/ic_flag_france.svg">
				</div>
				<h3>{{ leagueName }}</h3>
				<p>{{ description }}</p>
				<button v-if="available" v-on:click="createBetgame"><img src="../assets/icons/ic_12_plus_white.svg" class="button-icon button-icon--left">{{ $t('user_betgame_settings.button_create_betgame') }}</button>
        		<button v-else disabled>Bald verfügbar</button>
			</div>
			<div class="leaguebetgame__divider">
				oder
			</div>
			<div class="button-group">
                <a target="_blank" href="https://itunes.apple.com/app/teamtip/id1496608495" class="router_link">
                  	<div class="button button--appstore"><img src="../assets/landingpage/ic_32_system_apple.svg">Download (iOS)</div>
                </a>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.sportplatzmedia.teamtip" class="router_link">
                  	<div class="button button--appstore"><img src="../assets/landingpage/ic_32_system_android.svg">Download (Android)</div>
                </a>
          	</div>
		</div>
	</div>
</template>

<script>

export default {
  name: 'LeagueBetgame',
  data() {
    return {
      league: 0,
      leagueName: '',
      description: ''
    }
  },
  created() {
    this.league = parseInt(this.$route.params.id)
    this.setLeagueName()
    this.setDescription()
  },
  computed: {
    available() {
      if(this.league === 1) {
        return true
      } else if(this.league === 2) {
        return true
      } else if(this.league === 3) {
        return true
      } else if(this.league === 71) {
        return true
      } else if(this.league === 91) {
        return true
      } else if(this.league === 97) {
        return true
      } else if(this.league === 111) {
        return true
      } else if(this.league === 116) {
        return true
      }
      return false
    }
  },
  methods: {
    setLeagueName() {
      if(this.league === 1) {
        this.leagueName = '1. Bundesliga'
      } else if(this.league === 2) {
        this.leagueName = '2. Bundesliga'
      } else if(this.league === 3) {
        this.leagueName = '3. Liga'
      } else if(this.league === 19) {
        this.leagueName = 'Champions League 2020/21'
      } else if(this.league === 91) {
        this.leagueName = 'Premier League'
      } else if(this.league === 97) {
        this.leagueName = 'Primera División'
      } else if(this.league === 111) {
        this.leagueName = 'Serie A'
      } else if(this.league === 116) {
        this.leagueName = 'SüperLig'
      } else if(this.league === 71) {
        this.leagueName = 'Ligue 1'
      }
    },
    setDescription() {
      if(this.league === 1) {
        this.description = 'Die Bundesliga steht in den Startlöchern! Starte jetzt deine individuelle Tipprunde.'
      } else if(this.league === 2) {
        this.description = 'Der Ball rollt wieder in der 2. Bundesliga. Jetzt deine Tipprunde mit Freunden starten!'
      } else if(this.league === 3) {
        this.description = 'Die 3. Liga geht in die nächste Saison. Deine Chance für eine individuelle Tipprunde. Auf geht`s.'
      } else if(this.league === 19) {
        this.description = 'Die Königsklasse wartet auf dich. Sei der Champion in deiner individuellen Tipprunde.'
      } else if(this.league === 91) {
        this.description = 'Liverpool, Manchester oder Chelsea FC? Wer macht dieses Jahr das Rennen und holt sich den Titel? Starte jetzt deine Tipprunde.'
      } else if(this.league === 97) {
        this.description = 'Das Warten hat ein Ende. Der Titelkampf der LaLiga geht wieder los. Starte jetzt deine Tipprunde für dich und deine Freunde.'
      } else if(this.league === 111) {
        this.description = 'Schafft Cristiano mit Juventus Turin die 10. Meisterschaft in Folge? Nicht mehr lange und wir werden es erfahren. Starte jetzt deine individuelle Tipprunde.'
      } else if(this.league === 116) {
        this.description = 'Die SüperLig startet in die Saison 20/21. Starte auch du deine Tipprunde für dich und deine Freunde.'
      } else if(this.league === 71) {
        this.description = 'In Frankreich rollt der Ball wieder. Jetzt Tipprunde für dich und deine Freunde starten.'
      }
    },    
    createBetgame() {
      this.$router.push('/createuserbetgame')
    },
    flagSelected(id) {
      this.league = id
      this.setLeagueName()
      this.setDescription()
    },
    teamtipLogoClicked() {
      if(window.parent) {
        window.parent.open('https://teamtip.net', '_blank')
      } else {
        window.open('https://teamtip.net', '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

	.leaguebetgame {
		display: flex;
		flex-direction: column;
		padding: 36px 0 0 !important;
		box-sizing: border-box;
		min-height: 100vh;
	    background: url('../assets/landingpage/header-background.jpg');
	    background-position: center center;
	    background-repeat: no-repeat;
	    background-size: cover;

	    @media screen and (max-width: 767px) {
			padding: 18px 0 !important;
			min-height: 100vh;
			min-height: calc(var(--vh, 1vh) * 100);
			max-height: inherit;
			height: auto;
	    }

	    &__container {
	    	display: flex;
	    	align-items: center;
	    	justify-content: center;
	    	flex-direction: column;
	    	flex: 1;
	    	max-width: 528px;
	    	margin: 0px auto 36px;
	    	padding: 0 16px;
	    }

	    &__list {
			background: $colorWhite;
			border-radius: 11px;
			padding: 16px;
			box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
			text-align: center;

			&__images {
				display: flex;
				justify-content: center;
				margin: 16px 0;

				@media screen and (min-width: 768px) {
		        	margin: 32px 0;
		      	}

				img {
					margin: 0 6px; 

					&:first-child, &:last-child {
						transition: .2s ease-in-out;

						&:hover {
							cursor: pointer;
							opacity: 1;
							transition: .2s ease-in-out;
						}

						@media screen and (max-width: 320px) {
				        	display: none
				      	}
					}

					&:first-child {
						transform-origin: right bottom;
					}

					&:last-child {
						transform-origin: left bottom;
					}

					&:nth-child(2), &:nth-child(4) {
						transform: scale(.75);
						opacity: .64;
						transition: .2s ease-in-out;
						transform-origin: center bottom;

						&:hover {
							cursor: pointer;
							opacity: 1;
							transition: .2s ease-in-out;
						}
					}
				}
			}

	    	h3 {
				font-size: 28px;
				line-height: 32px;
				font-weight: 500;
				color: $colorGreyDark;
				max-width: 576px;
				margin: 0px auto;

		      	@media screen and (min-width: 768px) {
		        	margin-bottom: 8px;
	      		}
		    }

		    p {
				margin: 3px auto 26px;
		    }
	    }

	    &__divider {
	    	width: 100%;
	    	position: relative;
	    	text-align: center;
	    	text-transform: uppercase;
	    	font-size: 14px;
	    	font-weight: 500;
	    	color: $colorWhite;
	    	opacity: .48;
	    	margin: 20px 0;

	    	&:before, &:after {
	    		content: '';
	    		position: absolute;
	    		top: calc(50% - 1px);
	    		width: calc(50% - 30px);
	    		height: 2px;
	    		background: $colorWhite;
	    	}

	    	&:before {
	    		left: 0;
	    	}

	    	&:after {
	    		right: 0;
	    	}
	    }
	}

	nav {
	    width: 100%;
	    max-width: 1108px;
	    margin: 0px auto;
	    padding: 0 16px;
	    box-sizing: border-box;
	}

	.button-group {
		width: 100%;
		justify-content: space-between;
		margin-top: 0;

		@media screen and (max-width: 576px) {
			flex-direction: column;
		}

		.router_link {
			width: calc(50% - 12px);
			text-decoration: none;

			@media screen and (max-width: 576px) {
	        	width: 100%;
	      	}
		}
	}

    .button {
		display: flex;
		align-items: center;
		justify-content: center;
		background: $colorWhite;
		color: var(--color-primary);
		width: 100%;
		font-size: 17px;
		font-weight: 500;
		border: 0;
		border-radius: 6px;
		padding: 16px;
		transition: .2s;
		margin-right: 40px;
		box-sizing: border-box;

      	&--appstore {
        	padding: 12px 16px;
      	}

      	@media screen and (max-width: 576px) {
        	margin-bottom: 16px;
      	}

      	img {
        	margin-right: 8px;
      	}
    }
</style>
