<template>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="M20,0 C22.209139,0 24,1.790861 24,4 L24,20 C24,22.209139 22.209139,24 20,24 L4,24 C1.790861,24 0,22.209139 0,20 L0,4 C0,1.790861 1.790861,0 4,0 L20,0 Z M20,2 L4,2 C2.8954305,2 2,2.8954305 2,4 L2,20 C2,21.1045695 2.8954305,22 4,22 L20,22 C21.1045695,22 22,21.1045695 22,20 L22,4 C22,2.8954305 21.1045695,2 20,2 Z M6,17 L18,17 C18.5522847,17 19,17.4477153 19,18 C19,18.5128358 18.6139598,18.9355072 18.1166211,18.9932723 L18,19 L6,19 C5.44771525,19 5,18.5522847 5,18 C5,17.4871642 5.38604019,17.0644928 5.88337887,17.0067277 L6,17 L18,17 L6,17 Z M6,13 L18,13 C18.5522847,13 19,13.4477153 19,14 C19,14.5128358 18.6139598,14.9355072 18.1166211,14.9932723 L18,15 L6,15 C5.44771525,15 5,14.5522847 5,14 C5,13.4477153 5.44771525,13 6,13 Z M17,5 C18.1045695,5 19,5.8954305 19,7 L19,9 C19,10.1045695 18.1045695,11 17,11 L7,11 C5.8954305,11 5,10.1045695 5,9 L5,7 C5,5.8954305 5.8954305,5 7,5 L17,5 Z M17,7 L7,7 L7,9 L17,9 L17,7 Z" id="detailview" fill-rule="nonzero"></path>
    </svg>
</template>

<script>
  export default {
    name: 'DetailViewIcon'
  }
</script>

<style lang="scss" scoped>
	svg {
		fill: var(--color-primary);
	}
</style>