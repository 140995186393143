<template>
    <div class="swiper-container">
        <swiper v-if="selectedBetgame === '2'" :options="swiperOption" ref="mySwiper">
            <swiper-slide class="welcome_slide">
                <img src="@/assets/images/login/login-image-01.png">
                <div class="swiper-headline">Herzlich Willkommen</div>
                <div class="swiper-subline">Starte jetzt das 2. Bundesliga-Tippspiel von FussiFreunde und gewinne 500€.</div>
            </swiper-slide>
            <swiper-slide class="welcome_slide">
                <img src="@/assets/images/login/login-image-02.png">
                <div class="swiper-headline">Platziere deinen Tipp</div>
                <div class="swiper-subline">Schnelle & einfache Erfassung deiner Tipps dank intuitivem Bedienungssystem!</div>
            </swiper-slide>
            <swiper-slide class="welcome_slide">
                <img src="@/assets/images/login/login-image-03.png">
                <div class="swiper-headline">Spieltagsgewinne</div>
                <div class="swiper-subline">Erreiche den Spieltagssieg und gewinne Goodies von unserem Partner SpielerPlus.</div>
            </swiper-slide>
            <swiper-slide class="welcome_slide">
                <img src="@/assets/images/login/login-image-04b.png">
                <div class="swiper-headline">Hol' dir den Sieg!</div>
                <div class="swiper-subline">Der Tippkönig kann sich über 500€ freuen.</div>
            </swiper-slide>
        </swiper>
        <swiper v-else :options="swiperOption" ref="mySwiper">
            <swiper-slide class="welcome_slide">
                <img src="@/assets/images/login/login-image-01.png">
                <div class="swiper-headline">{{ headlineSlide1 }}</div>
                <div class="swiper-subline">{{ sublineSlide1 }}</div>
            </swiper-slide>
            <swiper-slide class="welcome_slide">
                <img src="@/assets/images/login/login-image-02.png">
                <div class="swiper-headline">Platziere deinen Tipp</div>
                <div class="swiper-subline">Schnelle & einfache Erfassung deiner Tipps dank intuitivem Bedienungssystem!</div>
            </swiper-slide>
            <swiper-slide class="welcome_slide">
                <img src="@/assets/images/login/login-image-03.png">
                <div class="swiper-headline">Hol' dir den Sieg!</div>
                <div class="swiper-subline">{{ sublineSlide3 }}</div>
            </swiper-slide>
        </swiper>
        <div class="swiper-pagination" slot="pagination"></div>
    </div>
</template>

<script>

  import 'swiper/dist/css/swiper.css'
  import { swiper, swiperSlide } from 'vue-awesome-swiper'

  export default {
    name: 'WelcomeSwiper',
    components: {
      swiper,
      swiperSlide
    },
    data() {
      return {
        swiperOption: {
          effect: 'slide',
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: 'auto',
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true
          },
          pagination: {
            el: '.swiper-pagination'
          }
        }
      }
    },
    computed: {
      headlineSlide1() {
        if(this.$store.state.betgameId === '1') {
          return 'Bundesliga-Tippspiel 2019/2020'
        }
        return 'Herzlich Willkommen'
      },
      sublineSlide1() {
        if(this.$store.state.betgameId === '1') {
          return 'Jetzt anmelden, sofort lostippen und tolle Preise gewinnen.'
        }
        return 'Beginne jetzt mit deinem einzigartigen Tipp-Erlebnis.'
      },
      sublineSlide3() {
        if(this.$store.state.betgameId === '1') {
          return 'Sei der beste Tipper im Revier und hol‘ Dir jede Woche den Spieltagssieg.'
        }
        return 'Sei der Beste deiner Tipprunde und erreiche den Spieltagssieg.'
      },
      selectedBetgame() {
        return this.$store.state.betgameId
      },
      swiper() {
        return this.$refs.mySwiper.swiper
      }
    },
    methods: {
    },
    mounted() {
      //this.swiper.slideTo(3, 1000, false)
    }
  }
</script>

<style lang="scss">
  
  .swiper {

      &-container {
        width: 100%;
      }

      &-slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        border:2px #f00;
        padding: 0 16px;
        box-sizing: border-box;

        img {
          align-self: center;
          margin-bottom: 8px;
          height: 180px;
        }

        .swiper-headline {
          font-size: 22px;
          line-height: 28px;
          font-weight: 500;
          color: $colorGreyDark;
          margin-bottom: 5px;
        }

        .swiper-subline {
          font-size: 14px;
          line-height: 20px;
          color: $colorGreyDark;
          margin-bottom: 10px;
        }
      }

      &-pagination {
        position: relative;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        &-bullet {
          background: $colorGreyMid;
          opacity: 1;
          margin: 0 6px;
          width: 5px;
          height: 5px;

          &-active {
            background: $colorGreyDark;
            width: 8px;
            height: 8px;
            margin: 0 4px;
            transition: all .2s ease-in-out;
          }
        }
      }
  }
</style>
