<template>
    <div :key="componentKey" class="betgameEditSpecialQuestions" id="betgameEditSpecialQuestions">
      
      <NavigationBar>
        <template v-slot:left>
          <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
        </template>
        <template v-slot:center>
          {{ $t('betgame_settings.special_questions') }}
        </template>
        <template v-slot:right>
        </template>
      </NavigationBar>

      <!-- Wenn noch keine Sonderfragen erstellt wurden -->
      <div class="notification">
        <h1>{{ $t('betgame_settings.special_questions_title') }}</h1>
        <p>{{ $t('betgame_settings.special_questions_description') }}</p>
        <button v-on:click="questionClicked({})"><img class="button-icon button-icon--left" src="../assets/icons/ic_12_plus_white.svg">{{ $t('betgame_settings.button_create_special_question') }}</button>
      </div>

      <!-- Sobald eine oder mehere Sonderfragen vorliegen -->
      <div v-if="questions.length > 0" class="list p-top-none sonderfragen">
        <div class="table-view-title">
          {{ $t('betgame_settings.special_questions_list_header') }}
        </div>
  
        <div v-on:click="questionClicked(question)" v-for="(question, index) in questions" :key="question.id" class="table-view-cell" v-bind:class="{ default_question: question.fk_betgame === null }">
          <div class="table-view-cell-icon size-24">
            <img src="../assets/icons/ic_24_question.svg">
          </div>
          <div class="table-view-cell-label">{{ question.question }}</div>
          <div class="table-view-cell-drilldown"></div>
        </div>
        
        <!--
        <div class="table-view-cell">
          <div class="table-view-cell-icon size-24">
            <img src="../assets/icons/ic_24_question.svg">
          </div>
          <div class="table-view-cell-label">Wer wird Europameister?</div>
          <div class="table-view-cell-drilldown"></div>
        </div>
        <div class="table-view-cell">
          <div class="table-view-cell-icon size-24">
            <img src="../assets/icons/ic_24_question.svg">
          </div>
          <div class="table-view-cell-label">Wer wird Torschützenkönig?</div>
          <div class="table-view-cell-drilldown"></div>
        </div>
        <div class="table-view-cell">
          <div class="table-view-cell-icon size-24">
            <img src="../assets/icons/ic_24_question.svg">
          </div>
          <div class="table-view-cell-label">Wer kommt ins Finale?</div>
          <div class="table-view-cell-drilldown"></div>
        </div>
        -->
        
      </div>

      <!--
      <button v-on:click="questionClicked({})"><img class="button-icon button-icon--left" src="../assets/icons/ic_12_plus_white.svg">Sonderfrage erstellen</button>
      -->
      
    </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  
  export default {
    name: 'BetgameEditSpecialQuestions',
    components: { NavigationBar },
    data () {
      return {
        componentKey: 0
      }
    },
    created () {
    },
    mounted () {
      const that = this
      this.$store.dispatch('fetchQuestions', []).then(function (response) {
        that.componentKey += 1
      })
    },
    computed: {
      questions() {
        return this.$store.state.questions.filter(q => parseInt(q.fk_betgame) === parseInt(this.$store.state.betgameId))
      }
    },
    methods: {
      questionClicked(question) {
        this.$router.push({ name: 'betgameeditspecialquestiondetail', params: { question: question }})
      },
      goBack () {
        this.$router.push('/betgameedit')
      },
    }
  }
</script>

<style lang="scss" scoped>

  .betgameEditSpecialQuestions {
    max-width: 576px;
    margin: 0 auto auto auto;

    @media screen and (min-width: 768px) {
      margin: 48px auto 0;
    }
  }

  .sonderfragen {
    margin-top: 16px;

    .table-view-cell:last-child {
      border-bottom: 0;
    }
  }

  button {
    margin-top: 16px;
  }
  
  .default_question {
    background-color: lightgrey;
  }
</style>
