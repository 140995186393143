/* eslint-disable */
<template>
  <div id="resetPassword" style="justify-content: flex-start; margin-top: 32px;">

    <NavigationBar>
      <template v-slot:left>
      </template>
      <template v-slot:center>
        {{ $t('password_reset.new_password') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <h1>{{ $t('password_reset.new_password_title') }}</h1>
    <p>{{ $t('password_reset.new_password_text') }}</p>

    <form @submit.prevent="resetPassword">
      
      <div class="input-container">

        <div>
          <div class="input-element">
            <input v-model="userEmail" type="email" name="email" id="email" placeholder="email" value />
            <label for="email">{{ $t('email') }}</label>
          </div>
          
          <div class="input-element">
            <input v-model="token" type="text" name="token" id="token" placeholder="token" value />
            <label for="token">{{ $t('password_reset.validation_code') }}</label>
          </div>

          <div class="input-element">
            <input v-model="password" type="password" name="password" id="password" placeholder="password" value />
            <label for="password">{{ $t('password_reset.new_password') }}</label>
          </div>

          <div class="input-element">
            <input v-model="password_repeat" type="password" name="password_repeat" id="password_repeat" placeholder="password_repeat" value />
            <label for="password_repeat">{{ $t('password_reset.new_password_repeat') }}</label>
          </div>
        </div>

        <div class="input-button-row">
          <button type="submit" name="button">
            {{ $t('password_reset.button_save_password') }}
          </button>
        </div>

      </div>
      
    </form>

    <div class="forgot-password">
      <span>{{ $t('not_registered') }}</span>
      <router-link to="/register">{{ $t('join_now') }}</router-link>
    </div>
    
  </div>
</template>

<script>
import NavigationBar from './../components/NavigationBar'
import Vue from 'vue'
import { TOAST_DURATION } from '../main'
import { setOptions, bootstrap } from 'vue-gtag'

export default {
  name: 'enterpasswordreset',
  components: { NavigationBar },
  props: {
    email: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userEmail: '',
      token: '',
      password: '',
      password_repeat: '',
    }
  },
  created () {
    Vue.$log.debug('enterpasswordreset created ' + this.$route.query.email + ' --- ' + this.$route.query.code)
    this.userEmail = this.$route.query.email || this.email
    this.token = this.$route.query.code || ''
  },
  methods: {
    resetPassword() {
      if(this.password === null || this.password === undefined || this.password.length < 5 ||
              this.password !== this.password_repeat) {
        alert(this.$i18n.t('errors.passwords_missmatch'))
        this.password = ''
        this.password_repeat = ''
        this.token = ''
        return
      }
      const that = this
      this.$store
        .dispatch('resetPassword', {
          email: this.userEmail,
          token: this.token,
          pass: this.password
        })
        .then(() => {
          Vue.$toast.open({ message: that.$i18n.t('password_reset.password_updated'), position: 'top', duration: TOAST_DURATION, type: 'success' })
          if (this.$store.state.betgame.analytics && this.$store.state.betgame.analytics.length > 0) {
            const codes = this.$store.state.betgame.analytics.split(",")
            if (codes.length > 0) {
              if (codes.length > 1) {
                setOptions({
                  config: { id: codes[0] },
                  includes: [
                    { id: codes[1] }]
                })
              } else {
                setOptions({
                  config: { id: codes[0] }
                })
              }
              bootstrap().then(gtag => {
                this.$gtag.set({
                  'user_id': this.$store.state.user ? '' + this.$store.state.user.id : 'user id not set',
                  'anonymize_ip': true,
                  'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
                  'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
                  'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set'
                })
                this.$gtag.pageview({
                  page_title: this.$route.name,
                  page_path: this.$route.path,
                  page_location: window.location.href
                })
              })
            }
          }
          this.$router.push({ name: 'dashboard' })
        }).catch(function (error) {
          Vue.$toast.open({ message: that.$i18n.t('password_reset.password_not_updated_please_check_input'), position: 'top', duration: 2 * TOAST_DURATION, type: 'error' })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  #resetPassword {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 64px);
    padding: 64px 16px 0 16px;

    h1 {
        text-align: center;
    }
    p {
        text-align: center;
        margin-bottom: 26px;
    }
  }

  .forgot-password {
    width: 100%;
    text-align: center;
    color: $colorGreyDark;
    padding: 20px 0 16px;

    a {
      font-weight: 500;
      text-decoration: none;
      margin-left: 6px;
      padding: 12px 0;
    }
  }
</style>
