<template>
  <div class="register" id="register" style="justify-content: flex-start; margin-top: 32px;">
  
    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('register.register') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>
    
    <div style="margin-top: 20px;">
      <h1>{{ $t('register.title') }}</h1>
      <p>{{ $t('register.text') }}</p>
    </div>
    
    <form @submit.prevent="register">

        
        <div class="input-container">

          <div class="input-header">
            {{ $t('register.your_account') }}
          </div>

          <div v-if="betgame_id === 3003 || betgame_id === 4003" class="input-element" v-bind:class="{ validation_true: firstnameValid === true, validation_false: firstname.length > 0 && firstnameValid === false }">
            <input v-model="firstname" type="text" name="firstname" id="firstname" placeholder="firstname" />
            <label for="firstname">{{ $t('firstname') }}</label>
          </div>

          <div v-if="betgame_id === 3003 || betgame_id === 4003" class="input-element" v-bind:class="{ validation_true: lastnameValid === true, validation_false: lastname.length > 0 && lastnameValid === false }">
            <input v-model="lastname" type="text" name="lastname" id="lastname" placeholder="lastname" />
            <label for="lastname">{{ $t('lastname') }}</label>
          </div>
          
          <div v-if="betgame_id !== 3003" class="input-element" v-bind:class="{ validation_true: nicknameValid === true, validation_false: name.length > 0 && nicknameValid === false }">
            <input v-model="name" type="text" name="name" id="name" placeholder="name" />
            <label for="name">{{ $t('nickname') }}</label>
          </div>

          <div v-if="betgame_id === 3003" class="input-element" v-bind:class="{ validation_true: this.selectedUserSource && this.selectedUserSource.length > 0 }">
            <select name="landkreis" id="landkreis" @change="filterChanged($event)" required>
              <option value=""></option>
              <option v-for="(src) in usersources_polygon" :value="src">{{ src }}</option>
            </select>
            <label for="landkreis">{{ $t('country') }}</label>
          </div>
          
          <div class="input-element" v-bind:class="{ validation_true: emailValid === true, validation_false: email.length > 0 && emailValid === false }" >
            <input @blur="handleEmailBlur" v-model="email" type="email" name="email" id="email" placeholder="email" />
            <label for="email">{{ $t('email') }}</label>
          </div>

          <div class="input-element" v-bind:class="{ validation_true: passwordValid === true, validation_false: password.length > 0 && passwordValid === false }">
            <input v-model="password" type="password" name id="password" placeholder="password" />
            <label for="password">{{ $t('password') }}</label>
          </div>

        </div>

        <div v-if="showAddress" class="input-container">
  
          <div class="input-header">
            {{ $t('personal_information') }}
          </div>
  
          <div class="input-element" >
            <input v-model="address" type="text" name="adresse" id="adresse" placeholder="adresse" />
            <label for="adresse">{{ $t('street_and_number') }}</label>
          </div>

          <div class="input-element" >
            <input v-model="zip" type="text" name="zip" id="zip" placeholder="zip" />
            <label for="zip">{{ $t('zip') }}</label>
          </div>

          <div class="input-element" >
            <input v-model="city" type="text" name="city" id="city" placeholder="city"/>
            <label for="city">{{ $t('city') }}</label>
          </div>
          
          <div v-if="this.$store.state.betgame.id === 1" class="input-checkbox">
            <input v-model="newsletterSubscription" type="checkbox" id="newsletterSubscription1" name="newsletterSubscription" />
            <label for="newsletterSubscription1">Ich bin damit einverstanden, dass die Boyens Medien GmbH & Co. KG per E-Mail anfragt und mir interessante Verlagsangebote unterbreitet.</label>
          </div>
          <!--
          <div v-else-if="this.$store.state.betgame.id === 11" class="input-checkbox">
            <input v-model="newsletterSubscription" type="checkbox" id="newsletterSubscription3" name="newsletterSubscription" />
            <label for="newsletterSubscription3">Ich bin damit einverstanden, dass das Medienhaus - Pforzheimer Zeitung - J. Esslinger GmbH & Co. KG per E-Mail anfragt und mir interessante Verlagsangebote unterbreitet.</label>
          </div>
          -->
        </div>

        <div v-else-if="betgame_id === 1702 || betgame_id === 1902" class="input-container">
  
          <div class="input-header">
            {{ $t('personal_information') }}
          </div>
  
          <div class="input-element">
            <select name="landkreis" id="landkreis" @change="filterChanged($event)" required>
              <option value=""></option>
              <option v-if="betgame_id === 1702" v-for="(src) in usersources_nwz" :value="src">{{ src }}</option>
              <option v-if="betgame_id === 1902" v-for="(src) in usersources_ez" :value="src">{{ src }}</option>
            </select>
            <label for="landkreis">{{ $t('district') }}</label>
          </div>
  
          <div class="input-element" >
            <input v-model="address" type="text" name="adresse" id="adresse" placeholder="adresse" />
            <label for="adresse">{{ $t('address') }}</label>
          </div>
  
          <div v-if="betgame_id === 1702" class="input-checkbox">
            <input v-model="newsletterSubscription" type="checkbox" id="newsletterSubscription" name="newsletterSubscription" />
            <label for="newsletterSubscription">Ich möchte mir weitere Vorteile (z.B. exklusive Gewinnspiele oder preisreduzierte Angebote) sichern und erlaube der NWZ, mich entsprechend der von mir zur Kenntnis genommenen datenschutzrechtlichen Einwilligungserklärung per Email zu kontaktieren.</label>
          </div>
          <div v-else-if="betgame_id === 1902" class="input-checkbox">
            <input v-model="newsletterSubscription" type="checkbox" id="newsletterSubscription" name="newsletterSubscription" />
            <label for="newsletterSubscription">Ich möchte mir weitere Vorteile (z.B. exklusive Gewinnspiele oder preisreduzierte Angebote) sichern und erlaube der EZ, mich entsprechend der von mir zur Kenntnis genommenen datenschutzrechtlichen Einwilligungserklärung per Email zu kontaktieren.</label>
          </div>
          
        </div>
      
        <div v-if="this.$store.state.betgame.id === 7" class="input-container">
          <div class="input-checkbox">
            <input v-model="newsletterSubscription" type="checkbox" id="newsletterSubscription7" name="newsletterSubscription" />
            <label for="newsletterSubscription7">Ich habe die <a target="_blank" href="https://www.mannheimer-morgen.de/leben_artikel,-freizeit-mm-tippspiel-zur-fussball-em-2021-_arid,1806818.html">{{ $t('conditions') }}</a> gelesen und stimme diesen zu. Die Teilnahme am Tippspiel erfolgt freiwillig. Voraussetzung für die Teilnahme ist die Erteilung einer jederzeit widerruflichen Einwilligung zur Zusendung des EM-Newsletters (11.06.-11.07.2021). Die Rechtsgrundlage für die Datenverarbeitung in diesem Zusammenhang ist Art. 6 Abs. 1 lit. a DSGVO. Ansonsten werden meine Daten nur zur Durchführung des Tippspiels und zur Gewinnbenachrichtigung verwendet. Eine Weitergabe meiner Daten findet nicht statt. Meine Einwilligung kann ich jederzeit über einen Link im Newsletter oder per Mail an <a target="_blank" href="mailto:tippspiel@mamo.de">tippspiel@mamo.de</a> widerrufen. Weitere Informationen, insbesondere zum Umgang mit meinen personenbezogenen Daten und zu meinen Rechten finde ich unter: <a target="_blank" href="https://mannheimer-morgen.de/datenschutzerklaerung">mannheimer-morgen.de/datenschutzerklaerung</a>.</label>
          </div>
        </div>

        <div v-if="betgame_id === 4001 || betgame_id === 4002 || betgame_id === 4003" class="input-container">
  
          <div class="input-header">
            {{ $t('create_betgame.conditions_and_data_protection_funke') }}
          </div>

          <div v-if="this.$store.state.language === 'de'" class="input-text">
            <label>Hier finden Sie unsere <a href="/termsofuse">Nutzungsbedingungen</a> sowie die <a href="/privacy">Datenschutzerklärung</a>.</label>
          </div>
          <div v-else class="input-text"> 
            <label>I agree with the <a href="/termsofuse">terms and conditions</a>.</label>
          </div>
  
          <div class="input-checkbox">
            <input v-model="adConsent" type="checkbox" id="adConsent" name="adConsent" />
            <label for="adConsent">Mit meiner Anmeldung zum Newsletter stimme ich der <a href="/adconsenttext">Werbevereinbarung</a> zu. Eine Abmeldung ist jederzeit über einen Link im Newsletter möglich.</label>
          </div>

          <div class="input-checkbox">
            <input v-model="isUnder18" type="checkbox" id="isUnder18" name="isUnder18" />
            <label for="isUnder18">{{ $t('create_betgame.i_am_over_18') }}</label>
          </div>
          
        </div>
      
        <div v-else class="input-container">

          <div class="input-header">
            {{ $t('create_betgame.conditions_and_data_protection') }}
          </div>

          <div v-if="betgame_id !== 3003" class="input-checkbox">
            <input v-model="agreedTOS" type="checkbox" id="agreedTOS" name="agreedTOS" />
            <!--<label for="agreedTOS">{{ $t('create_betgame.agree_terms_of_use') }} <a target="_blank" href="/termsofuse">({{ $t('create_betgame.link') }})</a>.</label>-->
            <label v-if="this.$store.state.language === 'de'" for="agreedTOS">Ich stimme den <a href="/termsofuse">Nutzungsbedingungen</a> zu.</label>
            <label v-else for="agreedTOS">I agree with the <a href="/termsofuse">terms and conditions</a>.</label>
          </div>
          <div v-else class="input-checkbox">
            <input v-model="agreedTOS" type="checkbox" id="agreedTOS" name="agreedTOS" />
            <label v-if="this.$store.state.language === 'de'" for="agreedTOS">Ich stimme den <a href="/conditions">Teilnahmebedingungen</a> zu.</label>
            <label v-else for="agreedTOS">I agree with the <a href="/conditions">conditions of participation</a>.</label>
          </div>
          
          <div v-if="privacyLink && privacyLink.length > 0" class="input-empty">
            <a target="_blank" :href="privacyLink">{{ $t('register.data_protection') }}</a>
          </div>

          <div v-if="betgame_id === 1702" class="input-empty">
            <a target="_blank" href="https://www.nwzmedien.de/datenschutz/nwzonline">Datenschutzhinweise</a>
          </div>

          <div v-if="betgame_id === 1902" class="input-empty">
            <a target="_blank" href="https://www.emderzeitung.de/sonstige/datenschutz.html">Datenschutzhinweise</a>
          </div>
          
          <div v-if="this.$store.state.betgame && this.$store.state.betgame.id === 11" class="input-checkbox">
            <input v-model="agreedCOP" type="checkbox" id="agreedCOP" name="agreedCOP" />
            <label for="agreedCOP">Ich stimme den <a href="/conditions">{{ $t('conditions') }}</a> zu.</label>
          </div>
          <div v-else-if="conditionsOfParticipation && conditionsOfParticipation.length > 0 && betgame_id !== 3003" class="input-empty">
            <a href="/conditions">{{ $t('conditions') }}</a>
          </div>

          <div v-if="betgame_id === 3003" class="input-empty">
            <a href="/rules">{{ $t('menu.betting_rules') }}</a>
          </div>
          
          <div v-if="betgame_id !== 3003" class="input-checkbox">
            <input v-model="isUnder18" type="checkbox" id="isUnder18" name="isUnder18" />
            <label for="isUnder18">{{ $t('create_betgame.i_am_under_18') }} <span style="font-size: 14px; opacity: .48;">({{ $t('create_betgame.optional') }})</span></label>
          </div>

          <div v-if="betgame_id === 1607" class="input-checkbox">
            <input v-model="adConsent" type="checkbox" id="adConsent" name="adConsent" />
            <label for="adConsent">Ja, ich möchte Informationen zu den Medienangeboten der FUNKE MEDIEN NRW GmbH erhalten und stimme den <a target='_blank' href="/adconsent">Werberichtlinien</a> zu.</label>
          </div>
          
        </div>

        <div class="input-button-row">
          <button type="submit" name="button" :disabled="this.emailAlreadyRegistered">
            {{ $t('register.button_register_text') }}
          </button>
        </div>

    </form>
  
    <ul class="error-container" style="margin-bottom: 20px;">
      <li v-for="error in errors">{{ error }}</li>
    </ul>
  
    <div v-if="emailAlreadyRegistered" class="forgot-password">
      <p>
        <span>{{ $t('forgot_password') }} </span>
        <router-link :to="{ name: 'resetpassword', params: { email: this.email }}">{{ $t('reset_password') }}</router-link>
      </p>
      <p>
        <span>{{ $t('create_betgame.already_registered') }} </span>
        <router-link :to="{ name: 'login', params: { email: this.email }}">{{ $t('login') }}</router-link>
      </p>
    </div>
    
  </div>
</template>

<script>
  
  import APIService from '@/services/APIService'
  import NavigationBar from './../components/NavigationBar'
  import Numeral from 'numeral'
  import moment from 'moment'
  
export default {
  name: 'registeruser',
  components: { NavigationBar },
  props: {
    emailparam: {
      type: String,
      default: ''
    },
    passparam: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      firstname: '',
      lastname: '',
      name: '',
      email: this.emailparam,
      password: this.passparam,
      errors: [],
      emailAlreadyRegistered: false,
      agreedTOS: false,
      isUnder18: false,
      adConsent: false,
      address: '',
      zip: '',
      city: '',
      newsletterSubscription: false,
      agreedCOP: false,
      selectedUserSource: '',
      usersources_nwz: ['Stadt Oldenburg', 'Stadt Emden', 'Stadt Delmenhorst', 'Stadt Wilhelmshaven', 'Bremerhaven', 'Bremen',
        'LK Cuxhaven', 'LK Emsland', 'LK Diepholz', 'LK Osterholz', 'LK Ammerland',
        'LK Aurich', 'LK Cloppenburg', 'LK Friesland', 'LK Leer', 'LK Oldenburg', 'LK Vechta', 'LK Wesermarsch',
        'LK Wittmund', 'Ostfriesische Inseln'],
      usersources_ez: ['Stadt Oldenburg', 'Stadt Emden', 'Stadt Delmenhorst', 'Stadt Wilhelmshaven', 'LK Ammerland',
        'LK Aurich', 'LK Cloppenburg', 'LK Friesland', 'LK Leer', 'LK Oldenburg', 'LK Vechta', 'LK Wesermarsch',
        'LK Wittmund', 'Ostfriesische Inseln'],
      usersources_polygon: ['Polygon HQ', 'Austria', 'Germany', 'Sweden', 'Belgium',
        'Italy', 'Switzerland', 'Canada', 'Luxembourg', 'United Kingdom', 'Denmark', 'Netherlands',
        'United States', 'Finland', 'Norway', 'France', 'Singapore', 'Spain', 'Ireland'],
    }
  },
  computed: {
    betgame_id() {
      return this.$store.state.betgame ? this.$store.state.betgame.id : -1
    },
    showAddress() {
      return this.$store.state.betgame && (this.$store.state.betgame.id === 1 || this.$store.state.betgame.id === 11)
    },
    privacyLink () {
      if(this.$store.state.betgame && this.$store.state.betgame.privacy && this.$store.state.betgame.privacy.length > 0) {
        return this.$store.state.betgame.privacy
      }
      return ''
    },
    conditionsOfParticipation () {
      if(this.$store.state.betgame && this.$store.state.betgame.conditions && this.$store.state.betgame.conditions.length > 0) {
        return this.$store.state.betgame.conditions
      }
      return ''
    },
    nicknameValid () {
      if(this.name === null || this.name === undefined) {
        return false
      }
      return this.name.length >= 3
    },
    firstnameValid () {
      if(this.firstname === null || this.firstname === undefined) {
        return false
      }
      return this.firstname.length >= 1
    },
    lastnameValid () {
      if(this.lastname === null || this.lastname === undefined) {
        return false
      }
      return this.lastname.length >= 1
    },
    emailValid () {
      if(this.email === null || this.email === undefined) {
        return false
      }
      const valid = this.isValidEmail(this.email)
      return valid
      /*
      if(valid) {
        APIService.isEmailRegistered(this.email).then(response => {
          if (response.data.valid === '1') {
            this.emailAlreadyRegistered = false
            this.errors = this.errors.filter(e => e !== this.$t('errors.email_already_registered'))
            return true
          } else {
            this.emailAlreadyRegistered = true
            if(!this.errors.includes(this.$t('errors.email_already_registered'))) {
              this.errors.push(this.$t('errors.email_already_registered'))
            }
            return false
          }
        })
      } else {
        this.errors = this.errors.filter(e => e !== this.$t('errors.email_already_registered'))
        return valid
      }
       */
    },
    passwordValid () {
      if(this.password === null || this.password === undefined) {
        return false
      }
      return this.password.length > 6
    }
  },
  methods: {
    filterChanged(event) {
      this.selectedUserSource = event.target.value || ''
    },
    handleEmailBlur() {
      const that = this
      APIService.isEmailRegistered(this.email).then(response => {
        if (response.data.valid === '1') {
          that.emailAlreadyRegistered = false
          that.errors = that.errors.filter(e => e !== that.$t('errors.email_already_registered'))
        } else {
          that.emailAlreadyRegistered = true
          if(!that.errors.includes(that.$t('errors.email_already_registered'))) {
            that.errors.push(that.$t('errors.email_already_registered'))
          }
        }
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    register() {
      this.errors = []
      this.emailAlreadyRegistered = false
      if(parseInt(this.getBGValue('spmbgid')) === 3003) {
        if (this.isNullOrUndefined(this.firstname) || this.firstname.trim().length === 0) {
          this.errors.push(this.$i18n.t('errors.enter_firstname'))
        }
        if (this.isNullOrUndefined(this.lastname) || this.lastname.trim().length === 0) {
          this.errors.push(this.$i18n.t('errors.enter_lastname'))
        }
      } else if(parseInt(this.getBGValue('spmbgid')) === 4003) {
        if (this.isNullOrUndefined(this.firstname) || this.firstname.trim().length === 0) {
          this.errors.push(this.$i18n.t('errors.enter_firstname'))
        }
        if (this.isNullOrUndefined(this.lastname) || this.lastname.trim().length === 0) {
          this.errors.push(this.$i18n.t('errors.enter_lastname'))
        }
        if (this.isNullOrUndefined(this.name) || this.name.length === 0) {
          this.errors.push(this.$i18n.t('errors.enter_nickname'))
        }
      } else {
        if (this.isNullOrUndefined(this.name) || this.name.length === 0) {
          this.errors.push(this.$i18n.t('errors.enter_nickname'))
        }
      }
      if(this.isNullOrUndefined(this.email) || this.email.length === 0) {
        this.errors.push(this.$i18n.t('errors.enter_email'))
      } else if(!this.isValidEmail(this.email)) {
        this.errors.push(this.$i18n.t('errors.enter_valid_email'))
      }
      if(this.isNullOrUndefined(this.password) || this.password.length === 0) {
        this.errors.push(this.$i18n.t('errors.enter_password_length'))
      }
      if(this.showAddress && (this.address === '' || this.address.length >= 200)) {
        this.errors.push(this.$i18n.t('errors.enter_valid_address'))
      }
      if(this.showAddress && (this.zip === '' || this.zip.length >= 20)) {
        this.errors.push(this.$i18n.t('errors.enter_valid_zip_code'))
      }
      if(this.showAddress && (this.city === '' || this.city.length >= 100)) {
        this.errors.push(this.$i18n.t('errors.enter_valid_city'))
      }
      if((this.betgame_id === 1702 || this.betgame_id === 1902) && this.selectedUserSource === '') {
        this.errors.push(this.$i18n.t('errors.select_region'))
      }
      if(this.betgame_id === 3003 && this.selectedUserSource === '') {
        this.errors.push(this.$i18n.t('errors.select_country'))
      }
      if (!this.agreedTOS && this.betgame_id !== 4001 && this.betgame_id !== 4002 && this.betgame_id !== 4003) {
        if(this.betgame_id === 3003) {
          this.errors.push(this.$i18n.t('errors.confirm_conditions_of_participation'))
        } else {
          this.errors.push(this.$i18n.t('errors.confirm_terms_of_usage'))
        }
      }
      if (!this.isUnder18 && (this.betgame_id === 4001 || this.betgame_id === 4002 || this.betgame_id === 4003)) {
        this.errors.push(this.$i18n.t('errors.confirm_is_adult'))
      }
      if (!this.adConsent && this.betgame_id === 4003) {
        this.errors.push(this.$i18n.t('errors.confirm_ad_consent'))
      }
      if(this.$store.state.betgame.id === 11 && !this.agreedCOP) {
        this.errors.push(this.$i18n.t('errors.confirm_conditions_of_participation'))
      }
      if(this.$store.state.betgame.id === 7 && !this.newsletterSubscription) {
        this.errors.push(this.$i18n.t('errors.confirm_conditions_of_participation'))
      }
      if (this.errors.length === 0) {
        var src = this.$store.state.userSource || this.getBGValue('spmic') || ''
        if(this.betgame_id === 1702 || this.betgame_id === 1902 || this.betgame_id === 3003) {
          src = this.selectedUserSource
        }
        var username = this.name
        if (parseInt(this.getBGValue('spmbgid')) === 3003) {
          username = this.firstname.trim() + ' ' + this.lastname.trim()
        }
        APIService.isEmailRegistered(this.email).then(response => {
          if (response.data.valid === '1') {
            if (parseInt(this.getBGValue('spmbgid')) === 1607 ||
                parseInt(this.getBGValue('spmbgid')) === 4001 || parseInt(this.getBGValue('spmbgid')) === 4002) {
              const language = this.$store.state.language ? this.$store.state.language : 'de'
              this.$store.dispatch('register', {
                nick: this.name,
                email: this.email.toLowerCase(),
                pass: this.password,
                lang: language,
                source: src,
                fk_betgame: this.getBGValue('spmbgid'),
                ad_consent: this.adConsent ? '2' : '1'
              })
              .then(() => {
                var adult = !this.isUnder18
                if(parseInt(this.getBGValue('spmbgid')) === 4001 || parseInt(this.getBGValue('spmbgid')) === 4002) {
                  adult = this.isUnder18
                }
                this.$store.dispatch('patchUser', {acceptedtos: this.agreedTOS, isadult: adult,
                  address: this.address, allow_email: this.newsletterSubscription, acceptedcop: this.agreedCOP,
                  zip: this.zip, city: this.city, ad_confirm_version: 1
                }).then(() => {
                })
                if(this.$store.state.language !== undefined && this.$store.state.language !== null &&
                  this.$store.state.language.length > 1) {
                  Numeral.locale(this.$store.state.language)
                  moment.locale(this.$store.state.language)
                }
                if(this.$store.state.language !== undefined && this.$store.state.language !== null &&
                  this.$store.state.language.length > 1 && this.$i18n.locale !== this.$store.state.language) {
                  this.$i18n.locale = this.$store.state.language
                }
                this.setBGValue('spmic', '')
                const bgid = parseInt(this.getBGValue('spmbgid'))
                if(bgid !== undefined && bgid !== null && this.isNumeric(bgid) && bgid > 0) {
                  if(bgid === 4001) {
                    this.$store.dispatch('userEnterBetgame', 4002).then(() => {
                    })
                  } else if(bgid === 4002) {
                    this.$store.dispatch('userEnterBetgame', 4001).then(() => {
                    })
                  }
                  this.$store.dispatch('userEnterBetgame', bgid).then(() => {
                    this.$router.push('/dashboard')
                  })
                } else {
                  this.$router.push('/searchbetgame')
                }
              }).catch(function (error) {
                if (error.response.status === 409) {
                  alert(this.$t('errors.email_already_registered'))
                } else {
                  alert(this.$t('errors.registration_failed'))
                }
              })
            } else if (parseInt(this.getBGValue('spmbgid')) === 4003) {
              const language = this.$store.state.language ? this.$store.state.language : 'de'
              this.$store.dispatch('register', {
                nick: this.name,
                email: this.email.toLowerCase(),
                pass: this.password,
                lang: language,
                source: src,
                fk_betgame: this.getBGValue('spmbgid'),
                ad_consent: this.adConsent ? '2' : '1',
                firstname: this.firstname,
                lastname: this.lastname
              })
              .then(() => {
                this.$store.dispatch('patchUser', {acceptedtos: this.agreedTOS, isadult: this.isUnder18,
                  firstname: this.firstname, allow_email: this.newsletterSubscription, acceptedcop: this.agreedCOP,
                  zip: this.zip, lastname: this.lastname, ad_confirm_version: 1
                }).then(() => {
                })
                if(this.$store.state.language !== undefined && this.$store.state.language !== null &&
                    this.$store.state.language.length > 1) {
                  Numeral.locale(this.$store.state.language)
                  moment.locale(this.$store.state.language)
                }
                if(this.$store.state.language !== undefined && this.$store.state.language !== null &&
                    this.$store.state.language.length > 1 && this.$i18n.locale !== this.$store.state.language) {
                  this.$i18n.locale = this.$store.state.language
                }
                this.setBGValue('spmic', '')
                const bgid = parseInt(this.getBGValue('spmbgid'))
                if(bgid !== undefined && bgid !== null && this.isNumeric(bgid) && bgid > 0) {
                  this.$store.dispatch('userEnterBetgame', bgid).then(() => {
                    this.$router.push('/dashboard')
                  })
                } else {
                  this.$router.push('/searchbetgame')
                }
              }).catch(function (error) {
                if (error.response.status === 409) {
                  alert(this.$t('errors.email_already_registered'))
                } else {
                  alert(this.$t('errors.registration_failed'))
                }
              })
            } else {
              const language = this.$store.state.language ? this.$store.state.language : 'de'
              this.$store.dispatch('register', {
                nick: username,
                email: this.email.toLowerCase(),
                pass: this.password,
                lang: language,
                source: src,
                fk_betgame: this.getBGValue('spmbgid')
              })
              .then(() => {
                this.$store.dispatch('patchUser', {acceptedtos: this.agreedTOS, isadult: !this.isUnder18,
                  address: this.address, allow_email: this.newsletterSubscription, acceptedcop: this.agreedCOP,
                  zip: this.zip, city: this.city
                }).then(() => {
                })
                if(this.$store.state.language !== undefined && this.$store.state.language !== null &&
                  this.$store.state.language.length > 1) {
                  Numeral.locale(this.$store.state.language)
                  moment.locale(this.$store.state.language)
                }
                if(this.$store.state.language !== undefined && this.$store.state.language !== null &&
                  this.$store.state.language.length > 1 && this.$i18n.locale !== this.$store.state.language) {
                  this.$i18n.locale = this.$store.state.language
                }
                this.setBGValue('spmic', '')
                const bgid = parseInt(this.getBGValue('spmbgid'))
                if(bgid !== undefined && bgid !== null && this.isNumeric(bgid) && bgid > 0) {
                  if(bgid === 1405) {
                    this.$store.dispatch('userEnterBetgame', 1404).then(() => {
                    })
                  } else if(bgid === 1404) {
                    this.$store.dispatch('userEnterBetgame', 1405).then(() => {
                    })
                  }
                  this.$store.dispatch('userEnterBetgame', bgid).then(() => {
                    this.$router.push('/dashboard')
                  })
                } else {
                  this.$router.push('/searchbetgame')
                }
              }).catch(function (error) {
                if (error.response.status === 409) {
                  alert(('errors.email_already_registered'))
                } else {
                  alert(('errors.registration_failed'))
                }
              })
            }
          } else {
            this.emailAlreadyRegistered = true
            this.errors.push(this.$t('errors.email_already_registered'))
          }
        })
      }
    },
    isNullOrUndefined(data) {
      return data === null || data === undefined
    },
    isValidEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !this.emailAlreadyRegistered && re.test(String(email).toLowerCase());
    }
  }
}
</script>

<style lang="scss" scoped>
  .register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 64px);
    padding: 64px 16px 0 16px;
    max-width: 400px;
    margin: 0px auto;

    h1 {
      text-align: center;
    }
    p {
      text-align: center;
      margin-bottom: 26px;
    }
  }
  .forgot-password {
    width: 100%;
    text-align: center;
    color: $colorGreyDark;
    padding: 20px 0;
  
    a {
      font-weight: 500;
      text-decoration: none;
      margin-left: 6px;
      padding: 12px 0;
    }
  
    p {
      margin-bottom: 16px;
    }
  }
  .input-container {
    margin: 16px auto 0;
  }
  button {
    margin-top: 16px;
  }
  .input-text {

    input {
      display: none;
    }

    label {
      box-sizing: border-box;
      padding: 22px 24px 22px 0;
      border-bottom: 1px solid rgba($colorGreyLight, .2);
      display: inline-block;
      position: relative;
      width: 100%;
      color: $colorGreyDark;
      cursor: pointer;
    }
    
    &:last-child {
      label {
        border-bottom: 0;
      }
    }
  }
</style>
