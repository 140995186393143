<template>
    <div :key="componentKey" class="betgameReset" id="betgameReset">
        
        <NavigationBar>
            <template v-slot:left>
                <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
            </template>
            <template v-slot:center>
              {{ $t('betgame_settings.betgame_reset') }}
            </template>
            <template v-slot:right>
            </template>
        </NavigationBar>
        
        <form @submit.prevent="resetBetgame">
            <div class="input-container">
                <div>
                    <div class="input-header">
                      {{ $t('betgame_settings.betgame_reset') }}
                    </div>
                    <div class="input-infotext">
                      {{ $t('betgame_settings.betgame_reset_description_1') }}<br>
                      {{ $t('betgame_settings.betgame_reset_description_2') }}<br>
                      {{ $t('betgame_settings.betgame_reset_description_3') }}<br><br>
                      {{ $t('betgame_settings.betgame_reset_description_4') }}<br>
                    </div>
                    <div class="input-element">
                        <input autofocus id="email" name="email" placeholder="email"
                               type="email" v-model="email" value/>
                        <label for="email">{{ $t('email') }}</label>
                    </div>
                    <div class="input-element">
                        <input id="pasword" name="pasword" placeholder="pasword"
                               type="password" v-model="password" value/>
                        <label for="pasword">{{ $t('password') }}</label>
                    </div>
                </div>
                <div class="input-button-row">
                    <button name="button" type="submit">
                      {{ $t('betgame_settings.button_reset_text') }}
                    </button>
                </div>
            </div>
        </form>
      
        <ul class="error-container">
            <li v-for="error in errors">{{ error }}</li>
        </ul>
        
    </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  import APIService from '@/services/APIService'
  import Vue from 'vue'
  
  export default {
    name: 'BetgameReset',
    components: { NavigationBar },
    data () {
      return {
        deletion: false,
        errors: [],
        betgame: null,
        email: '',
        password: '',
        componentKey: 0,
        transferEmail: '',
        action: ''
      }
    },
    created () {
      this.betgame = this.$route.params.betgame
      this.action = this.$route.params.action
      Vue.$log.debug('BetgameReset created ' + this.betgame.id + ' --- ' + this.action)
    },
    mounted () {
      Vue.$log.debug('BetgameReset mounted ' + JSON.stringify(this.betgame))
      if (this.betgame === null || this.betgame === undefined) {
        this.goBack()
      }
    },
    methods: {
      resetBetgame() {
        this.errors = []
        if(this.isNullOrUndefined(this.email) || this.email.length === 0) {
          this.errors.push(this.$i18n.t('betgame_settings.enter_email_address'))
        } else {
          if (this.email !== this.$store.state.user.email) {
            this.errors.push(this.$i18n.t('betgame_settings.enter_administrator_email_address'))
          }
        }
        if(this.isNullOrUndefined(this.password) || this.password.length === 0) {
          this.errors.push(this.$i18n.t('betgame_settings.enter_password'))
        }
        if (this.errors.length === 0) {
          var r = confirm(this.$i18n.t('betgame_settings.confirm_reset_betgame'))
          if (r === true) {
            const that = this
            this.$store
            .dispatch('resetBetgame', {
              betgame: this.betgame.id,
              email: this.email,
              pass: this.password
            })
            .then(() => {
              this.$toast.open({ message: this.$i18n.t('betgame_settings.reset_betgame_success'), position: 'top', duration: TOAST_DURATION, type: 'success' })
              this.$router.push('/betgameedit')
            }).catch(function (error) {
              alert(that.$i18n.t('betgame_settings.reset_betgame_error') + ' ' + error)
            })
          }
        }
      },
      goBack () {
        this.$router.push('/betgameedit')
      },
      isNullOrUndefined(data) {
        return data === null || data === undefined
      }
    }
  }
</script>

<style lang="scss" scoped>
    .betgameReset {
        
        form {
            padding-top: 16px;
        }
        
        h1 {
            text-align: center;
        }
        
        p {
            text-align: center;
            margin-bottom: 26px;
        }
    }
</style>
