import { mapGetters } from 'vuex'

export const authComputed = {
  ...mapGetters(['loggedIn'])
}

export const isNotNull = function (n) {
  return n !== undefined && n !== null
}

export const isNumeric = function (n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export const isToday = function (someDate) {
  const today = new Date()
  return today.toDateString() === someDate.toDateString()
  /*
  return someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
    
   */
}
