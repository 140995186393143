<template>
    <div :key="componentKey" class="dashboard" v-bind:style="{ paddingBottom: '24px' }">
        <keep-alive>
            <NavigationBar id="dashboardNavigationBarId" ref="dashboardNavigationBarRef">
                <template v-slot:center>
                    {{ betgameName }}
                </template>
                <template v-slot:right>
                    <div class="navigation_icon"></div>
                </template>
            </NavigationBar>
        </keep-alive>
    
        <div v-if="showOdds" style="background: white; border-radius: 10px; margin: 10px auto 0; max-width: 576px;">
            <div style="margin: 10px 40px 10px 40px; padding: 5px;">
                <!-- <span class="bwin_ad_marker">-W-</span> -->
                <p style="text-align: center; font-size: small; line-height: 1.2rem;">Im Team der <b>Tippkönig</b>, aber beim <b>Wetten</b> kneifst du?! 🤔/🧐/🤫</p>
                <div style="margin-top: 8px; text-align: center;" class="bwin_button" v-on:click="deepLinkClicked">
                    <a :href="deepLink" target="_blank"><img src="../assets/icons/ic_logo_bwin_small_white.svg"></a>
                </div>
                <div class="bet-box-infotext">
                  Lizenziert (Whitelist) | 18+ | Suchtrisiko | <a target="_blank" href="https://buwei.de" style="color: darkgray">buwei.de</a> | -w-
                </div>
            </div>
        </div>

        <!--
        <div v-if="this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1" style="border-radius: 10px; border: #F54D42 4px solid; padding: 5px; background-color: white; 
                 margin: 16px auto 0px; max-width: 458px;">
          <div class="dashboard-text" style="padding: 2px;">
            <p style="font-weight: bold">Abbruch Bochum vs. Gladbach</p>
            <p style="color: #5F5D61">Das Spiel wird bis zur Entscheidung des Sportgerichts mit dem Spielstand bei Zeitpunkt des Abbruches geführt. Sollte es zu einer anderen Umwertung kommen, wird das nach Inkrafttreten des Urteils direkt korrigiert.</p>
          </div>
        </div>
        -->
      
        <div v-if="!enterCodeClicked && this.$store.state.user && this.$store.state.user.status === 'validation_pending'"
             style="border-radius: 10px; border: #F54D42 4px solid; padding: 5px; background-color: white; 
               margin: 16px auto 0px; max-width: 458px;">
          <div class="dashboard-text" style="padding: 2px;">
            <h1 style="color: #F54D42">{{ $t('dashboard.finish_registration') }}</h1>
            <p style="color: #5F5D61">{{ $t('dashboard.please_enter_validation_code', {email: this.$store.state.user.email}) }}</p>
            <router-link :to="{name: 'validateregistration'}">
              <button @click="setEnterCodeClicked(1)" style="margin-top: 10px;" id="validate_registration">{{ $t('dashboard.button_enter_validation_code') }}</button>
            </router-link>
          </div>
        </div>
      
        <div v-visibility-change="visibilityChange" class="dashboard-container" v-bind:class="{ fullwidth : dashboardRankings.length < 1 && betgameUsers.length < 1 }">
            <div v-if="nextMatch">
                
                <div v-if="(days < 4 || !lastMatchdayWon) && !matchRunning" v-bind:class="{ last_minute : days < 1}">
                    <table v-if="!countdownStopped && (days || hours || minutes || seconds)" class="dashboard-countdown">
                        <tr>
                            <td class="dashboard-countdown--value">{{ days }}</td>
                            <td class="dashboard-countdown--value">{{ hours }}</td>
                            <td class="dashboard-countdown--value">{{ minutes }}</td>
                            <td class="dashboard-countdown--value">{{ seconds }}</td>
                        </tr>
                        <tr>
                            <td class="dashboard-countdown--label">{{ days === 1 ? $t('dashboard.countdown_day')  : $t('dashboard.countdown_days') }}</td>
                            <td class="dashboard-countdown--label">{{ $t('dashboard.countdown_hours') }}</td>
                            <td class="dashboard-countdown--label">{{ $t('dashboard.countdown_minutes') }}</td>
                            <td class="dashboard-countdown--label">{{ $t('dashboard.countdown_seconds') }}</td>
                        </tr>
                    </table>
                    <div class="dashboard-text">
                        <div v-if="days < 1 && !betForAllMatchdayMatches">
                            <h1>{{ $t('dashboard.matchday_preview_title') }}</h1>
                            <p>{{ $t('dashboard.matchday_preview_text_1') }}</p>
                        </div>
                        <div v-else-if="!betForAllMatchdayMatches">
                            <h1>{{ matchdayText }}</h1>
                            <p>{{ $t('dashboard.matchday_preview_text_2') }}</p>
                        </div>
                        <div v-else>
                            <h1>{{ matchdayText }}</h1>
                            <p>{{ $t('dashboard.matchday_preview_text_3') }}</p>
                        </div>
                        <router-link :to="{ name: 'bet', params: { matchday: nextMatch.matchday }}">
                            <button v-if="!betForAllMatchdayMatches" id="bet_now_button">{{ $t('dashboard.button_edit_bets') }}</button>
                            <button v-else id="view_bets_button">{{ $t('dashboard.button_view_bets') }}</button>
                        </router-link>
                    </div>
                </div>
                
                <div v-else-if="days > 3 && lastMatchdayWon && !matchRunning" class="matchdayWon">
                    <div class="dashboard-image">
                        <span>{{ matchdayWins.length }}</span>
                        <img src="../assets/achievements/ic_140_achievement_spieltagssieg.svg">
                    </div>
                    <div class="dashboard-text">
                        <h1>{{ $t('dashboard.matchday_winnner_title') }}</h1>
                        <p v-if="matchdayWins.length > 1">{{ $t('dashboard.matchday_winnner_text', { count: matchdayWins.length }) }}</p>
                        <p v-else-if="matchdayWins.length === 1">{{ $t('dashboard.matchday_winnner_first_time_text') }}</p>
                        <router-link :to="{name: 'rankingsbymatchday', params: { matchday: nextMatch.matchday - 1 }}">
                            <button id="view_results">{{ $t('dashboard.button_view_results') }}</button>
                        </router-link>
                    </div>
                </div>

                <!-- START Live-Anzeige // muss noch mit Bedingung verknüpft werden, damit es nur während des Spieltags angezeigt wird -->
                <div v-else-if="matchRunning" class="live">
                    <div class="dashboard-image">
                        <img src="../assets/icons/ic_dashboard_live.svg">
                    </div>
                    <div class="dashboard-text">
                        <h1>{{ $t('dashboard.match_running_title') }}</h1>
                        <p>{{ $t('dashboard.match_running_text') }}</p>
                        <router-link :to="{name: 'rankingsbymatchday', params: { matchday: matchRunning.matchday }}">
                            <button id="view_live_results_1">{{ $t('dashboard.match_running_button_text') }}</button>
                        </router-link>
                    </div>
                </div>
                <!-- ENDE Live-Anzeige -->
                
            </div>
    
            <div v-else-if="matchRunning" class="live">
                <div class="dashboard-image">
                    <img src="../assets/icons/ic_dashboard_live.svg">
                </div>
                <div class="dashboard-text">
                    <h1>{{ $t('dashboard.matchday_running_title') }}</h1>
                    <p>{{ $t('dashboard.matchday_running_text') }}</p>
                    <router-link :to="{name: 'rankingsbymatchday', params: { matchday: matchRunning.matchday }}">
                        <button id="view_live_results_2">{{ $t('dashboard.button_view_live_results') }}</button>
                    </router-link>
                </div>
            </div>
            
            <div v-else-if="allMatchesFinished" class="leaguePaused">
                <div class="dashboard-image">
                    <img src="../assets/icons/ic_dashboard_pause.svg">
                </div>
                <div class="dashboard-text">
                  <h1>{{ $t('dashboard.summer_break') }}</h1>
                  <!--<p>Die EM ist vorbei und die Bundesliga steht schon in den Startlöchern...</p>-->
                  <!--<p>{{ $t('dashboard.summer_break_text') }}</p>-->
                  <div class="powered-by-teamtip" style="">
                    <a :href="teamtipURL" target="_blank">
                      <button v-on:click="openTeamtip"><img class="button-icon button-icon--left" src="../assets/icons/ic_12_plus_white.svg">{{ $t('button_start_own_betgame') }}</button>
                    </a>
                  </div>
                  <!--
                  <router-link :to="{name: 'rankings'}">
                      <button>{{ $t('dashboard.button_view_rankings_total') }}</button>
                  </router-link>
                  -->
                </div>
            </div>
        </div>
        
        <RankingsTable v-if="this.hasSeasonStarted() && dashboardRankings.length > 0" :rankings="dashboardRankings"></RankingsTable>
        <BetgameUsersTable v-else-if="(!this.hasSeasonStarted()) && betgameUsers.length > 0" :betgameUsers="betgameUsers"></BetgameUsersTable>

        <ProfileTrendBox v-if="!isBattle() && !isTournament() && $store.state.currentMatchdayRankingByMatchday > 0 && $store.state.bets && $store.state.bets.length > 0 && $store.state.matches && finishedMatches > 0"></ProfileTrendBox>
      
        <div v-if="dashboardAd && dashboardAd.image" class="" style="width: 100%; margin-bottom: 32px; text-align: center; background-color: #F1F1F4; border: none; box-shadow: none">
          <a :href="dashboardAd.link" target="_blank">
            <img style="max-width: 100%; width: 100%;" :src="buildAdPath(dashboardAd.image, 300)">
          </a>
        </div>
        <div v-else-if="dashboardAd && dashboardAd.title" class="" style="width: 100%; margin-bottom: 32px; text-align: center; background-color: #F1F1F4; border: none; box-shadow: none">
          <a :href="dashboardAd.link" target="_blank">
            <span>{{ dashboardAd.title }}</span>
          </a>
        </div>

      <div style="padding-top: 30px;"></div>
    </div>
</template>

<script>
    import moment from 'moment'
    import RankingsTable from '../components/RankingsTable'
    import NavigationBar from './../components/NavigationBar'
    import ProfileTrendBox from './../components/ProfileTrendBox'
    import Vue from 'vue'
    import BetgameUsersTable from '@/components/BetgameUsersTable'
    export default {
        components: { BetgameUsersTable, RankingsTable, NavigationBar, ProfileTrendBox },
        data () {
            return {
                deepLink: 'https://sports.bwin.de/de/sports/fussball-4/wetten?wm=5047188',
                now: moment(),
                date: null,
                diff: null,
                interval: null,
                countdownStopped: false,
                componentKey: 0
            }
        },
      created () {
        Vue.$log.debug('dashboard created')
        
          if(this.$store.state.betgame.id === 4001 || this.$store.state.betgame.id === 4002 || this.$store.state.betgame.id === 1607) {
            if(this.$store.getters.loggedIn) {
              if(this.$store.state.user && this.$store.state.user.status === 'validation_pending') {
                this.$store.dispatch('checkUserStatus', this.$store.state.user.id).then(() => {
                }).catch(function (error) {
                })
            }
          }
        }
        
        if(this.$store.state.leagueId) {
          const lastFetchSchedule = this.getBGValue('spmlfs')
          const duration2 = (new Date() - new Date(lastFetchSchedule)) / 1000
          const cachingDuration = Math.min(this.$store.getters.getCachingDuration, 60)
          if ((lastFetchSchedule !== null && duration2 > cachingDuration) ||
              this.$store.state.matches == null || this.$store.state.matches.length === 0) {
            this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
              this.componentKey += 1
            })
          }
        }
        if((!this.$store.state.matchDayWins || this.$store.state.matchDayWins.length === 0) && this.$store.state.leagueId) {
          //this.$store.dispatch('fetchMatchdayWins', this.$store.state.betgameId)
        }
        if(this.$store.state.user && (this.$store.state.missing_bets === undefined || this.$store.state.missing_bets === null || this.$store.state.missing_bets.length === 0)) {
          this.$store.dispatch('fetchUserBetgames', this.$store.state.user.id)
        }
        //if(!this.$store.state.questions || this.$store.state.questions.length === 0) {
          this.$store.dispatch('fetchQuestions', []).then(function (response) {
          })
        //}
        if(!this.$store.state.answers || this.$store.state.updateAnswerCount) {
          this.$store.dispatch('updateAnswerCount', false).then(function (response) {
          })
          this.$store.dispatch('fetchUserAnswers', []).then(function (response) {
          })
        }
        if(this.$store.state.betgameId) {
          if(this.$store.state.betgame && this.$store.state.betgame.is_whitelabel && 
              !this.$store.state.betgameAds || this.$store.state.betgameAds.length === 0) {
            this.$store.dispatch('fetchBetgameAds', this.$store.state.betgameId).then(() => {
              this.$log.debug('LoginUser fetchBetgameAds ' + this.$store.state.betgameId)
            })
          }
        }
        if(this.nextMatch) {
          const n = this.nowToLocalTimeZone()
          const d = this.parseMatchdateToLocalTimeZone(this.nextMatch.matchdate).toDate()
          const range = n.diff(d)
          const dif = moment.duration(range)
          if (dif != null) {
            this.minutesInit = Math.abs(dif.get('minutes'))
            this.secondsInit = Math.abs(dif.get('seconds'))
            this.daysInit = Math.abs(dif.get('days'))
            this.hourssInit = Math.abs(dif.get('hours'))
          }
        }
      },
      beforeCreate() {
        Vue.$log.debug('dashboard beforeCreate')
      },
      mounted() {
        Vue.$log.debug('dashboard mounted')
            if(this.interval == null) {
              this.interval = setInterval(() => {
                if (this.nextMatch != null && this.date == null) {
                  //this.date = new Date(Date.parse(this.nextMatch.matchdate))
                  this.date = this.parseMatchdateToLocalTimeZone(this.nextMatch.matchdate).toDate()
                }
                this.now = this.nowToLocalTimeZone()
                if(this.date < this.now.toDate()) {
                  this.countdownStopped = true
                }
              }, 1000)
            }

            if(this.$store.state.betgameId && (!this.hasSeasonStarted() || !this.$store.state.betgameUsers || this.$store.state.betgameUsers.length === 0)) {
              this.$store.dispatch('fetchBetgameUsers', this.$store.state.betgameId)
            }
            
            if(this.$store.state.user && this.$store.state.betgameId) {
              this.$store.dispatch('fetchBets', this.$store.state.user.id).then(() => {
                this.componentKey += 1
              })
            }
            
            if(this.$store.state.betgameId) {
              if (this.$store.state.leagueId) {
                this.$store.dispatch('fetchMatchdayWins', this.$store.state.betgameId).then(() => {
                  this.componentKey += 1
                })
              } else {
                this.$store.dispatch('fetchBetgameLeague', this.$store.state.betgameId).then(() => {
                  this.componentKey += 1
                })
              }
  
              /*
              if (this.$store.state.betgame && !this.countryBlocked() && this.$store.state.betgame.hide_ads === false && this.$store.state.matchBets.length === 0 &&
                this.$store.state.user && this.$store.state.user.isadult === true && this.$store.state.betgame.competition) {
                this.$store.dispatch('fetchMatchBets', this.$store.state.betgame.competition.id).then(() => {
                  this.componentKey += 1
                })
              }
              */
  
              const lastFetchLiveRanking = this.getBGValue('lastFetchLiveRanking')
              const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
              if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
                this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
                if (this.$store.state.leagueId && this.$store.state.betgameId) {
                  this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                    this.componentKey += 1
                  })
                }
              }
              if (duration1 > (this.$store.getters.getCachingDuration)) {
                if (this.$store.state.leagueId && this.$store.state.user && this.$store.state.betgameId) {
                  //this.$store.dispatch('fetchUserRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.$store.state.user.id]).then(() => {
                  //  this.componentKey += 1
                  //})
                }
              }
              this.componentKey += 1
            } else {
              this.$router.push('/searchbetgame')
            }
        },
        computed: {
          enterCodeClicked() {
            return this.getBGValue('spmvr')
          },
          teamtipURL() {
            return 'https://teamtip.net/tipprunde-erstellen?src=' + window.localStorage.getItem('userSource')
          },
          dashboardAd() {
            const ads = this.$store.state.betgameAds.filter(a => a.position === 1)
            if(ads.length === 1) {
              return ads[0]
            }
            return null
          },
          showOdds() {
            return false
            /*
            if(this.$store.state.betgame && this.$store.state.user) {
              return !this.countryBlocked() && this.$store.state.betgame.hide_ads === false && this.$store.state.user.isadult === true
            }
            return false
            */
          },
          matchdayText() {
            if(this.nextMatch === undefined) {
              return ''
            }
            const nextMatchday = this.nextMatch.matchday
            if(nextMatchday === undefined) {
              return ''
            }
            if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 19) {
              if(nextMatchday === 7) {
                return this.$i18n.t('ko_round_of_16_first_legs')
              } else if(nextMatchday === 8) {
                return this.$i18n.t('ko_round_of_16_second_legs')
              } else if(nextMatchday === 9) {
                return this.$i18n.t('ko_round_quarter_finals_first_legs')
              } else if(nextMatchday === 10) {
                return this.$i18n.t('ko_round_quarter_finals_second_legs')
              } else if(nextMatchday === 11) {
                return this.$i18n.t('ko_round_semi_finals_first_legs')
              } else if(nextMatchday === 12) {
                return this.$i18n.t('ko_round_semi_finals_second_legs')
              } else if(nextMatchday === 13) {
                return this.$i18n.t('ko_round_final')
              }
            } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 36) {
              if(nextMatchday === 1) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 2) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 3) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 4) {
                return this.$i18n.t('em_ko_round_of_16')
              } else if(nextMatchday === 5) {
                return this.$i18n.t('em_ko_round_quarter_finals')
              } else if(nextMatchday === 6) {
                return this.$i18n.t('em_ko_round_semi_finals')
              } else if(nextMatchday === 7) {
                return this.$i18n.t('ko_round_final')
              }
            } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 107) {
              if(nextMatchday === 1) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 2) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 3) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 4) {
                return this.$i18n.t('em_ko_round_of_16')
              } else if(nextMatchday === 5) {
                return this.$i18n.t('em_ko_round_quarter_finals')
              } else if(nextMatchday === 6) {
                return this.$i18n.t('em_ko_round_semi_finals')
              } else if(nextMatchday === 7) {
                return this.$i18n.t('ko_round_final')
              }
            } else if(this.$store.state.betgame && this.$store.state.betgame.competition && (this.$store.state.betgame.competition.id === 139 || this.$store.state.betgame.competition.id === 1110)) {
              if(nextMatchday === 1) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 2) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 3) {
                return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 4) {
                return this.$i18n.t('em_ko_round_of_16')
              } else if(nextMatchday === 5) {
                return this.$i18n.t('em_ko_round_quarter_finals')
              } else if(nextMatchday === 6) {
                return this.$i18n.t('em_ko_round_semi_finals')
              } else if(nextMatchday === 7) {
                return this.$i18n.t('ko_round_third_place_short')
              } else if(nextMatchday === 8) {
                return this.$i18n.t('ko_round_final')
              }
            }
            return this.$i18n.t('matchday', { matchday: nextMatchday })
          },
          allMatchesFinished() {
            const matches = this.$store.state.matches
            if(matches === undefined || matches === null || matches.length === 0) {
              return false
            }
            for (var i = 0; i < matches.length; i++) {
              const match = matches[i]
              if (!this.isNumeric(match.goalshome) || !this.isNumeric(match.goalsguest)) {
                return false
              }
            }
            return true
          },
          betForAllMatchdayMatches () {
            const bets = this.$store.state.bets
            var result = false
            if (bets.length > 0 && this.nextMatch) {
              const matchdayMatches = this.$store.getters.getMatchesByMatchday(this.nextMatch.matchday)
              if (matchdayMatches.length > 0) {
                for (var i = 0; i < matchdayMatches.length; i++) {
                  const match = matchdayMatches[i]
                  if(this.isPast(match.matchdate)) {
                    continue
                  }
                  if(match.status && (match.status.trim() === 'annulliert' || match.status.trim() === 'abgesagt'
                      || match.status.trim() === 'verlegt')) {
                    continue
                  }
                  const betMatches = bets.filter(betMatch => betMatch.fk_match === match.id)
                  if (betMatches.length === 0) {
                    return false
                  } else {
                    result = true
                  }
                }
              }
            }
            return result
          },
            matchRunning() {
              return this.$store.getters.getLiveMatch
            },
            nextMatch() {
              if(this.$store.state.matches.length > 0) {
                return this.$store.getters.getClosestFutureMatch
              } else {
                if(this.$store.state.leagueId) {
                  this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
                } else {
                  if(this.getBGValue('spmbgid')) {
                    this.$store.dispatch('fetchBetgameLeague', this.getBGValue('spmbgid'))
                  }
                }
              }
            },
            betgameName() {
              if(this.$store.state.betgame) {
                return this.$store.state.betgame.name
              } else {
                if(this.getBGValue('spmbgid')) {
                  this.$store.dispatch('fetchBetgame', this.getBGValue('spmbgid'))
                }
              }
            },
            matchdayWins() {
              if(this.$store.state.user) {
                return this.$store.getters.getMatchdayWins(this.$store.state.user.id)
              }
              return []
            },
            lastMatchdayWon() {
              if(this.nextMatch && this.nextMatch.matchday > 1) {
                if (this.matchdayWins.length > 0) {
                  return this.matchdayWins.filter(matchdayWin => matchdayWin.matchday === (this.nextMatch.matchday - 1)).length > 0
                  /*
                  for (var i = 0; i < this.matchdayWins.length; i++) {
                    const matchdayWin = this.matchdayWins[i]
                    if(matchdayWin.matchday === (this.nextMatch.matchday - 1)) {
                      return true
                    }
                  }
                   */
                }
              }
              return false
            },
            dashboardRankings() {
              if(!this.$store.state.userNeighbourRankings) {
                if (!this.$store.state.leagueId && this.getBGValue('spmbgid')) {
                    this.$store.dispatch('fetchBetgameLeague', this.getBGValue('spmbgid'))
                }
              }
              return this.$store.getters.getRankingForDashboard
            },
            seconds () {
                if (this.diff != null) {
                    return Math.abs(this.diff.get('seconds'))
                } else {
                    return 0
                }
            },
            minutes () {
                if (this.diff != null) {
                    return Math.abs(this.diff.get('minutes'))
                } else {
                    return 0
                }
            },
            hours () {
                if (this.diff != null) {
                    return Math.abs(this.diff.get('hours'))
                } else {
                    return 0
                }
            },
            days () {
                if (this.diff != null) {
                    return Math.abs(this.now.diff(this.date, 'days'))
                } else {
                    return 0
                }
            },
            betgameUsers () {
              const r = this.$store.state.betgameUsers
              if(!r) {
                return []
              }
              if(r.length > 7) {
                return r.slice(0, 7)
              }
              return r
            },
          finishedMatches() {
            return this.$store.getters.finishedMatches
          }
        },
        watch: {
            now (value) {
                if (this.now != null && this.date != null) {
                    const range = this.now.diff(this.date)
                    this.diff = moment.duration(range)
                }
            }
        },
        beforeDestroy () {
            clearInterval(this.interval)
        },
        methods: {
          setEnterCodeClicked(val) {
            this.setBGValue('spmvr', val)
          },
          openTeamtip() {
            this.$gtag.event('EigeneTipprunde', {
              'event_category': 'teamtip',
              'event_label': window.localStorage.getItem('userSource') + '.sidebar',
              'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
              'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
              'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set'
            })
            if(window.parent) {
              window.parent.open('https://teamtip.net/tipprunde-erstellen?src=' + window.localStorage.getItem('userSource'), '_blank')
            } else {
              window.open('https://teamtip.net/tipprunde-erstellen?src=' + window.localStorage.getItem('userSource'), '_blank')
            }
          },
          visibilityChange(evt, hidden) {
            if(hidden === false && this.$store.state.leagueId) {
              const lastFetchSchedule = this.getBGValue('spmlfs')
              const duration2 = (new Date() - new Date(lastFetchSchedule)) / 1000
              const cachingDuration = Math.min(this.$store.getters.getCachingDuration, 60)
              if((lastFetchSchedule !== null && duration2 > cachingDuration) || 
                  this.$store.state.matches == null || this.$store.state.matches.length === 0) {
                this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
                  this.componentKey += 1
                })
                if(this.$store.state.betgameId) {
                  this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                    this.componentKey += 1
                  })
                }
              } 
            }
          },
          deepLinkClicked() {
            if(this.deepLink) {
              this.$gtag.event('Kombiwette', {
                'event_category': 'bwin',
                'event_label': this.matchday ? '' + this.matchday + '. Spieltag' : '',
                'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
                'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
                'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set'
              })
              if(window.parent) {
                window.parent.open(this.deepLink, '_blank')
              } else {
                window.open(this.deepLink, '_blank')
              }
            }
          },
          openMenu() {
            this.$modal.show('betgame-menu')
          }
        }
    }
</script>

<style lang="scss">

    @keyframes live {
        0%   { opacity: .48; }
        50%  { opacity: 1; }
        100% { opacity: .48; }
    }
    
    .dashboard {
        max-width: 768px;
        margin: 0px auto;

        @media screen and (min-width: 768px) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        &-container {
            background: $colorWhite;
            box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
            border-radius: 11px;
            overflow: hidden;
            margin: 16px 0;

            @media screen and (min-width: 768px) {
                flex: 0 calc(50% - 12px);
                height: 0%;
                margin: 24px 0 0;

                &.fullwidth {
                    flex: calc(50% - 12px);
                    margin: 24px auto 0;
                    max-width: 576px;
                }
            }
        }

        &-image {
            display: flex;
            justify-content: center;
            border-bottom: 1px solid rgba($colorGreyLight, .08);
            position: relative;

            .matchdayWon &, .leaguePaused & {
                background: rgba(#F5B842, .08);
            }

            .live & {
                background: rgba($colorNegative, .08);

                img {
                    animation: live 2.5s infinite;
                }

                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: url('../assets/icons/ic_dashboard_live_bg.svg');
                    background-position: center center;
                }
            }

            span {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
                color: $colorWhite;
                font-size: 28px;
                font-weight: 500;
            }
        }

        &-countdown {
            width: 100%;
            padding: 35px 16px 32px 16px;
            border-bottom: 1px solid rgba($colorGreyLight, .08);
            background: rgba($colorGreyLight, .04);

            &--value {
                font-size: 48px;
                line-height: 40px;
                font-weight: 500;
                color: $colorGreyDark;
                width: 25%;
                text-align: center;
                overflow: hidden;
            }

            &--label {
                font-size: 17px;
                color: $colorGreyMid;
                width: 25%;
                text-align: center;
                overflow: hidden;
            }

            .last_minute & {
                background: rgba($colorNegative, .12);
            }
        }

        &-text {
            padding: 26px 16px 16px 16px;
            text-align: center;

            h1 {
                .last_minute & {
                    color: $colorNegative;
                }
            }

            button {
                margin-top: 26px;
            }
        }


        @media screen and (min-width: 768px) {
            
            #bg_rankings_table, #betgame_users_table {
                flex: 0 calc(50% - 12px);
                max-width: calc(50% - 12px);
                margin: 24px 0;
                overflow: hidden;

              .table {
                  width: 100%;
                  margin: 0 0 24px;
                  background: $colorWhite;
                  box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
                  border-radius: 11px;
                  padding-bottom: 0;
                  overflow: hidden;
              }
            }

            .trend-box {
              flex: 0 100%;
              margin: 24px 0 48px;
            }
        }
    }
    
    .table_background {
        background: rgba(208,208,208,.08);
    }

    .bwin_button {
        background: black;
        font-size: 17px;
        font-weight: 500;
        border: 0;
        border-radius: 6px;
        padding: 6px 10px;
        a {
            color: white;
            text-decoration: none;
        }
    }
    .bet-box-infotext {
        font-size: 9px;
        color: $colorGreyMid;
        text-align: center;
        width: 100%;
        margin-top: 3px;
    }
    .bwin_ad_marker {
        position: relative;
        float: right;
        left: 30px;
        font-size: 9px;
        color: rgba($colorGreyLight, .18)
    }
</style>
