<template>
    <div class="profileChangeNotifications" id="profileChangeNotifications">
        
        <NavigationBar>
            <template v-slot:left>
                <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
            </template>
            <template v-slot:center>
              {{ $t('profile.notification_settings') }}
            </template>
            <template v-slot:right>
            </template>
        </NavigationBar>
        
        <form @submit.prevent="changeNotifications">
            <div class="input-container">
              <div>
                  <div class="input-header">
                    {{ $t('profile.enable_bet_reminder_email_notifications') }}
                  </div>
                  <div class="input-infotext">
                    {{ $t('profile.enable_bet_reminder_email_notifications_text') }}
                  </div>
                  <div class="input-radio">
                      <input id="email_yes" name="email_notifications_active" type="radio" v-bind:value="true"
                             v-model="email_notifications_new"/>
                      <label for="email_yes">{{ $t('yes') }}</label>
                  </div>
                  <div class="input-radio">
                      <input id="email_no" name="email_notifications_active" type="radio" v-bind:value="false"
                             v-model="email_notifications_new"/>
                      <label for="email_no">{{ $t('no') }}</label>
                  </div>
              </div>
              <div v-if="!isApp" class="input-button-row">
                <button name="button" type="submit">
                  {{ $t('button_save_text') }}
                </button>
              </div>
            </div>
            <div v-if="isApp" class="input-container" style="margin-top: 16px;">
                <div>
                    <div class="input-header">
                      {{ $t('profile.enable_bet_reminder_push_notifications') }}
                    </div>
                    <div class="input-infotext">
                      {{ $t('profile.enable_bet_reminder_push_notifications_text') }}
                    </div>
                    <div class="input-radio">
                        <input id="push_yes" name="push_notifications_active" type="radio" v-bind:value="true"
                               v-model="push_notifications_new"/>
                        <label for="push_yes">{{ $t('yes') }}</label>
                    </div>
                    <div class="input-radio">
                        <input id="push_no" name="push_notifications_active" type="radio" v-bind:value="false"
                               v-model="push_notifications_new"/>
                        <label for="push_no">{{ $t('no') }}</label>
                    </div>
                </div>
            </div>

            <div v-if="isApp" class="input-button-row" style="margin-top: 16px;">
              <button name="button" type="submit">
                {{ $t('button_save_text') }}
              </button>
            </div>
        </form>
    
        <ul class="error-container">
            <li v-for="error in errors">{{ error }}</li>
        </ul>
    
    </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  
  export default {
    name: 'profileChangeNotifications',
    components: { NavigationBar },
    data () {
      return {
        email_notifications_new: '',
        push_notifications_new: '',
        errors: []
      }
    },
    created () {
      this.email_notifications_new = this.$store.state.user.email_notifications
      this.push_notifications_new = this.$store.state.user.push_notifications
    },
    mounted () {
    },
    computed: {
      isApp() {
        return navigator.userAgent.indexOf('TEAMTIP') > 0
      }
    },
    methods: {
      goBack () {
        this.$router.push('/profile')
      },
      changeNotifications () {
        this.errors = []
        if (this.errors.length == 0) {
          this.$store.dispatch('updateUserNotifications', {
            id: '' + this.$store.state.user.id,
            email: this.$store.state.user.email,
            email_notifications: this.email_notifications_new,
            push_notifications: this.push_notifications_new
          })
          .then(() => {
            if(this.notifications_new) {
              this.$toast.open({ message: this.$i18n.t('profile.settings_saved'),
                position: 'top',
                duration: TOAST_DURATION,
                type: 'success'
              })
            } else {
              this.$toast.open({ message:  this.$i18n.t('profile.settings_saved'),
                position: 'top',
                duration: TOAST_DURATION,
                type: 'success'
              })
            }
          }).catch(function (error) {
            this.notifications_new = this.$store.state.user.notifications
            alert(this.$i18n.t('profile.error_settings_not_saved') + ' ' + error)
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .profileChangeNotifications {
        
        form {
            padding-top: 16px;
            max-width: 432px;
            margin: 0px auto;
        }
        
        h1 {
            text-align: center;
        }
        
        p {
            text-align: center;
            margin-bottom: 26px;
        }
    }
</style>
