<template>
  <div :key="componentKey">
    
    <div id="swiper_container">
      <BetMatchDetailsSwiper :matches="matches"></BetMatchDetailsSwiper>
    </div>

  </div>
</template>

<script>

  import BetMatchDetailsSwiper from '../components/BetMatchDetailsSwiper'
  import NavigationBar from './../components/NavigationBar'
export default {
  name: 'BetMatchSlider',
  props: {
    matchday: {
      type: Number,
      default: () => {
        return 1
      }
    }
  },
  data () {
    return {
      componentKey: 0
    }
  },
  components: {
    BetMatchDetailsSwiper, NavigationBar
  },
  created() {
    this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
  },
  mounted() {
    this.componentKey += 1
  },
  computed: {
    switchViewAvailable() {
      return this.$store.state.betgame.competition.type === 'LEAGUE'
    },
    matches() {
      const m = this.$store.getters
              .getMatchesByMatchday(this.matchday)
              .sort(function(a, b) {
                return Date.parse(a.matchdate) - Date.parse(b.matchdate)
              })
      return m
    },
    matchdayCount() {
      return this.$store.state.matchdayCount
    }
  },
  methods: {
  }
}
</script>

<style scoped>
  #swiper_container {
    width: 100%;
    margin-bottom: 10px;
  }
</style>
