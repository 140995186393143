<template>
  <div class="adconsent" v-bind:style="{ paddingBottom: '96px' }">

    <NavigationBar>
      <template v-slot:left>
        <div v-if="canGoBack()">
          <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
        </div>
        <div v-else>
        </div>
      </template>
      <template v-slot:center>
        {{ $t('register.ad_consent') }}
      </template>
    </NavigationBar>

    <div v-if="adconsentText" v-html="adconsentText" style="margin-top: 24px; margin-bottom:  24px;"></div>

  </div>
</template>

<script>
import NavigationBar from '../components/NavigationBar'

export default {
  name: 'BetgameAdconsent',
  components: { NavigationBar },
  computed: {
    userlang() {
      return this.$store.state.language
    },
    adconsentText () {
      if(this.$store.state.betgame && this.$store.state.betgame.adconsent_text && this.$store.state.betgame.adconsent_text.length > 0) {
        return this.$store.state.betgame.adconsent_text
      }
      return ''
    }
  },
  methods: {
    goBack () {
      if (this.$store.getters.loggedIn) {
        this.$router.push({ name: 'profile' }).catch(err => {})
      } else {
        this.$router.go(-1)
      }
    },
    canGoBack () {
      return window.history && window.history.length > 1
    }
  }
}

</script>

<style lang="scss" scoped>
.adconsent {
  h1 {
    font-weight: 600;
    font-size: 1.3em;
    color: black;
  }
  h3 {
    font-weight: 500;
    font-size: 1.2em;
  }
  h5 {
    font-weight: 300;
    font-size: 1.1em;
  }
  h6 {
    font-weight: 200;
    font-size: 1.0em;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
  }
  p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 100;
    font-size: 0.9em;
  }
  a {
    word-break: break-word;
  }
  ul > li {
    font-weight: 100;
    font-size: 0.9em;
    color: #5F5D61;
  }
}
</style>
