<template>
  <div class="ShareBetgame">
  
    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('share_betgame.header') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>
    
    <div class="notification">
      <h1>{{ $t('share_betgame.invitation_link') }}</h1>
      <p>{{ $t('share_betgame.text') }}</p>
      <div v-clipboard:copy="share_betgame_link" v-on:click="shareLinkClicked" id="shareInput">
        <p style="width: 100%;">{{ share_betgame_link }}</p>
      </div>
      <div v-if="isPrivateBetgame" style="margin-top: 20px">
        <p>{{ $t('share_betgame.refresh_invitation_link_text') }}</p>
        <button style="margin-top: 10px" v-on:click="refreshInvitationCode">{{ $t('share_betgame.button_refresh_invitation') }}</button>
      </div>
    </div>

    <div class="divider">
      <div class="divider-stroke"></div>
      <div class="divider-label">{{ $t('or') }}</div>
      <div class="divider-stroke"></div>
    </div>
    
    <social-sharing :url="share_betgame_link"
                    :title="share_betgame_text"
                    :description="share_betgame_text"
                    hashtags="teamtip,tippspiel,tippen"
                    inline-template>
      <div class="list">
        <network network="facebook">
          <div class="table-view-cell">
            <div class="table-view-cell-icon size-24">
              <img src="../assets/icons/ic_24_social_facebook.svg" alt="Facebook">
            </div>
            <div class="table-view-cell-label">
              Facebook
            </div>
            <div class="table-view-cell-drilldown">
            </div>
          </div>
        </network>
        <network network="whatsapp">
          <div class="table-view-cell">
            <div class="table-view-cell-icon size-24">
              <img src="../assets/icons/ic_24_social_whatsapp.svg" alt="WhatsApp">
            </div>
            <div class="table-view-cell-label">
              WhatsApp
            </div>
            <div class="table-view-cell-drilldown">
            </div>
          </div>
        </network>
        <network network="twitter">
          <div class="table-view-cell">
            <div class="table-view-cell-icon size-24">
              <img src="../assets/icons/ic_24_social_twitter.svg" alt="Twitter">
            </div>
            <div class="table-view-cell-label">
              Twitter
            </div>
            <div class="table-view-cell-drilldown">
            </div>
          </div>
        </network>
        <network network="email">
          <div class="table-view-cell">
            <div class="table-view-cell-icon size-24">
              <img src="../assets/icons/ic_24_social_email.svg" alt="E-Mail">
            </div>
            <div class="table-view-cell-label">
              E-Mail
            </div>
            <div class="table-view-cell-drilldown">
            </div>
          </div>
        </network>
        <!--
        <network network="line">
          <i class="fa fa-line"></i> Line
        </network>
        <network network="linkedin">
          <i class="fa fa-linkedin"></i> LinkedIn
        </network>
        <network network="odnoklassniki">
          <i class="fa fa-odnoklassniki"></i> Odnoklassniki
        </network>
        <network network="pinterest">
          <i class="fa fa-pinterest"></i> Pinterest
        </network>
        <network network="reddit">
          <i class="fa fa-reddit"></i> Reddit
        </network>
        <network network="skype">
          <i class="fa fa-skype"></i> Skype
        </network>
        <network network="sms">
          <i class="fa fa-commenting-o"></i> SMS
        </network>
        <network network="telegram">
          <i class="fa fa-telegram"></i> Telegram
        </network>
        <network network="vk">
          <i class="fa fa-vk"></i> VKontakte
        </network>
        <network network="weibo">
          <i class="fa fa-weibo"></i> Weibo
        </network>
        -->
      </div>
    </social-sharing>

    <!--
    <div style="margin-top: 20px; margin-bottom: 20px">
      Teile diesen Link {{ share_betgame_link }}, um Freunde zu deiner Tipprunde einzuladen.
    </div>
    -->

  </div>
</template>

<script>
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '@/main'
export default {
  name: 'ShareBetgame',
  components: {
    NavigationBar
  },
  data() {
    return {
    }
  },
  beforeCreate() {
  },
  mounted() {
  },
  created() {
  },
  computed: {
    isPrivateBetgame() {
      return this.$store.state.betgame.is_public === false
    },
    share_betgame_link() {
      return this.$store.state.apiUrl + '/join/' + this.$store.state.betgame.id + '/' + this.$store.state.betgame.invitation_code
    },
    share_betgame_text() {
      return this.$t('betgame_settings.share_invitation_text')
    }
  },
  methods: {
    refreshInvitationCode() {
      const newcode = Math.random().toString().slice(2,10)
      this.$store.dispatch('patchBetgame', {invitation_code: newcode})
      .then(() => {
        this.$store.dispatch('updateBetgameInvitationCode', newcode)
        .then(() => {
        })        
      })
      .catch(function (error) {
        this.$toast.open({ message: this.$i18n.t('share_betgame.refresh_invitation_failed_try_again'),
          position: 'top',
          duration: TOAST_DURATION,
          type: 'error'
        })
      })
      .finally(function () {
      })
    },
    shareLinkClicked() {
      this.$toast.open({ message:  this.$i18n.t('share_betgame.invitation_link_copied_to_clipboard'), position: 'top', duration: 1000, type: 'info' })
    },
    goBack() {
      this.$router.push('/betgameedit')
    }
  }
}

</script>

<style lang="scss" scoped>

  #shareInput {
    word-break: break-all;
    position: relative;
    display: flex;
    width: 100%;
    padding: 16px 8px 16px;
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-primary);
    text-align: center;
    box-sizing: border-box;
    background: rgba($colorGreyDark, .08);
    border-radius: 6px;
    border: 0;
    -webkit-text-fill-color: var(--color-primary);
    opacity: 1;
  }

  .divider {
    display: flex;
    align-items: center;
    margin: 24px 0;

    &-stroke {
      flex: 1;
      height: 1px;
      background: rgba($colorGreyLight, .08);
    }

    &-label {
      margin: 0 8px;
      font-size: 14px;
      color: $colorGreyMid;
    }
  }

  .list {
    padding: 8px 16px;
  }

</style>
