<template>
  <div :key="componentKey" class="searchbetgame" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">

    <NavigationBar >
      <template v-slot:left>
        <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
      </template>
      <template v-slot:center>
        {{ $t('betgames') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <div class="notification">
      <h1>{{ $t('search_betgame.title') }}</h1>
      <p>{{ $t('search_betgame.text') }}</p>
      <router-link to="/createUserBetgame">
        <button><img class="button-icon button-icon--left" src="../assets/icons/ic_12_plus_white.svg">{{ $t('user_betgame_settings.button_create_betgame') }}</button>
      </router-link>
    </div>

    <div class="table-view">
      <div class="table-view-header">
        {{ $t('search_betgame.public_betgames') }}
      </div>
      <router-link v-for="(betgame, index) in betgamesToJoin" :key="betgame.id" to="" class="router_link" :to="'/join/' + betgame.id + '/' + betgame.invitation_code">
        <div class="table-view-cell">
          <div class="table-view-cell-icon size-24">
            <img v-if="isValidIcon(betgame.icon)" style="max-width: 24px; max-height: 24px;" v-bind:src="buildIconPath(betgame.icon, 24)">
            <img v-else src="../assets/icons/ic_24_logo.svg">
          </div>
          <div class="table-view-cell-label">
            {{ betgame.name }}
          </div>
          <div class="table-view-cell-drilldown">
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import NavigationBar from './../components/NavigationBar'

export default {
  name: 'SearchBetgame',
  components: {
    NavigationBar
  },
  data() {
    return {
      publicBetgames: null,
      componentKey:0
    }
  },
  beforeCreate() {
  },
  mounted() {
  },
  created() {
    //if(this.$store.state.publicBetgames && this.$store.state.publicBetgames.length > 0) {
    //  this.publicBetgames = this.$store.state.publicBetgames
    //} else {
      this.$store.dispatch('fetchPublicBetgames').then(() => {
        this.publicBetgames = this.$store.state.publicBetgames
        this.componentKey += 1
      })
    //}
  },
  computed: {
    betgamesToJoin() {
      if(this.publicBetgames) {
        const p = this.$store.state.betgames_participant
        const a = this.$store.state.betgames_author
        const o = this.$store.state.betgames_owner
        var newArray = this.publicBetgames.filter(function (el) {
          return o.filter(function (item) {
                    return item.id === el.id;
                  }).length === 0 &&
                  a.filter(function (item) {
                    return item.id === el.id;
                  }).length === 0 &&
                  p.filter(function (item) {
                    return item.id === el.id;
                  }).length === 0;
        });
        return newArray
      }
      return []
    }
  },
  methods: {
    goBack() {
      if(this.hasHistory()) {
        this.$router.go(-1)
      } else {
        this.$router.push('/dashboard')
      }
    },
  }
}

</script>

<style lang="scss" scoped>

.searchbetgame {
  max-width: 576px;
  margin: 0 auto auto auto;

  @media screen and (min-width: 768px) {
    margin: 48px auto 0;
  }
}

.router_link {
  text-decoration: none;
}

</style>
