<template>
    <div class="createuserbetgame" id="createuserbetgame">
        
        <NavigationBar>
            <template v-slot:left>
              <div v-if="!userSource || userSource.length === 0">
                <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
              </div>
              <div v-else>
              </div>
            </template>
            <template v-slot:center>
              {{ $t('create_betgame.title') }}
            </template>
            <template v-slot:right>
            </template>
        </NavigationBar>

        <div v-if="userSource && userSource.length > 0" class="intro-header">
          <div class="intro-header-content">
            <img src="../assets/landingpage/logo-teamtip.svg" alt="TEAMTIP Logo">
            <h1>{{ $t('create_betgame.welcome_to_teamtip') }}</h1>
            <p>{{ $t('create_betgame.welcome_to_teamtip_text') }}</p>
          </div>
        </div>
        <div v-else>
          <h1>{{ $t('create_betgame.create_betgame') }}</h1>
          <p>{{ $t('create_betgame.create_betgame_text') }}</p>
        </div>
    
        <form @submit.prevent="registerUserCreateBetgame">
        <div class="input-container">
          <div class="input-header">
            {{ $t('create_betgame.general_betgame_informations') }}
          </div>
          <div class="input-element">
            <input @focus="handleBetgameNameFocus" v-model="betgameName" name="betgameName" type="text" id="tipprunde-name" placeholder="tipprunde-name"/>
            <label for="tipprunde-name">{{ $t('create_betgame.betgame_name') }}</label>
          </div>
          <!--
          <div class="input-element">
            <input id="tipprunde-beschreibung" placeholder="tipprunde-beschreibung"/>
            <label for="tipprunde-beschreibung">Beschreibung</label>
          </div>
          -->
        </div>
    
        <div class="user-input-error">
            <p v-for="error in betgameErrors">{{ error }}</p>
        </div>
        
        <div class="input-container">
          <div class="input-header">
            {{ $t('create_betgame.select_competition') }}
          </div>
          <div class="input-radio">
            <input v-on:change="competitionSelected" v-model="selectedCompetition" type="radio" id="wettbewerb-10" name="wettbewerb" value="107" />
            <label for="wettbewerb-10">EURO 2020</label>
            <!-- <label v-else for="wettbewerb-10" style="color: lightgray">EURO 2020 (bald verfügbar)</label> -->
          </div>
          <div class="input-radio">
            <input v-on:change="competitionSelected" v-model="selectedCompetition" type="radio" id="wettbewerb-9" name="wettbewerb" value="19"/>
            <label for="wettbewerb-9">Champions League</label>
            <!-- <label v-else for="wettbewerb-9" style="color: lightgray">Champions League 2020/21 (bald verfügbar)</label> -->
          </div>
          <div class="input-radio">
            <input v-on:change="competitionSelected" v-model="selectedCompetition" type="radio" id="wettbewerb-2" name="wettbewerb" value="1" />
            <label for="wettbewerb-2">1. Bundesliga</label>
            <!--<label v-else for="wettbewerb-2" style="color: lightgray">1. Bundesliga 2020/21 (bald verfügbar)</label>-->
          </div>
          <div class="input-radio">
            <input v-on:change="competitionSelected" v-model="selectedCompetition" type="radio" id="wettbewerb-3" name="wettbewerb" value="2" />
            <label for="wettbewerb-3">2. Bundesliga</label>
            <!--<label v-else for="wettbewerb-3" style="color: lightgray">2. Bundesliga 2020/21 (bald verfügbar)</label>-->
          </div>
          <div class="input-radio">
            <input v-on:change="competitionSelected" v-model="selectedCompetition" type="radio" id="wettbewerb-1" name="wettbewerb" value="3" />
            <label for="wettbewerb-1">3. Liga</label>
            <!--<label v-else for="wettbewerb-1" style="color: lightgray">3. Liga 2020/21 (bald verfügbar)</label>-->
          </div>          
          <div class="input-radio">
            <input v-on:change="competitionSelected" v-model="selectedCompetition" type="radio" id="wettbewerb-5" name="wettbewerb" value="91" />
            <label for="wettbewerb-5">Premier League</label>
            <!-- <label v-else for="wettbewerb-5" style="color: lightgray">Premier League 2020/21 (bald verfügbar)</label> -->
          </div>
          <div class="input-radio">
            <input v-on:change="competitionSelected" v-model="selectedCompetition" type="radio" id="wettbewerb-4" name="wettbewerb" value="97" />
            <label for="wettbewerb-4">Primera División</label>
            <!-- <label v-else for="wettbewerb-4" style="color: lightgray">Primera División 2020/21 (bald verfügbar)</label> -->
          </div>
          <div class="input-radio">
            <input v-on:change="competitionSelected" v-model="selectedCompetition" type="radio" id="wettbewerb-6" name="wettbewerb" value="111" />
            <label for="wettbewerb-6">Serie A</label>
            <!-- <label v-else for="wettbewerb-6" style="color: lightgray">Serie A 2020/21 (bald verfügbar)</label>-->
          </div>
          <div class="input-radio">
            <input v-on:change="competitionSelected" v-model="selectedCompetition" type="radio" id="wettbewerb-7" name="wettbewerb" value="116" />
            <label for="wettbewerb-7">SüperLig</label>
            <!-- <label v-else for="wettbewerb-7" style="color: lightgray">SüperLig 2020/21 (bald verfügbar)</label> -->
          </div>
          <div class="input-radio">
            <input v-on:change="competitionSelected" v-model="selectedCompetition" type="radio" id="wettbewerb-8" name="wettbewerb" value="71" />
            <label for="wettbewerb-8">Ligue 1</label>
            <!-- <label v-else for="wettbewerb-8" style="color: lightgray">Ligue 1 2020/21 (bald verfügbar)</label> -->
          </div>
        </div>

        <div v-if="this.$store.getters.loggedIn && this.isSPMUser()" class="input-container">
          <div class="input-header">
            {{ $t('betgame_settings.bet_mode') }}
          </div>
          <div class="input-infotext">
            {{ $t('betgame_settings.bet_mode_description_4') }}
          </div>
          <div class="input-radio">
            <input v-on:change="betmodeSelected" v-model="selectedBetmode" v-bind:value="1" type="radio" id="ergebnis" name="modus" value="" />
            <label for="ergebnis">{{ $t('betgame_settings.bet_mode_result') }}</label>
          </div>
          <div class="input-radio">
            <input v-on:change="betmodeSelected" v-model="selectedBetmode" v-bind:value="2" type="radio" id="toto" name="modus" value="" />
            <label for="toto">{{ $t('betgame_settings.bet_mode_toto') }}</label>
          </div>
        </div>

        <div v-if="notLoggedIn" class="input-container">
          <div class="input-header">
            {{ $t('create_betgame.user_data') }}
          </div>
          <div class="input-element">
            <input  v-model="name" name="name" type="text" id="username" placeholder="username"/>
            <label for="username">{{ $t('user_name') }}</label>
          </div>
          <div class="input-element">
            <input v-model="email" type="email" name="email" id="email" placeholder="email"/>
            <label for="email">{{ $t('email') }}</label>
          </div>
          <div class="input-element">
            <input v-model="password" type="password" name="password" id="password" placeholder="password"/>
            <label for="password">{{ $t('password') }}</label>
          </div>
        </div>
        
        <div v-if="notLoggedIn" class="input-container">
          <div class="input-header">
            {{ $t('create_betgame.conditions_and_data_protection') }}
          </div>
          <div class="input-checkbox">
              <input id="agreedTOS" name="agreedTOS" type="checkbox" v-model="agreedTOS"/>
              <label for="agreedTOS">{{ $t('create_betgame.agree_terms_of_use') }} <a target="_blank" href="/termsofuse">({{ $t('create_betgame.link') }})</a>.</label>
          </div>
          <div class="input-empty">
              <a target="_blank" href="/privacy">{{ $t('create_betgame.data_protection_teamtip') }}</a>
          </div>
          <div class="input-checkbox">
              <input id="isUnder18" name="isUnder18" type="checkbox" v-model="isUnder18"/>
              <label for="isUnder18">{{ $t('create_betgame.i_am_under_18') }} <span style="font-size: 14px; opacity: .48;">({{ $t('create_betgame.optional') }})</span></label>
          </div>
        </div>

        <button type="submit" name="button" :disabled="this.emailAlreadyRegistered">
          {{ $t('create_betgame.button_open_betgame') }}
        </button>
        </form>
    
        <ul class="error-container">
            <li v-for="error in errors">{{ error }}</li>
        </ul>
        
        <div v-if="emailAlreadyRegistered" class="forgot-password">
            <p>
                <span>{{ $t('forgot_password') }}</span>
                <router-link :to="{ name: 'resetpassword', params: { email: this.email }}">{{ $t('reset_password') }}</router-link>
            </p>
            <p>
                <span>{{ $t('create_betgame.teamtip_account_exists') }}</span>
                <router-link :to="{ name: 'login', params: { email: this.email }}">{{ $t('login') }}</router-link>
            </p>
        </div>
    
    </div>
</template>

<script>
  
  import APIService from '@/services/APIService'
  import NavigationBar from './../components/NavigationBar'
  import 'vue-select/dist/vue-select.css'
  import Vue from 'vue'
  import Numeral from 'numeral'
  import moment from 'moment'
  
  export default {
    name: 'CreateUserBetgame',
    components: { NavigationBar },
    props: {
      emailparam: {
        type: String,
        default: ''
      },
      passparam: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        competitions: [{label: '1. Bundesliga', code: '1'},{label: '2. Bundesliga', code: '2'},
          {label: '3. Liga', code: '3'},{label: 'Champions League', code: '19'},
          {label: 'Premier League', code: '91'},
          {label: 'Serie A', code: '111'},{label: 'Primera División', code: '97'},
          {label: 'SüperLig', code: '116'},{label: 'Ligue 1', code: '71'},
          {label: 'EURO 2020', code: '107'}],
        selectedCompetition: '107',
        betgameName: 'EURO 2020',
        name: '',
        email: this.emailparam,
        password: this.passparam,
        errors: [],
        betgameErrors: [],
        userSource: '',
        emailAlreadyRegistered: false,
        userEditedBetgameName: false,
        agreedTOS: false,
        isUnder18: false,
        selectedBetmode: '1'
      }
    },
    mounted () {
      const src = this.$route.query.src
      Vue.$log.debug('CreateUserBetgame mounted ' + src)
      if(src !== undefined && src !== null && src.trim().length > 0) {
        this.userSource = src
      } else {
        this.userSource = ''
      }
    },
    computed: {
      notLoggedIn() {
        return !this.$store.getters.loggedIn
      },
      betgameNameValid () {
        if(this.betgameName === null || this.betgameName === undefined) {
          return false
        }
        return this.betgameName.length >= 6
      },
      nicknameValid () {
        if(this.name === null || this.name === undefined) {
          return false
        }
        return this.name.length >= 3
      },
      emailValid () {
        if(this.email === null || this.email === undefined) {
          return false
        }
        return this.isValidEmail(this.email)
      },
      passwordValid () {
        if(this.password === null || this.password === undefined) {
          return false
        }
        return this.password.length > 6
      }
    },
    methods: {
      betmodeSelected() {
        const sm = this.selectedBetmode
        Vue.$log.debug('betmodeSelected ' + sm)
      },
      handleBetgameNameFocus() {
        this.userEditedBetgameName = true
        this.betgameErrors = []
      },
      handleEmailBlur() {
        APIService.isEmailRegistered(this.email).then(response => {
          if (response.data.valid === '1') {
            this.emailAlreadyRegistered = false
            this.errors = []
          } else {
            this.emailAlreadyRegistered = true
            this.errors.push(this.$t('errors.email_already_registered'))
          }
        })
      },
      competitionSelected() {
        const sc = this.selectedCompetition
        var c = this.competitions.filter(function (el) {
          return el.code === sc;
        });
        if(!this.betgameName || !this.userEditedBetgameName) {
          this.betgameName = c[0].label + "-Tipprunde"
          this.userEditedBetgameName = false
        }
      },
      goBack() {
        this.$router.go(-1)
      },
      registerUserCreateBetgame() {
        Vue.$log.debug('registerUserCreateBetgame 123')
        if(this.notLoggedIn) {
          this.errors = []
          this.betgameErrors = []
          this.emailAlreadyRegistered = false
          if (this.isNullOrUndefined(this.name) || this.name.trim().length === 0) {
            this.errors.push(this.$t('errors.enter_nickname'))
          }
          if (this.isNullOrUndefined(this.email) || this.email.trim().length === 0) {
            this.errors.push(this.$t('errors.enter_email'))
          } else if (!this.isValidEmail(this.email)) {
            this.errors.push(this.$t('errors.enter_valid_email'))
          }
          if (this.isNullOrUndefined(this.password) || this.password.trim().length === 0) {
            this.errors.push(this.$t('errors.enter_password_length'))
          }
          if (this.isNullOrUndefined(this.betgameName)) {
            this.betgameErrors.push(this.$t('errors.betgame_name_length'))
          } else {
            if (this.betgameName.trim().length < 6) {
                this.betgameErrors.push(this.$t('errors.betgame_name_length'))
            } else if (this.betgameName.trim().length >= 100) {
              this.betgameErrors.push(this.$t('errors.betgame_name_max_length'))
            }
          }
          if (!this.agreedTOS) {
            this.errors.push(this.$t('errors.confirm_terms_of_use'))
          }
          if (this.errors.length === 0 && this.betgameErrors.length === 0) {
                const language = this.$store.state.language ? this.$store.state.language : 'de'
                this.$store.dispatch('registerUserCreateBetgame', {
                  email: this.email.toLowerCase(),
                  pass: this.password,
                  nick: this.name.trim(),
                  lang: language,
                  betgame: this.betgameName.trim(),
                  competition: this.selectedCompetition
                })
                .then(() => {
                  this.$store.dispatch('patchUser', {source: this.userSource, acceptedtos: this.agreedTOS, isadult: !this.isUnder18}).then(() => {
                  })
                  this.$store.dispatch('patchBetgame', { bet_mode: this.selectedBetmode }).then(() => {
                  })
                  if(this.$store.state.language !== undefined && this.$store.state.language !== null &&
                      this.$store.state.language.length > 1) {
                    Numeral.locale(this.$store.state.language)
                    moment.locale(this.$store.state.language)
                  }
                  if(this.$store.state.language !== undefined && this.$store.state.language !== null &&
                      this.$store.state.language.length > 1 && this.$i18n.locale !== this.$store.state.language) {
                    this.$i18n.locale = this.$store.state.language
                  }
                  Vue.$log.debug('registerUserCreateBetgame OK ' + this.$store.state.user.id)
                  this.$store.dispatch('fetchUserBetgames', this.$store.state.user.id)
                  .then(() => {
                    Vue.$log.debug('registerUserCreateBetgame OK fetchUserBetgames + ' + this.$store.state.userBetgames)
                    Vue.$log.debug('bgame ' + this.$store.state.betgameId)
                    this.$store.dispatch('switchBetgame', this.$store.state.betgameId).then(() => {
                      //this.$store.dispatch('fetchBetgame', this.$store.state.betgameId).then(() => {
                      this.$router.push('/betgameedit')
                      //})
                    })
                  }).catch(function (error) {
                    Vue.$log.debug('registerUserCreateBetgame ' + error)
                  })
                }).catch(function (error) {
                  if (error.response.status === 409) {
                    alert(this.$t('errors.email_already_registered'))
                  } else {
                    alert(this.$t('errors.registration_failed'))
                  }
                })
          }
        } else {
          this.errors = []
          this.betgameErrors = []
          if (this.isNullOrUndefined(this.betgameName)) {
            this.betgameErrors.push(this.$t('errors.betgame_name_length'))
          } else {
            if (this.betgameName.trim().length < 6) {
              this.betgameErrors.push(this.$t('errors.betgame_name_length'))
            } else if (this.betgameName.trim().length >= 100) {
              this.betgameErrors.push(this.$t('errors.betgame_name_max_length'))
            }
          }
          if (this.betgameErrors.length === 0) {
            this.$store.dispatch('createBetgame', {
              email: this.$store.state.user.email.toLowerCase(),
              user_id: '' + this.$store.state.user.id,
              betgame: this.betgameName.trim(),
              competition: this.selectedCompetition
            })
            .then(() => {
              this.$store.dispatch('patchBetgame', { bet_mode: this.selectedBetmode }).then(() => {
              })
              this.$store.dispatch('addBetgamesOwner', {
                id: this.$store.state.betgameId,
                name: 'Neue Tipprunde',
                type: 'Wettbewerb'
              })
              Vue.$log.debug('createBetgame OK ' + this.$store.state.user.id)
              Vue.$log.debug('createBetgame OK fetchUserBetgames + ' + this.$store.state.userBetgames)
              Vue.$log.debug('bgame ' + this.$store.state.betgameId)
              this.$store.dispatch('switchBetgame', this.$store.state.betgameId).then(() => {
                //this.$store.dispatch('fetchBetgame', this.$store.state.betgameId).then(() => {
                this.$router.push('/betgameedit')
                //})
              })
            }).catch(function (error) {
              alert(this.$t('errors.betgame_save_failed') + ' ' + error.toString())
            })
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .user-input-error {
      margin: 10px 10px 10px 10px;
      p {
          color: red;
      }
  }
  .createuserbetgame {
    max-width: 400px;
    margin: 43px auto 16px;

    h1 {
      text-align: center;
    }

    p {
      text-align: center;
      margin-bottom: 34px;
    }

    .input-container {
      margin: 16px auto 0;
    }

    button {
      margin-top: 16px;
    }
  }
  .intro-header {
    background: url('../assets/landingpage/header-background.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    margin-left: calc(-100vw / 2 + 400px / 2);
    margin-right: calc(-100vw / 2 + 400px / 2);
    margin-top: -40px;
    margin-bottom: 24px;

    @media screen and (max-width: 432px) {
      width: calc(100% + 32px);
      margin-left: -16px;
      margin-right: -16px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 400px;
      padding: 32px 16px;
      margin: 0px auto;

      img {
        margin-bottom: 24px;
      }

      h1{
        color: $colorWhite;
      }

      p {
        margin-bottom: 0;
        color: rgba($colorWhite, .64);
      }
    }
  }
</style>

