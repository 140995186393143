<template>
    <div class="scrolling-wrapper-flexbox">

        <div class="searchbar" v-bind:class="{ searchbar_active: searchActive, searchbar_margin_top: searchActive && showOdds }">
            <div class="searchbar-item searchtype-user">
              <input ref="searchInput" class="search-input" @input="searchInputChanged" type="text" :placeholder="searchuser">
            </div>
        </div>

        <table id="bg_rankings_detail_table" class="table card">

            <RankingsDetailTableHeader :searchString="searchString" @sortingChanged="sortingChanged" @refreshRankingByMatchday="refreshRankingByMatchday" :matches="matches"></RankingsDetailTableHeader>
            
            <tbody v-if="matchdayRankings" :key="sorting + '_' + matchday">

                <tr v-for="(ranking, index) in matchdayRankings" :key="index + '_' + sorting + '_' + matchday + '_' + ranking.fk_user" class="" v-bind:class="{ current_user: ranking.fk_user === currentUser }">
                    <td v-if="sorting === 1 && !equalPoints(index)">{{ ranking.position }}.</td>
                    <td v-else-if="sorting === 1 && equalPoints(index)"></td>
                    <td v-else-if="sorting === 2 && !equalPoints(index)">{{ index + 1 }}.</td>
                    <td v-else-if="sorting === 2 && equalPoints(index)"></td>
                    <td class="col_user">{{ ranking.user_name }}</td>
                    <td class="col_value" :class="{ sort_column: sorting === 1 }">{{ totalpoints(ranking.fk_user) }}</td>
                    <td v-if="!isBattle() && !isTournament()" class="col_value" :class="{ sort_column: sorting === 2 }">{{ livepoints(ranking.fk_user) }}</td>
                    <!--
                    <td v-if="sorting === 2" class="col_result">{{ ranking.points_total }}<span style="color: #ACACAC; font-size: smaller; vertical-align: center"> ({{ totalpoints(ranking.fk_user) }})</span></td>
                    <td v-if="sorting === 1" class="col_result">{{ totalpoints(ranking.fk_user) }}<span style="color: #ACACAC; font-size: smaller; vertical-align: center"> ({{ livepoints(ranking.fk_user) }})</span></td>
                    -->
                    <td class="col_result" v-for="(match, index) in matches" :key="index + '_' + sorting + '_' + matchday + '_' + match.id + '_' + ranking.fk_user" v-bind:class="ranking.fk_user === currentUser ? 'current_user' : index % 2 === 0 ? 'col_white' : 'col_grey'">
                        <span v-if="betmode === 1" v-bind:class="getClass(match, ranking.fk_user)">
                            {{ bet(match, ranking.fk_user) }}
                            <small v-if="betPoints(match, ranking.fk_user) > -1">{{ betPoints(match, ranking.fk_user) }}</small>
                        </span>
                        <div v-else-if="betmode === 2" class="toto-result" v-bind:class="getClass(match, ranking.fk_user)">
                          {{ betToto(match, ranking.fk_user) }}
                        </div>
                    </td>
                </tr>

                <tr v-if="!isUserTopTen" v-for="(ranking, index) in usermatchdayRanking" :key="ranking.fk_user" class="" v-bind:class="{ current_user: ranking.fk_user === currentUser }">
                    <td v-if="sorting === 1">{{ userLivePosition }}.</td>
                    <td v-else></td>
                    <td class="col_user">{{ ranking.user_name }}</td>
                    <td class="col_value">{{ userTotalPoints }}</td>
                    <td v-if="!isBattle() && !isTournament()" class="col_result">{{ ranking.points_total }}</td>
                    <td class="col_result" v-for="(match, index) in matches" :key="match.id + '_' + ranking.fk_user" v-bind:class="ranking.fk_user === currentUser ? 'current_user' : index % 2 === 0 ? 'col_white' : 'col_grey'">
                        <span v-bind:class="getClass(match, ranking.fk_user)">
                            {{ bet(match, ranking.fk_user) }}
                            <small v-if="betPoints(match, ranking.fk_user) > -1">{{ betPoints(match, ranking.fk_user) }}</small>
                        </span>
                    </td>
                </tr>
            
            </tbody>
            <!--

            -->
            <tfoot></tfoot>
        </table>
        
    </div>
</template>

<script>
    import RankingsDetailTableHeader from './RankingsDetailTableHeader'
    import { RESULT_MODE_120M, RESULT_MODE_90M, RESULT_MODE_TOTAL, TOP_TEN } from '../main'
    import { isNumeric } from '../vuex/helpers'
    
    export default {
        name: 'RankingsDetailTable',
        components: { RankingsDetailTableHeader },
        data() {
          return {
            searchString: ''
          };
        },
        props: {
          searchActive: {
            type: Boolean,
            default: () => {
              return false
            }
          }
        },
        created() {
          /*
          if(this.sorting === 1) {
            this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId])
          } else if(this.sorting === 2) {
            this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday])
          }
          
           */
        },
        computed: {
          sortmode() {
            return this.$store.state.betgame.sort_mode || 1
          },
          resultmode() {
            return this.$store.state.betgame.result_mode || RESULT_MODE_TOTAL
          },
          searchuser() {
            return this.$i18n.t('search_user')
          },
          betmode() {
            return this.$store.state.betgame.bet_mode || 1
          },
          showOdds() {
            return false 
            /*
            if(this.$store.state.betgame && this.$store.state.user) {
              return !this.countryBlocked() && this.$store.state.betgame.hide_ads === false && this.$store.state.user.isadult === true
            }
            return false
            */
          },          
          pointsBetResult() {
            return this.$store.state.betgame.points_bet_result
          },
          pointsBetGoaldiff() {
            return this.$store.state.betgame.points_bet_goaldiff
          },
          pointsBetTendency() {
            return this.$store.state.betgame.points_bet_tendency
          },
          sorting() {
            return this.$store.state.matchdayRankingSorting
          },
          userLivePosition() {
            return this.$store.state.userLiveRanking.position
          },
          userTotalPoints() {
            return this.$store.state.userLiveRanking.points_total
          },
          isUserTopTen() {
            const result = this.matchdayRankings.filter(r => r.fk_user === this.$store.state.user.id)
            return result.length === 1
          },
          matchdayRankings() {
            if(!this.hasSeasonStarted()) {
              if(!this.$store.state.betgameUsers || this.$store.state.betgameUsers.length === 0) {
                return []
              }
              const bu = this.$store.state.betgameUsers
              var res = []
              for (var i = 0; i < bu.length && i < TOP_TEN; i++) {
                var u = bu[i]
                u.position = 1
                u.user_name = u.nickname
                res.push(u)
              }
              return res
            } else {
              if (this.sorting === 1) {
                return this.$store.state.liveRankings.slice(0, TOP_TEN)
              } else if (this.sorting === 2) {
                return this.$store.state.matchdayRankings[this.matchday]
              }
              return []
            }
          },
          usermatchdayRanking() {
            return this.$store.state.userMatchdayRankings[this.matchday]
          },
          matchday() {
            return this.$store.state.currentMatchdayRankingByMatchday
          },
            matches () {
                const m = this.$store.getters.getMatchesByMatchday(this.matchday)
                        .sort(function (a, b) {
                            const livea = isNumeric(a.livegoalshome) && isNumeric(a.livegoalsguest) && !isNumeric(a.goalshome) && !isNumeric(a.goalsguest)
                            const liveb = isNumeric(b.livegoalshome) && isNumeric(b.livegoalsguest) && !isNumeric(b.goalshome) && !isNumeric(b.goalsguest)
                            const live = livea === liveb ? 0 : (livea ? -1 : 1)
                            return live ||  Date.parse(a.matchdate) - Date.parse(b.matchdate) || a.id - b.id
                        })
                return m
            },
            currentUser () {
                if(this.$store.state.user) {
                  return this.$store.state.user.id
                }
            }
        },
        methods: {
          getClass(match, user) {
            const bp = this.betPoints(match, user)
            return { bet_exact: bp === this.pointsBetResult,
              bet_goal_diff: bp === this.pointsBetGoaldiff,
              bet_result: bp === this.pointsBetTendency,
              bet_no_points: bp === 0 }
          },
          equalPoints(index) {
            if(index === 0 || !this.matchdayRankings || this.matchdayRankings.length <= index) {
              return false
            }
            const r1 = this.matchdayRankings[index]
            const r2 = this.matchdayRankings[index - 1]
            if(this.sorting === 1) {
              return this.totalpoints(r1.fk_user) === this.totalpoints(r2.fk_user) &&
                  this.totalmatchdaywins(r1.fk_user) === this.totalmatchdaywins(r2.fk_user)
            } else if(this.sorting === 2) {
              return this.livepoints(r1.fk_user) === this.livepoints(r2.fk_user)
            }
            return false
          },
          totalmatchdaywins(user) {
            if(this.sorting === 1) {
              const t = this.$store.state.liveRankings.filter(r => r.fk_user === user)
              if (t.length === 1) {
                return t[0].matchday_wins
              }
              return 0
            } else if(this.sorting === 2) {
              const t = this.$store.state.liveRankingsByMatchday[this.matchday].filter(r => r.fk_user === user)
              if (t.length === 1) {
                return t[0].matchday_wins
              }
              return 0
            }
          },          
          clearSearchString() {
            this.$refs.searchInput.value = ''
            this.searchString = ''
            this.filterTable(this.searchString)
          },
          searchInputChanged({ type, target }) {
            this.searchString = target.value.trim()
            if(this.searchString.trim().length !== 1) {
              this.filterTable(this.searchString)
            }
          },
          filterTable (value) {
              if(this.sorting === 1) {
                this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0, value]).then(() => {
                  this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday, value]).then(() => {
                  })
                })
              } else if(this.sorting === 2) {
                this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0, value]).then(() => {
                    this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday, value]).then(() => {
                      this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday, value]).then(() => {
                      })
                    })
                })
              }
            },
            sortingChanged() {
              this.$emit('sortingChanged', {})
            },
            refreshRankingByMatchday() {
              this.$emit('refreshRankingByMatchday', {})
            },
            totalpoints(user) {
              if(this.sorting === 1) {
                const t = this.$store.state.liveRankings.filter(r => r.fk_user === user)
                if (t.length === 1) {
                  return t[0].points_total
                }
                return 0
              } else if(this.sorting === 2 && this.$store.state.liveRankingsByMatchday && this.$store.state.liveRankingsByMatchday[this.matchday]) {
                const t = this.$store.state.liveRankingsByMatchday[this.matchday].filter(r => r.fk_user === user)
                if (t.length === 1) {
                  return t[0].points_total
                }
                return 0
              }
            },
            livepoints(user) {
                var result = 0
                for (var i = 0; i < this.matches.length; i++) {
                    const match = this.matches[i]
                    const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
                    const p = this.getPoints(bet, match)
                    if(p > -1) {
                      result += p
                    }
                }
                return result
            },
            bet(match, user) {
                if(user !== this.$store.state.user.id && !this.isNumeric(match.goalshome) &&
                  !this.isNumeric(match.goalsguest) && !this.isNumeric(match.livegoalshome) &&
                  !this.isNumeric(match.livegoalsguest)) {
                    return '-:-'
                }
                const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
                if(bet !== undefined && bet !== null && isNumeric(bet.goalshome) && isNumeric(bet.goalsguest))  {
                    //const p = this.getPoints(bet, match)
                    return bet.goalshome + ' : ' + bet.goalsguest //+ ' (' + p + ')'
                } else {
                    return '-:-'
                }
            },
            betToto(match, user) {
              if(user !== this.$store.state.user.id && !this.isNumeric(match.goalshome) &&
                  !this.isNumeric(match.goalsguest) && !this.isNumeric(match.livegoalshome) &&
                  !this.isNumeric(match.livegoalsguest)) {
                return '-'
              }
              const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
              if(bet !== undefined && bet !== null && isNumeric(bet.goalshome) && isNumeric(bet.goalsguest))  {
                return bet.goalshome
              } else {
                return '-'
              }
            },
            betPoints(match, user) {
                if(user !== this.$store.state.user.id &&
                        match.goalshome === null && match.goalsguest === null &&
                            match.livegoalshome === null && match.livegoalsguest === null) {
                    return ''
                }
                const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
                if(bet !== undefined && bet !== null) {
                    const p = this.getPoints(bet, match)
                    return p
                } else {
                  if((this.isNumeric(match.goalshome) && this.isNumeric(match.goalsguest)) ||
                    (this.isNumeric(match.livegoalshome) && this.isNumeric(match.livegoalsguest))) {
                    return 0
                  }
                }
            },
          getPoints(bet, match) {
            if(bet === null || match === null) {
              return -1
            }
            if(!this.isNumeric(match.goalshome) && !this.isNumeric(match.goalsguest) &&
                !this.isNumeric(match.livegoalshome) && !this.isNumeric(match.livegoalsguest)) {
              return -1
            }
            var goalshome = null
            var goalsguest = null
            if(this.resultmode === RESULT_MODE_90M && match.goalshome_90 !== null && match.goalsguest_90 !== null) {
              goalshome = parseInt(match.goalshome_90)
              goalsguest = parseInt(match.goalsguest_90)
            } else if(this.resultmode === RESULT_MODE_120M && match.goalshome_120 !== null && match.goalsguest_120 !== null) {
              goalshome = parseInt(match.goalshome_120)
              goalsguest = parseInt(match.goalsguest_120)
            } else if(match.livegoalshome !== null && match.livegoalsguest !== null &&
                match.goalshome === null && match.goalsguest === null) {
              goalshome = parseInt(match.livegoalshome)
              goalsguest = parseInt(match.livegoalsguest)
            } else if(match.goalshome !== null && match.goalsguest !== null) {
              goalshome = parseInt(match.goalshome)
              goalsguest = parseInt(match.goalsguest)
            }
            if(goalshome !== null && goalsguest !== null) {
              const betgoalshome = parseInt(bet.goalshome)
              const betgoalsguest = parseInt(bet.goalsguest)
              if(this.betmode === 1) {
                if ((betgoalshome === goalshome) && (betgoalsguest === goalsguest)) {
                  return this.$store.getters.pointsBetResult
                } else if ((betgoalshome - betgoalsguest) === (goalshome - goalsguest)) {
                  return this.$store.getters.pointsBetGoaldiff
                } else if ((betgoalshome > betgoalsguest) && (goalshome > goalsguest)) {
                  return this.$store.getters.pointsBetTendency
                } else if ((betgoalshome < betgoalsguest) && (goalshome < goalsguest)) {
                  return this.$store.getters.pointsBetTendency
                }
              } else if(this.betmode === 2) {
                if ((betgoalshome === 1) && (goalshome > goalsguest)) {
                  return this.$store.getters.pointsBetResult
                } else if ((betgoalshome === 0) && (goalshome === goalsguest)) {
                  return this.$store.getters.pointsBetResult
                } else if ((betgoalshome === 2) && (goalshome < goalsguest)) {
                  return this.$store.getters.pointsBetResult
                }
              }
            }
            return 0
          }
        }
    }
</script>

<style lang="scss" scoped>
    
    .scrolling-wrapper-flexbox {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        overflow-x: auto;
        margin: 0 -16px;
    
        .card {
            flex: 0 0 auto;
        }
    }

    .col_result {
        text-align: center;

        span {
            position: relative;
            font-weight: 400;
            letter-spacing: -1px;

            small {
                position: absolute;
                right: -6px;
                top: -1px;
                font-size: 8px;
                color: $colorGreyDark;
            }
        }
    }

    .col_value {
        text-align: center;
        overflow: hidden;
        border-right: 1px solid rgba($colorGreyLight, 0.08);
    }
    
    .col_user {
        text-align: center;
        overflow: hidden;
        max-width: 88px;
        padding-right: 8px;
    }
    
    .table {
        overflow: scroll; /* Scrollbar are always visible */
        overflow: auto;
        margin: 0;

        .rankings & {
            width: 100%;
        }

        td {
            &:first-child {
                text-align: center;
                padding-right: 4px;
                padding-left: 12px;
            }
            &:last-child {
                padding-right: 8px;
            }
        }
    }

    .col_grey {
        background-color: rgba(#F2F2F2, .5);
    }
    .col_white {
        background-color: white;
    }
    .current_user {
        background-color: #F2F2F2;
    }
    .bet_exact {
        color: #24E07C;
    }
    .bet_goal_diff {
        color: #F5B842;
    }
    .bet_result {
        color: $colorGreyDark;
    }
    .bet_no_points {
        color: $colorNegative;
    }
    .sort_column {
        background-color: $colorBackground;
    }
    .searchbar_margin_top {
      margin-top: 0px;
    }

    .toto-result {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin: -2px auto;
      font-size: 11px;
      font-weight: 600;
      color: $colorGreyDark;
      border-radius: 3px;
      box-shadow: inset 0 0 0 1.5px rgba($colorGreyDark, .16);
      background: none;

      &.bet_no_points {
        color: $colorWhite;
        background: $colorNegative;
        box-shadow: none;
      }

      &.bet_exact {
        color: $colorWhite;
        background: $colorPositive;
        box-shadow: none;
      }
    }
</style>
