<template>
    <div style="max-width: 100%; width: 100%; height: auto;">
        <video style="max-width: 100%; height:auto;" ref="videoPlayer" class="video-js"></video>
    </div>
</template>

<script>
  import videojs from 'video.js';
  import 'video.js/dist/video-js.min.css'
  
  export default {
    name: "VideoPlayer",
    props: {
      options: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        player: null
      }
    },
    mounted() {
      const that = this
      this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
        that.$log.debug('onPlayerReady', this);
      })
    },
    beforeDestroy() {
      if (this.player) {
        this.player.dispose()
      }
    }
  }
</script>
