<template>
  <div class="profileChangePassword" id="profileChangePassword">
    
    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('profile.change_password') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>
    
    <form @submit.prevent="changePassword">
      
      <div class="input-container">
        
        <div>
          <div class="input-element">
            <input v-model="password_old" type="password" name="password_old" id="password_old" placeholder="password_old" autofocus value />
            <label for="password_old">{{ $t('profile.current_password') }}</label>
          </div>
          
          <div class="input-element">
            <input v-model="password_new" type="password" name="password_new" id="password_new" placeholder="password_new" value />
            <label for="password_new">{{ $t('profile.new_password') }}</label>
          </div>
  
          <div class="input-element">
            <input v-model="password_new_repeat" type="password" name="password_new_repeat" id="password_new_repeat" placeholder="password_new_repeat" value />
            <label for="password_new_repeat">{{ $t('profile.mew_password_repeat') }}</label>
          </div>
        </div>
        
        <div class="input-button-row">
          <button type="submit" name="button">
            {{ $t('button_save_text') }}
          </button>
        </div>
      
      </div>
    
    </form>
  
    <ul class="error-container">
      <li v-for="error in errors">{{ error }}</li>
    </ul>
  
  </div>
</template>

<script>
  
  import APIService from '@/services/APIService'
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  
  export default {
    components: { NavigationBar },
    data() {
      return {
        password_old: '',
        password_new: '',
        password_new_repeat: '',
        errors: []
      }
    },
    methods: {
      goBack() {
        this.$router.push('/profile')
      },
      changePassword() {
        this.errors = []
        if(this.isNullOrUndefined(this.password_old) || this.password_old.length === 0) {
          this.errors.push(this.$i18n.t('profile.error_enter_current_password'))
        }
        if(this.isNullOrUndefined(this.password_new) || this.password_new.length === 0) {
          this.errors.push(this.$i18n.t('profile.error_enter_new_password'))
        }
        if(this.isNullOrUndefined(this.password_new) || this.password_new.length < 5) {
          this.errors.push(this.$i18n.t('profile.error_password_length', { length: 5 }))
        }
        if(this.isNullOrUndefined(this.password_new_repeat) || this.password_new_repeat.length === 0) {
          this.errors.push(this.$i18n.t('profile.error_repeat_new_password'))
        }
        if(this.password_new !== this.password_new_repeat) {
          this.errors.push(this.$i18n.t('profile.error_new_password_mismatch'))
        }
        if (this.errors.length === 0) {
          this.$store
          .dispatch('updateUserPassword', {
            id: '' + this.$store.state.user.id,
            email: this.$store.state.user.email,
            pass_old: this.password_old,
            pass_new: this.password_new,
          })
          .then(() => {
            //this.errors.push('Dein Passwort wurde geändert.')
            this.$toast.open({ message:  this.$i18n.t('profile.success_new_password_saved'), position: 'top', duration: TOAST_DURATION, type: 'info' })
            this.password_old = ''
            this.password_new = ''
            this.password_new_repeat = ''
          }).catch(function (error) {
            this.password_old = ''
            this.password_new = ''
            this.password_new_repeat = ''
            alert(this.$i18n.t('profile.error_new_password_not_saved') + ' ' + error)
          })
        }
      },
      isNullOrUndefined(data) {
        return data === null || data === undefined
      },
      isValidEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }
    }
  }
</script>

<style lang="scss" scoped>
  .profileChangePassword {
    form {
      padding-top: 16px;
    }
    h1 {
      text-align: center;
    }
    p {
      text-align: center;
      margin-bottom: 26px;
    }
  }
</style>
