<template>
  <div class="adconsentconfirm" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">

    <keep-alive>
      <NavigationBar id="adconsentconfirmNavigationBarId" ref="adconsentconfirmNavigationBarRef">
        <template v-slot:center>
          Werbeeinwilligung
        </template>
      </NavigationBar>
    </keep-alive>


    <div >
      
      <h1 class="adconsent_confirm_header">Werbeeinwilligung bestätigt</h1>
      <p>Vielen Dank für die Bestätigung der Werbeeinwilligung.</p>
      <br>


    </div>
  </div>
</template>

<script>

import NavigationBar from '../components/NavigationBar'
import Vue from 'vue'
import { TOAST_DURATION } from '@/main'
export default {
  name: 'AdConsentConfirm',
  components: { NavigationBar },
  beforeCreate () {
    // https://reviersport.teamtip.net/adconsentconfirm?email=foo%40ellh.de&code=488073
    // http://localhost:8080/adconsentconfirm?email=foo%40ellh.de&code=488073
    Vue.$log.debug('AdConsentConfirm mounted ')
  },
  created () {
    Vue.$log.debug('AdConsentConfirm mounted ')
  },
  mounted() {
    Vue.$log.debug('AdConsentConfirm mounted ')
    if(this.$route.query.code && this.$route.query.email) {
        this.$store
        .dispatch(
          'confirmAdConsent',
          {
            email: this.$route.query.email,
            token: this.$route.query.code
          }
        )
        .then(() => {

        })

    }
  },
  methods: {
  },
  computed: {
  }
}
</script>
<style lang="scss" scoped>
.adconsent_confirm_header {
  margin-top: 30px;
  margin-bottom: 20px;
}
</style>
