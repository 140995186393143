<template>
    <div class="resetPassword" style="justify-content: flex-start; margin-top: 32px;">

        <NavigationBar >
          <template v-slot:left>
            <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
          </template>
          <template v-slot:center>
            {{ $t('password_reset.reset_password') }}
          </template>
          <template v-slot:right>
          </template>
        </NavigationBar>

        <h1>{{ $t('password_reset.title') }}</h1>
        <p>{{ $t('password_reset.text') }}</p>
        
        <form @submit.prevent="resetPassword">
            
            <div class="input-container">

                <div class="input-element">
                    <input v-model="email" type="email" name="email" id="email" placeholder="email" value />
                    <label for="email">{{ $t('email') }}</label>
                </div>

                <div class="input-button-row">
                    <button type="submit" name="button" :disabled="isDisabled">
                      {{ $t('password_reset.button_reset_password') }}
                    </button>
                </div>
            </div>
        
        </form>
      
        <div class="forgot-password">
          <span>{{ $t('password_reset.password_reset_email_received') }}</span>
          <router-link v-if="this.isValidEmail(this.email)" to="/enterpasswordreset">{{ $t('password_reset.enter_code_and_reset_password') }}</router-link>
          <router-link v-else to="/enterpasswordreset">{{ $t('password_reset.enter_code_and_reset_password') }}</router-link>
        </div>
      
    </div>
</template>

<script>
    import NavigationBar from './../components/NavigationBar'

    export default {
        components: { NavigationBar },
        data () {
            return {
                name: '',
                email: '',
                password: '',
                isDisabled: false
            }
        },
        created () {
            this.email = this.$route.params.email
        },
        methods: {
            goBack() {
                this.$router.push('/')
            },
            resetPassword () {
              if(this.email) {
                this.isDisabled = true
                const that = this
                this.$store.dispatch('requestPasswordReset', {
                  email: String(this.email).toLowerCase(),
                })
                .then(() => {
                  this.$router.push({
                    name: 'enterpasswordreset',
                    params: { email: String(this.email).toLowerCase() }
                  })
                }).catch(function (error) {
                  if(error && error.message && error.message.includes('code 500')) {
                    alert(that.$i18n.t('password_reset.request_password_reset_error'))
                  } else {
                    alert(error)
                  }
                })
              } else {
                alert(this.$i18n.t('password_reset.request_password_reset_error'))
              }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .resetPassword {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: calc(100vh - 64px);
        padding: 64px 16px 0 16px;

        h1 {
            text-align: center;
        }
        p {
            text-align: center;
            margin-bottom: 26px;
        }
    }

    .forgot-password {
      width: 100%;
      text-align: center;
      color: $colorGreyDark;
      padding: 21px 0 0;

      a {
        font-weight: 500;
        text-decoration: none;
        margin-left: 6px;
        padding: 12px 0;
      }
    }
</style>
