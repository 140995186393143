<template>
  <div class="mybetgames" v-bind:style="{ paddingBottom: '32px' }">
  
    <keep-alive>
      <NavigationBar id="mybetgamesNavigationBarId" ref="mybetgamesNavigationBarRef">
        <template v-slot:center>
          {{ $t('my_betgames.title') }}
        </template>
        <template v-slot:right>
        </template>
      </NavigationBar>
    </keep-alive>
    
    <div v-if="incomingBetgameTransfers.length > 0" class="list p-top-none mybetgameslist">
      <div class="table-view-title">
        {{ $t('my_betgames.transfer_betgame') }}
      </div>
      <a v-for="(transfer, index) in incomingBetgameTransfers" :key="transfer.bg_betgame.id" href=""
         v-bind:class="{ active : currentBetgame.id === transfer.bg_betgame.id }"
         v-on:click.stop.prevent="finishBetgameTransfer(transfer.bg_betgame.id)"
         class="table-view-cell">
        <div class="table-view-cell-icon size-24">
          <img v-if="isValidIcon(transfer.icon)" v-bind:src="buildIconPath(transfer.icon, 300)">
          <img v-else src="../assets/icons/ic_24_logo.svg">
        </div>
        <div class="table-view-cell-label">
          {{ transfer.bg_betgame.name }}
        </div>
        <div class="table-view-cell-drilldown"></div>
      </a>
    </div>
    
    <div class="list p-top-none mybetgameslist">
      <div class="table-view-title">
        {{ $t('my_betgames.my_betgames') }}
      </div>
  
      <!--
      <router-link to="/" class="router_link">
        <div class="table-view-cell">
          <div class="table-view-cell-icon size-24">
            <img src="../assets/icons/ic_24_logo.svg">
          </div>
          <div class="table-view-cell-label">
            {Tipprundenname}
          </div>
          <div class="table-view-cell-drilldown">
          </div>
        </div>
      </router-link>
      <router-link to="/" class="router_link">
        <div class="table-view-cell">
          <div class="table-view-cell-icon size-24">
            <img src="../assets/icons/ic_24_logo.svg">
          </div>
          <div class="table-view-cell-label">
            {Tipprundenname}
          </div>
          <div class="table-view-cell-drilldown">
          </div>
        </div>
      </router-link>
      -->
      <a v-for="(betgame, index) in userBetgamesOwner" :key="betgame.id" href=""
         v-bind:class="{ active : currentBetgame.id === betgame.id }"
         v-on:click.stop.prevent="switchBetgame(betgame.id)"
         class="table-view-cell">
        <div class="table-view-cell-icon size-24">
          <img v-if="isValidIcon(betgame.icon)" v-bind:src="buildIconPath(betgame.icon, 300)">
          <img v-else src="../assets/icons/ic_24_logo.svg">
        </div>
        <div class="table-view-cell-label">
          {{ betgame.name }}
          <div class="table-view-cell-label-sublabel">
            <span v-if="currentBetgame.id === betgame.id">
              Aktiv
            </span>
            <span v-if="currentBetgame.id === betgame.id"> ∙ </span>
            Admin
          </div>
        </div>
        <div v-if="pendingTipsCount(betgame.id) === 1" class="table-view-cell-value"><span class="indicator">{{ pendingTipsCount(betgame.id) }} {{ $t('one_bet_missing') }}</span></div>
        <div v-else-if="pendingTipsCount(betgame.id) > 1" class="table-view-cell-value"><span class="indicator">{{ pendingTipsCount(betgame.id) }} {{ $t('two_or_more_bets_missing') }}</span></div>
        <div class="table-view-cell-drilldown"></div>
      </a>
      <a v-for="(betgame, index) in userBetgamesAuthor" :key="betgame.id" href=""
         v-bind:class="{ active : currentBetgame.id === betgame.id }"
         v-on:click.stop.prevent="switchBetgame(betgame.id)"
         class="table-view-cell">
        <div class="table-view-cell-icon size-24">
          <img v-if="isValidIcon(betgame.icon)" v-bind:src="buildIconPath(betgame.icon, 300)">
          <img v-else src="../assets/icons/ic_24_logo.svg">
        </div>
        <div class="table-view-cell-label">
          {{ betgame.name }}
          <div class="table-view-cell-label-sublabel">
            <span v-if="currentBetgame.id === betgame.id">
              Aktiv
            </span>
            <span v-if="currentBetgame.id === betgame.id"> ∙ </span>
            Author
          </div>
        </div>
        <div v-if="pendingTipsCount(betgame.id) === 1" class="table-view-cell-value"><span class="indicator">{{ pendingTipsCount(betgame.id) }} {{ $t('one_bet_missing') }}</span></div>
        <div v-else-if="pendingTipsCount(betgame.id) > 1" class="table-view-cell-value"><span class="indicator">{{ pendingTipsCount(betgame.id) }} {{ $t('two_or_more_bets_missing') }}</span></div>
        <div class="table-view-cell-drilldown"></div>
      </a>
      <a v-for="(betgame, index) in userBetgamesParticipant" :key="betgame.id" href=""
         v-bind:class="{ active : currentBetgame.id === betgame.id }"
         v-on:click.stop.prevent="switchBetgame(betgame.id)"
         class="table-view-cell">
        <div class="table-view-cell-icon size-24">
          <img v-if="isValidIcon(betgame.icon)" v-bind:src="buildIconPath(betgame.icon, 300)">
          <img v-else src="../assets/icons/ic_24_logo.svg">
        </div>
        <div class="table-view-cell-label">
          {{ betgame.name }}
          <div class="table-view-cell-label-sublabel">
            <span v-if="currentBetgame.id === betgame.id">
              Aktiv
            </span>
          </div>
        </div>
        <div v-if="pendingTipsCount(betgame.id) === 1" class="table-view-cell-value"><span class="indicator">{{ pendingTipsCount(betgame.id) }} {{ $t('one_bet_missing') }}</span></div>
        <div v-else-if="pendingTipsCount(betgame.id) > 1" class="table-view-cell-value"><span class="indicator">{{ pendingTipsCount(betgame.id) }} {{ $t('two_or_more_bets_missing') }}</span></div>
        <div class="table-view-cell-drilldown"></div>
      </a>
      <!--

      -->
    </div>

    <router-link to="/searchbetgame" class="router_link">
      <div class="secondary-button"><img src="../assets/icons/ic_12_plus.svg">{{ $t('my_betgames.button_create_search_betgame') }}</div>
    </router-link>
    
  </div>
</template>

<script>
import NavigationBar from './../components/NavigationBar'
import { TOAST_DURATION } from '../main'
import Vue from 'vue'

export default {
  name: 'MyBetgames',
  components: {
    NavigationBar
  },
  data() {
    return {
    }
  },
  beforeCreate() {
  },
  mounted() {
  },
  created() {
    //if (this.$store.state.userBetgames == null || this.$store.state.userBetgames.length === 0 || this.$store.state.missing_bets === undefined) {
      this.$store.dispatch('fetchUserBetgames', this.$store.state.user.id)
    //}
    this.$store.dispatch('fetchBetgameTransfersByUser', this.$store.state.user.id).then(() => {
      Vue.$log.debug('incomingBetgameTransfers ' + JSON.stringify(incomingBetgameTransfers))
    }).catch(function (error) {

    })
  },
  computed: {
    incomingBetgameTransfers() {
      return this.$store.state.incomingBetgameTransfers
    },
    currentBetgame() {
      return this.$store.state.betgame
    },
    userBetgamesOwner() {
      return this.$store.state.betgames_owner
    },
    userBetgamesAuthor() {
      const a = this.$store.state.betgames_author
      const o = this.userBetgamesOwner
      var newArray = a.filter(function (el) {
        return o.filter(function(item) { return item.id === el.id; }).length === 0;
      });
      return newArray.sort((a, b) => a.id - b.id)
    },
    userBetgamesParticipant() {
      const p = this.$store.state.betgames_participant
      const a = this.$store.state.betgames_author
      const o = this.userBetgamesOwner
      var newArray = p.filter(function (el) {
        return o.filter(function(item) { return item.id === el.id; }).length === 0 &&
                a.filter(function(item) { return item.id === el.id; }).length === 0;
      });
      return newArray.sort((a, b) => a.id - b.id)
    },
    userId() {
      return this.$store.state.user.id
    }
  },
  methods: {
    pendingAnswersCount(betgame) {
      if(this.$store.state.questions) {
        const questions = this.$store.state.questions.filter(q => parseInt(q.fk_betgame) === parseInt(betgame))
        var result = 0
        for (var i = 0; i < questions.length; i++) {
          const question = questions[i]
          const editableUntil = this.parseMatchdate(question.editable_until)
          if(editableUntil > Date.now()) {
            const answers = this.$store.state.answers.filter(a => parseInt(a.fk_question) === parseInt(question.id))
            if(answers.length !== question.answer_count) {
              result += question.answer_count
            }
          }
        }
        return result
      }
      return 0
    },
    pendingTipsCount(betgame) {
      var newArray = this.$store.state.missing_bets.filter(d => d.id === betgame)
      if(newArray.length > 0) {
        return (newArray[0].matchcount - newArray[0].betcount) + this.pendingAnswersCount(betgame)
      }
      return this.pendingAnswersCount(betgame)
    },
    finishBetgameTransfer(betgame) {
      Vue.$log.debug('finishBetgameTransfer ' + betgame)
      const that = this
      this.$store.dispatch('finishBetgameTransfer', '' + betgame).then(() => {
        that.$toast.open({ message: that.$i18n.t('my_betgames.transfer_betgame_success'), position: 'top', duration: TOAST_DURATION, type: 'success' })
        that.switchBetgame(betgame)
      }).catch(function (error) {
        alert(that.$i18n.t('my_betgames.transfer_betgame_error') + ' ' + error)
      })
    },
    switchBetgame(betgame) {
      if(this.currentBetgame.id !== betgame) {
        this.setBGValue('spmbgid', betgame)
        if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
          this.setBGValue('betgameId', betgame)
        }
        this.$store.dispatch('switchBetgame', betgame).then(() => {
          this.$gtag.set({
            'dimension1': (this.$store.state.betgame && this.$store.state.betgame.id === betgame && this.$store.state.betgame.name) ? this.$store.state.betgame.name : 'betgame not set',
            'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
            'dimension3': betgame ? betgame : 'betgame id not set'
          })
          /*
          this.$gtag.event('switchBetgame', {
            'event_category': 'switchBetgame',
            'event_label': '' + betgame,
            'dimension1': (this.$store.state.betgame && this.$store.state.betgame.id === betgame && this.$store.state.betgame.name) ? this.$store.state.betgame.name : 'betgame not set',
            'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
            'dimension3': betgame ? betgame : 'betgame id not set'
          })
          */
          this.$router.push('/dashboard')
        })
      } else {
        this.$router.push('/dashboard')
      }
    },
  }
}

</script>

<style lang="scss" scoped>

.mybetgameslist {
  margin-top: 16px;
}

.mybetgames {
  max-width: 576px;
  margin: 0 auto auto auto;

  @media screen and (min-width: 768px) {
    margin: 48px auto 0;
  }

  .table-view-cell:last-child {
    border-bottom: 0;
  }

  .table-view-cell-value {
    flex-shrink: 0;
  }

  .indicator {
    padding: 5px 8px;
    border-radius: 10px;
    font-size: 9px;
    font-weight: 500;
    color: $colorWhite;
    background: $colorNegative;
  }

  .active {
    background: rgba($colorGreyLight, .04);
    padding-left: 0;

    &:before, &:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 100%;
      top: 0;
      background: rgba($colorGreyLight, .04);
      border-radius: 0;
    }

    &:before {
      left: -16px;
    }

    &:after {
      right: -16px;
    }

    .table-view-cell-icon {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        right: -2px;
        bottom: -2px;
        width: 10px;
        height: 10px;
        border-radius: 6px;
        background: $colorPositive;
        box-shadow: 0 0 0 2px #F9F9F9;
      }
    }

    .table-view-cell-value {
      display: flex;
    }

    .table-view-cell-label-sublabel {
      span {

        &:first-child {
          color: $colorPositive;
          font-weight: 500;
        }
      }
    }
  }
}

.table-view-cell-icon {
  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 3px;
  }
}

.router_link {
  text-decoration: none;
}

.secondary-button {
  display: flex;
  justify-content: center;
  font-size: 17px;
  font-weight: 400;
  color: $colorGreyDark;
  background: rgba($colorGreyDark, .08);
  padding: 22px 16px;
  border-radius: 11px;
  margin-top: 16px;

  img {
    margin-right: 8px;
  }
}

</style>
