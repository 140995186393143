<template>
    <div class="rules" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
        
        <NavigationBar>
          <template v-slot:left>
            <div></div>
          </template>
            <template v-slot:center>
              {{ $t('profile.manage_notifications') }}
            </template>
        </NavigationBar>
    
        <div class="input-container">
            <div v-if="email && email.length > 0" class="input-infotext">
              {{ $t('profile.manage_notifications_text', { email_adress: email }) }}
            </div>
        </div>
      
    </div>
</template>

<script>
  import NavigationBar from '../components/NavigationBar'
  import Vue from 'vue'
  import { TOAST_DURATION } from '../main'
  
  export default {
    name: 'UnsubscribeEmailNotifications',
    components: { NavigationBar },
    data() {
      return {
        email: '',
      }
    },
    created () {
      this.$log.debug('UnsubscribeEmailNotifications created ' + this.$route.query.user + ' - ' + this.$route.query.email)
      const id = parseInt(this.$route.query.user)
      this.email = this.$route.query.email
      Vue.$log.debug('postUnsubscription start ' + id + ' --- ' + this.email)
      if(id !== undefined && this.email !== undefined) {
        const insertUnsubscription = {
          'fk_user': parseInt(id),
          'email': this.email
        }
        Vue.$log.debug('postUnsubscription ' + JSON.stringify(insertUnsubscription))
        this.$store.dispatch('postUnsubscription', insertUnsubscription).then(response => {
          Vue.$log.debug('postUnsubscription ok ' + JSON.stringify(response))
          this.$toast.open({
            message: this.$i18n.t('profile.send_no_more_emails'),
            position: 'top',
            duration: TOAST_DURATION,
            type: 'success'
          })
        }, error => {
          Vue.$log.debug('postUnsubscription error ' + error.toString())
        })
      } else {
        Vue.$log.debug('postUnsubscription error ' + id + ' --- ' + email)
      }
    },
    methods: {},
    computed: {}
  }
  
</script>

<style lang="scss" scoped>
</style>
