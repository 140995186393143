<template>
  <div class="profileEditAvatar" id="profileEditAvatar">

    <NavigationBar>
      <template v-slot:left>
        <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
      </template>
      <template v-slot:center>
        {{ $t('profile.profile_picture_upload') }}
      </template>
      <template v-slot:right>
        <div style="display: flex;" v-on:click="openHelp"><HintIcon class="navigation_icon"></HintIcon></div>
      </template>
    </NavigationBar>

    <div class="notification">
      <div class="notification-icon">
        <img v-if="!isValidIcon(this.$store.state.user.avatar)" src="../assets/icons/avatar_placeholder.svg">
        <img v-else v-bind:src="buildAvatarPath(this.$store.state.user.avatar, 300)">
      </div>
      <div v-if="this.$store.state.user.avatar" style="font-size: 10px; color: lightgray; margin-top: -20px; margin-bottom: 20px;">{{ this.$store.state.user.avatar }}</div>
      <h1>{{ $t('profile.show_your_face') }}</h1>
      <p>{{ $t('profile.upload_your_image') }}</p>
      <input id="imageFileInput" type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
             accept="image/*" class="input-file" hidden>
      <button v-if="!isValidIcon(this.$store.state.user.avatar)" v-on:click="uploadButtonClicked">
        <img class="button-icon button-icon--left" src="../assets/icons/ic_12_plus_white.svg">{{ $t('profile.profile_picture_upload') }}
      </button>
      <button v-if="isValidIcon(this.$store.state.user.avatar)" v-on:click="uploadButtonClicked">
        <img class="button-icon button-icon--left" src="../assets/icons/ic_12_switch.svg">{{ $t('profile.profile_picture_change') }}
      </button>
      <button v-if="isValidIcon(this.$store.state.user.avatar)" v-on:click="deleteAvatarClicked" class="button-danger">
        <img class="button-icon button-icon--left" src="../assets/icons/ic_12_delete.svg">{{ $t('profile.profile_picture_delete') }}
      </button>
    </div>

    <!--<div class="input-container">
      <div>
        <div class="input-header">
          {{ $t('profile.profile_picture_upload') }}
        </div>
        <div class="input-infotext">
        </div>
      </div>
      
      <div style="margin-bottom: 20px;">
        <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
          <div class="dropbox">
            <input type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                   accept="image/*" class="input-file">
            <p v-if="isInitial">
              {{ $t('profile.profile_picture_select_file_text') }}
            </p>
            <p v-if="isSaving">
              {{ $t('profile.profile_picture_saving') }}
            </p>
          </div>
        </form>
      </div>
    </div>

    <div v-if="isValidIcon(this.$store.state.user.avatar)" class="input-container">
      <div class="input-header">
        {{ $t('profile.profile_picture_delete') }}
      </div>
      <div style="margin-top: 0px; margin-bottom: 20px;">
        <img style="max-width: 64px; max-height: 64px;" v-bind:src="buildAvatarPath(this.$store.state.user.avatar, 300)">
        <button v-on:click="deleteAvatarClicked">{{ $t('button_delete_text') }}</button>
      </div>
    </div>-->

    <modal class="modal" name="edit-points-help" id="edit-points-help" :scrollable="true" width="100%" height="auto">
      <div class="modal-header">
        <div class="modal-header-left"></div>
        <div class="modal-header-center">{{ $t('betgame_settings.betgame_hint') }}</div>
        <div class="modal-header-right">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('edit-points-help')"
            aria-label="Close modal">
          </button>
        </div>
      </div>
      <div class="modal-body">
        <p>
          {{ $t('betgame_settings.betgame_image_upload_text_2') }}   
        </p>
      </div>
    </modal>
    
  </div>
</template>

<script>

import NavigationBar from './../components/NavigationBar'
import { TOAST_DURATION } from '@/main'
import HintIcon from './../components/icons/HintIcon'

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
  name: 'ProfileEditAvatar',
  components: { NavigationBar, HintIcon },
  data () {
    return {
      currentStatus: null,
      uploadFieldName: 'avatar'
    }
  },
  created () {

  },
  mounted () {
    this.reset();
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },  
  methods: {
    uploadButtonClicked() {
      document.getElementById('imageFileInput').click()
    },
    openHelp() {
      this.$modal.show('edit-points-help');
    },
    updateUser (data) {
      this.$store.dispatch('patchUser', data)
      .then(() => {
        this.$toast.open({ message: this.$i18n.t('save_changes_success'),
          position: 'top',
          duration: TOAST_DURATION,
          type: 'success'
        })
        this.$store.dispatch('updateUserAvatar', null)
        .then(() => {
          this.$router.push('/profile')
        })
      })
      .catch(function (error) {
        this.$toast.open({ message: this.$i18n.t('save_changes_error'),
          position: 'top',
          duration: TOAST_DURATION,
          type: 'error'
        })
      })
      .finally(function () {
      })
    },
    deleteAvatarClicked() {
      this.updateUser({ avatar: null })
    },
    reset() {
      this.currentStatus = STATUS_INITIAL;
    },
    save(file) {
      this.currentStatus = STATUS_SAVING
      const that = this
      this.$store.dispatch('uploadUserAvatar', file)
      .then(() => {
        that.currentStatus = STATUS_SUCCESS
        //that.$router.push('/profile')
      })
      .catch(err => {
        that.currentStatus = STATUS_FAILED
      })
    },
    filesChange(fieldName, fileList) {
      if (!fileList.length) return;
      let file =  fileList[0]
      let fileSizeinMB = file.size/1024/1024
      if(fileSizeinMB < 10) {
        this.save(file);
      } 
    },    
    goBack () {
      this.$router.push('/profile')
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container {
  margin: 16px auto 0;
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.notification {
  &-icon {
    width: 80px;
    height: 80px;
    background: rgba($colorGreyDark, .08);
    margin: 22px auto;
    border-radius: 8px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  p {
    margin-bottom: 26px;
  }

  button {
    margin-top: 16px;
  }
}
</style>
