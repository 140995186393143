<template>
    <div class="rules" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
        
        <NavigationBar>
            <template v-slot:left>
                <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
            </template>
            <template v-slot:center>
              {{ $t('profile.contact') }}
            </template>
        </NavigationBar>
        
        <div>
            <h2>{{ $t('profile.contact') }}</h2>
            <h3>{{ $t('profile.we_are_ready_to_play') }}</h3>
            <p>
                <strong>SPM Sportplatz Media GmbH</strong><br />
                Schleidenstraße 3<br />
                22083 Hamburg
            </p>
            <p>
               E-Mail: <a href="mailto:kontakt@teamtip.net">kontakt@teamtip.net</a>
            </p>
        </div>
      
    </div>
</template>

<script>
  import NavigationBar from '../components/NavigationBar'
  
  export default {
    name: 'BetgameContact',
    components: { NavigationBar },
    computed: {},
    methods: {
      goBack() {
        if (this.$store.getters.loggedIn) {
          this.$router.push({ name: 'profile' }).catch(err => {})
        } else {
          this.$router.go(-1)
        }
      }
    }
  }
  
</script>

<style lang="scss" scoped>
</style>
