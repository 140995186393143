/* eslint-disable */
<template>
  <div class="rules" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
  
    <keep-alive>
      <NavigationBar id="rulesNavigationBarId" ref="rulesNavigationBarRef">
        <template v-slot:left>
          <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
        </template>
        <template v-slot:center>
          {{ $t('conditions') }}
        </template>
      </NavigationBar>
    </keep-alive>
    
    <div v-if="conditionsOfParticipation" v-html="conditionsOfParticipation" style="margin-top: 20px; margin-bottom:  20px;"></div>
    
  </div>
</template>

<script>
  
  import NavigationBar from '../components/NavigationBar'
  export default {
    name: 'Conditions',
    components: { NavigationBar },
    beforeCreate () {
      if(this.$store.state.betgame) {
        this.$store.dispatch('fetchBetgame', this.$store.state.betgame.id)
      }
    },
    methods: {
      openMenu () {
        this.$modal.show('betgame-menu')
      },
      goBack() {
        this.$router.go(-1)
      }
    },
    computed: {
      conditionsOfParticipation () {
        if(this.$store.state.betgame && this.$store.state.betgame.conditions && this.$store.state.betgame.conditions.length > 0) {
          return this.$store.state.betgame.conditions
        }
        return ''
      }
    }
  }
</script>
<style lang="scss" scoped>
  .rules_header {
    margin-top: 30px;
    margin-bottom: 20px;
  }
</style>
