<template>
  <div class="rankingspublic" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">

    <keep-alive>
      <NavigationBar id="rankingspublicNavigationBarId" ref="rankingspublicNavigationBarRef">
        <template v-slot:center>
          <div v-if="betgameId === 3003" class="navigation_title--headline">
            {{ $t('menu.leaderboard') }}
          </div>
          <div v-else class="navigation_title--headline">
            {{ $t('menu.total_ranking') }}
          </div>
          <div v-if="finishedMatches && matchCount && !matchRunning" class="navigation_title--subline">
            {{ $t('after_x_of_y_matches', { finished: finishedMatches, total: matchCount }) }}
          </div>
          <div v-else-if="matchRunning" class="navigation_title--subline">
            <span class="match_result_live">Live</span>
          </div>
        </template>
        <template v-slot:right>
          <div v-on:click="openHelp" class="navigation_icon small">
            <HintIcon v-if="!helpActive"></HintIcon>
            <HintIconInverted v-else ></HintIconInverted>
          </div>
          <div v-on:click="toggleSearch" class="navigation_icon small">
            <SearchIcon></SearchIcon>
          </div>
        </template>
      </NavigationBar>
    </keep-alive>
    
    <div v-if="helpActive" class="input-container" style="margin-top: 20px; margin-bottom: 20px; padding: 0px 0px;">
        <div class="input-infotext">

          <div class="input-infotext" v-if="betmode === 1 && sortmode === 1" style="margin-left: 20px; margin-right: 20px;">{{ $t('rankings.tie_paragraph_1') }}</div>
          <div class="input-infotext" v-else-if="betmode === 1 && sortmode === 2" style="margin-left: 20px; margin-right: 20px;">{{ $t('rankings.tie_paragraph_2') }}</div>
          <div class="input-infotext" v-else-if="betmode === 2" style="margin-left: 20px; margin-right: 20px;">{{ $t('rankings.tie_paragraph_3') }}</div>
          <div class="input-infotext" style="margin-left: 20px; margin-right: 20px;">{{ $t('rankings.table_columns_description') }}</div>
          <div class="table-view">
            <tr class="help_item">
              <td class="column">{{ $t('rankings_table_header_points') }}</td>
              <td>{{ $t('rankings.help_total_points') }}</td>
            </tr>
            <tr class="help_item">
              <td class="column"><img src="../assets/icons/ic_16_points.svg" alt="Puktzahl Tippen" title="Puktzahl Tippen"></td>
              <td>{{ $t('rankings.help_bet_points') }}</td>
            </tr>
            <tr class="help_item">
              <td class="column"><img src="../assets/icons/ic_16_questions.svg" alt="Puktzahl Sonderfragen" title="Puktzahl Sonderfragen"></td>
              <td>{{ $t('rankings.help_questions_points') }}</td>
            </tr>
            <tr class="help_item">
              <td class="column">Pk</td>
              <td>{{ $t('rankings.help_points_correction') }}</td>
            </tr>
            <tr v-if="!isBattle()" class="help_item">
              <td class="column"><img src="../assets/icons/ic_16_star.svg" alt="Spieltagssiege" title="Spieltagssiege"></td>
              <td>{{ $t('rankings.help_matchday_wins') }}</td>
            </tr>
            <tr v-if="betmode === 1" class="help_item">
              <td class="column">Exa</td>
              <td>{{ $t('rankings.help_exact_bets_count') }}</td>
            </tr>
            <tr v-if="betmode === 1" class="help_item">
              <td class="column">Dif</td>
              <td>{{ $t('rankings.help_bet_goal_diff_count') }}</td>
            </tr>
            <tr v-if="betmode === 1" class="help_item">
              <td class="column">Ten</td>
              <td>{{ $t('rankings.help_bet_tendency_count') }}</td>
            </tr>
            <tr class="help_item">
              <td class="column">0Pk</td>
              <td>{{ $t('rankings.help_zero_points_count') }}</td>
            </tr>
            <tr class="help_item">
              <td class="column">Anz</td>
              <td>{{ $t('rankings.help_bet_count') }}</td>
            </tr>
          </div>
        </div>
    </div>
    
    <RankingsTableScrollingPublic v-if="hasSeasonStarted()" :searchActive="searchActive"></RankingsTableScrollingPublic>
    <BetgameUsersTable v-else-if="(!hasSeasonStarted()) && betgameUsers.length > 0" :betgameUsers="betgameUsers" :searchActive="searchActive"></BetgameUsersTable>
    
    <modal class="modal" name="rankings-public-help" id="rankings-public-help" :scrollable="true" width="100%" height="auto">
      <div class="modal-header">
        <div class="modal-header-left"></div>
        <div class="modal-header-center"></div>
        <div class="modal-header-right">
          <button
              type="button"
              class="btn-close"
              @click="$modal.hide('rankings-public-help')"
              aria-label="Close modal">
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div class="table-view">
          <tr class="help_item">
            <td class="column">{{ $t('rankings_table_header_points') }}</td>
            <td>{{ $t('rankings.help_total_points') }}</td>
          </tr>
          <tr class="help_item">
            <td class="column"><img src="../assets/icons/ic_16_points.svg" alt="Puktzahl Tippen" title="Puktzahl Tippen"></td>
            <td>{{ $t('rankings.help_bet_points') }}</td>
          </tr>
          <tr class="help_item">
            <td class="column"><img src="../assets/icons/ic_16_questions.svg" alt="Puktzahl Sonderfragen" title="Puktzahl Sonderfragen"></td>
            <td>{{ $t('rankings.help_questions_points') }}</td>
          </tr>
          <tr class="help_item">
            <td class="column">Pk</td>
            <td>{{ $t('rankings.help_points_correction') }}</td>
          </tr>
          <tr v-if="!isBattle()" class="help_item">
            <td class="column"><img src="../assets/icons/ic_16_star.svg" alt="Spieltagssiege" title="Spieltagssiege"></td>
            <td>{{ $t('rankings.help_matchday_wins') }}</td>
          </tr>
          <tr v-if="betmode === 1" class="help_item">
            <td class="column">Exa</td>
            <td>{{ $t('rankings.help_exact_bets_count') }}</td>
          </tr>
          <tr v-if="betmode === 1" class="help_item">
            <td class="column">Dif</td>
            <td>{{ $t('rankings.help_bet_goal_diff_count') }}</td>
          </tr>
          <tr v-if="betmode === 1" class="help_item">
            <td class="column">Ten</td>
            <td>{{ $t('rankings.help_bet_tendency_count') }}</td>
          </tr>
          <tr class="help_item">
            <td class="column">0Pk</td>
            <td>{{ $t('rankings.help_zero_points_count') }}</td>
          </tr>
          <tr class="help_item">
            <td class="column">Anz</td>
            <td>{{ $t('rankings.help_bet_count') }}</td>
          </tr>
        </div>
      </div>
    </modal>
    
  </div>
</template>

<script>
  //import { mapState } from 'vuex'
  import RankingsTableScrollingPublic from '../components/RankingsTableScrollingPublic'
  import NavigationBar from './../components/NavigationBar'
  import BetgameUsersTable from '@/components/BetgameUsersTable'
  import HintIcon from './../components/icons/HintIcon'
  import SearchIcon from './../components/icons/SearchIcon'
  import HintIconInverted from '@/components/icons/HintIconInverted'
  export default {
    name: 'rankingspublic',
    components: { HintIconInverted, RankingsTableScrollingPublic, NavigationBar, BetgameUsersTable, HintIcon, SearchIcon },
    data () {
      return {
        helpActive: false,
        searchActive: false,
      }
    },
    beforeCreate () {
      const domain = document.domain
      if(domain === 'pz.teamtip.net') {
        window.localStorage.setItem('spmau', 'https://pz.teamtip.net')
        window.localStorage.setItem('spmbgid', '12')
        this.$store.dispatch('setCurrentSeason', { id: 13, name: '2022/23', is_current: true }).then(() => {
          this.$store.dispatch('fetchSchedule', 1)
        })
        this.$store.dispatch('fetchBetgame', '12').then(() => {
        })
      } else if(domain === 'polygon.teamtip.net') {
        window.localStorage.setItem('spmau', 'https://polygon.teamtip.net')
        window.localStorage.setItem('spmbgid', '3003')
        this.$store.dispatch('setCurrentSeason', { id: 14, name: '2023/24', is_current: true }).then(() => {
          this.$store.dispatch('fetchSchedule', 36)
        })
        this.$store.dispatch('fetchBetgame', '3003').then(() => {
        })
      }
    },
    created() {
      //if (this.$store.state.rankings == null || this.$store.state.rankings.length == 0) {
      //  this.$store.dispatch('fetchRanking', [this.$store.state.betgameId, this.$store.state.leagueId])
      //}
    },
    computed: {
      betgameId() {
        if(this.$store.state.betgame) {
          return parseInt(this.$store.state.betgame.id)
        }
        return 0
      },
      sortmode() {
        return this.$store.state.betgame.sort_mode || 1
      },
      betmode() {
        if(!this.$store.state.betgame) {
          return 1
        }
        return this.$store.state.betgame.bet_mode || 1
      },
      betgameUsers () {
        if(this.$store.state.betgame && (!this.$store.state.betgameUsers || this.$store.state.betgameUsers.length === 0)) {
          this.$store.dispatch('fetchBetgameUsers', this.$store.state.betgame.id)
        }
        return this.$store.state.betgameUsers
      },
      matchRunning() {
        return this.$store.getters.matchWithLiveGoals
      },
      matchCount() {
        return this.$store.state.matches.length
      },
      finishedMatches() {
        return this.$store.getters.finishedMatches
      }
    },
    methods: {
      //hasSeasonStartedPublic () {
      //  return this.$store.getters.startedMatches > 0 || window.localStorage.getItem('spmbgid') === '12'
      //},
      isBattle() {
        return this.$store.state.betgame && this.$store.state.betgame.type && parseInt(this.$store.state.betgame.type) === TYPE_BATTLE_ID
      },
      toggleSearch() {
        this.searchActive = !this.searchActive
      },
      openMenu () {
        this.$modal.show('betgame-menu')
      },
      openHelp() {
        this.helpActive = !this.helpActive
        //this.$modal.show('rankings-help');
      },
    }
  }
</script>

<style lang="scss" scoped>
  ul {
    list-style: none;
  }
  .col_user {
    text-align: left;
    overflow: hidden;
    width: 50%;
  }
  .col_value {
    text-align: right;
    overflow: hidden;
  }
  .col_points {
    margin-right: 10px;
  }
  .match_result_live {
    color: var(--color-primary);
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    padding-right: 12px;
    font-size: 14px;
    opacity: .24;
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    -webkit-animation: live-data-v-43a5ba3a 3.4s infinite;
    animation: live-data-v-43a5ba3a 3.4s infinite;
  
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 5px;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background: var(--color-primary);
    }
  }
  @media screen and (max-width: 567px) {
    .mobile-hidden {
      display: none;
    }
  }
  .help_item {
    padding: 5px 10px;
    color: gray;
  }
  .column {
    padding: 0 10px;
  }
</style>
