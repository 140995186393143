<template>
  <div class="orderAgreement" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">

    <NavigationBar>
      <template v-slot:left>
        <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
      </template>
      <template v-slot:center>
        {{ $t('profile.order_processing_agreement') }}
      </template>
    </NavigationBar>

    <div v-if="userlang === 'de'">
      <br>

      <h1>Auftragsverarbeitungsvereinbarung gemäß Art. 28, 29 DSGVO</h1>
      <br>

      <p><b>SPM Sportplatz-Media GmbH</b> (nachfolgend „Auftragnehmer“), Schleidenstraße 3, 22083 Hamburg, betreibt die
        Website https://teamtip.net/ und die dazugehörige TeamTip-App. Mit dieser Anwendung (nachfolgend „TeamTip“)
        können
        <b>Tippspielleiter/Admins</b> (nachfolgend „Auftraggeber“) ihre Tipprunden als Administrator organisieren. Der
        Auftraggeber verwaltet die personenbezogenen Daten eigenverantwortlich.</p>
      <p>Der Auftragnehmer verarbeitet personenbezogene Daten im Auftrag des Auftraggebers i.S.d. Art. 4 Nr. 8 und Art.
        28 der Verordnung (EU) 2016/679 – Datenschutz-Grundverordnung (DSGVO). Diese Auftragsverarbeitungsvereinbarung
        konkretisiert die datenschutzrechtlichen Verpflichtungen der Vertragsparteien, die sich aus der im Hauptvertrag
        beschriebenen Auftragsdatenverarbeitung ergeben. Sie findet Anwendung auf alle Tätigkeiten, die mit dem
        Hauptvertrag in Zusammenhang stehen und bei denen Mitarbeiter von Auftragnehmer oder durch Auftragnehmer
        beauftragte Dritte mit personenbezogenen Daten des Auftraggebers in Berührung kommen können.</p>

      <h3>§ 1 Definitionen</h3>
      <p>1.1 Personenbezogene Daten sind Einzelangaben über persönliche oder sachliche Verhältnisse einer bestimmten
        oder bestimmbaren natürlichen Person (Art. 4 Nr. 1 DSGVO).</p>
      <p>1.2 Datenverarbeitung im Auftrag ist die Erhebung, Verarbeitung und Nutzung personenbezogener Daten i.S.d. Art.
        4 Nr. 2 DSGVO durch den Auftragnehmer im Auftrag des Auftraggebers.</p>
      <p>1.3 Weisung bedeutet alle Anweisungen, die der Auftraggeber an den Auftragnehmer erteilt und mit denen der
        Auftragnehmer zur Ausführung einer bestimmten Handlung in Bezug auf personenbezogene Daten aufgefordert wird (z.
        B. Anonymisierung, Sperrung, Löschung, Herausgabe) Die Weisungen werden anfänglich durch den Hauptvertrag
        festgelegt und können vom Auftraggeber danach in schriftlicher Form durch einzelne Weisungen geändert, ergänzt
        oder ersetzt werden (Einzelweisung).</p>
      <p>1.4 Erheben, Verarbeiten und Nutzen von personenbezogenen Daten hat die in Art. 4 Nr. 2 DSGVO definierte
        Bedeutung.</p>

      <h3>§ 2 Anwendungsbereich, Verantwortlichkeit</h3>
      <p>Auftragnehmer verarbeitet personenbezogene Daten im Auftrag des Auftraggebers. Dies umfasst Tätigkeiten, die im
        Hauptvertrag und in der Leistungsbeschreibung konkretisiert sind. Der Auftraggeber ist im Rahmen dieses
        Vertrages für die Einhaltung der gesetzlichen Bestimmungen der Datenschutzgesetze, insbesondere für die
        Rechtmäßigkeit der Datenweitergabe an Auftragnehmer sowie für die Rechtmäßigkeit der Datenverarbeitung allein
        verantwortlich („Verantwortlicher“ im Sinne Art. 4 Nr. 7 DSGVO). Dem Auftragnehmer steht das Recht zu, den
        Auftraggeber darauf hinzuweisen, wenn eine seiner Meinung nach rechtlich unzulässige Datenverarbeitung
        Gegenstand des Auftrags und/oder einer Weisung ist.</p>


      <h3>§ 3 Gegenstand und Dauer</h3>
      <p>3.1 Der Gegenstand des Auftrags ergibt sich aus dem Hauptvertrag/Leistungsbeschreibung auf den/die hier
        verwiesen wird.</p>
      <p>3.2 Die Dauer dieses Auftrags (Laufzeit) entspricht der Laufzeit des Hauptvertrages.</p>
      <p>3.3 Das Recht zur außerordentlichen Kündigung bleibt hiervon unberührt.</p>

      <h3>§ 4 Umfang, Art und Zweck der vorgesehenen Erhebung, Verarbeitung oder Nutzung von Daten</h3>
      <p>Umfang, Art und Zweck der Erhebung, Verarbeitung und Nutzung personenbezogener Daten durch Auftragnehmer für
        den Auftraggeber sind konkret beschrieben in dem Hauptvertrag.</p>

      <h3>§ 5 Art der Daten</h3>
      <p>Gegenstand der Erhebung, Verarbeitung und Nutzung personenbezogener Daten sind folgende Datenarten/ -kategorien
        (Aufzählung/ Beschreibung der Datenkategorien):</p>
      <br>
      <p>Art der Daten (allgemein):</p>
      <ul>
        <li>Vor- und Nachname</li>
        <li>Benutzername</li>
        <li>E-Mail-Adresse</li>
        <li>Tippverhalten</li>
      </ul>

      <h3>§ 6 Kreis der Betroffenen</h3>
      <p>Der Kreis der durch den Umgang mit ihren personenbezogenen Daten im Rahmen dieses Auftrags Betroffenen
        umfasst:</p>
      <ul>
        <li>Tipprundenleiter/-Admin</li>
      </ul>

      <h3>§ 7 Berichtigung, Löschung, Sperrung und Herausgabe von Daten</h3>
      <p>7.1 Die im Rahmen des Auftrages verarbeiteten Daten, dürfen von Auftragnehmer nur nach Weisung des
        Auftraggebers berichtigt, gelöscht oder gesperrt werden.</p>
      <p>7.2 Der Auftraggeber kann jederzeit während und nach Beendigung dieses Auftrages bzw. des Hauptvertrages die
        Berichtigung, Löschung, Sperrung und Herausgabe von Daten verlangen.</p>


      <h3>§ 8 Technisch-organisatorische Maßnahmen</h3>
      <p>8.1 Auftragnehmer wird in seinem Verantwortungsbereich die innerbetriebliche Organisation so gestalten, dass
        sie den besonderen Anforderungen des Datenschutzes gerecht wird.</p>
      <p>8.2 Auftragnehmer wird technische und organisatorische Maßnahmen zur angemessenen Sicherung der Daten des
        Auftraggebers vor Missbrauch und Verlust treffen, die den Anforderungen der Datenschutzgrundverordnung (Art. 24,
        32 DSGVO) entsprechen. Dies beinhaltet insbesondere, sofern dies angemessen ist,</p>
      <ul>
        <li>Unbefugten den Zutritt zu Datenverarbeitungsanlagen, mit denen die personenbezogenen Daten verarbeitet und
          genutzt werden, zu verwehren (Zutrittskontrolle),
        </li>
        <li>zu verhindern, dass Datenverarbeitungssysteme von Unbefugten genutzt werden können (Zugangskontrolle),</li>
        <li>dafür Sorge zu tragen, dass die zur Benutzung eines Datenverarbeitungssystems Berechtigten ausschließlich
          auf die ihrer Zugriffsberechtigung unterliegenden Daten zugreifen können, und dass personenbezogene Daten bei
          der Verarbeitung, Nutzung und nach der Speicherung nicht unbefugt gelesen, kopiert, verändert oder entfernt
          werden können (Zugriffskontrolle),
        </li>
        <li>dafür Sorge zu tragen, dass personenbezogene Daten bei der elektronischen Übertragung oder während ihres
          Transports oder ihrer Speicherung auf Datenträger nicht unbefugt gelesen, kopiert, verändert oder entfernt
          werden können, und dass überprüft und festgestellt werden kann, an welche Stellen eine Übermittlung
          personenbezogener Daten durch Einrichtungen zur Datenübertragung vorgesehen ist (Weitergabekontrolle),
        </li>
        <li>dafür Sorge zu tragen, dass nachträglich geprüft und festgestellt werden kann, ob und von wem
          personenbezogene Daten in Datenverarbeitungssysteme eingegeben, verändert oder entfernt worden sind
          (Eingabekontrolle),
        </li>
        <li>dafür Sorge zu tragen, dass personenbezogene Daten, die im Auftrag verarbeitet werden, nur entsprechend den
          Weisungen des Auftraggebers verarbeitet werden können (Auftragskontrolle),
        </li>
        <li>dafür Sorge zu tragen, dass personenbezogene Daten gegen zufällige Zerstörung oder Verlust geschützt sind
          (Verfügbarkeitskontrolle),
        </li>
        <Li>dafür Sorge zu tragen, dass zu unterschiedlichen Zwecken erhobene Daten getrennt verarbeitet werden können
          (Trennungskontrolle),
        </Li>
        <li>die Pseudonymisierung und Verschlüsselung personenbezogener Daten,</li>
        <Li>die Fähigkeit, die Vertraulichkeit, Integrität, Verfügbarkeit und Belastbarkeit der Systeme und Dienste im
          Zusammenhang mit der Verarbeitung auf Dauer sicherzustellen,
        </Li>
        <li>die Fähigkeit, die Verfügbarkeit der personenbezogenen Daten und den Zugang zu ihnen bei einem physischen
          oder technischen Zwischenfall rasch wiederherzustellen,
        </li>
        <Li>ein Verfahren zur regelmäßigen Überprüfung, Bewertung und Evaluierung der Wirksamkeit der technischen und
          organisatorischen Maßnahmen zur Gewährleistung der Sicherheit der Verarbeitung.
        </Li>
      </ul>
      <br>
      <p>8.3 Die technischen und organisatorischen Maßnahmen unterliegen dem technischen Fortschritt und der
        Weiterentwicklung. Insoweit ist es Auftragnehmer gestattet, alternative adäquate Maßnahmen umzusetzen. Dabei
        darf das Sicherheitsniveau der festgelegten Maßnahmen nicht unterschritten werden. Wesentliche Änderungen sind
        zu dokumentieren.</p>


      <h3>§ 9 Sonstige Pflichten des Auftragnehmers</h3>
      <p>9.1 Der Auftraggeber hat das Recht, jederzeit ergänzende Weisungen über Art, Umfang und Verfahren der
        Datenverarbeitung gegenüber dem Auftragnehmer zu erteilen. Weisungen können in Textform (z.B. E-Mail)
        erfolgen.</p>
      <p>9.2 Auftragnehmer darf Daten nur im Rahmen des Auftrages und der Weisungen des Auftraggebers erheben,
        verarbeiten oder nutzen, es sei denn, dass er nach dem Unionsrecht oder dem Recht der Mitgliedstaaten zur
        Verarbeitung der Daten verpflichtet ist.</p>
      <p>9.3 Regelungen über eine etwaige Vergütung von Mehraufwänden, die durch ergänzende Weisungen des Auftraggebers
        beim Auftragnehmer entstehen, bleiben unberührt.</p>
      <p>9.4 Auftragnehmer muss den Auftraggeber von Ausnahmen von der Weisungspflicht aufgrund für ihn geltendem Recht
        unterrichten, es sei denn gerade dieses Recht verbietet solche Mitteilung wegen eines wichtigen öffentlichen
        Interesses.</p>
      <p>9.5 Auftragnehmer bestellt schriftlich – soweit gesetzlich vorgeschrieben – einen Datenschutzbeauftragten, der
        seine Tätigkeit gemäß Art. 37, 38, 39 DSGVO ausüben kann. Dessen Kontaktdaten werden dem Auftraggeber zum Zweck
        der direkten Kontaktaufnahme mitgeteilt.</p>
      <p>9.6 Auftragnehmer stellt sicher, dass die mit der Verarbeitung der personenbezogenen Daten des Auftraggebers
        befassten Mitarbeiter auf das Datengeheimnis verpflichtet werden (Art. 29 DSGVO) und in die Schutzbestimmungen
        der DSGVO eingewiesen worden sind. Das Datengeheimnis besteht auch nach Beendigung der Tätigkeit fort.</p>
      <p>9.7 Auftragnehmer unterrichtet den Auftraggeber unverzüglich, sofern er der Auffassung ist, dass eine Weisung
        des Auftraggebers gegen geltendes Datenschutzrecht verstößt.</p>
      <p>9.8 Auftragnehmer unterrichtet den Auftraggeber bei schwerwiegenden Störungen des Betriebsablaufes, bei
        Verdacht auf Datenschutzverletzungen oder andere Unregelmäßigkeiten bei der Verarbeitung der Daten des
        Auftraggebers. Dies gilt auch für etwaige Kontrollhandlungen, Maßnahmen der Aufsichtsbehörde nach Art. 51 – 59
        DSGVO oder Ermittlungen nach Art. 83, 84 DSGVO.</p>
      <p>9.9 Es ist bekannt, dass den Auftraggeber nach § Art. 33 DSGVO Informationspflichten im Falle der
        unrechtmäßigen Übermittlung oder Kenntniserlangung von bestimmten personenbezogenen Daten treffen können.
        Deshalb sind solche Vorfälle ohne Ansehen der Verursachung unverzüglich dem Auftraggeber mitzuteilen. Die
        Meldung des Auftragnehmers an den Auftraggeber muss insbesondere folgende Informationen beinhalten:</p>
      <ul>
        <li>eine Beschreibung der Art der Verletzung des Schutzes personenbezogener Daten, soweit möglich mit Angabe der
          Kategorien und der ungefähren Zahl der betroffenen Personen, der betroffenen Kategorien und der ungefähren
          Zahl der betroffenen personenbezogenen Datensätze;
        </li>
        <li>eine Beschreibung der von dem Auftragnehmer ergriffenen oder vorgeschlagenen Maßnahmen zur Behebung der
          Verletzung des Schutzes personenbezogener Daten und gegebenenfalls Maßnahmen zur Abmilderung ihrer möglichen
          nachteiligen Auswirkungen.
        </li>
      </ul>
      <p>Auftragnehmer hat im Benehmen mit dem Auftraggeber angemessene Maßnahmen zur Sicherung der Daten sowie zur
        Minderung möglicher nachteiliger Folgen für Betroffene zu ergreifen. Soweit den Auftraggeber Pflichten nach Art.
        33 DSGVO treffen, hat der Auftragnehmer ihn hierbei zu unterstützen.</p>
      <p>9.10 Auftragnehmer ist verpflichtet, dem Auftraggeber jederzeit Auskünfte zu erteilen, soweit seine Daten und
        Unterlagen von einer Verletzung des Schutzes personenbezogener Daten betroffen sind. Die datenschutzkonforme
        Vernichtung von Material übernimmt Auftragnehmer auf Grund einer Einzelbeauftragung durch den Auftraggeber. In
        besonderen, vom Auftraggeber zu bestimmenden Fällen erfolgt eine Aufbewahrung bzw. Übergabe.</p>
      <p>9.11 Der Auftragnehmer wird den Auftraggeber darüber informieren, wenn Betroffene ihre Betroffenenrechte
        gegenüber dem Auftragnehmer geltend machen.</p>


      <h3>§ 10 Rechte und Pflichten des Auftraggebers</h3>
      <p>10.1 Der Auftraggeber ist für die Beurteilung der Zulässigkeit der Datenerhebung, -verarbeitung oder -nutzung
        sowie für die Wahrung der Reche der Betroffenen allein verantwortlich.</p>
      <p>10.2 Der Auftraggeber hat Auftragnehmer unverzüglich und vollständig zu informieren, wenn er bei der Prüfung
        der Auftragsergebnisse Fehler oder Unregelmäßigkeiten bzgl. datenschutzrechtlicher Bestimmungen feststellt.</p>
      <p>10.3 Die Pflicht zur Führung des Verarbeitungsverzeichnisses nach Art.30 DSGVO liegt beim Auftraggeber.</p>
      <p>10.4 Dem Auftraggeber obliegen die aus Art. 33 DSGVO resultierenden Informationspflichten.</p>
      <p>10.5 Der Auftraggeber legt die Maßnahmen zur Rückgabe der überlassenen Datenträger und/oder Löschung der
        gespeicherten Daten nach Beendigung des Vertrages vertraglich oder durch Weisung fest.</p>


      <h3>§ 11 Anfragen Betroffener an den Auftraggeber</h3>
      <p>Ist der Auftraggeber auf Grund geltender Datenschutzgesetze gegenüber einer Einzelperson verpflichtet,
        Auskünfte zur Erhebung, Verarbeitung oder Nutzung von Daten dieser Person zu geben, wird Auftragnehmer den
        Auftraggeber dabei unterstützen, diese Informationen bereit zu stellen, vorausgesetzt der Auftraggeber hat
        Auftragnehmer hierzu schriftlich aufgefordert.</p>


      <h3>§ 12 Zusammenarbeit mit der Aufsichtsbehörde</h3>
      <p>Auftraggeber und Auftragnehmer und gegebenenfalls deren Vertreter arbeiten auf Anfrage mit der Aufsichtsbehörde
        bei der Erfüllung ihrer Aufgaben zusammen.</p>


      <h3>§ 13 Kontrollpflichten des Auftraggebers</h3>
      <p>Der Auftraggeber überzeugt sich vor der Aufnahme der Datenverarbeitung und sodann regelmäßig von den
        technischen und organisatorischen Maßnahmen von Auftragnehmer und dokumentiert das Ergebnis. Hierfür kann er
        etwa Selbstauskünfte des Auftragnehmers einholen oder auf eigene Kosten ein Audit durchführen lassen. In Falle
        eines Audits trägt Auftraggeber die Kosten der Mitarbeiter von Auftragnehmer, die am Audit mitwirken müssen.</p>


      <h3>§ 14 Subunternehmer</h3>
      <p>14.1 Die Weitergabe von Aufträgen im Rahmen dieses Vertrages und der in §§ 3, 4, 5, 6 konkretisierten
        Tätigkeiten an Subunternehmer ist möglich, sofern Auftragnehmer sicherstellt, dass der Subunternehmer die
        Pflichten aus diesem Vertrag gegenüber dem Auftragnehmer übernimmt. Erteilt Auftragnehmer Aufträge an
        Unterauftragnehmer, so obliegt es Auftragnehmer, seine Pflichten aus diesem Vertrag dem Unterauftragnehmer zu
        übertragen. Es gelten insbesondere die Anforderungen an Vertraulichkeit, Datenschutz und Datensicherheit
        zwischen den Vertragspartnern dieses Vertrages.</p>
      <p>14.2 Dem Auftraggeber sind Kontroll- und Überprüfungsrechte entsprechend § 13 einzuräumen. Durch schriftliche
        Aufforderung ist der Auftraggeber berechtigt, von Auftragnehmer Auskunft über den wesentlichen Vertragsinhalt
        und die Umsetzung der datenschutzrelevanten Verpflichtungen des Unterauftragnehmers zu erhalten,
        erforderlichenfalls auch durch Einsicht in die relevanten Vertragsunterlagen.</p>


      <h3>§ 15 Vertraulichkeitsverpflichtung</h3>
      <p>Der Auftragnehmer ist bei der Verarbeitung von Daten für den Auftraggeber zur Wahrung der Vertraulichkeit über
        Daten, die er im Zusammenhang mit dem Auftrag erhält bzw. zur Kenntnis erlangt, verpflichtet. Der Auftragnehmer
        verpflichtet sich, die gleichen Geheimnisschutzregeln zu beachten, wie sie dem Auftraggeber obliegen. Der
        Auftraggeber ist verpflichtet, dem Auftragnehmer etwaige besondere Geheimnisschutzregeln mitzuteilen.</p>

      <h3>§ 16 Allgemeine Regelungen, Informationspflichten, Schriftformklausel, Rechtswahl</h3>
      <p>16.1 Sollten die Daten des Auftraggebers bei Auftragnehmer durch Pfändung oder Beschlagnahme, durch ein
        Insolvenz- oder Vergleichsverfahren oder durch sonstige Ereignisse oder Maßnahmen Dritter gefährdet werden, so
        hat Auftragnehmer den Auftraggeber unverzüglich darüber zu informieren. Auftragnehmer wird alle in diesem
        Zusammenhang Verantwortlichen unverzüglich darüber informieren, dass die Hoheit und das Eigentum an den Daten
        ausschließlich beim Auftraggeber als „verantwortlicher Stelle“ im Sinne der DSGVO liegen.</p>
      <p>16.2 Die Verarbeitung und Nutzung der Daten findet ausschließlich im Gebiet der Bundesrepublik Deutschland, in
        einem Mitgliedsstaat der Europäischen Union oder in einem anderen Vertragsstaat des Abkommens über den
        Europäischen Wirtschaftsraum statt. Jede Verlagerung in ein Drittland bedarf der vorherigen Zustimmung des
        Auftraggebers und darf nur erfolgen, wenn die besonderen Voraussetzungen der Art. 44, 45, 46 DSGVO erfüllt sind.
        Dem Auftraggeber ist bekannt, dass der Auftragnehmer sich der Dienstleistungen des Subauftragnehmers Amazon Web
        Services, Inc., P.O. Box 81226, Seattle, WA 98108-122 (AWS) bedient. AWS bietet Hostingleistungen auch innerhalb
        der EU (u.a. in Deutschland) an. Für den Fall einer Datenverarbeitung in den USA bietet AWS hinreichende
        Garantien zur datenschutzkonformen Verarbeitung i.S.d. Art. 46 DSGVO durch eine US/EU Privacy Shield
        Zertifizierung.</p>
      <p>16.3 Änderungen und Ergänzungen dieses Vertrages und aller ihrer Bestandteile - einschließlich etwaiger
        Zusicherungen von Auftragnehmer - bedürfen einer schriftlichen Vereinbarung und des ausdrücklichen Hinweises
        darauf, dass es sich um eine Änderung bzw. Ergänzung dieser Regelungen handelt. Dies gilt auch für den Verzicht
        auf dieses Formerfordernis.</p>
      <p>16.4 Es gilt deutsches Recht, mit Ausnahme des Kollisionsrechts.</p>

      <br><br>
      <p>
        Stand: Februar 2021
      </p>
      <br><br>

    </div>

    <div v-else>
      <br>

      <h1>Order processing agreement pursuant to Art. 28, 29 DSGVO</h1>
      <br>

      <p><b>SPM Sportplatz-Media GmbH</b> (hereinafter "Contractor"), Schleidenstraße 3, 22083 Hamburg, operates the
        website https://teamtip.net/ and the associated TEAMTIP app. With this application (hereinafter "TEAMTIP"), <b>betting
          managers/admins</b> (hereinafter "clients") can organise their betting rounds as administrators. The Principal
        manages the personal data on his own responsibility.</p>
      <p>The contractor processes personal data on behalf of the principal within the meaning of Art. 4 No. 8 and Art.
        28 of Regulation (EU) 2016/679 - General Data Protection Regulation (DSGVO). This commissioned processing
        agreement specifies the data protection obligations of the contracting parties arising from the commissioned
        data processing described in the main contract. It applies to all activities related to the main contract in
        which employees of the contractor or third parties commissioned by the contractor may come into contact with
        personal data of the client.</p>

      <h3>§ 1 Definitions</h3>
      <p>1.1 Personal data is individual information about personal or factual circumstances of an identified or
        identifiable natural person (Art. 4 No. 1 GDPR).</p>
      <p>1.2 Data processing on behalf means the collection, processing and use of personal data as defined in Art. 4
        No. 2 GDPR by the contractor on behalf of the principal.</p>
      <p>1.3 Instruction means any instruction issued by the Principal to the Contractor requiring the Contractor to
        perform a specific act in relation to personal data (e.g. anonymisation, blocking, deletion, surrender). The
        instructions are initially set out in the main contract and may subsequently be amended, supplemented or
        replaced by the Principal in writing by individual instructions (individual instruction).</p>
      <p>1.4 Collection, processing and use of personal data has the meaning defined in Art. 4 No. 2 DSGVO.</p>

      <h3>§ 2 Scope of application, responsibility</h3>
      <p>The contractor processes personal data on behalf of the client. This includes activities that are specified in
        the main contract and in the service description. Within the scope of this contract, the Principal shall be
        solely responsible for compliance with the statutory provisions of the data protection laws, in particular for
        the lawfulness of the transfer of data to the Contractor as well as for the lawfulness of the data processing
        ("Responsible Party" within the meaning of Art. 4 No. 7 DSGVO). The contractor shall be entitled to point out to
        the client if data processing which it considers to be legally inadmissible is the subject of the order and/or
        an instruction.</p>


      <h3>§ 3 Subject matter and duration</h3>
      <p>3.1 The subject matter of the order results from the main contract/service description to which reference is
        made here.</p>
      <p>3.2 The duration of this order (term) corresponds to the term of the main contract.</p>
      <p>3.3 The right to extraordinary termination remains unaffected.</p>

      <h3>§ 4 Scope, nature and purpose of the intended collection, processing or use of data</h3>
      <p>The scope, type and purpose of the collection, processing and use of personal data by the contractor for the
        client are specifically described in the main contract.</p>

      <h3>§ 5 Nature of the data</h3>
      <p>The following types/categories of data (enumeration/description of the categories of data) are the subject of
        the collection, processing and use of personal data:</p>
      <br>
      <p>Type of data (general):</p>
      <ul>
        <li>First and last name</li>
        <li>User name</li>
        <li>E-mail address</li>
        <li>Typing behaviour</li>
      </ul>

      <h3>§ 6 Circle of data subjects</h3>
      <p>The group of persons affected by the handling of their personal data within the scope of this contract
        includes:</p>
      <ul>
        <li>Betting Round Leader/Admin</li>
      </ul>

      <h3>§ 7 Correction, deletion, blocking and release of data</h3>
      <p>7.1 The data processed within the scope of the order may only be corrected, deleted or blocked by the
        contractor on the instructions of the principal.</p>
      <p>7.2 The principal may demand the correction, deletion, blocking and release of data at any time during and
        after the termination of this order or the main contract.</p>


      <h3>§ 8 Technical and organisational measures</h3>
      <p>8.1 The contractor shall organise the internal organisation in his area of responsibility in such a way that it
        meets the special requirements of data protection.</p>
      <p>8.2 Contractor shall take technical and organisational measures to adequately protect the Customer's data from
        misuse and loss which comply with the requirements of the General Data Protection Regulation (Art. 24, 32
        DSGVO). This includes in particular, where appropriate,</p>
      <ul>
        <li>denying unauthorised persons access to data processing systems with which the personal data are processed
          and used (access control),
        </li>
        <li>to prevent data processing systems from being used by unauthorised persons (access control),</li>
        <li>to ensure that those authorised to use a data processing system can only access the data subject to their
          access authorisation and that personal data cannot be read, copied, modified or removed without authorisation
          during processing, use and after storage (access control),
        </li>
        <li>ensure that personal data cannot be read, copied, altered or removed by unauthorised persons during
          electronic transmission or during their transport or storage on data media, and that it is possible to verify
          and establish to which bodies personal data are intended to be transmitted by data transmission equipment
          (transfer control),
        </li>
        <li>to ensure that it is possible to check and establish retrospectively whether and by whom personal data have
          been entered into, altered or removed from data processing systems (input control),
        </li>
        <li>to ensure that personal data processed on behalf of a client can only be processed in accordance with the
          client's instructions (client control),
        </li>
        <li>ensure that personal data are protected against accidental destruction or loss (availability control),</li>
        <li>ensure that data collected for different purposes can be processed separately (separation control),</li>
        <Li>the pseudonymisation and encryption of personal data,</Li>
        <li>the ability to ensure the confidentiality, integrity, availability and resilience of systems and services
          related to the processing on an ongoing basis,
        </li>
        <Li>the ability to rapidly restore the availability of and access to personal data in the event of a physical or
          technical incident,
        </Li>
        <li>a procedure for periodic review, assessment and evaluation of the effectiveness of the technical and
          organisational measures to ensure the security of the processing.
        </li>
      </ul>
      <br>
      <p>8.3 The technical and organisational measures are subject to technical progress and further development. In
        this respect, contractors are permitted to implement alternative adequate measures. In doing so, the security
        level of the specified measures must not be undercut. Significant changes shall be documented.</p>


      <h3>§ 9 Other obligations of the contractor</h3>
      <p>9.1 The Client has the right to issue supplementary instructions to the Contractor at any time regarding the type, scope and procedure of data processing. Instructions may be given in text form (e.g. e-mail).</p>
      <p>9.2 The contractor may only collect, process or use data within the scope of the order and the client's instructions, unless he is obliged to process the data under Union law or the law of the member states.</p>
      <p>9.3 Regulations on any remuneration of additional expenses incurred by the contractor due to supplementary instructions of the client shall remain unaffected.</p>
      <p>9.4 The contractor must inform the client of any exceptions to the obligation to issue instructions on the basis of the law applicable to it, unless this law prohibits such notification on the grounds of an important public interest.</p>
      <p>9.5 Contractor shall appoint in writing - to the extent required by law - a data protection officer who may perform his duties in accordance with Art. 37, 38, 39 DSGVO. The contact details of the data protection officer shall be communicated to the Client for the purpose of direct contact.</p>
      <p>9.6 The Contractor shall ensure that the employees involved in the processing of the Client's personal data are bound to data secrecy (Art. 29 DSGVO) and have been instructed in the protective provisions of the DSGVO. The data secrecy shall continue to exist after termination of the activity.</p>
      <p>9.7 The Contractor shall inform the Client without delay if it is of the opinion that an instruction of the Client violates applicable data protection law.</p>
      <p>9.8 The Contractor shall inform the Client in the event of serious disruptions to the operational process, suspected data protection violations or other irregularities in the processing of the Client's data. This shall also apply to any control actions, measures of the supervisory authority pursuant to Art. 51 - 59 DSGVO or investigations pursuant to Art. 83, 84 DSGVO.</p>
      <p>9.9 It is known that the Client may be subject to information obligations pursuant to Art. 33 DSGVO in the event of the unlawful transmission or acquisition of knowledge of certain personal data. Therefore, such incidents must be reported to the Principal without delay, regardless of the cause. The notification by the contractor to the principal must in particular contain the following information:</p>
      <ul>
        <li>a description of the nature of the personal data breach, including, where possible, the categories and approximate number of data subjects concerned, the categories concerned and the approximate number of personal data records concerned;</li>
        <li>a description of the measures taken or proposed by the contractor to address the personal data breach and, where appropriate, measures to mitigate its possible adverse effects.</li>
      </ul>
      <p>The contractor shall, in consultation with the contracting authority, take appropriate measures to secure the data and to mitigate any possible adverse effects on data subjects. Insofar as the Client is subject to obligations pursuant to Article 33 of the GDPR, the Contractor shall support the Client in this regard.</p>
      <p>9.10 The Contractor shall be obliged to provide the Client with information at any time insofar as its data and documents are affected by a breach of the protection of personal data. The Contractor shall undertake the destruction of material in conformity with data protection on the basis of an individual order by the Client. In special cases to be determined by the Principal, material shall be stored or handed over.</p>
      <p>9.11 The contractor shall inform the client if data subjects assert their data subject rights against the contractor.</p>


      <h3>§ 10 Rights and obligations of the client</h3>
      <p>10.1 The Client shall be solely responsible for assessing the permissibility of the data collection, processing or use and for safeguarding the rights of the data subjects.</p>
      <p>10.2 The Principal shall inform the Contractor immediately and in full if he discovers errors or irregularities with regard to data protection provisions when checking the results of the order.</p>
      <p>10.3 The Client shall be responsible for keeping a processing list in accordance with Article 30 of the Data Protection Regulation.</p>
      <p>10.4 The Client shall be responsible for the information obligations resulting from Art. 33 of the GDPR.</p>
      <p>10.5 The Client shall determine the measures for the return of the data carriers provided and/or the deletion of the stored data after termination of the contract by contract or by instruction.</p>


      <h3>§ 11 Requests by data subjects to the Principal</h3>
      <p>If the customer is obliged under applicable data protection laws to provide an individual with information on the collection, processing or use of that individual's data, the contractor shall assist the customer in providing this information, provided the customer has requested the contractor to do so in writing.</p>


      <h3>§ 12 Cooperation with the supervisory authority</h3>
      <p>The client and the contractor and, if applicable, their representatives shall cooperate with the supervisory authority in the performance of their duties upon request.</p>


      <h3>§ 13 Kontrollpflichten des Auftraggebers</h3>
      <p>The contracting authority shall satisfy itself of the technical and organisational measures taken by the contractor prior to the commencement of data processing and regularly thereafter and shall document the result. For this purpose, he may, for example, obtain information from the contractor himself or have an audit carried out at his own expense. In the event of an audit, the client shall bear the costs of the contractor's employees who have to participate in the audit.</p>


      <h3>§ 14 Subcontractors</h3>
      <p>14.1 The subcontracting of orders under this contract and the activities specified in §§ 3, 4, 5, 6 to subcontractors is possible, provided that the contractor ensures that the subcontractor assumes the obligations under this contract towards the contractor. If the Contractor places orders with subcontractors, it shall be incumbent on the Contractor to assign its obligations under this Contract to the subcontractor. In particular, the requirements of confidentiality, data protection and data security between the parties to this contract shall apply.</p>
      <p>14.2 The Principal shall be granted control and inspection rights in accordance with § 13. By written request, the Principal shall be entitled to obtain information from the Contractor about the essential content of the contract and the implementation of the Subcontractor's obligations relevant to data protection, if necessary also by inspecting the relevant contractual documents.</p>


      <h3>§ 15 Confidentiality Obligation</h3>
      <p>When processing data for the Principal, the Contractor shall be obliged to maintain confidentiality with regard to data which it receives or becomes aware of in connection with the order. The contractor undertakes to observe the same rules for the protection of secrets as are incumbent on the principal. The Client shall be obliged to inform the Contractor of any special rules for the protection of secrets.</p>

      <h3>§ 16 General provisions, information obligations, written form clause, choice of law</h3>
      <p>16.1 If the Customer's data at the Contractor is endangered by attachment or seizure, by insolvency or composition proceedings or by other events or measures of third parties, the Contractor shall immediately inform the Customer thereof. Contractor shall immediately inform all persons responsible in this context that the sovereignty and ownership of the data lies exclusively with Customer as the "responsible entity" within the meaning of the GDPR.</p>
      <p>16.2 The processing and use of the data shall take place exclusively in the territory of the Federal Republic of Germany, in a member state of the European Union or in another state party to the Agreement on the European Economic Area. Any relocation to a third country requires the prior consent of the Client and may only take place if the special requirements of Articles 44, 45, 46 of the GDPR are met. The Client is aware that the Contractor uses the services of the subcontractor Amazon Web Services, Inc. Box 81226, Seattle, WA 98108-122 (AWS). AWS also offers hosting services within the EU (including in Germany). In the event of data processing in the USA, AWS offers sufficient guarantees for data protection-compliant processing within the meaning of Art. 46 of the GDPR through a US/EU Privacy Shield certification.</p>
      <p>16.3 Amendments and supplements to this Agreement and all of its components - including any assurances by Contractor - require a written agreement and the express indication that it is an amendment or supplement to these provisions. This also applies to the waiver of this formal requirement.</p>
      <p>16.4 German law shall apply, with the exception of conflict of laws.</p>

      <br><br>
      <p>
        Current as of: Feb 2021
      </p>
      <br><br>

    </div>

  </div>
</template>

<script>
import NavigationBar from '../components/NavigationBar'

export default {
  name: 'BetgameOrderAgreement',
  components: { NavigationBar },
  computed: {
    userlang () {
      return this.$store.state.language
    }
  },
  methods: {
    goBack () {
      if (this.$store.getters.loggedIn) {
        this.$router.push({ name: 'profile' }).catch(err => {
        })
      } else {
        this.$router.go(-1)
      }
    }
  },
  mounted () {
    $(window).on('load', function () {

    })
  }
}

</script>

<style lang="scss" scoped>
h1 {
  margin-top: 30px;
}

h3 {
  font-weight: normal;
}

h5 {
  font-weight: normal;
}

p {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
