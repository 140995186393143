<template>
    <div class="betgameeditpublic" id="betgameeditpublic">
        
        <NavigationBar>
            <template v-slot:left>
                <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
            </template>
            <template v-slot:center>
              {{ $t('betgame_settings.betgame_is_public') }}
            </template>
            <template v-slot:right>
            </template>
        </NavigationBar>
        
        <ul class="error-container">
            <li v-for="error in errors">{{ error }}</li>
        </ul>

        <form @submit.prevent="changeBetgamePublic">
            <div class="input-container">
              <div>
                <div class="input-header">
                  {{ $t('betgame_settings.betgame_is_public_title') }}
                </div>
                <div class="input-infotext">
                  {{ $t('betgame_settings.betgame_is_public_text') }}
                </div>
                <div class="input-radio">
                  <input v-model="betgame.is_public" v-bind:value="true" type="radio" id="yes" name="oeffentlich"/>
                  <label for="yes">{{ $t('yes') }}</label>
                </div>
                <div class="input-radio">
                  <input v-model="betgame.is_public" v-bind:value="false" type="radio" id="no" name="oeffentlich"/>
                  <label for="no">{{ $t('no') }}</label>
                </div>
              </div>
              <div class="input-button-row">
                <button name="button" type="submit">
                  {{ $t('button_save_text') }}
                </button>
              </div>
            </div>
        </form>
    </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  
  export default {
    name: 'BetgameEditPublic',
    components: { NavigationBar },
    data () {
      return {
        betgame: null,
        errors: []
      }
    },
    created () {
      this.betgame = this.$route.params.betgame
    },
    mounted () {
      if (this.betgame === null || this.betgame === undefined) {
        this.goBack()
      }
    },
    computed: {
    },
    methods: {
      updateBetgame (data) {
        this.$store.dispatch('patchBetgame', data)
        .then(() => {
          this.$toast.open({ message: this.$i18n.t('save_changes_success'),
            position: 'top',
            duration: TOAST_DURATION,
            type: 'success'
          })
          this.goBack()
        })
        .catch(function (error) {
          this.$toast.open({ message: this.$i18n.t('save_changes_error'),
            position: 'top',
            duration: TOAST_DURATION,
            type: 'error'
          })
        })
        .finally(function () {
        })
      },
      goBack () {
        this.$router.push('/betgameedit')
      },
      changeBetgamePublic () {
        this.errors = []
        if (this.errors.length === 0) {
          this.$store.dispatch('setBetgame', this.betgame)
          this.updateBetgame({ is_public: this.betgame.is_public })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .betgameeditpublic {
        
        form {
            padding-top: 16px;
        }
        
        h1 {
            text-align: center;
        }
        
        p {
            text-align: center;
            margin-bottom: 26px;
        }
    }
</style>
