<template>
  <div class="profileDeleted" style="justify-content: flex-start; margin-top: 32px;">

    <NavigationBar>
      <template v-slot:left>
      </template>
      <template v-slot:center>
        {{ $t('profile.account_deleted') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <h1>{{ $t('profile.account_deleted') }}</h1>
    <p>{{ $t('profile.success_account_deleted') }}</p>

    <div class="input-button-row">
      <button @click="gotoLandingPage" name="button" >
        {{ $t('profile.go_to_start') }}
      </button>
    </div>

  </div>
</template>

<script>
import NavigationBar from './../components/NavigationBar'

export default {
  name: 'ProfileDeleted',
  components: { NavigationBar },
  data () {
    return {}
  },
  created () {
  },
  methods: {
    gotoLandingPage() {
      this.$router.push('/')
    },
  }
}
</script>

<style lang="scss" scoped>
.profileDeleted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 64px);
  padding: 64px 16px 0 16px;

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
    margin-bottom: 26px;
  }
}

.profile-deleted {
  width: 100%;
  text-align: center;
  color: $colorGreyDark;
  padding: 21px 0 0;

  a {
    font-weight: 500;
    text-decoration: none;
    margin-left: 6px;
    padding: 12px 0;
  }
}
</style>
