<template>
    <div class="userBetgameSettings" id="userBetgameSettings">
        
        <NavigationBar id="userBetgameSettingsNavigationBarId" ref="userBetgameSettingsNavigationBarRef">
            <template v-slot:center>
              {{ $t('menu.settings') }}
            </template>
            <template v-slot:right>
            </template>
        </NavigationBar>
    
        <div class="input-container">
            <div class="input-infotext">
              {{ $t('user_betgame_settings.switch_my_betgames') }}
            </div>
            <button v-on:click="switchBetgame">{{ $t('user_betgame_settings.button_switch_betgame') }}</button>
        </div>
    
        <div class="input-container">
            <div class="input-infotext">
              {{ $t('user_betgame_settings.create_betgame') }}
            </div>
            <button v-on:click="createBetgame">{{ $t('user_betgame_settings.button_create_betgame') }}</button>
        </div>
    
        <div class="input-container">
            <div class="input-infotext">
              {{ $t('user_betgame_settings.betgame_admin_name', {admin: betgameOwnerName}) }}
            </div>
            <button v-on:click="contactAdmin">{{ $t('user_betgame_settings.button_contact_betgame_admin') }}</button>
        </div>
        
        <div class="input-container">
            <div class="input-infotext">
              {{ $t('user_betgame_settings.leave_betgame', {betgame: betgameName}) }}
            </div>
            <button v-on:click="leaveBetgame">{{ $t('user_betgame_settings.buton_leave_betgame') }}</button>
        </div>
      
    </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  
  export default {
    name: 'UserBetgameSettings',
    components: { NavigationBar },
    data () {
      return {}
    },
    mounted () {
    
    },
    computed: {
      betgameName () {
        return this.$store.state.betgame.name
      },
      betgameOwnerName() {
        return this.$store.state.betgame ? this.$store.state.betgame.bg_betgame_fk_user_key.nickname || '' : ''
      }
    },
    methods: {
      contactAdmin() {
        var mail = document.createElement('a')
        mail.href = 'mailto:' + encodeURIComponent(this.$store.state.betgame.email) + '?subject=' + encodeURIComponent(this.$store.state.betgame.name)
        mail.target = '_blank'
        mail.click()
      },
      switchBetgame() {
        this.$log.debug('switchBetgame')
        this.$router.push('/mybetgames')
      },
      createBetgame() {
        this.$log.debug('createBetgame')
        this.$router.push('/createuserbetgame')
      },
      leaveBetgame() {
        this.$log.debug('leaveBetgame')
        var r = confirm(this.$i18n.t('user_betgame_settings.leave_betgame_confirm', {betgame: this.betgameName}));
        if (r === true) {
          const that = this
          this.$store.dispatch('userLeaveBetgame', this.$store.state.betgameId).then(() => {
            that.$toast.open({ message:  that.$i18n.t('user_betgame_settings.success_leave_betgame'), position: 'top', duration: TOAST_DURATION, type: 'success' })
            that.$router.push('/mybetgames')
          }).catch(function (error) {
            that.$toast.open({ message:  that.$i18n.t('error'), position: 'top', duration: 2 * TOAST_DURATION, type: 'error' })
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    
    .input-container {
        margin: 16px auto 0;
    }

    button {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    
    .userBetgameSettings {
        
        form {
            padding-top: 16px;
        }
        
        h1 {
            text-align: center;
        }
        
        p {
            text-align: center;
            margin-bottom: 26px;
        }
    }
</style>
