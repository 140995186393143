<template>
  <div class="profileChangeNickname" id="profileChangeNickname">
  
    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('profile.change_nickname') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>
    
    <form @submit.prevent="changeNickname">

      <div class="input-container">
        
        <div>
          <div class="input-element">
            <input v-model="nickname_old" type="text" name="nickname_old" id="nickname_old" placeholder="nickname_old" value disabled />
            <label for="nickname_old">{{ $t('profile.current_nickname') }}</label>
          </div>

          <div class="input-element">
            <input v-model="nickname_new" type="text" name="nickname_new" id="nickname_new" placeholder="nickname_new" autofocus value />
            <label for="nickname_new">{{ $t('profile.new_nickname') }}</label>
          </div>
  
          <!--
          <div class="input-element">
            <input v-model="password" type="password" name="password" id="password" placeholder="password" autofocus value />
            <label for="password">Passwort</label>
          </div>
          -->
        </div>

        <div class="input-button-row">
          <button type="submit" name="button">
            {{ $t('button_save_text') }}
          </button>
        </div>

      </div>
      
    </form>
  
    <ul class="error-container">
      <li v-for="error in errors">{{ error }}</li>
    </ul>
    
  </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  
  export default {
    name: 'profileChangeNickname',
  components: { NavigationBar },
  data() {
    return {
      nickname_new: '',
      nickname_old: '',
      errors: []
    }
  },
  mounted(){
    this.nickname_old = this.$store.state.user.nickname
  },
  methods: {
    goBack() {
      this.$router.push('/profile')
    },
    changeNickname() {
      this.errors = []
      if(this.isNullOrUndefined(this.nickname_new) || this.nickname_new.length === 0) {
        this.errors.push(this.$i18n.t('profile.error_enter_new_nickname'))
      }
      if (this.errors.length === 0) {
        this.$store.dispatch('updateUserNickname', {
          id: '' + this.$store.state.user.id,
          email: this.$store.state.user.email,
          nickname: this.nickname_new
        })
        .then(() => {
          //this.errors.push('Dein Benutzername wurde geändert. Es kann ein paar Minuten dauern, bis er in der Gesamtwertung aktualisiert angezeigt wird.')
          this.$toast.open({ message:  this.$i18n.t('profile.success_new_nickname_saved'), position: 'top', duration: TOAST_DURATION, type: 'info' })
          this.nickname_old = this.nickname_new
          this.nickname_new = ''
        }).catch(function (error) {
          //this.password = ''
          alert(this.$i18n.t('profile.error_new_nickname_not_saved') + ' ' + error)
        })
      }
    },
    isNullOrUndefined(data) {
      return data === null || data === undefined
    }
  }
}
</script>

<style lang="scss" scoped>
  .profileChangeNickname {

    form {
      padding-top: 16px;
    }
    h1 {
      text-align: center;
    }
    p {
      text-align: center;
      margin-bottom: 26px;
    }
  }
</style>
