import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Dashboard from './views/Dashboard.vue'
import RegisterUser from './views/RegisterUser.vue'
import ResetPassword from './views/ResetPassword'
import EnterPasswordReset from './views/EnterPasswordReset'
import ValidateRegistration from './views/ValidateRegistration'
import LoginUser from './views/LoginUser.vue'
import Bet from './views/Bet.vue'
import Table from './views/Table.vue'
import Rankings from './views/Rankings'
import Profile from './views/Profile.vue'
import ProfileChangeEmail from './views/ProfileChangeEmail.vue'
import ProfileChangePassword from './views/ProfileChangePassword.vue'
import ProfileDeleteAccount from './views/ProfileDeleteAccount.vue'
import Rules from './views/Rules.vue'
import Conditions from './views/Conditions.vue'
import ErrorPage from './views/ErrorPage.vue'
import store from './vuex/store.js'
import RankingsByMatchday from './views/RankingsByMatchday'
import ProfileChangeNickname from './views/ProfileChangeNickname'
import SpecialQuestions from './views/SpecialQuestions'
import CreateUserBetgame from './views/CreateUserBetgame'
import BetgameWelcome from './views/BetgameWelcome'
import BetgameEdit from './views/BetgameEdit'
import SearchBetgame from './views/SearchBetgame'
import MyBetgames from './views/MyBetgames'
import Tournament from './views/Tournament'
import { isNotNull } from './vuex/helpers'
import ShareBetgame from './views/ShareBetgame'
import BetgameEditName from './views/BetgameEditName'
import BetgameEditDescription from './views/BetgameEditDescription'
import BetgameEditPoints from './views/BetgameEditPoints'
import BetgameEditPublic from './views/BetgameEditPublic'
import BetgameEditSpecialQuestions from './views/BetgameEditSpecialQuestions'
import BetgameEditSpecialQuestionDetail from './views/BetgameEditSpecialQuestionDetail'
import ProfileChangeNotifications from './views/ProfileChangeNotifications'
import UserBetgameSettings from './views/UserBetgameSettings'
import BetgameDelete from './views/BetgameDelete'
import BetgameReset from './views/BetgameReset'
import BetgameTermsOfUse from './views/BetgameTermsOfUse'
import BetgamePrivacy from './views/BetgamePrivacy'
import BetgameImprint from './views/BetgameImprint'
import BetgameContact from './views/BetgameContact'
import BetgameFAQ from './views/BetgameFAQ'
import BetgameTippen from './views/BetgameTippen'
import UnsubscribeEmailNotifications from './views/UnsubscribeEmailNotifications'
import { TOAST_DURATION } from './main'
import BetgameEditSpecialQuestionAnswer from './views/BetgameEditSpecialQuestionAnswer'
import BetgameAdminPrivacy from './views/BetgameAdminPrivacy'
import BetgameOrderAgreement from './views/BetgameOrderAgreement'
import LeagueBetgame from './views/LeagueBetgame'
import BetgameEditUsers from '@/views/BetgameEditUsers'
import BetgameEditUser from '@/views/BetgameEditUser'
import ProfileChangeLanguage from '@/views/ProfileChangeLanguage'
import BetgameEditIcon from '@/views/BetgameEditIcon'
import ProfileEditAvatar from '@/views/ProfileEditAvatar'
import BetgameEditBetmode from '@/views/BetgameEditBetmode'
import BetgameEditSortmode from '@/views/BetgameEditSortmode'
import AdConsent from '@/views/AdConsent'
import AdConsentConfirm from '@/views/AdConsentConfirm'
import RankingsPublic from '@/views/RankingsPublic'
import BetgameAdconsent from '@/views/BetgameAdconsent.vue'
import ProfileDeleted from '@/views/ProfileDeleted.vue'
import ProfileChangeName from '@/views/ProfileChangeName.vue'

Vue.use(Router)


const router = new Router({
  scrollBehavior (to, from, savedPosition) {
    //document.getElementById("app").scrollIntoView()
    return savedPosition || { x: 0, y: 0 }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: { requiresAuth: true }
    },
    {
      path: '/register',
      name: 'register',
      component: RegisterUser,
      props: true
    },
    {
      path: '/logout',
      beforeEnter: (to, from, next) => {
        store.dispatch('logout').then(() => {
          next('/')
        })
      }
    },
    {
      path: '/resetpassword',
      name: 'resetpassword',
      component: ResetPassword
    },
    {
      path: '/enterpasswordreset',
      name: 'enterpasswordreset',
      component: EnterPasswordReset,
      props: true
    },
    {
      path: '/pr',
      name: 'pr',
      component: EnterPasswordReset,
      props: true
    },
    {
      path: '/validateregistration',
      name: 'validateregistration',
      component: ValidateRegistration,
      props: true
    },
    {
      path: '/vr',
      name: 'vr',
      component: ValidateRegistration,
      props: true
    },
    {
      path: '/bet',
      name: 'bet',
      component: Bet,
      meta: { requiresAuth: true }
    },
    {
      path: '/specialquestions',
      name: 'specialquestions',
      component: SpecialQuestions,
      meta: { requiresAuth: true }
    },
    {
      path: '/table',
      name: 'table',
      component: Table,
      meta: { requiresAuth: true }
    },
    {
      path: '/tournament',
      name: 'tournament',
      component: Tournament,
      meta: { requiresAuth: true }
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorPage,
      meta: { requiresAuth: true }
    },
    {
      path: '/rankings',
      name: 'rankings',
      component: Rankings,
      meta: { requiresAuth: true }
    },
    {
      path: '/leaderboard',
      name: 'rankingspublic',
      component: RankingsPublic,
      meta: { requiresAuth: false }
    },
    {
      path: '/rankingsbymatchday',
      name: 'rankingsbymatchday',
      component: RankingsByMatchday,
      meta: { requiresAuth: true }
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: { requiresAuth: true }
    },
    {
      path: '/profilechangenickname',
      name: 'profilechangenickname',
      component: ProfileChangeNickname,
      meta: { requiresAuth: true }
    },
    {
      path: '/profilechangename',
      name: 'profilechangename',
      component: ProfileChangeName,
      meta: { requiresAuth: true }
    },
    {
      path: '/profilechangelanguage',
      name: 'profilechangelanguage',
      component: ProfileChangeLanguage,
      meta: { requiresAuth: true }
    },
    {
      path: '/profilechangeemail',
      name: 'profilechangeemail',
      component: ProfileChangeEmail,
      meta: { requiresAuth: true }
    },
    {
      path: '/profilechangepassword',
      name: 'profilechangepassword',
      component: ProfileChangePassword,
      meta: { requiresAuth: true }
    },
    /*
    {
      path: '/profileeditavatar',
      name: 'profileeditavatar',
      component: ProfileEditAvatar,
      meta: { requiresAuth: true }
    },
    */
    {
      path: '/profilechangenotifications',
      name: 'profilechangenotifications',
      component: ProfileChangeNotifications,
      meta: { requiresAuth: true }
    },
    {
      path: '/profiledeleteaccount',
      name: 'profiledeleteaccount',
      component: ProfileDeleteAccount,
      meta: { requiresAuth: true }
    },
    {
      path: '/profiledeleted',
      name: 'profiledeleted',
      component: ProfileDeleted,
      meta: { requiresAuth: false }
    },
    /*
    {
      path: '/userbetgamesettings',
      name: 'userbetgamesettings',
      component: UserBetgameSettings,
      meta: { requiresAuth: true }
    },
    */
    {
      path: '/rules',
      name: 'rules',
      component: Rules
    },
    {
      path: '/conditions',
      name: 'conditions',
      component: Conditions,
      meta: { requiresAuth: false }
    },
    {
      path: '/adconsent',
      name: 'adconsent',
      component: AdConsent,
      meta: { requiresAuth: false }
    },
    {
      path: '/adconsentconfirm',
      name: 'adconsentconfirm',
      component: AdConsentConfirm,
      meta: { requiresAuth: false }
    },
    /*
    {
      path: '/tipprunde-erstellen',
      name: 'createuserbetgame',
      component: CreateUserBetgame
    },
    {
      path: '/createuserbetgame',
      name: 'createuserbetgame',
      component: CreateUserBetgame
    },
     */
    {
      path: '/betgamewelcome/:id/:name',
      name: 'betgamewelcome',
      component: BetgameWelcome
    },
    {
      path: '/join/:id/:code',
      name: 'join',
      component: BetgameWelcome
    },
    {
      path: '/tippen/:user/:betgame/:matchday',
      name: 'tippen',
      component: BetgameTippen,
      meta: { requiresAuth: true }
    },
    {
      path: '/unsubscribe',
      name: 'unsubscribe',
      component: UnsubscribeEmailNotifications
    },
    /*
    {
      path: '/betgameedit',
      name: 'betgameedit',
      component: BetgameEdit,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgamedelete',
      name: 'betgamedelete',
      component: BetgameDelete,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgamereset',
      name: 'betgamereset',
      component: BetgameReset,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditname',
      name: 'betgameeditname',
      component: BetgameEditName,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditusers',
      name: 'betgameeditusers',
      component: BetgameEditUsers,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameedituser',
      name: 'betgameedituser',
      component: BetgameEditUser,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameediticon',
      name: 'betgameediticon',
      component: BetgameEditIcon,
      meta: { requiresAuth: true }
    },    
    {
      path: '/betgameeditdescription',
      name: 'betgameeditdescription',
      component: BetgameEditDescription,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditpoints',
      name: 'betgameeditpoints',
      component: BetgameEditPoints,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditpublic',
      name: 'betgameeditpublic',
      component: BetgameEditPublic,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditbetmode',
      name: 'betgameeditbetmode',
      component: BetgameEditBetmode,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditsortmode',
      name: 'betgameeditsortmode',
      component: BetgameEditSortmode,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditspecialquestions',
      name: 'betgameeditspecialquestions',
      component: BetgameEditSpecialQuestions,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditspecialquestiondetail',
      name: 'betgameeditspecialquestiondetail',
      component: BetgameEditSpecialQuestionDetail,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditspecialquestionanswers',
      name: 'betgameeditspecialquestionanswers',
      component: BetgameEditSpecialQuestionAnswer,
      meta: { requiresAuth: true }
    },
    {
      path: '/searchbetgame',
      name: 'searchbetgame',
      component: SearchBetgame
    },
    {
      path: '/mybetgames',
      name: 'mybetgames',
      component: MyBetgames
    },
    {
      path: '/sharebetgame',
      name: 'sharebetgame',
      component: ShareBetgame,
      meta: { requiresAuth: true }
    },
     */
    {
      path: '/imprint',
      name: 'imprint',
      component: BetgameImprint
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: BetgamePrivacy
    },
    {
      path: '/adconsenttext',
      name: 'adconsenttext',
      component: BetgameAdconsent
    },
    {
      path: '/adminprivacy',
      name: 'adminprivacy',
      component: BetgameAdminPrivacy
    },
    {
      path: '/orderagreement',
      name: 'orderagreement',
      component: BetgameOrderAgreement
    },
    {
      path: '/termsofuse',
      name: 'termsofuse',
      component: BetgameTermsOfUse
    },
    {
      path: '/contact',
      name: 'contact',
      component: BetgameContact
    },
    {
      path: '/faq',
      name: 'faq',
      component: BetgameFAQ
    },
    {
      path: '/tipprunde/:id/:description',
      name: 'tipprunde',
      component: LeagueBetgame
    },    
    {
      path: '*',
      name: 'login',
      component: LoginUser
    },
  ]
})

router.beforeEach((to, from, next) => {
  Vue.$log.debug('router.beforeEach from: ' + from.name + ' --- to: ' + to.name + ' --- query: ' + JSON.stringify(to.query))
  const id = to.query.id
  const token = to.query.token
  const api = to.query.api
  if(id !== undefined && id !== null && id.length > 0 && token !== undefined && token !== null && token.length > 0 &&
    api !== undefined && api !== null && api.length > 0) {
    store.dispatch('logout').then(() => {
      next()
    })
  }
  
  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.loggedIn) {
    Vue.$log.debug('router.beforeEach 000')
    store.dispatch('logout').then(() => {
      next('/')
    })
  }
  if (to.name === 'home' && store.getters.loggedIn) {
    const clearLastRoute = to.query.clr
    if(clearLastRoute !== undefined && clearLastRoute !== null && clearLastRoute === '1') {
      window.localStorage.removeItem('spmlr')
      next('/dashboard')
    } else {
      const r = window.localStorage.getItem('spmlr')
      if (r !== undefined && r !== null && r.length >= 0 && r !== 'undefined' && r !== 'home' && r !== from.name) {
        next('/' + r)
      } else {
        next('/dashboard')
      }
    }
  }
  if (to.name === 'vr' || to.name === 'validateregistration') {
    Vue.$log.debug('router.beforeEach 222')
    if(store.getters.loggedIn && store.state.user.status === 'active' && store.state.user.email === to.query.email) {
      Vue.$toast.open({ message: 'Registrierung abgeschlossen. Viel Spass beim Tippen!', position: 'top', duration: TOAST_DURATION, type: 'success' })
      next('/dashboard')
    } else {
    }
  }
  if ((to.name === 'register' || to.name === 'login') && store.getters.loggedIn) {
    Vue.$log.debug('router.beforeEach 333')
    if(store.state.betgameId) {
      const r = window.localStorage.getItem('spmlr')
      if (r !== undefined && r !== null && r.length >= 0 && r !== 'undefined' && r !== 'login' && r !== 'register' && r !== from.name) {
        next('/' + r)
      } else {
        next('/dashboard')
      }
    } else {
      next('/mybetgames')
    }
  }
  if(!hasQueryParams(to) && hasQueryParams(from)){
    Vue.$log.debug('router.beforeEach 444')
    if(typeof to === 'object' && (typeof to.name === 'string' || typeof to.path === 'string')) {
      next({ name: to, query: from.query });
    } else if(typeof to === 'string') {
      next({ name: to, query: from.query });
    } else {
      next();
    }
  } else {
    Vue.$log.debug('router.beforeEach 555')
    next()
  }
})

router.afterEach((to, from) => {
  window.localStorage.setItem('spmlr', to.name)
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

export default router
