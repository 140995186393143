<template>
    <thead :key="componentKey">

        <tr>
            <th class="col_pos"></th>
            <th class="col_user"></th>
            <th v-on:click="sortByTotal" class="col_value col_value--gesamt" :class="{ sort: sorting === 1 }"><span>{{ $t('total') }}</span></th>
            <th v-if="!isBattle() && !isTournament()" v-on:click="sortByMatchday" class="col_value col_value--spieltag" :class="{ sort: sorting === 2 }"><span>{{ $t('matchday_short') }}</span></th>
            <!--
            <th v-on:click="sortByTotal" class="col_value"><span :class="{ sort: sorting === 1 }">Gesamt </span><span v-if="sorting === 1" class="sort_arrow">&darr;</span></th>
            <th v-on:click="sortByMatchday" class="col_value"><span :class="{ sort: sorting === 2 }">Spieltag </span><span v-if="sorting === 2" class="sort_arrow">&darr;</span></th>
            -->
            <!--
            <th v-on:click="toggleSorting" v-if="sorting === 2 && matchRunning" class="col_value live">Live<span style="color: #ACACAC; font-size: smaller; vertical-align: center"> (Gesamt)</span></th>
            <th v-on:click="toggleSorting" v-else-if="sorting === 2" class="col_value">Spieltag<span style="color: #ACACAC; font-size: smaller; vertical-align: center"> (Gesamt)</span></th>
            <th v-on:click="toggleSorting" v-if="sorting === 1 && matchRunning" class="col_value">Gesamt<span style="color: red; font-size: smaller; vertical-align: center"> (Live)</span></th>
            <th v-on:click="toggleSorting" v-else-if="sorting === 1" class="col_value">Gesamt<span style="color: #ACACAC; font-size: smaller; vertical-align: center"> (Spieltag)</span></th>
            -->
            <th class="col_teams" v-for="(match, index) in matches" :key="match.id" v-bind:class="index % 2 === 0 ? 'col_white' : 'col_grey'">
                  <span class="col_teams_name" v-bind:class="match.goalshome >= match.goalsguest ? '' : 'col_teams_name--win'">
                    <img v-if="teamLogo(match.fk_team_home) && teamLogo(match.fk_team_home).length > 6" :src="teamLogo(match.fk_team_home)" width="16">
                    <div v-else-if="teamLogo(match.fk_team_home) && teamLogo(match.fk_team_home).length <= 6">
                      <flag :iso="teamLogo(match.fk_team_home)"/>
                    </div>
                    <div v-else>
                      <svg width="24px" height="16px" viewBox="0 0 24 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getTeamColor(match.fk_team_home)}"></path>
                      </svg>
                    </div>
                    {{ getTeamShort(match.fk_team_home, match.team_home) }}
                  </span>
              <span class="col_teams_name" v-bind:class="match.goalshome <= match.goalsguest ? '' : 'col_teams_name--win'">
                    {{ getTeamShort(match.fk_team_guest, match.team_guest) }}
                    <img v-if="teamLogo(match.fk_team_guest) && teamLogo(match.fk_team_guest).length > 6" :src="teamLogo(match.fk_team_guest)" width="16">
                    <div v-else-if="teamLogo(match.fk_team_guest) && teamLogo(match.fk_team_guest).length <= 6">
                      <flag :iso="teamLogo(match.fk_team_guest)" />
                    </div>
                    <div v-else>
                      <svg width="24px" height="16px" viewBox="0 0 24 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getTeamColor(match.fk_team_guest)}"></path>
                      </svg>
                    </div>
                  </span>
              <span v-if="betgame.result_mode === 3 && isNumeric(match.goalshome_90) && isNumeric(match.goalsguest_90)">{{ match.goalshome_90 }}:{{ match.goalsguest_90 }}</span>
              <span v-else-if="betgame.result_mode === 2 && isNumeric(match.goalshome_120) && isNumeric(match.goalsguest_120)">{{ match.goalshome_120 }}:{{ match.goalsguest_120 }}</span>
              <span v-else-if="isNumeric(match.goalshome) && isNumeric(match.goalsguest)">{{ match.goalshome }}:{{ match.goalsguest }}</span>
              <span v-else-if="isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest)" class="live_result">{{ match.livegoalshome }}:{{ match.livegoalsguest }}</span>
              <span v-else-if="match.status && match.status.length > 2">{{ match.status.slice(0, 3).toUpperCase() }}</span>
              <span v-else>-:-</span>
            </th>
        </tr>
        <!--<tr>
            <th></th>
            <th class="col_user"></th>
            <th class="col_value"></th>
            <th class="col_value"></th>
            <th v-for="(match, index) in matches" :key="match.id" v-bind:class="index % 2 == 0 ? 'col_grey' : 'col_white'"
                style="white-space: nowrap;padding-left: 5px; padding-right: 5px">{{ getResult(match) }}</th>
        </tr>-->
    </thead>
</template>

<script>
    export default {
        name: 'RankingsDetailTableHeader',
        data() {
          return {
            componentKey: 0
          };
        },
        props: {
          searchString: {
              type: String,
              default: () => {
                return ''
              }
            },
            matches: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        computed: {
          betgame() {
            return this.$store.state.betgame
          },
          sorting() {
            return this.$store.state.matchdayRankingSorting
          },
          matchday() {
            return this.$store.state.currentMatchdayRankingByMatchday
          },
          matchRunning() {
            if(this.matches === null) {
              return false
            }
            for (var i = 0; i < this.matches.length; i++) {
              const match = this.matches[i]
              if (this.isNumeric(match.livegoalshome) && this.isNumeric(match.livegoalsguest)) {
                return true
              }
            }
          },
        },
        methods: {
            teamLogo(teamid) {
              const l = this.getTeamLogo(teamid)
              if(l && l.length > 6) {
                return this.getBGValue('spmau') + '/images/jerseys/24/' + l
              } else if(l && l.length <= 6) {
                return l
              }
              return null
            },
            sortByTotal() {
              if(this.$store.state.matchdayRankingSorting === 2) {
                this.$emit('sortingChanged', {})
                const lastFetchLiveRanking = this.getBGValue('lastFetchLiveRanking')
                const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
                if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length == 0 ||
                  this.$store.state.allbets == null || this.$store.state.allbets.length == 0) {
                  if (this.$store.state.leagueId) {
                    this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                      this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
                        this.componentKey += 1
                      })
                    })
                  }
                }
                this.$store.dispatch('setMatchdayRakingSorting', 1)
              }
            },
            sortByMatchday() {
              if(this.$store.state.matchdayRankingSorting === 1) {
                this.$emit('sortingChanged', {})
                const lastFetchLiveRanking = this.getBGValue('lastFetchLiveRanking')
                const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
                if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length == 0 ||
                  this.$store.state.allbets == null || this.$store.state.allbets.length == 0) {
                  if (this.$store.state.leagueId) {
                    this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
                      this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
                        this.componentKey += 1
                      })
                    })
                  }
                }
                this.$store.dispatch('setMatchdayRakingSorting', 2)
              }
            },
            refresh() {
              this.$emit('refreshRankingByMatchday', {})
            },
            getResult(match) {
                if(match.livegoalshome !== null && match.livegoalsguest !== null &&
                        match.goalshome === null && match.goalsguest === null) {
                    return match.livegoalshome + ' : ' + match.livegoalsguest
                } else if(match.goalshome !== null && match.goalsguest !== null) {
                    return match.goalshome + ' : ' + match.goalsguest
                }
                return '- : -'
            }
        },
        filters: {
            subStr: function(string) {
                return string.substring(0,6);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .col_teams {
      background: none;
      text-align: center;
      padding: 14px 8px 12px;
      max-width: 40px;
      font-weight: 500;
    
      &_name {
        opacity: 1;
    
        &--win {
          opacity: 1;
        }
      }
    
      > span {
        display: block;
        width: 24px;
        margin: 0 auto;
    
        &:first-child {
          border-bottom: 1px dashed $colorGreyMid;
        }
    
        .flag-icon {
          border-radius: 50%;
        }
      }
    
      &:last-child {
        padding-right: 16px;
      }
    }
    .col_value {
      text-align: center;
      overflow: hidden;
      font-weight: 600;
      letter-spacing: -0.9px;
      color: rgba($colorGreyMid, .24);

      &--gesamt, &--spieltag {
        span {
          font-size: 9px;
          font-weight: 600;
          text-transform: uppercase;
          color: rgba($colorWhite, .64);
          background: var(--color-primary);
          padding: 4px;
          border-radius: 2px;
          opacity: .48;
          cursor: pointer;
        }
      }

      &--gesamt {
        padding-left: 4px;

        span {
          border-radius: 2px 0 0 2px;
        }
      }

      &--spieltag {
        padding-right: 4px;

        span {
          border-radius: 0 2px 2px 0;
        }
      }

      &.sort {
        span {
          color: $colorWhite;
          background: var(--color-primary);
          opacity: 1;
        }
      }

      &.live {
        color: $colorNegative;
      }
    }
    
    .col_user {
        text-align: left;
        overflow: hidden;
    }

    .reload {
        font-weight: 500;
        color: var(--color-primary);
        padding-left: 5px;
        img {
            margin-top: 2px;
        }
    }

    thead {

        tr {
            background: none;

            th{
                font-size: 11px;

                &:last-child {
                    padding-right: 8px;
                }
            }
        }
    }
    
    .live_result {
        color: $colorNegative;
    }

    .sort {
        position: relative;

        &:after {
            color: var(--color-primary);
            content: '↓';
        }
    }

    .sort_arrow {
        color: var(--color-primary);
    }
</style>
