<template>
  <div class="betgameEditUser" id="betgameEditUser">

    <NavigationBar>
      <template v-slot:left>
        <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
      </template>
      <template v-slot:center>
        {{ titleText }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <div class="input-container">
      <div class="input-header">
        Bist du sicher?
      </div>
      <div class="input-infotext">
        {{ $t('betgame_settings.remove_participant_from_betgame_text_1') }}<br>
        {{ $t('betgame_settings.remove_participant_from_betgame_text_2') }}  
      </div>
      <button v-on:click="removeUser">{{ $t('betgame_settings.button_remove_participant_text') }}</button>
    </div>

  </div>
</template>

<script>

import NavigationBar from './../components/NavigationBar'
import { TOAST_DURATION } from '../main'

export default {
  name: 'BetgameEditUser',
  components: { NavigationBar },
  data () {
    return {
      betgameUser: null
    }
  },
  created () {
    this.betgameUser = this.$route.params.user
  },
  mounted () {
    if (this.betgameUser === null || this.betgameUser === undefined) {
      this.goBack()
    }
  },
  computed: {
    titleText() {
      if(this.betgameUser) {
        return this.betgameUser.nickname
      }
      return this.$i18n.t('betgame_settings.betgame_participants')
    }
  },
  methods: {
    removeUser () {
      this.$log.debug('removeUser')
      var r = confirm(this.$i18n.t('betgame_settings.confirm_remove_participant', {participant: this.betgameUser.nickname}))
      if (r === true) {
        const that = this
        this.$store.dispatch('removeUserFromBetgame', {
          betgame: this.$store.state.betgameId,
          user: this.betgameUser.fk_user
        }).then(() => {
          that.$router.push('/betgameeditusers')
        }).catch(function (error) {
          that.$toast.open({ message: that.$i18n.t('error'), position: 'top', duration: 2 * TOAST_DURATION, type: 'error' })
        })
      }
    },
    goBack () {
      this.$router.push('/betgameeditusers')
    },
    isNullOrUndefined (data) {
      return data === null || data === undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container {
  margin: 16px auto 0;
}

button {
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>
