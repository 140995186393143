<template>
   	<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="M20,0 C22.209139,0 24,1.790861 24,4 L24,20 C24,22.209139 22.209139,24 20,24 L4,24 C1.790861,24 0,22.209139 0,20 L0,4 C0,1.790861 1.790861,0 4,0 L20,0 Z M20,2 L4,2 C2.9456382,2 2.08183488,2.81587779 2.00548574,3.85073766 L2,4 L2,20 C2,21.0543618 2.81587779,21.9181651 3.85073766,21.9945143 L4,22 L20,22 C21.0543618,22 21.9181651,21.1841222 21.9945143,20.1492623 L22,20 L22,4 C22,2.9456382 21.1841222,2.08183488 20.1492623,2.00548574 L20,2 Z M13.5,13 C16.4634791,13 18.8795391,15.34378 18.9956328,18.2787929 L19,18.5 L19,19 C19,19.5522847 18.5522847,20 18,20 C17.4871642,20 17.0644928,19.6139598 17.0067277,19.1166211 L17,19 L17,18.5 C17,16.6314366 15.5357224,15.1048736 13.6920352,15.0051789 L13.5,15 L10.5,15 C8.6314366,15 7.10487355,16.4642776 7.00517886,18.3079648 L7,18.5 L7,19 C7,19.5522847 6.55228475,20 6,20 C5.48716416,20 5.06449284,19.6139598 5.00672773,19.1166211 L5,19 L5,18.5 C5,15.5365209 7.34378001,13.1204609 10.2787929,13.0043672 L10.5,13 L13.5,13 Z M12,4 C14.209139,4 16,5.790861 16,8 C16,10.209139 14.209139,12 12,12 C9.790861,12 8,10.209139 8,8 C8,5.790861 9.790861,4 12,4 Z M12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 Z" id="profile"></path>
    </svg>
</template>

<script>
  export default {
    name: 'ProfileIcon'
  }
</script>

<style lang="scss" scoped>
	svg {
		fill: var(--color-primary);
	}
</style>