/* eslint-disable */
<template>
  <div :key="componentKey" class="betgame_welcome" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
  
    <NavigationBar >
      <template v-slot:center>
        {{ $t('betgame_welcome.join_betting_group') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <div v-if="this.$store.state.betgameSlides.length > 0" style="margin-top: 20px">
      <div class="swiper" id="swiper_container">
        <swiper v-if="this.$store.state.betgameSlides.length > 0" :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(slide, index) in this.$store.state.betgameSlides" class="welcome_slide" :key="slide.id">
            <img :src="buildSlidePath(slide.image, 300)">
            <div class="swiper-headline">{{ slide.title }}</div>
            <div class="swiper-subline">{{ slide.text }}</div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div v-else class="team-list" :key="betgameId">
      <img v-if="this.$store.state.betgame && this.$store.state.betgame.icon"  v-bind:src="buildIconPath(this.$store.state.betgame.icon, 300)" class="logo">
      <img v-else src="../assets/icons/ic_64_logo.svg">
      <h1 v-if="this.$store.state.betgame">{{ this.$store.state.betgame.name }}</h1>
      <span v-if="this.$store.state.betgame && this.$store.state.betgame.competition" class="league">{{ this.$store.state.betgame.competition.name }}</span>
      <p v-if="this.$store.state.betgame">{{ this.$store.state.betgame.description }}</p>
    </div>
  
    <div v-if="!loggedIn" class="team-list">
      {{ $t('betgame_welcome.already_registered') }}
      <button v-on:click="doLogin">{{ $t('betgame_welcome.button_login_and_place_bets') }}</button>
    </div>
  
    <div v-if="!loggedIn" class="team-list">
      {{ $t('betgame_welcome.not_registered_now') }}
      <button v-on:click="doRegister">{{ $t('betgame_welcome.button_register_and_place_bets') }}</button>
    </div>

    <div v-if="loggedIn" class="team-list">
      {{ $t('betgame_welcome.join_betgroup') }}
      <button v-on:click="enterBetgame">{{ $t('betgame_welcome.button_join_and_place_bets') }}</button>
      <button v-on:click="searchBetgame">{{ $t('betgame_welcome.button_back_to_overview') }}</button>
    </div>

    <div v-if="dashboardAd && dashboardAd.image" style="margin: 10px auto 0px; max-width: 576px; padding-bottom: 24px; padding-top: 24px; text-align: center">
      <a :href="dashboardAd.link" target="_blank">
        <img style="max-width: 100%;" :src="buildAdPath(dashboardAd.image, 300)">
      </a>
    </div>
    
  </div>
</template>

<script>
import NavigationBar from './../components/NavigationBar'
import Vue from 'vue'
import { TOAST_DURATION } from '../main'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'BetgameWelcome',
  components: { NavigationBar, swiper, swiperSlide },
  data() {
    return {
      betgameId: null,
      code: null,
      componentKey: 0,
      swiperOption: {
        effect: 'slide',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true
        },
        pagination: {
          el: '.swiper-pagination'
        }
      }
    }
  },
  beforeCreate() {
    if(this.$route.params) {
      this.$log.debug('BetgameWelcome beforeCreate ' + this.$route.params.id + ' - ' + this.$route.params.name + ' - ' + this.$route.params.code)
    } else {
      this.$log.debug('BetgameWelcome beforeCreate no params')
    }
  },
  created () {
    if(this.$route.params) {
      this.$log.debug('BetgameWelcome created '  + this.$route.params.id + ' - ' + this.$route.params.name + ' - ' + this.$route.params.code)
    } else {
      this.$log.debug('BetgameWelcome created no params')
    }
    if(this.$route.params.id && this.$route.params.code) {
      this.betgameId = this.$route.params.id
      this.code = this.$route.params.code
      const that = this
      this.$store.dispatch('checkInvitation', [this.betgameId, this.code]).then(() => {
          const that = this
          const betgameid = this.$route.params.id
          this.setBGValue('spmbgid', betgameid)
          this.setBGValue('spmic', this.$route.params.code)
          if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
            this.setBGValue('betgameId', betgameid)
          }
          this.$store.dispatch('fetchBetgame', betgameid).then(() => {
              that.$log.debug('BetgameWelcome created loaded '  + betgameid)
              that.$store.dispatch('fetchBetgameSlides', betgameid).then(() => {
                that.$log.debug('BetgameWelcome fetchBetgameSlides '  + betgameid)
                that.componentKey += 1
              })
              that.$store.dispatch('fetchBetgameAds', betgameid).then(() => {
                that.$log.debug('BetgameWelcome fetchBetgameAds '  + betgameid)
                that.componentKey += 1
              })
          })
      }).catch(function (error) {
        that.$router.push({ name: 'home'})
        that.$toast.open({ message: that.$i18n.t('register.invitation_link_invalid'), position: 'top', duration: TOAST_DURATION, type: 'error'})
        Vue.$log.debug('BetgameWelcome checkInvitation ' + error)
      })
    } else {
      this.$router.push({ name: 'home'})
      this.$toast.open({ message: this.$i18n.t('register.invitation_link_invalid'), position: 'top', duration: TOAST_DURATION, type: 'error'})
    }
  },
  mounted() {
    if(this.$route.params) {
      this.$log.debug('BetgameWelcome mounted ' + this.$route.params.id + ' - ' + this.$route.params.name)
    } else {
      this.$log.debug('BetgameWelcome mounted no params')
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn
    },
    dashboardAd() {
      const ads = this.$store.state.betgameAds.filter(a => a.position === 1)
      if(ads.length === 1) {
        return ads[0]
      }
      return null
    }
  },
  methods: {
    doLogin(betgame) {
      window.localStorage.setItem('spmbgid', this.betgameId)
      if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
        window.localStorage.setItem('betgameId', this.betgameId)
      }
      this.$router.push({ name: 'login', params: { action: 'join', betgame: this.betgameId }})
    },
    doRegister() {
      window.localStorage.setItem('spmbgid', this.betgameId)
      if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
        window.localStorage.setItem('betgameId', this.betgameId)
      }
      this.$router.push({ name: 'register'})
    },
    enterBetgame() {
      if (this.$store.getters.loggedIn) {
        const that = this
        this.$store.dispatch('userEnterBetgame', this.betgameId).then(() => {
          this.$log.debug('enterBetgame1 ' + this.betgameId)
          this.setBGValue('spmbgid', this.betgameId)
          if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
            this.setBGValue('betgameId', this.betgameId)
          }
          this.$store.dispatch('switchBetgame', this.betgameId).then(() => {
            this.$log.debug('enterBetgame2 ' + this.betgameId)
            this.$store.dispatch('fetchBetgame', this.betgameId).then(() => {
              that.$router.push('/dashboard')
            })
          })
        })
      }
    },
    searchBetgame() {
      if (this.$store.getters.loggedIn) {
        if(this.$store.state.userBetgames.length > 0) {
          this.$router.push('/mybetgames')
        } else {
          this.$router.push('/searchbetgame')
        }
      }
    }
  }
}
</script>

<style lang="scss">
  
  .team-list {
    max-width: 528px;
    margin: 16px auto;
    padding: 16px 16px 16px 16px;
    text-align: center;
    background: $colorWhite;
    box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
    border-radius: 11px;
    overflow: hidden;

    img {
      margin-top: 32px;
      margin-bottom: 22px;
    }

    .logo {
      width: 64px;
      height: 64px;
      border-radius: 6px;
      overflow: hidden;
      object-fit: cover;
    }

    .league {
      color: $colorGreyMid;
    }

    p {
      text-align: left;
      margin-top: 32px;
    }

    button {
      margin-top: 34px;
    }
  }

</style>
