<template>
    <div class="rules" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
        
        <NavigationBar>
            <template v-slot:left>
                <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
            </template>
            <template v-slot:center>
                FAQ
            </template>
        </NavigationBar>
        
        <div class="faq">
          
          <div class="faq-item">
            <div class="question">
              1)	Ist das Tippspiel kostenlos?
            </div>
            <div class="answer">
              Ja, das TEAMTIP Tippspiel ist absolut kostenlos. Es entstehen keine Kosten, weder für die Anmeldung noch die Tipps, die du abgibst.
            </div>
          </div>

          <div class="faq-item">
            <div class="question">
              2)	Wie kann ich eine Tipprunde erstellen?
            </div>
            <div class="answer">
              Nachdem du dich in der App oder auf www.Teamtip.net registriert und angemeldet hast, kannst du ganz einfach auf der Startseite auf die graue Schaltfläche „Tipprunde erstellen/suchen“ klicken. Nun kannst du nach einer vorhandenen Tipprunde suchen oder eine ganz neue Tipprunde eröffnen. Sobald Du Dich für eine neue Tipprunde entschieden hast, kannst Du den Namen der Tipprunde bestimmen. Du kannst zwischen den Wettbewerben Champions League, Bundesliga, 2. Bundesliga, 3.Liga, Premier League, Primera División, Serie A, SüperLig und der Ligue 1 entscheiden. Zum abschließen klickst Du auf die blaue Schaltfläche „Tipprunde eröffnen“.
            </div>
          </div>

          <div class="faq-item">
            <div class="question">
              3)	Wie kann ich meine Freunde zur Tippspielrunde einladen?
            </div>
            <div class="answer">
              Um Deine Freunde in Deine Tipprunde einzuladen, erscheint nach Eröffnung der Tipprunde eine blaue Schaltfläche „+Freunde einladen“. Wenn Du auf diesen Reiter klickst, werden Dir verschiedene Versendungsmöglichkeiten aufgezeigt. Lade Deine Freunde per Mail, Facebook, Twitter oder Whatsapp in Deine Tippspielrunde ein.
              Solltest Du noch andere Programme nutzen, kannst Du natürlich auch einen Einladungslink generieren und diesen an Deine Freunde verschicken.
            </div>
          </div>

          <div class="faq-item">
            <div class="question">
              4)	Nach welchen Regeln werden die Punkte vergeben?
            </div>
            <div class="answer">
              Bist Du der Ersteller der Tipprunde, kannst Du die Verteilung der Punkte selber wählen. Unter „Einstellungen“ und „Wertung“ kannst Du die Vergabe der Punkte bestimmen.<br>
              Bist Du nicht der Ersteller der Tipprunde, kannst Du Dir anzeigen lassen, wie die Wertung bei der Tipprunde ist, der du beigetreten bist. Gehe in die „Einstellungen“, unter dem Punkt „Wertung“ wird Dir die genaue Verteilung der Punkte angezeigt.<br>
              Standardmäßig ist die Punktevergabe wie folgt: Für jedes richtig getippte Endergebnis einer Partie bekommst Du 3 Punkte. Wenn Du die richtige Tordifferenz tippst, (Dein Tipp war ein 3:1 und das Spiel endet 2:0) gibt es 2 Punkte. Für die richtige Tendenz, also ein Heim- oder ein Auswärtssieg bzw. Unentschieden, bekommst du 1 Punkt.
            </div>
          </div>

          <div class="faq-item">
            <div class="question">
              5)	Wie viele Tipprunden kann ich anlegen?
            </div>
            <div class="answer">
              Du kannst so viele Tipprunden erstellen, wie Du möchtest.
            </div>
          </div>

          <div class="faq-item">
            <div class="question">
              6)	Gibt es Erinnerungsmails?
            </div>
            <div class="answer">
              Ja, angemeldete Teilnehmer erhalten an Spieltagen rechtzeitig vor Anpfiff eine Erinnerungsmail für noch nicht getippte Partien. Die Funktion kannst Du über dein Profil und „Benachrichtigungen“ deaktivieren.
            </div>
          </div>

          <div class="faq-item">
            <div class="question">
              7)	Kann ich das Tippspiel auf meiner eigenen Website einbauen?
            </div>
            <div class="answer">
              Ja, fülle dazu einfach das Formular auf  https://teamtip.net/#whitelabel_id aus und wir melden uns bei dir.
            </div>
          </div>

          <div class="faq-item">
            <div class="question">
              8)	Wie viele Teilnehmer können an einer/meiner Tipprunde mitmachen?
            </div>
            <div class="answer">
              Für eine Tipprunde bei TEAMTIP gibt es keine Begrenzungen. An einer Tipprunde können beliebig viele Teilnehmer tippen.
            </div>
          </div>

          <div class="faq-item">
            <div class="question">
              9)	 Wie gebe ich meine Tipps ab?
            </div>
            <div class="answer">
              Klicke auf der Startseite auf den blauen Button „“Tipps abgeben“ oder navigiere zum Reiter links oben in der Ecke und wähle „Tippabgabe“. Nun kannst du den aktuellen Spieltag tippen. An jedem Spieltag werden Dir alle Partien eines Spieltages angezeigt. In die Felder hinter jeder Begegnung, trägst Du Deinen Tipp ein. Deine Tipps werden automatisch gespeichert.  Am unteren Bildrand, kannst du die Tipps der vergangenen letzten Spieltage einsehen oder für weitere Spiele im Voraus tippen.
            </div>
          </div>

          <div class="faq-item">
            <div class="question">
              10)	Kann ich meine Tipps später noch ändern?
            </div>
            <div class="answer">
              Ja, bis zum Spielbeginn kannst Du deine Tipps so oft ändern, wie Du möchtest. Einfach in die grauen Kästchen hinter einem Spiel, Deinen neuen Tipp eintragen, auch hier wird dein Tipp wieder automatisch gespeichert. Sobald das Spiel begonnen hat, kannst Du deine Tipps nicht mehr verändern.
            </div>
          </div>

          <div class="faq-item">
            <div class="question">
              11)	Ich habe mein Passwort vergessen, was nun?
            </div>
            <div class="answer">
              In der Anmeldemaske zum Tippspiel siehst Du rechts oben in der Ecke den blauen Button „Login“. Nachdem du auf diese Schaltfläche geklickt hast, erscheint die Option „Passwort vergessen?“. Nachdem Du auf „Passwort vergessen“ geklickt hast, kannst Du deine aktuelle E-Mail-Adresse eingeben. Wir senden dir eine E-Mail mit einem 6-stelligen Code, über den Du ein neues Passwort auf der Teamtip.net Seite festlegen kannst.
            </div>
          </div>

          <div class="faq-item">
            <div class="question">
              12)	Wie stelle ich Sonderfragen ein?
            </div>
            <div class="answer">
              Zuerst einmal ist es wichtig anzumerken, dass nur der Gruppen-Administrator, also der Ersteller des Tippspiels, Sonderfragen einstellen kann.
              Um eine Sonderfrage einzustellen, musst Du in das TEAMTIP Menü gehen. Klicke dafür auf die drei Striche oben links.
              Nun öffnet sich das Menü und du hast verschiedene Möglichkeiten. Gehe nun ganz unten auf „Einstellungen“.
              Jetzt bist Du in den Tipprundeneinstellungen. Hier kannst Du nun die Sonderfragen einstellen. Klicke auf „Sonderfragen“, um in die Sonderfragen zu gelangen.
              Jetzt musst du auf den blauen Button „+Sonderfrage erstellen“ drücken.
              Du bist jetzt in der Anlegemaske. Hier hast du die Möglichkeit, eine vorgefertigte Frage auszuwählen oder eine eigene Frage zu erstellen. Wähle den passenden Antworttyp und wähle aus, wie viele Punkte Du für eine richtige Antwort verteilen möchtest. Im Anschluss brauchst Du nur noch die Anzahl der Antworten auszuwählen und eine Deadline bei „Teilnahme bis“ setzen. Drücke auf Speichern und Du hast Deine Sonderfrage erfolgreich angelegt.<br>
              Wichtig: Du musst die Lösung nach Ablauf der Frage eigenständig eintragen. Hierfür gehst Du den oben genannten Weg bis zum Punkt „Sonderfragen“.
              Du siehst jetzt unter dem Punkt „+ Sonderfragen erstellen“ Deine bereits erstellen Sonderfragen. Navigiere auf deine erstellte Frage.
              Die Anmeldemaske hat sich um einen Punkt erweitert. Du findest jetzt unter „Anzahl Antworten“ einen weiteren Button „Lösung eingeben“.
            </div>
          </div>

          <div class="faq-item">
            <div class="question">
              13)	Können andere Mitspieler meine Tipps sehen?
            </div>
            <div class="answer">
              Deine Tipps sind bis zum Anpfiff und somit bis zur Schließung der Tippabgabe für niemanden außer Dir zu sehen. Nachdem die Tippabgabe für ein Spiel geschlossen ist, können andere sehen, wie du getippt hast.
            </div>
          </div>


          <div class="faq-item">
            <div class="question">
              14)	Wo kann ich meine Tipps abgeben?
            </div>
            <div class="answer">
              Du kannst deine Tipps bis zum Spielbeginn in der TEAMTIP-App aus dem Appstore oder aus dem Google Playstore abgeben oder trage Deine Tipps auf www.teamtip.net ein.
            </div>
          </div>

          <div class="faq-item">
            <div class="question">
            </div>
            <div class="answer">
            </div>
          </div>
          
        </div>
      
      <br><br><br>
      
    </div>
</template>

<script>
import NavigationBar from '../components/NavigationBar'

export default {
    name: 'BetgameFAQ',
    components: { NavigationBar },
    computed: {},
    methods: {
      goBack() {
        if (this.$store.getters.loggedIn) {
          this.$router.push({ name: 'profile' }).catch(err => {})
        } else {
          this.$router.go(-1)
        }
      }
    }
  }
  
</script>

<style lang="scss" scoped>
.faq {
  margin-top: 30px;
  margin-bottom: 100px;
}
.faq-item {
  margin-top: 15px;
  margin-bottom: 15px;
}
.question {
  font-size: 20px;
  font-weight: 500;
}
.answer {
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
}
</style>
