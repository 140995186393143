<template>
  <div class="profileChangeLanguage" id="profileChangeLanguage">
  
    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('profile.change_language') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>
    
    <form @submit.prevent="changeLanguage">

      <div class="input-container">

        <div class="input-header">
          {{ $t('language') }}
        </div>
        <div class="input-radio">
          <input v-on:change="languageSwitched" v-model="selectedLanguage" type="radio" id="lang-de" name="sprache" value="de"/>
          <label for="lang-de">{{ $t('german') }}</label>
        </div>
        <div class="input-radio">
          <input v-on:change="languageSwitched" v-model="selectedLanguage" type="radio" id="lang-en" name="sprache" value="en" />
          <label for="lang-en">{{ $t('english') }}</label>
        </div>

        <div class="input-button-row">
          <button type="submit" name="button">
            {{ $t('button_save_text') }}
          </button>
        </div>

      </div>
      
    </form>
  
    <ul class="error-container">
      <li v-for="error in errors">{{ error }}</li>
    </ul>
    
  </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  import Vue from 'vue'
  import Numeral from 'numeral'
  import moment from 'moment'
  
  export default {
    name: 'profileChangeLanguage',
  components: { NavigationBar },
  data() {
    return {
      errors: [],
      selectedLanguage: ''
    }
  },
  mounted() {
    this.selectedLanguage = this.$store.state.language
  },
  methods: {
    goBack() {
      this.$router.push('/profile')
    },
    languageSwitched() {
      this.errors = []
    },
    changeLanguage() {
      this.errors = []
      const l = this.selectedLanguage
      this.$store.dispatch('changeLanguage', l)
      .then(() => {
        Vue.$log.debug('changeLanguage ok + ' + this.$store.state.language)
        this.$i18n.locale = this.$store.state.language
        Numeral.locale(this.$store.state.language)
        moment.locale(this.$store.state.language)
        this.$router.push('/profile')
      }).catch(function (error) {
        Vue.$log.debug('changeLanguage error ' + error)
      })
    },
    isNullOrUndefined(data) {
      return data === null || data === undefined
    }
  }
}
</script>

<style lang="scss" scoped>
  .profileChangeLanguage {

    form {
      padding-top: 16px;
    }
    h1 {
      text-align: center;
    }
    p {
      text-align: center;
      margin-bottom: 26px;
    }
  }
</style>
