<template>
	<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="M3.05489527,11.000274 C3.01862184,11.3285393 3,11.6620949 3,12 C3,16.9705627 7.02943725,21 12,21 C14.7398637,21 17.1937746,19.7756906 18.8444937,17.8443108 L16.8535534,15.8535534 C16.6582912,15.6582912 16.6582912,15.3417088 16.8535534,15.1464466 C16.9473216,15.0526784 17.0744985,15 17.2071068,15 L22,15 C22.5522847,15 23,15.4477153 23,16 L23,20.7928932 C23,21.0690356 22.7761424,21.2928932 22.5,21.2928932 C22.3673918,21.2928932 22.2402148,21.2402148 22.1464466,21.1464466 L20.2618247,19.2625979 C18.2459457,21.5540302 15.291835,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,11.6630038 1.01515421,11.329547 1.04481833,11.000274 L3.05489527,11.000274 Z M12,1 C18.0751322,1 23,5.92486775 23,12 C23,12.337337 22.9848151,12.6711273 22.9550916,13.0007248 L20.9449943,13.0007248 C20.9813404,12.6721373 21,12.3382478 21,12 C21,7.02943725 16.9705627,3 12,3 C9.26010104,3 6.80616223,4.22434094 5.15544261,6.15576376 L7.14644661,8.14644661 C7.34170876,8.34170876 7.34170876,8.65829124 7.14644661,8.85355339 C7.05267842,8.94732158 6.92550146,9 6.79289322,9 L2,9 C1.44771525,9 1,8.55228475 1,8 L1,3.20710678 C1,2.93096441 1.22385763,2.70710678 1.5,2.70710678 C1.63260824,2.70710678 1.7597852,2.7597852 1.85355339,2.85355339 L3.73745391,4.73822214 C5.75334955,2.44631478 8.70777231,1 12,1 Z" id="Shape" fill-rule="nonzero"></path>
	</svg>
</template>

<script>
  export default {
    name: 'UpdateIcon'
  }
</script>

<style lang="scss" scoped>
	svg {
		fill: var(--color-primary);
	}
</style>