<template>
    <div :key="componentKey" class="betgameEditSpecialQuestionDetail" id="betgameEditSpecialQuestionDetail">
      
      <NavigationBar>
          <template v-slot:left>
              <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
          </template>
          <template v-slot:center>
            {{ $t('betgame_settings.edit_special_question') }}
          </template>
          <template v-slot:right>
          </template>
      </NavigationBar>
  
      <div v-if="isCreateState" class="input-container">
        <div class="input-header">
          {{ $t('betgame_settings.edit_special_question_select_teamplate_or_create_own') }}
        </div>
        <div class="input-element">
          <select id="select_question" v-model="selectedQuestion" @change="questionSelected($event)">
            <option disabled value="" hidden>{{ $t('betgame_settings.edit_special_question_select_teamplate') }}:</option>
            <option v-for="q in teamtipQuestions" :value="q.id">{{ q.question }}</option>
          </select>
        </div>
      </div>
      
      <div class="input-container">
        <div class="input-header">
          {{ $t('betgame_settings.edit_special_question_text_label') }}
        </div>
        <div class="input-element">
          <input type="text" v-model="questionText" name="nickname_old" id="question" placeholder="Fragestellung" value />
          <label for="question">{{ $t('betgame_settings.edit_special_question_text_placeholder') }}</label>
        </div>
      </div>

      <div class="input-container">
        <div class="input-header">
          {{ $t('betgame_settings.edit_special_question_solution_type_label') }}
        </div>
        <div class="input-radio">
          <input type="radio" id="team" name="answertype" v-model="questionType" v-bind:value="'TEAM'" />
          <label for="team">{{ $t('betgame_settings.edit_special_question_solution_type_team') }}</label>
        </div>
        <!--
        <div class="input-radio">
          <input type="radio" id="player" name="answertype" value="2" />
          <label for="player">Spieler</label>
        </div>
        -->
        <div class="input-radio">
          <input type="radio" id="bool" name="answertype" v-model="questionType" v-bind:value="'BOOL'" />
          <label for="bool">{{ $t('betgame_settings.edit_special_question_solution_type_yes_no') }}</label>
        </div>
        <div class="input-radio">
          <input type="radio" id="number" name="answertype" v-model="questionType" v-bind:value="'NUMBER'" />
          <label for="number">{{ $t('betgame_settings.edit_special_question_solution_type_number') }}</label>
        </div>
      </div>
  
      <div v-if="questionType === 'TEAM'" class="input-container">
        <div class="input-header">
          {{ $t('betgame_settings.edit_special_question_solution_type_team') }}
        </div>
        <div class="input-empty" style="margin-bottom: 20px; color: #5F5D5C;">
          {{ $t('betgame_settings.edit_special_question_solution_type_team_description') }}
        </div>
      </div>
      <div v-else-if="questionType === 'BOOL'" class="input-container">
        <div class="input-header">
          {{ $t('betgame_settings.edit_special_question_solution_type_yes_no') }}
        </div>
        <div class="input-empty" style="margin-bottom: 20px; color: #5F5D5C;">
          {{ $t('betgame_settings.edit_special_question_solution_type_team') }}
        </div>
      </div>
      <div v-else-if="questionType === 'NUMBER'" class="input-container">
        <div class="input-header">
          {{ $t('betgame_settings.edit_special_question_solution_type_number') }}
        </div>
        <div class="input-empty" style="color: #5F5D5C;">
          {{ $t('betgame_settings.edit_special_question_solution_type_number_description') }}
        </div>
        <div class="input-stepper">
          <div @click="minNumberUp" class="input-stepper-button input-stepper-button-up"></div>
          <input id="minNumberUp" min="0" v-model.number="minNumber"
                 step="1" placeholder="-" type="number">
          <div @click="minNumberDown" class="input-stepper-button input-stepper-button-down"></div>
          <label for="minNumberUp">{{ $t('betgame_settings.edit_special_question_solution_type_number_min') }}</label>
        </div>
        <div class="input-stepper">
          <div @click="maxNumberUp" class="input-stepper-button input-stepper-button-up"></div>
          <input id="maxNumberUp" min="0" v-model.number="maxNumber"
                 step="1" placeholder="-" type="number">
          <div @click="maxNumberDown" class="input-stepper-button input-stepper-button-down"></div>
          <label for="maxNumberUp">{{ $t('betgame_settings.edit_special_question_solution_type_number_max') }}</label>
        </div>
      </div>
      
      <div class="input-container">
        <div class="input-header">
          {{ $t('betgame_settings.edit_special_question_points_per_correct_answer') }}
        </div>
        <div class="input-stepper">
          <div @click="pointsUp" class="input-stepper-button input-stepper-button-up"></div>
          <input id="points1" max="99" min="1" v-model.number="points"
                 step="1" placeholder="-" type="number">
          <div @click="pointsDown" class="input-stepper-button input-stepper-button-down"></div>
          <label for="points1">{{ $t('points') }}</label>
        </div>
        
        <!-- Die Abfrage nach der Anzahl der Punkte pro Antwort soll nur angezeigt werden, wenn in der Liste "Antwort(en)" mehr als eine Antwort ausgewählt wurde
        <div class="input-infotext">
          Soll die Anzahl der Punkte für jede richtige Antwortmöglichkeit gelten?
        </div>
        <div class="input-radio">
          <input type="radio" id="yes" name="points" value="1" />
          <label for="yes">Ja</label>
        </div>
        <div class="input-radio">
          <input type="radio" id="no" name="points" value="2" />
          <label for="no">Nein</label>
        </div>
        -->
      </div>
  
      <div class="input-container">
        <div class="input-header">
          {{ $t('betgame_settings.edit_special_question_answer_count') }}
        </div>
        <div class="input-stepper" style="border-bottom: 0">
          <div @click="answerCountUp" class="input-stepper-button input-stepper-button-up"></div>
          <input id="points2" max="99" min="1" v-model.number="answerCount"
                 step="1" placeholder="-" type="number">
          <div @click="answerCountDown" class="input-stepper-button input-stepper-button-down"></div>
          <label for="points2">{{ $t('betgame_settings.edit_special_question_solutions') }}</label>
        </div>
        <div v-if="!this.isCreateState && solutions.length > 0" style="font-size: 15px; color: #5F5D61;">
          <div v-if="solutions.length === 1" style="width: 100%;">
            {{ $t('betgame_settings.edit_special_question_entered_solution') }}:
          </div>
          <div v-else-if="solutions.length > 1" style="width: 100%;">
            {{ $t('betgame_settings.edit_special_question_entered_solutions') }}:
          </div>
          <div v-for="(solution, index) in solutions" style="width: 100%;">
            <div v-if="question.type === 'TEAM'" style="">
              {{ getTeamName(parseInt(solution.solution)) }}
            </div>
            <div v-else-if="question.type === 'NUMBER'">
              {{ solution.solution }}
            </div>
            <div v-else-if="question.type === 'BOOL' && '' + solution.solution === '1'">
              Ja
            </div>
            <div v-else-if="question.type === 'BOOL' && '' + solution.solution === '0'">
              Nein
            </div>
          </div>
        </div>
        <div v-if="!this.isCreateState" class="input-button-row">
          <button v-if="answerCount === 1 && solutions.length === 0" v-on:click="enterSolutions({})" name="button">{{ $t('betgame_settings.edit_special_question_button_enter_solution') }}</button>
          <button v-else-if="answerCount > 1  && solutions.length === 0" v-on:click="enterSolutions({})" name="button">{{ $t('betgame_settings.edit_special_question_button_enter_solutions') }}</button>
          <button v-else-if="answerCount === 1 && solutions.length > 0" v-on:click="enterSolutions({})" name="button">{{ $t('betgame_settings.edit_special_question_button_change_solution') }}</button>
          <button v-else-if="answerCount > 1  && solutions.length > 0" v-on:click="enterSolutions({})" name="button">{{ $t('betgame_settings.edit_special_question_button_change_solutions') }}</button>
        </div>
      </div>
  
      <div class="list">
        <div class="table-view-title">
          {{ $t('betgame_settings.edit_special_question_enter_answer_until') }}
        </div>
        <div class="table-view-cell">
          <datetime v-model="editableUntil" type="datetime" :min-datetime="new Date().toISOString()" :minute-step="5"></datetime>
        </div>
      </div>
  
      <ul class="error-container">
        <li v-for="error in errors">{{ error }}</li>
      </ul>
      
      <button v-on:click="saveQuestion">{{ $t('button_save_text') }}</button>
      
      <button v-on:click="deleteQuestion" v-if="this.question !== undefined && this.question.id > 0 && this.question.fk_betgame === parseInt(this.$store.state.betgameId)" class="button-danger">{{ $t('button_delete_text') }}</button>

    </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import 'vue-multiselect/dist/vue-multiselect.min.css'
  import { Datetime } from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'
  import { MATCH_DATE_FORMAT, TOAST_DURATION } from '../main'
  import moment from 'moment'
  import Vue from 'vue'
  
  export default {
    name: 'BetgameEditSpecialQuestionDetail',
    components: { NavigationBar, datetime: Datetime },
    data () {
      return {
        question: null,
        componentKey: 0,
        questionText: '',
        questionType: '',
        points: 1,
        answerCount: 1,
        minNumber: 0,
        maxNumber: 10,
        editableUntil: new Date(Date.now() + (1000 * 60 * 60 * 24 * 30) ).toISOString(),
        selectedQuestion: '',
        errors: []
      }
    },
    created () {
      this.question = this.$route.params.question
      if(this.question === null || this.question === undefined) {
        this.$router.push('/betgameeditspecialquestions')
      } else {
        if(this.question.id > 0) {
          this.editableUntil = this.question.editable_until
          this.questionText = this.question.question !== undefined ? this.question.question : ''
          this.questionType = this.question.type !== undefined ? this.question.type : ''
          this.points = this.question.points !== undefined ? this.question.points : 1
          this.answerCount = this.question.answer_count !== undefined ? this.question.answer_count : 1
          this.minNumber = 0
          this.maxNumber = 10
          if(this.questionType === 'NUMBER') {
            const opt = this.question.options !== undefined ? this.question.options : ''
            if (opt !== undefined && opt.length > 0) {
              const minmax = opt.trim().split(',')
              if (minmax.length === 2) {
                const minVal = parseInt(minmax[0])
                const maxVal = parseInt(minmax[1])
                this.minNumber = minVal < maxVal ? minVal : maxVal
                this.maxNumber = minVal < maxVal ? maxVal : minVal
              }
            }
          }
        } else {
          this.editableUntil = new Date(Date.now() + (1000 * 60 * 60 * 24 * 30)).toISOString()
          this.questionText = ''
          this.questionType = ''
          this.points = 1
          this.answerCount = 1
          this.minNumber = 0
          this.maxNumber = 10
        }
        this.componentKey += 1
      }
    },
    mounted () {
      if (this.$store.state.teams.length === 0) {
        if(this.$store.state.leagueId !== undefined && this.$store.state.leagueId > 0) {
          this.$store.dispatch('fetchTeams', this.$store.state.leagueId)
        } else if(this.$store.state.betgame.competition !== undefined) {
          this.$store.dispatch('fetchTeams', this.$store.state.betgame.competition.id)
        }
      }
    },
    computed: {
      solutions() {
        if(this.$store.state.solutions && this.question) {
          const solutions = this.$store.state.solutions.filter(s => s.fk_question === this.question.id)
          if (solutions.length > 0) {
            return solutions
          }
        }
        return []
      },
      teamtipQuestions() {
        var q = [
          {"id":13,"question":this.$i18n.t('special_questions.default_special_question_1'),"type":"TEAM","points":10,"answer_count":1,"editable_until":"2030-01-01T08:00:00","options":"<null>","fk_betgame":null,"fk_season":11,"optionsJSON":[]}, 
          {"id":14,"question":this.$i18n.t('special_questions.default_special_question_2'),"type":"TEAM","points":10,"answer_count":1,"editable_until":"2030-01-01T08:00:00","options":"<null>","fk_betgame":null,"fk_season":11,"optionsJSON":[]},
          {"id":48,"question":this.$i18n.t('special_questions.default_special_question_3'),"type":"NUMBER","points":10,"answer_count":1,"editable_until":"2030-01-01T08:00:00","options":"0,2000","fk_betgame":null,"fk_season":11},
          {"id":49,"question":this.$i18n.t('special_questions.default_special_question_4'),"type":"NUMBER","points":10,"answer_count":1,"editable_until":"2030-01-01T08:00:00","options":"0,100","fk_betgame":null,"fk_season":11},
          {"id":50,"question":this.$i18n.t('special_questions.default_special_question_5'),"type":"NUMBER","points":10,"answer_count":1,"editable_until":"2030-01-01T08:00:00","options":"0,100","fk_betgame":null,"fk_season":11}
          ]
        /*
        var r = []
        for (var i = 0; i < this.$store.state.questions.length; i++) {
          const q = this.$store.state.questions[i]
          if (q.fk_betgame === null) {
            r.push(q)
          }
        }
        */
        return q
      },
      isCreateState() {
        return this.question === undefined || this.question.id === undefined || this.question.id === null || this.question.id === 0
      }
    },
    methods: {
      enterSolutions() {
        if(this.question && this.question.id > 0) {
          this.$router.push({ name: 'betgameeditspecialquestionanswers', params: { question: this.question } })
        } else {
        
        }
      },
      pointsUp() {
        if(this.points < 100) {
          this.points += 1
        } else {
          this.points = 100
        }
      },
      pointsDown() {
        if(this.points > 1) {
          this.points -= 1
        }
      },
      answerCountUp() {
        if(this.answerCount < 5) {
          this.answerCount += 1
        } else {
          this.answerCount = 5
        }
      },
      answerCountDown() {
        if(this.answerCount > 1) {
          this.answerCount -= 1
        }
      },
      minNumberUp() {
        if(this.minNumber < 1000) {
          this.minNumber += 1
        } else {
          this.minNumber = 10
        }
      },
      minNumberDown() {
        if(this.minNumber > 0) {
          this.minNumber -= 1
        }
      },
      maxNumberUp() {
        if(this.maxNumber < 1000) {
          this.maxNumber += 1
        } else {
          this.maxNumber = 10
        }
      },
      maxNumberDown() {
        if(this.maxNumber > 1) {
          this.maxNumber -= 1
        }
      },
      deleteQuestion() {
        var r = confirm(this.$i18n.t('betgame_settings.confirm_delete_special_question'));
        if (r === true) {
          this.$store.dispatch('deleteQuestion', this.question.id).then(response => {
            Vue.$log.debug('deleteQuestion ok')
            this.$toast.open({ message: this.$i18n.t('betgame_settings.delete_special_question_success'), position: 'top', duration: TOAST_DURATION, type: 'success' })
            this.$router.push('/betgameeditspecialquestions')
          }, error => {
            Vue.$log.debug('deleteQuestion error ' + error.toString())
          })
        }
      },
      saveQuestion() {
        Vue.$log.debug('saveQuestion')
        this.errors = []
        if(this.isNullOrUndefined(this.questionText) || this.questionText.length === 0) {
          this.errors.push(this.$i18n.t('betgame_settings.special_question_save_error_mssing_question'))
        }
        if(this.isNullOrUndefined(this.questionType) || this.questionType.length === 0) {
          this.errors.push(this.$i18n.t('betgame_settings.special_question_save_error_answer_type_not_selected'))
        }
        if(this.isNullOrUndefined(this.points) || this.points < 1) {
          this.errors.push(this.$i18n.t('betgame_settings.special_question_save_error_mssing_points'))
        }
        if(this.isNullOrUndefined(this.answerCount) || this.answerCount < 1) {
          this.errors.push(this.$i18n.t('betgame_settings.special_question_save_error_mssing_answer_count'))
        }
        if(this.isNullOrUndefined(this.answerCount) || this.answerCount > 5) {
          this.errors.push(this.$i18n.t('betgame_settings.special_question_save_error_max_answer_count'))
        }
        if(this.isNullOrUndefined(this.editableUntil) || (new Date().toISOString() > this.editableUntil) && this.solutions.length === 0) {
          this.errors.push(this.$i18n.t('betgame_settings.special_question_save_error_mssing_editable_util_date'))
        }
        Vue.$log.debug('editableUntil ' + this.question.id + ' --- ' + this.editableUntil)
        Vue.$log.debug('editableUntil ' + moment(this.editableUntil))
        Vue.$log.debug('momformat ' + moment(this.editableUntil).format('YYYY-MM-DD HH:mm:ss'))
        if (this.errors.length === 0) {
          var options = this.question.options
          if(this.questionType === 'NUMBER') {
            options = this.maxNumber > this.minNumber ? '' + this.minNumber + ',' + this.maxNumber :
                    '' + this.maxNumber + ',' + this.minNumber
          } else if(this.questionType === 'TEAM') {
            const teams = this.$store.state.teams
            var result = []
            if (teams.length > 0) {
              for(var i = 0; i < teams.length; i++) {
                const team = teams[i]
                result.push({id: team.id, name: team.name})
              }
            }
            options = JSON.stringify(result)
          } else if(this.questionType === 'BOOL') {
            options = '0,1'
          }
          if(this.question.id > 0) {
            Vue.$log.debug('patch 1')
            const updatequestion = {
              'id': this.question.id,
              'question': this.questionText,
              'type': this.questionType,
              'points': parseInt(this.points),
              'answer_count': parseInt(this.answerCount),
              'editable_until': moment(this.editableUntil).format('YYYY-MM-DD HH:mm:ss'),
              'options': options
            }
            /*
            this.question.question = this.questionText
            this.question.type = this.questionType
            this.question.points = parseInt(this.points)
            this.question.answer_count = parseInt(this.answerCount)
            this.question.editable_until = moment(this.editableUntil).format('YYYY-MM-DD HH:mm:ss')
            */
            Vue.$log.debug('patch ' + JSON.stringify(updatequestion))
            this.$store.dispatch('patchQuestion', updatequestion).then(response => {
              Vue.$log.debug('patchQuestion ok')
              this.$toast.open({ message: this.$i18n.t('betgame_settings.save_special_question_success'), position: 'top', duration: TOAST_DURATION, type: 'success' })
            }, error => {
              Vue.$log.debug('patchQuestion error ' + error.toString())
            })
          } else {
            const insertquestion = {
              'question': this.questionText,
              'type': this.questionType,
              'points': parseInt(this.points),
              'answer_count': parseInt(this.answerCount),
              'fk_betgame': parseInt(this.$store.state.betgameId),
              'editable_until': moment(this.editableUntil).format('YYYY-MM-DD HH:mm:ss'),
              'options': options
            }
            Vue.$log.debug('postQuestion ' + JSON.stringify(insertquestion))
            this.$store.dispatch('postQuestion', insertquestion).then(response => {
              this.question.id = response
              Vue.$log.debug('postQuestion ok ' + JSON.stringify(response))
              this.$toast.open({ message: this.$i18n.t('betgame_settings.save_special_question_success'), position: 'top', duration: TOAST_DURATION, type: 'success' })
            }, error => {
              Vue.$log.debug('postQuestion error ' + error.toString())
            })
          }
        }
      },
      questionSelected(event) {
        const temp = this.teamtipQuestions.filter(q => '' + q.id === '' + event.target.value)
        if (temp.length === 1) {
          this.questionText = temp[0].question
          this.questionType = temp[0].type
          this.points = temp[0].points
          this.answerCount = temp[0].answer_count
          this.componentKey += 1
        }
      },
      goBack () {
        this.$router.push('/betgameeditspecialquestions')
      },
    }
  }
</script>

<style lang="scss" scoped>
  
  .betgameEditSpecialQuestionDetail {
    max-width: 432px;
    margin: 0 auto auto auto;

    @media screen and (min-width: 768px) {
      margin: 48px auto 0;
    }
  }

  .list {
    position: relative;
    padding: 4px 16px;
    margin: 16px auto 0;
    max-width: 400px;
  }

  .input-container {
    margin: 16px auto 0;
  }

  button {
    margin-top: 16px;
  }

  #select_question {
    width: 100%;
    margin: 10px 0px;

  }
  
</style>
