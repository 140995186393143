<template>
    <div class="">

      <div style="background-color: #F1F1F5">
        <table class="table" style="background-color: #F1F1F5">
        </table>
      </div>

      <!--<div>{{ dataObject.name }}</div>-->
      <div v-for="(match, index) in dataObject.matches">

          <div v-if="index === 0 || dataObject.matches[index-1].date !== match.date" class="matchlist-title">
            <div class="matchlist-title-date">
              {{ match.date | formatDate }}
            </div>
            <div class="matchlist-title-time">
              {{ match.date | formatTime }}
            </div>
          </div>

          <div class="matchlist">
            <div class="matchitem">
              <div class="matchitem-home">
                <div class="matchitem-team-logo">
                  <img v-if="match.codeHome && match.codeHome.length > 6" :src="getLogo(match.codeHome)" width="24">
                  <flag v-if="match.codeHome && match.codeHome.length <= 6" :iso="match.codeHome" />
                  <svg v-else-if="match.colorHome" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': match.colorHome }"></path>
                  </svg>
                </div>
                <div class="matchitem-teamname">{{ match.teamHome }}</div>
              </div>
              <div class="matchitem-goals">
                <div class="matchitem-goals-home">
                  {{ match.goalsHome }}
                </div>
                <div class="matchitem-goals-divider">
                  :
                </div>
                <div class="matchitem-goals-guest">
                  {{ match.goalsGuest }}
                </div>
              </div>
              <div class="matchitem-guest">
                <div class="matchitem-teamname">{{ match.teamGuest }}</div>
                <div class="matchitem-team-logo">
                  <img v-if="match.codeGuest && match.codeGuest.length > 6" :src="getLogo(match.codeGuest)" width="24">
                  <flag v-if="match.codeGuest && match.codeGuest.length <= 6" :iso="match.codeGuest" />
                  <svg v-else-if="match.colorGuest" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': match.colorGuest }"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: 'TournamentRound',
    props: {
      dataObject: {
        type: Object,
        default: null
      }
    },
    methods: {
      getLogo(id) {
        return this.getBGValue('spmau') + '/images/jerseys/24/' + id
      }
    }
  }
</script>

<style lang="scss" scoped>
  .matchitem-team-logo {
    img {
      height: inherit;
      margin-bottom: 0;
    }
  }
  
  .table {
    padding: 4px 16px;
    background: $colorWhite;

    td {

      &.col_team_name {
        padding: 10px 0 9px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        white-space: normal;

        span {
          margin: 4px 12px 4px 4px;
          width: 16px;
          height: 16px;
          border-radius: 8px;
          overflow: hidden;
          box-shadow: inset 0 0 0 1px rgba($colorGreyDark, .08);
        }

        img {
          margin-right: 8px;
        }
      }

      &.col_points {
        padding-left: 8px;
      }

      &:first-child {
        width: 28px;
        max-width: 28px;
        padding-left: 8px;
        text-align: center;
      }
    }

    tr:last-child {
      td {
        border-bottom: 0;
      }
    }

    @media screen and (max-width: 767px) {
      .mobile-hidden {
        display: none;
      }
    }

    @media screen and (min-width:768px) {
      table-layout: fixed;

      td, th {
        text-align: center;

        &:first-child {
          text-align: left;
          padding-left: 16px;
          width: 24px;
        }

        &.col_team_name {
          text-align: left;
        }

        &.col_value, &.col_points {
          width: 22px;
          padding-right: 24px;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }
  
</style>
