<template>
   	<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="M11,1 C16.5228475,1 21,5.4771525 21,11 C21,13.2204074 20.2763291,15.2717924 19.0519783,16.9311639 L22.5606602,20.4393398 C23.1464466,21.0251263 23.1464466,21.9748737 22.5606602,22.5606602 C22.0114854,23.109835 21.142423,23.1441584 20.5532622,22.6636304 L20.4393398,22.5606602 L16.9311639,19.0519783 C15.2717924,20.2763291 13.2204074,21 11,21 C5.4771525,21 1,16.5228475 1,11 C1,5.4771525 5.4771525,1 11,1 Z M11,3 C6.581722,3 3,6.581722 3,11 C3,15.418278 6.581722,19 11,19 C12.9036246,19 14.6519564,18.3351121 16.0254352,17.2248964 C16.0791871,16.9361822 16.2171097,16.66157 16.4393398,16.4393398 C16.66157,16.2171097 16.9361822,16.0791871 17.223432,16.0255721 C18.3351121,14.6519564 19,12.9036246 19,11 C19,6.581722 15.418278,3 11,3 Z" id="search"></path>
    </svg>
</template>

<script>
  export default {
    name: 'SearchIcon'
  }
</script>

<style lang="scss" scoped>
	svg {
		fill: var(--color-primary);
	}
</style>