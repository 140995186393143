/* eslint-disable */
<template>
  <div class="rules" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
  
    <keep-alive>
      <NavigationBar id="rulesNavigationBarId" ref="rulesNavigationBarRef">
        <template v-if="!loggedIn" v-slot:left>
          <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
        </template>
        <template v-slot:center>
          {{ $t('menu.rules') }}
        </template>
      </NavigationBar>
    </keep-alive>
    
    <div v-if="betGameRules" v-html="betGameRules" style="margin-top: 20px; margin-bottom:  20px;"></div>
    
    <div v-else>
      
      <!--
      <h1 class="rules_header">{{ $t('rules.place_bet_title') }}:</h1>
      <p>{{ $t('rules.place_bet_paragraph_1') }}</p><br>
      <p>{{ $t('rules.place_bet_paragraph_2') }}</p><br>
      <p v-if="betmode === 1">{{ $t('rules.place_bet_paragraph_3_result') }}</p>
      <p v-if="betmode === 2">{{ $t('rules.place_bet_paragraph_3_toto') }}</p>
      <ul v-if="betmode === 1">
        <li>{{ $t('rules.bet_exact_result') }}: {{ pointsBetResult }} {{ $t('points') }}</li>
        <li>{{ $t('rules.bet_goal_difference') }}: {{ pointsBetGoaldiff }} {{ $t('points') }}</li>
        <li>{{ $t('rules.bet_tendency') }}: {{ pointsBetTendency }} {{ $t('points') }}</li>
      </ul>
  
  
      <h1 class="rules_header">{{ $t('rules.tie_title') }}:</h1>
      
      <p v-if="betmode === 1">{{ $t('rules.tie_paragraph_1') }}</p>
      <p v-if="betmode === 2">{{ $t('rules.tie_paragraph_2') }}</p>
      <br><br>
      -->
    </div>
    
  </div>
</template>

<script>
  
  import NavigationBar from '../components/NavigationBar'
  export default {
    name: 'Rules',
    components: { NavigationBar },
    beforeCreate () {
      if(this.$store.state.betgame) {
        this.$store.dispatch('fetchBetgame', this.$store.state.betgame.id)
      }
    },
    methods: {
      openMenu () {
        this.$modal.show('betgame-menu')
      },
      goBack() {
        if(this.hasHistory()) {
          this.$router.go(-1)
        } else {
          this.$router.push('/')
        }
      }
    },
    computed: {
      loggedIn() {
        return this.$store.getters.loggedIn
      },
      betmode() {
        return this.$store.state.betgame.bet_mode || 1
      },
      pointsBetResult () {
        return this.$store.state.betgame.points_bet_result
      },
      pointsBetGoaldiff () {
        return this.$store.state.betgame.points_bet_goaldiff
      },
      pointsBetTendency () {
        return this.$store.state.betgame.points_bet_tendency
      },
      betGameRules () {
        return this.$store.state.betgame.rules
      },
    }
  }
</script>
<style lang="scss" scoped>
  .rules_header {
    margin-top: 30px;
    margin-bottom: 20px;
  }
</style>
