<template>
  <div class="adminPrivacy" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">

    <NavigationBar>
      <template v-slot:left>
        <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
      </template>
      <template v-slot:center>
        {{ $t('profile.data_protection_admin') }}
      </template>
    </NavigationBar>


    <div v-if="userlang === 'de'">
      <br><br>

      <h1>I. Für den Datenschutz Verantwortlichen</h1>
      <p>Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der
        Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist die unter dem Menü-Punkt
        „Einstellungen“ als Admin der Tipprunde bezeichnete Person (nachfolgend „Admin“). Der Admin kann unter dem
        Menü-Punkt „Einstellungen“ mit einem Klick auf den Button „Admin kontaktieren“ kontaktiert werden.</p>


      <h1>II. Allgemeines zur Datenverarbeitung</h1>
      <h3>1. Umfang der Verarbeitung personenbezogener Daten</h3>
      <p>Der Admin erhebt und verwendet personenbezogene Daten der Tipprundenteilnehmer zur Durchführung einer Tipprunde
        nur nach Einwilligung des Nutzers (Tipprundenteilnehmer). Eine Ausnahme gilt in solchen Fällen, in denen eine
        vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der
        Daten durch gesetzliche Vorschriften gestattet ist.</p>
      <h3>2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h3>
      <p>Soweit für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person eingeholt
        wird, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage für die Verarbeitung
        personenbezogener Daten.</p>
      <p>Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die
        betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch
        für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.</p>
      <p>Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich
        ist, der der Verantwortliche unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.</p>
      <p>Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine
        Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
        Rechtsgrundlage.</p>
      <p>Ist die Verarbeitung zur Wahrung eines berechtigten Interesses des Verantwortlichen oder eines Dritten
        erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte
        Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.</p>

      <h3>3. Datenlöschung und Speicherdauer</h3>
      <p>Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der
        Speicherung entfällt. Dies ist regelmäßig dann der Fall, wenn ein Nutzer die Löschung seiner Daten/seines
        Accounts wünscht. Zudem kann der Nutzer seine editierbaren personenbezogenen Daten selbst verwalten.</p>
      <p>Eine Speicherung kann darüber hinaus dann erfolgen, wenn dies durch den europäischen oder nationalen
        Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche
        unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die
        genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren
        Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.</p>

      <h1>III. Kategorien personenbezogener Daten, die verarbeitet werden</h1>
      <ul>
        <li>Vor- und Nachname</li>
        <li>Benutzername</li>
        <li>E-Mail-Adresse</li>
        <li>Tippverhalten</li>
      </ul>

      <h1>IV. Empfänger oder Kategorien von Empfängern der personenbezogenen Daten</h1>
      <p>Tipprunden-Admin</p>

      <h1>V. Zwecke, für die die personenbezogenen Daten verarbeitet werden sollen, sowie die Rechtsgrundlage für die
        Verarbeitung</h1>

      <p>Die personenbezogenen Daten dienen der Organisation einer Tipprunde. Rechtsgrundlage für die Verarbeitung ist
        Ihre Einwilligung, die Sie durch die freiwillige Bereitstellung Ihrer persönlichen Daten erteilen (Art. 6 Abs. 1
        lit. a DSGVO). Diese Einwilligung kann jederzeit gegenüber dem Admin widerrufen werden, indem in die Felder, die
        nicht befüllt sein sollen oder nicht mehr befüllt werden sollen, mit dem Buchstaben „X“ versehen werden, oder
        durch eine vollständige Abmeldung.“</p>

      <h1>VI. Zugriffsrechte der App</h1>

      <p>Zur Bereitstellung der vollständigen Funktionen über die App werden die nachfolgenden Zugriffsrechte benötigt,
        die es ermöglichen, auf bestimmte Funktionen des Geräts eines Nutzers zuzugreifen:</p>

      <ul>
        <li><b>Gerätenummer Ihres Smartphones.</b> Die Erfassung erfolgt zu folgendem Zweck: Wiedererkennung des
          Smartphones zur Zuordnung bereits vorhandener Daten und ausgewählter Funktionen zum entsprechenden Nutzer und
          zur Vermeidung erneuten Einloggens, um die Anwendung nutzerfreundlich zu gestalten.
        </li>
        <li><b>Internet.</b> Die Erfassung erfolgt zu folgendem Zweck: Verbindung mit und zu Internetdiensten</li>
        <li><b>Netzwerkstatus.</b> Die Erfassung erfolgt zu folgendem Zweck: Erfassung der Verbindungsqualität</li>
      </ul>

      <p>Die Zugriffsrechte werden benötigt, um Ihnen die Nutzung bestimmter Funktionen der App zu ermöglichen und die
        Anwendung nutzerfreundlich zu gestalten.</p>

      <p>Rechtsgrundlage für den Zugriff ist Ihre Einwilligung, die Sie im Rahmen der Installation und der Einstellung
        der Zugriffsrechte abgegeben haben (Art. 6 Abs. 1 lit. a DSGVO).</p>

      <h1>VII. Rechte der betroffenen Person</h1>

      <p>Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen
        folgende Rechte gegenüber dem Verantwortlichen zu:</p>

      <h3>1. Auskunftsrecht</h3>
      <p>Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie
        betreffen, von uns verarbeitet werden.</p>
      <p>Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen Auskunft
        verlangen:</p>
      <br>
      <p>(1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</p>
      <p>(2) die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</p>
      <p>(3) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen
        Daten offengelegt wurden oder noch offengelegt werden;</p>
      <p>(4) die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete
        Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;</p>
      <p>(5) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten,
        eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen
        diese Verarbeitung;</p>
      <p>(6) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</p>
      <p>(7) alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der
        betroffenen Person erhoben werden;</p>
      <p>(8) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4
        DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die
        Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.</p>
      <br>
      <p>Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein
        Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie
        verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu
        werden.</p>


      <h3>2. Recht auf Berichtigung</h3>
      <p>Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die
        verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche
        hat die Berichtigung unverzüglich vorzunehmen.</p>

      <h3>3. Recht auf Einschränkung der Verarbeitung</h3>
      <p>Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden
        personenbezogenen Daten verlangen:</p>
      <br>
      <p>(1) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem
        Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;</p>
      <p>(2) die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen
        die Einschränkung der Nutzung der personenbezogenen Daten verlangen;</p>
      <p>(3) der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie
        diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder</p>
      <p>(4) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht
        feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.</p>
      <br>

      <p>Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von
        ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen
        eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.</p>
      <p>Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem
        Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.</p>

      <h3>4. Recht auf Löschung</h3>

      <h5>a) Löschungspflicht</h5>
      <p>Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich
        gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der
        folgenden Gründe zutrifft:</p>
      <br>
      <p>(1) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige
        Weise verarbeitet wurden, nicht mehr notwendig.</p>
      <p>(2) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs.
        2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.</p>
      <p>(3) Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen
        berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die
        Verarbeitung ein.</p>
      <p>(4) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</p>
      <p>(5) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung
        nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.</p>
      <p>(6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
        Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.</p>

      <h5>b) Information an Dritte</h5>
      <p>Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17
        Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und
        der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung
        Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene
        Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen
        dieser personenbezogenen Daten verlangt haben.</p>

      <h5>c) Ausnahmen</h5>
      <p>Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</p>
      <br>
      <p>(1) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</p>
      <p>(2) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der
        Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im
        öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen
        wurde;</p>
      <p>(3) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h
        und i sowie Art. 9 Abs. 3 DSGVO;</p>
      <p>(4) für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke
        oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte Recht
        voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt,
        oder</p>
      <p>(5) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>


      <h3>5. Recht auf Unterrichtung</h3>
      <p>Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem
        Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden
        personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der
        Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen
        Aufwand verbunden.</p>
      <p>Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.</p>

      <h3>6. Recht auf Datenübertragbarkeit</h3>
      <p>Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt
        haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht
        diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die
        personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern</p>
      <br>
      <p>(1) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO
        oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und</p>
      <p>(2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</p>
      <br>
      <p>In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen
        Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch
        machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.</p>
      <p>Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die
        Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher
        Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.</p>


      <h3>7. Widerspruchsrecht</h3>
      <p>Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die
        Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO
        erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.</p>
      <p>Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann
        zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
        überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
      <p>Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das
        Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke
        derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in
        Verbindung steht.</p>
      <p>Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden
        personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.</p>
      <p>Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft –
        ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei
        denen technische Spezifikationen verwendet werden.</p>

      <h3>8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h3>
      <p>Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den
        Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
        Verarbeitung nicht berührt.</p>

      <h3>9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling</h3>
      <p>Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich
        Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder
        Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung</p>
      <br>
      <p>(1) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich
        ist,</p>
      <p>(2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt,
        zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie
        Ihrer berechtigten Interessen enthalten oder
      </p>
      <p>(3) mit Ihrer ausdrücklichen Einwilligung erfolgt.</p>
      <br>
      <p>Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs.
        1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g gilt und angemessene Maßnahmen zum Schutz der Rechte
        und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.</p>
      <p>Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte
        und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des
        Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung
        der Entscheidung gehört.</p>

      <h3>10. Recht auf Beschwerde bei einer Aufsichtsbehörde</h3>
      <p>Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht
        auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres
        Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung
        der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.</p>
      <p>Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den
        Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach
        Art. 78 DSGVO.</p>

      <br><br>
      <p>
        Stand: Februar 2021
      </p>
      <br><br>

    </div>

    <div v-else>
      <br><br>

      <h1>I. For the data protection officer</h1>
      <p>The person responsible within the meaning of the General Data Protection Regulation and other national data
        protection laws of the member states as well as other data protection regulations is the person designated as
        Admin of the betting community under the menu item "Settings" (hereinafter "Admin"). The Admin can be contacted
        under the menu item "Settings" by clicking on the button "Contact Admin".</p>


      <h1>II. General information on data processing</h1>
      <h3>1. Scope of the processing of personal data</h3>
      <p>The admin collects and uses personal data of the betting community participants for the purpose of conducting a
        betting community only with the consent of the user (betting community participant). An exception applies in
        those cases in which it is not possible to obtain prior consent for actual reasons and the processing of the
        data is permitted by legal regulations.</p>
      <h3>2. Legal basis for the processing of personal data</h3>
      <p>Insofar as consent of the data subject is obtained for processing operations of personal data, Article 6(1)(a)
        of the EU General Data Protection Regulation (GDPR) serves as the legal basis for the processing of personal
        data.</p>
      <p>When processing personal data that is necessary for the performance of a contract to which the data subject is
        a party, Art. 6 (1) (b) DSGVO serves as the legal basis. This also applies to processing operations that are
        necessary for the performance of pre-contractual measures.</p>
      <p>Insofar as processing of personal data is necessary for compliance with a legal obligation to which the
        controller is subject, Art. 6 (1) c DSGVO serves as the legal basis.</p>
      <p>In the event that vital interests of the data subject or another natural person make processing of personal
        data necessary, Art. 6 (1) (d) DSGVO serves as the legal basis.</p>
      <p>If the processing is necessary to protect a legitimate interest of the controller or a third party and the
        interests, fundamental rights and freedoms of the data subject do not override the first-mentioned interest,
        Art. 6 (1) (f) DSGVO serves as the legal basis for the processing.</p>

      <h3>3. Deletion of data and storage period</h3>
      <p>The personal data of the data subject will be deleted or blocked as soon as the purpose of the storage no
        longer applies. This is regularly the case when a user requests the deletion of his/her data/account. In
        addition, users can manage their editable personal data themselves.</p>
      <p>Furthermore, storage may take place if this has been provided for by the European or national legislator in
        Union regulations, laws or other provisions to which the responsible party is subject. Data will also be blocked
        or deleted if a storage period prescribed by the aforementioned standards expires, unless there is a necessity
        for the continued storage of the data for the conclusion or performance of a contract.</p>

      <h1>III. Categories of personal data processed</h1>
      <ul>
        <li>First and last name</li>
        <li>Username</li>
        <li>E-mail address</li>
        <li>Typing behaviour</li>
      </ul>

      <h1>IV. Recipients or categories of recipients of the personal data</h1>
      <p>Betting Round Admin</p>

      <h1>V. Purposes for which the personal data are to be processed and the legal basis for the processing</h1>

      <p>The personal data is used for the organisation of a betting community. The legal basis for the processing is
        your consent, which you give by voluntarily providing your personal data (Art. 6 para. 1 lit. a DSGVO). This
        consent can be revoked at any time vis-à-vis the Admin by adding the letter "X" to the fields that should not be
        filled or should no longer be filled, or by logging out completely."</p>

      <h1>VI. App access rights</h1>

      <p>To provide full functionality through the App, the following access rights are required to access specific
        functions of a user's device:</p>

      <ul>
        <li><b>Device number of your smartphone.</b> This is collected for the following purpose: to recognise the
          smartphone in order to associate existing data and selected functions with the corresponding user and to avoid
          logging in again in order to make the app user-friendly.
        </li>
        <li><b>Internet.</b> This data is collected for the following purpose: connection to and from Internet services.
        </li>
        <li><b>Network status</b> collected for the following purpose: to record the quality of the connection.</li>
      </ul>

      <p>Access rights are required to enable you to use certain functions of the app and to make the app
        user-friendly.</p>

      <p>The legal basis for the access is your consent, which you gave in the context of the installation and the
        setting of the access rights (Art. 6 para. 1 lit. a DSGVO).</p>

      <h1>VII. Rights of the data subject</h1>

      <p>If your personal data is processed, you are a data subject within the meaning of the GDPR and you have the
        following rights vis-à-vis the controller:</p>

      <h3>1. Right of access</h3>
      <p>You may request confirmation from the controller as to whether personal data relating to you is being processed
        by us.</p>
      <p>If such processing is taking place, you may request the controller to provide you with the following
        information:</p>
      <br>
      <p>(1) the purposes for which the personal data are processed;</p>
      <p>(2) the categories of personal data which are processed;</p>
      <p>(3) the recipients or categories of recipients to whom the personal data concerning you have been or will be
        disclosed;</p>
      <p>(4) the envisaged duration of the storage of the personal data concerning you or, if specific information on
        this is not possible, criteria for determining the storage period;</p>
      <p>(5) the existence of a right to obtain the rectification or erasure of personal data concerning you, a right to
        obtain the restriction of processing by the controller or a right to object to such processing;</p>
      <p>(6) the existence of a right of appeal to a supervisory authority;</p>
      <p>(7) any available information on the origin of the data, if the personal data are not collected from the data
        subject;</p>
      <p>(8) the existence of automated decision-making, including profiling, pursuant to Article 22(1) and (4) of the
        GDPR and, at least in those cases, meaningful information about the logic involved and the scope and intended
        effects of such processing for the data subject.</p>
      <br>
      <p>You have the right to request information on whether personal data concerning you are transferred to a third
        country or to an international organisation. In this context, you may request to be informed about the
        appropriate safeguards pursuant to Article 46 of the GDPR in connection with the transfer.</p>


      <h3>2. Right of rectification</h3>
      <p>You have a right of rectification and/or completion vis-à-vis the controller if the personal data processed
        concerning you are inaccurate or incomplete. The controller must make the rectification without delay.</p>

      <h3>3. Right to restriction of processing</h3>
      <p>You may request the restriction of the processing of personal data concerning you under the following
        conditions:</p>
      <br>
      <p>(1) if you contest the accuracy of the personal data concerning you for a period enabling the controller to
        verify the accuracy of the personal data;</p>
      <p>(2) the processing is unlawful and you object to the erasure of the personal data and request instead the
        restriction of the use of the personal data;</p>
      <p>(3) the controller no longer needs the personal data for the purposes of the processing but you need it for the
        establishment, exercise or defence of legal claims; or</p>
      <p>(4) if you have objected to the processing pursuant to Article 21(1) DSGVO and it is not yet clear whether the
        controller's legitimate grounds override your grounds.</p>
      <br>

      <p>Where the processing of personal data relating to you has been restricted, such data may be processed, except
        for storage, only with your consent or for the establishment, exercise or defence of legal claims or for the
        protection of the rights of another natural or legal person or for reasons of substantial public interest of the
        Union or a Member State.</p>
      <p>If the restriction of processing has been restricted in accordance with the above conditions, you will be
        informed by the controller before the restriction is lifted.</p>

      <h3>4. Right to erasure</h3>

      <h5>a) Obligation to erase</h5>
      <p>You may request the controller to erase the personal data concerning you without undue delay and the controller
        is obliged to erase such data without undue delay if one of the following reasons applies:</p>
      <br>
      <p>(1) The personal data concerning you are no longer necessary for the purposes for which they were collected or
        otherwise processed.</p>
      <p>(2) You withdraw your consent on which the processing was based pursuant to Art. 6 (1) a or Art. 9 (2) a DSGVO
        and there is no other legal basis for the processing.</p>
      <p>(3) You object to the processing pursuant to Article 21(1) of the GDPR and there are no overriding legitimate
        grounds for the processing, or you object to the processing pursuant to Article 21(2) of the GDPR.</p>
      <p>(4) The personal data concerning you have been processed unlawfully.</p>
      <p>(5) The erasure of the personal data concerning you is necessary for compliance with a legal obligation under
        Union or Member State law to which the controller is subject.</p>
      <p>(6) The personal data concerning you have been collected in relation to information society services offered in
        accordance with Article 8(1) of the GDPR.</p>

      <h5>b) Information to third parties</h5>
      <p>If the controller has made the personal data concerning you public and is obliged to erase it pursuant to
        Article 17(1) of the GDPR, it shall take reasonable steps, including technical measures, having regard to the
        available technology and the cost of implementation, to inform data controllers which process the personal data
        that you, as the data subject, have requested that they erase all links to or copies or replications of such
        personal data.</p>

      <h5>c) Exceptions</h5>
      <p>The right to erasure does not apply to the extent that the processing is necessary</p>
      <br>
      <p>(1) for the exercise of the right to freedom of expression and information;</p>
      <p>(2) for compliance with a legal obligation which requires processing under Union or Member State law to which
        the controller is subject, or for the performance of a task carried out in the public interest or in the
        exercise of official authority vested in the controller;</p>
      <p>(3) for reasons of public interest in the area of public health pursuant to Article 9(2)(h) and (i) and Article
        9(3) of the GDPR;</p>
      <p>(4) for archiving purposes in the public interest, scientific or historical research purposes or statistical
        purposes pursuant to Article 89(1) of the GDPR, insofar as the right referred to in section (a) is likely to
        render impossible or seriously prejudice the achievement of the purposes of such processing; or</p>
      <p>(5) for the assertion, exercise or defence of legal claims.</p>


      <h3>5. Right to information</h3>
      <p>If you have exercised the right to rectification, erasure or restriction of processing against the controller,
        the controller is obliged to inform all recipients to whom the personal data concerning you have been disclosed
        of this rectification or erasure of the data or restriction of processing, unless this proves impossible or
        involves a disproportionate effort.</p>
      <p>You have the right to be informed about these recipients by the data controller.</p>

      <h3>6. Right to data portability</h3>
      <p>You have the right to receive the personal data concerning you that you have provided to the controller in a
        structured, common and machine-readable format. You also have the right to transfer this data to another
        controller without hindrance from the controller to whom the personal data has been provided, provided that.</p>
      <br>
      <p>(1) the processing is based on consent pursuant to Art. 6 para. 1 lit. a DSGVO or Art. 9 para. 2 lit. a DSGVO
        or on a contract pursuant to Art. 6 para. 1 lit. b DSGVO and</p>
      <p>(2) the processing is carried out with the aid of automated procedures.</p>
      <br>
      <p>In exercising this right, you also have the right to have the personal data concerning you transferred directly
        from one controller to another controller, insofar as this is technically feasible. This must not affect the
        freedoms and rights of other persons.</p>
      <p>The right to data portability does not apply to the processing of personal data necessary for the performance
        of a task carried out in the public interest or in the exercise of official authority vested in the
        controller.</p>


      <h3>7. Right of objection</h3>
      <p>You have the right to object at any time, on grounds relating to your particular situation, to the processing
        of personal data concerning you which is carried out on the basis of Article 6(1)(e) or (f) DSGVO; this also
        applies to profiling based on these provisions.</p>
      <p>The controller shall no longer process the personal data concerning you unless it can demonstrate compelling
        legitimate grounds for the processing which override your interests, rights and freedoms, or the processing
        serves the purpose of asserting, exercising or defending legal claims.</p>
      <p>If the personal data concerning you is processed for the purposes of direct marketing, you have the right to
        object at any time to the processing of personal data concerning you for the purposes of such marketing; this
        also applies to profiling insofar as it is related to such direct marketing.</p>
      <p>If you object to the processing for direct marketing purposes, the personal data concerning you will no longer
        be processed for these purposes.</p>
      <p>You have the possibility, in connection with the use of information society services, notwithstanding Directive
        2002/58/EC, to exercise your right to object by means of automated procedures using technical
        specifications.</p>

      <h3>8. Right to revoke the declaration of consent under data protection law</h3>
      <p>You have the right to revoke your declaration of consent under data protection law at any time. The revocation
        of the consent does not affect the lawfulness of the processing carried out on the basis of the consent until
        the revocation.</p>

      <h3>9. Automated decision in individual cases including profiling</h3>
      <p>You have the right not to be subject to a decision based solely on automated processing - including profiling -
        which produces legal effects concerning you or similarly significantly affects you. This does not apply if the
        decision</p>
      <br>
      <p>(1) is necessary for the conclusion or performance of a contract between you and the controller,</p>
      <p>(2) is permissible on the basis of legal provisions of the Union or the Member States to which the controller
        is subject and these legal provisions contain appropriate measures to safeguard your rights and freedoms as well
        as your legitimate interests; or</p>
      <p>(3) is made with your express consent.</p>
      <br>
      <p>However, these decisions may not be based on special categories of personal data pursuant to Art. 9(1) of the
        GDPR, unless Art. 9(2)(a) or (g) applies and appropriate measures have been taken to protect the rights and
        freedoms as well as your legitimate interests.</p>
      <p>With regard to the cases referred to in (1) and (3), the controller shall take reasonable steps to safeguard
        the rights and freedoms as well as your legitimate interests, including at least the right to obtain the
        intervention of a person on the part of the controller, to express your point of view and to contest the
        decision.</p>

      <h3>10. Right to complain to a supervisory authority</h3>
      <p>Without prejudice to any other administrative or judicial remedy, you have the right to lodge a complaint with
        a supervisory authority, in particular in the Member State of your residence, place of work or the place of the
        alleged infringement, if you consider that the processing of personal data concerning you infringes the
        GDPR.</p>
      <p>The supervisory authority to which the complaint has been lodged shall inform the complainant of the status and
        outcome of the complaint, including the possibility of a judicial remedy under Article 78 GDPR.</p>

      <br><br>
      <p>
        Current as of: Feb 2021
      </p>
      <br><br>

    </div>

  </div>
</template>

<script>
import NavigationBar from '../components/NavigationBar'

export default {
  name: 'BetgameAdminPrivacy',
  components: { NavigationBar },
  computed: {
    userlang () {
      return this.$store.state.language
    }
  },
  methods: {
    goBack () {
      if (this.$store.getters.loggedIn) {
        this.$router.push({ name: 'profile' }).catch(err => {
        })
      } else {
        this.$router.go(-1)
      }
    }
  }
}

</script>

<style lang="scss" scoped>
h1 {
  margin-top: 30px;
}

h3 {
  font-weight: normal;
}

h5 {
  font-weight: normal;
}

p {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
